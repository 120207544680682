import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useNotification } from "hooks/useNotification";
import { TCustomForm } from "../types";

export const createForm = ({ data }: { data: TCustomForm }) => {
  console.log("createForm", data);
  return axios.post("/forms", data);
};

export const useCreateForm = () => {
  const { addNotification } = useNotification();
  const navigate = useNavigate();

  return useMutation({
    onMutate: async (newForm: { data: TCustomForm }) => {
      await queryClient.cancelQueries("forms");

      const previousForm = queryClient.getQueryData<any>("forms");

      queryClient.setQueryData("forms", [
        ...(previousForm || []),
        newForm.data,
      ]);

      return { previousForm };
    },
    onError: (_, __, context: any) => {
      if (context?.previousForm) {
        queryClient.setQueryData("forms", context.previousForm);
      }

      addNotification({
        disallowClose: false,
        type: "error",
        title: "Could not create form!",
        message: "The form could not be created at this time, please try again",
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("forms");
      addNotification({
        disallowClose: false,
        type: "success",
        title: "Form Created",
        message: "Form Created Successfully!",
      });
      //   navigate("/forms");
    },
    mutationFn: createForm,
  });
};
