import { useMutation } from "react-query";

import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useNotification } from "hooks/useNotification";
import { RoleType } from "../types";

export type UpdateRoleType = {
  data: RoleType & { users: Array<string> };
  roleId: string;
};

export const updateRole = ({ data, roleId }: UpdateRoleType): Promise<RoleType> => {
  return axios.put(`/roles/${roleId}`, data);
};

export const useUpdateRole = () => {
  const { addNotification } = useNotification();

  return useMutation({
    onMutate: async (updatingRole: any) => {
      await queryClient.cancelQueries(["role", updatingRole?.roleId]);

      const previousRole = queryClient.getQueryData<RoleType>(["role", updatingRole?.roleId]);

      queryClient.setQueryData(["role", updatingRole?.roleId], {
        ...previousRole,
        ...updatingRole.data,
      });

      return { previousRole };
    },
    onError: (_, __, context: any) => {
      if (context?.previousRole) {
        queryClient.setQueryData(["role", context.previousRole.id], context.previousRole);
      }
    },
    onSuccess: (data) => {
      queryClient.refetchQueries(["role", data.id]);
      addNotification({
        disallowClose: true,
        type: "success",
        title: "Role Updated",
        message: "",
      });
    },
    mutationFn: updateRole,
  });
};
