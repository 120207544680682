import { DateTime } from "luxon";

export const isWeekend = (date: DateTime | number) => {
  // defined weekend from luxon weekday values, 1 is Monday and 7 is Sunday
  const weekend = [6, 7];

  if (typeof date === "number") {
    date = DateTime.fromSeconds(date);

    const dayOfWeek = date.weekday;

    if (weekend.includes(dayOfWeek)) {
      return true;
    }

    return false;
  } else {
    const dayOfWeek = date.weekday;

    if (weekend.includes(dayOfWeek)) {
      return true;
    }

    return false;
  }
};
