import { useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { PasswordInput, Button } from "@mantine/core";
import { z } from "zod";

import { useAuth } from "stores/Auth";
import { supabase } from "lib/supabase";
import logo from "assets/supported_logo.png";

export const Reset = () => {
  const [formErrors, setFormErrors] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [newPassword, setNewPassword] = useState<string | undefined>(undefined);
  const [confirmedPassword, setConfirmedPassword] = useState<
    string | undefined
  >(undefined);
  const [resetSuccess, setResetSuccess] = useState<boolean>(false);

  useEffect(() => {
    supabase.auth.onAuthStateChange(async (event, session) => {
      console.log("onAuthStateChange", event, session);
      if (!session) {
        setFormErrors([
          {
            message:
              "Your email link is invalid, please request a new email link from the 'Forgot Password' page and try again.",
          },
        ]);
      }
    });
  }, []);

  const RoleForm = z
    .object({
      newPassword: z
        .string({ required_error: "Password is required" })
        .min(8, "Password must be a minimum of 8 characters"),
      confirmedPassword: z
        .string({ required_error: "Password is required" })
        .min(8, "Password must be a minimum of 8 characters"),
    })
    .refine((data) => data.newPassword === data.confirmedPassword, {
      message: "Passwords don't match",
      path: ["confirmedPassword"],
    });

  const handleNewPassword = async () => {
    setFormErrors([]);
    setLoading(true);
    try {
      RoleForm.parse({
        newPassword: newPassword,
        confirmedPassword: confirmedPassword,
      });

      const { data, error } = await supabase.auth.updateUser({
        password: newPassword,
      });

      if (error) {
        console.log("handleNewPassword error: ", data, error);
        if (error.name === "AuthSessionMissingError") {
          console.log(
            "handleNewPassword error: ",
            data,
            error.name,
            formErrors
          );
          setFormErrors({
            formErrors: [
              "You are missing an authorised session, please request a new password reset email.",
            ],
          });
        } else {
          setFormErrors({
            formErrors: [
              "There was an error resetting your password, please try again.",
            ],
          });

          setLoading(false);

          return;
        }
      }

      setLoading(false);
      setResetSuccess(true);
    } catch (error: any) {
      console.log("error resetting password: ", error.flatten());
      setFormErrors(error.flatten());
      setLoading(false);
    }
  };

  return (
    <div className="flex h-screen w-screen">
      <div className="flex flex-1 items-center justify-center">
        <div className="max-w-sm space-y-4">
          <div className="flex flex-1 justify-center">
            <img src={logo} alt="Supported Logo" className="h-24" />
          </div>
          {resetSuccess ? (
            <>
              <div className="space-y-2">
                <p className="text-center text-2xl font-semibold text-gray-900">
                  Password reset
                </p>
                <p className="text-center font-medium text-gray-500">
                  Your password has been successfully reset. Click below to log
                  in to the application.
                </p>
              </div>
              <div className="flex flex-col space-y-3">
                <NavLink
                  className="flex h-[32px] items-center justify-center rounded bg-none font-semibold text-jira-button"
                  to="/"
                >
                  Continue
                </NavLink>
              </div>
            </>
          ) : (
            <>
              <div className="space-y-2">
                <p className="text-center text-2xl font-semibold text-gray-900">
                  Set new password
                </p>
                {formErrors.length > 0 ? (
                  <div className="max-w-sm rounded-md border border-red-700 bg-red-100">
                    <div className="p-2">
                      <p className="text-center font-medium text-red-700">
                        {formErrors[0].message}
                      </p>
                    </div>
                  </div>
                ) : (
                  <p className="text-center font-medium text-gray-500">
                    Your new password must be different from your previously
                    used passwords
                  </p>
                )}
              </div>
              {formErrors.length < 1 && (
                <div className="space-y-2">
                  <PasswordInput
                    disabled={loading}
                    label="Password"
                    value={newPassword}
                    error={
                      formErrors.fieldErrors?.newPassword &&
                      formErrors.fieldErrors?.newPassword[0]
                    }
                    onChange={(e) => setNewPassword(e.currentTarget.value)}
                  />
                  <PasswordInput
                    disabled={loading}
                    label="Confirm password"
                    value={confirmedPassword}
                    error={
                      formErrors.fieldErrors?.confirmedPassword &&
                      formErrors.fieldErrors?.confirmedPassword[0]
                    }
                    onChange={(e) =>
                      setConfirmedPassword(e.currentTarget.value)
                    }
                  />
                </div>
              )}
              <div className="flex flex-col space-y-3">
                {formErrors.length < 1 && (
                  <Button
                    loading={loading}
                    className="bg-jira-button hover:bg-jira-button-hover"
                    onClick={(e: any) => handleNewPassword()}
                  >
                    Reset Password
                  </Button>
                )}
                {formErrors.length > 0 && (
                  <NavLink
                    className="flex h-[32px] items-center justify-center rounded bg-none font-semibold text-jira-button"
                    to="/auth/forgot"
                  >
                    Forgot password?
                  </NavLink>
                )}

                <NavLink
                  className="flex h-[32px] items-center justify-center rounded bg-none font-semibold text-jira-button"
                  to="/auth/login"
                >
                  Back to login
                </NavLink>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
