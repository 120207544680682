import create from "zustand";

import { DateTime } from "luxon";
import { useNotification } from "hooks/useNotification";

type TRecordStore = {
  useNotify: any;
  timezone: string;
  currentDate: DateTime;
  medicationInformation: any;
  setMedicationInformation: (information: any) => void;
  updateInformation: any;
  setUpdateInformation: (information: any) => void;
  updateCountInformation: any;
  setUpdateCountInformation: (information: any) => void;
  updateReturnInformation: any;
  setUpdateReturnInformation: (information: any) => void;
  updateReceivedInformation: any;
  setUpdateReceivedInformation: (information: any) => void;
  selectedDate: any;
  setSelectedDate: (date: any) => void;
  selectedRow: number;
  setSelectedRow: (row: number) => void;
  selectedMedication: any;
  setSelectedMedication: (medication: any) => void;
  isCreateRecordModalOpen: boolean;
  setIsCreateRecordModalOpen: (open: boolean) => void;
  isUpdateRecordModalOpen: boolean;
  setIsUpdateRecordModalOpen: (open: boolean) => void;
  isCreateCountModalOpen: boolean;
  setIsCreateCountModalOpen: (open: boolean) => void;
  isUpdateCountModalOpen: boolean;
  setIsUpdateCountModalOpen: (open: boolean) => void;
  isCreateReturnModalOpen: boolean;
  setIsCreateReturnModalOpen: (open: boolean) => void;
  isUpdateReturnModalOpen: boolean;
  setIsUpdateReturnModalOpen: (open: boolean) => void;
  isCreateReceivedModalOpen: boolean;
  setIsCreateReceivedModalOpen: (open: boolean) => void;
  isUpdateReceivedModalOpen: boolean;
  setIsUpdateReceivedModalOpen: (open: boolean) => void;
};

export const useRecordStore = create<TRecordStore>((set, get) => ({
  useNotify() {
    const types = {
      futureRecord: {
        message: "You cannot add a medication record that would occur in the future",
      },
      record: {
        message: `You cannot add a medication record outside of the medication record date range`,
      },
      futureEnd: {
        message: "You cannot add a medication count that would occur in the future",
      },
      end: {
        message: `You cannot add a medication count outside of the medication record date range`,
      },
      futureReturn: {
        message: "You cannot add a medication return that would occur in the future",
      },
      return: {
        message: `You cannot add a medication return outside of the medication record date range`,
      },
      futureReceived: {
        message: "You cannot add a medication received that would occur in the future",
      },
      received: {
        message: `You cannot add a medication received outside of the medication record date range`,
      },
    };

    const { addNotification } = useNotification();

    const notify = (type: any) =>
      addNotification({
        disallowClose: false,
        type: "warning",
        title: "Not possible",
        message: types[type as keyof typeof types].message,
      });

    return { notify };
  },

  timezone: "Europe/London",

  currentDate: DateTime.now().setZone("Europe/London"),

  medicationInformation: {},
  setMedicationInformation: (information: any) => set(() => ({ medicationInformation: information })),

  updateInformation: null,
  setUpdateInformation: (information: any) => set(() => ({ updateInformation: information })),

  updateCountInformation: null,
  setUpdateCountInformation: (information: any) => set(() => ({ updateCountInformation: information })),

  updateReturnInformation: null,
  setUpdateReturnInformation: (information: any) => set(() => ({ updateReturnInformation: information })),

  updateReceivedInformation: null,
  setUpdateReceivedInformation: (information: any) => set(() => ({ updateReceivedInformation: information })),

  selectedDate: 0,
  setSelectedDate: (date: any) => set(() => ({ selectedDate: date })),

  selectedRow: 1,
  setSelectedRow: (row: number) => set(() => ({ selectedRow: row })),

  selectedMedication: { id: 0 },
  setSelectedMedication: (medication: any) => set(() => ({ selectedMedication: medication })),

  isCreateRecordModalOpen: false,
  setIsCreateRecordModalOpen: (open: boolean) => set(() => ({ isCreateRecordModalOpen: open })),

  isUpdateRecordModalOpen: false,
  setIsUpdateRecordModalOpen: (open: boolean) => set(() => ({ isUpdateRecordModalOpen: open })),

  isCreateCountModalOpen: false,
  setIsCreateCountModalOpen: (open: boolean) => set(() => ({ isCreateCountModalOpen: open })),

  isUpdateCountModalOpen: false,
  setIsUpdateCountModalOpen: (open: boolean) => set(() => ({ isUpdateCountModalOpen: open })),

  isCreateReturnModalOpen: false,
  setIsCreateReturnModalOpen: (open: boolean) => set(() => ({ isCreateReturnModalOpen: open })),

  isUpdateReturnModalOpen: false,
  setIsUpdateReturnModalOpen: (open: boolean) => set(() => ({ isUpdateReturnModalOpen: open })),

  isCreateReceivedModalOpen: false,
  setIsCreateReceivedModalOpen: (open: boolean) => set(() => ({ isCreateReceivedModalOpen: open })),

  isUpdateReceivedModalOpen: false,
  setIsUpdateReceivedModalOpen: (open: boolean) => set(() => ({ isUpdateReceivedModalOpen: open })),
}));
