import { useState } from "react";
import {
  Button,
  Checkbox,
  LoadingOverlay,
  NumberInput,
  TextInput,
} from "@mantine/core";
import { z } from "zod";
import { useNavigate } from "react-router-dom";

import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { useCreateRole } from "../api/createRole";
import { ColorPicker } from "components/ColorPicker/ColorPicker";
import { UsersSelect } from "features/area/components/UsersSelect";

const crumbs = [
  { title: "Payroll Dashboard", href: "/payroll" },
  { title: "Roles", href: "/payroll/roles" },
  { title: "Add Role", href: "#" },
];

export const CreateRole = () => {
  const navigate = useNavigate();

  const createRoleMutation = useCreateRole(() => navigate(-1));

  const [name, setName] = useState<string | undefined>(undefined);
  const [payCode, setPayCode] = useState<string | undefined>(undefined);
  const [colour, setColour] = useState<string>("");
  const [defaultBreak, setDefaultBreak] = useState<number | undefined>(
    undefined
  );
  const [shiftsClash, setShiftsClash] = useState<boolean>(false);
  const [isOnCall, setIsOnCall] = useState<boolean>(false);
  const [flatRateWeekday, setFlatRateWeekday] = useState<number | undefined>(
    undefined
  );
  const [flatRateWeekend, setFlatRateWeekend] = useState<number | undefined>(
    undefined
  );
  const [isSleep, setIsSleep] = useState<boolean>(false);
  const [sleepFlatRateWeekday, setSleepFlatRateWeekday] = useState<
    number | undefined
  >(undefined);
  const [sleepFlatRateWeekend, setSleepFlatRateWeekend] = useState<
    number | undefined
  >(undefined);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [formErrors, setFormErrors] = useState<any>([]);

  const RoleForm = z.object({
    name: z
      .string({ required_error: "Role name is required" })
      .min(1, "Role name is required"),
    colour: z.string().min(1, "Role colour is required"),
    payCode: z.string().nullish(),
    isOnCall: z.boolean(),
  });

  const postRole = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      RoleForm.parse({
        name: name,
        colour: colour,
        payCode: payCode,
        isOnCall: isOnCall,
      });

      setFormErrors([]);

      const data = {
        name: name,
        colour: colour,
        pay_code: payCode,
        is_on_call: isOnCall,
        flat_rate_weekday: flatRateWeekday,
        flat_rate_weekend: flatRateWeekend,
        shifts_clash: shiftsClash,
      };

      createRoleMutation.mutateAsync({ data });
    } catch (error: any) {
      console.log(error.flatten());
      setFormErrors(error.flatten());
    }
  };

  return (
    <div className="flex h-full min-h-0 w-full flex-col items-center overflow-y-auto">
      <div className="flex w-full flex-col">
        <div className=" relative top-0 right-0 flex">
          <div className="flex h-full w-full flex-col p-6">
            <Breadcrumbs items={crumbs} />
            <div className="flex flex-col space-y-4">
              <div className="flex justify-between">
                <p className="text-2xl font-semibold text-gray-900">Add Role</p>
              </div>
              <form
                className="relative flex flex-col space-y-4"
                onSubmit={(e) => postRole(e)}
              >
                <LoadingOverlay visible={createRoleMutation.isLoading} />
                <div className="flex flex-col space-y-2">
                  <TextInput
                    label="Name"
                    value={name}
                    onChange={(e) => setName(e.currentTarget.value)}
                    required
                    error={
                      formErrors.fieldErrors?.name &&
                      formErrors.fieldErrors?.name[0]
                    }
                  />
                  <NumberInput
                    label="Default break"
                    description="In minutes"
                    hideControls
                    value={defaultBreak}
                    onChange={setDefaultBreak}
                  />
                  <ColorPicker
                    label="Colour"
                    colour={colour}
                    setColour={setColour}
                    error={
                      formErrors.fieldErrors?.colour &&
                      formErrors.fieldErrors?.colour[0]
                    }
                  />
                  <TextInput
                    label="Pay code"
                    value={payCode}
                    onChange={(e) => setPayCode(e.currentTarget.value)}
                  />
                  <Checkbox
                    label="Shifts should clash"
                    checked={shiftsClash}
                    onChange={(e) => setShiftsClash(e.currentTarget.checked)}
                  />

                  <div className="flex flex-col space-y-2">
                    <Checkbox
                      label="On-call role"
                      checked={isOnCall}
                      onChange={(e) => setIsOnCall(e.currentTarget.checked)}
                    />
                    <div
                      className={`${
                        !isOnCall && "hidden"
                      } flex flex-col space-y-1`}
                    >
                      <NumberInput
                        label="Flat rate (weekday)"
                        hideControls
                        value={flatRateWeekday}
                        onChange={setFlatRateWeekday}
                      />
                      <NumberInput
                        label="Flat rate (weekend)"
                        hideControls
                        value={flatRateWeekend}
                        onChange={setFlatRateWeekend}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <Checkbox
                      label="Sleep role"
                      checked={isSleep}
                      onChange={(e) => setIsSleep(e.currentTarget.checked)}
                    />
                    <div
                      className={`${
                        !isSleep && "hidden"
                      } flex flex-col space-y-1`}
                    >
                      <NumberInput
                        label="Sleep flat rate (weekday)"
                        hideControls
                        value={sleepFlatRateWeekday}
                        onChange={setSleepFlatRateWeekday}
                      />
                      <NumberInput
                        label="Sleep flat rate (weekend)"
                        hideControls
                        value={sleepFlatRateWeekend}
                        onChange={setSleepFlatRateWeekend}
                      />
                    </div>
                  </div>
                  <UsersSelect
                    selectedUsers={selectedUsers}
                    setSelectedUsers={setSelectedUsers}
                  />
                </div>
                <div className="flex space-x-2">
                  <Button
                    className="bg-jira-button hover:bg-jira-button-hover"
                    type="submit"
                  >
                    Save Role
                  </Button>
                  <Button
                    type="reset"
                    className="bg-none font-medium text-slate-600 hover:bg-jira-button-subtle"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
