import { useEffect, useState } from "react";
import { Popover, Button, ActionIcon } from "@mantine/core";
import { TrashIcon } from "@heroicons/react/24/outline";

import { useDeleteMedication } from "../api/deleteMedication";

export const DeleteMedication = ({
  id,
  color,
  variant,
  closeEvent,
}: {
  id: number;
  color?: { bg?: string; text?: string; hover?: string; border?: string };
  variant?: any;
  closeEvent?: any;
}) => {
  const deleteMedicationMutation = useDeleteMedication(() => setConfirmDeleteModalOpen(false));

  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);

  useEffect(() => {
    console.log("deleteMedicationMutation error", deleteMedicationMutation.error, deleteMedicationMutation.isError);
  }, [deleteMedicationMutation]);

  return (
    <Popover
      opened={confirmDeleteModalOpen}
      onClose={() => setConfirmDeleteModalOpen(false)}
      target={
        <ActionIcon
          className={`${color && `${color.border} ${color.bg} ${color.hover}`} h-5 w-5`}
          variant={variant}
          onClick={(e: any) => {
            e.stopPropagation();
            setConfirmDeleteModalOpen((o) => !o);
          }}
        >
          <TrashIcon className={`${color && `${color.text}`} h-5 w-5`} />
        </ActionIcon>
      }
      width={260}
      position="bottom"
      withArrow
      gutter={1}
      // className={`${color && `${color}`} w-full`}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex flex-col space-y-2">
        <div className="flex flex-col space-y-1">
          <p className="text-[14px] font-medium text-zinc-900">Are you sure you want to delete this medication?</p>
          <p className="text-[13px] font-medium text-zinc-700">Once you delete, it's gone for good.</p>
        </div>
        <Button
          className="bg-red-500 hover:bg-red-600"
          onClick={() => {
            deleteMedicationMutation.mutateAsync({ medicationId: id });
          }}
          loading={deleteMedicationMutation.isLoading}
        >
          Delete Medication
        </Button>
      </div>
    </Popover>
  );
};
