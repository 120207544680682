import { Route, Routes } from "react-router-dom";
import { ClaimRoutes } from "../payroll/claims/routes";

import { Timesheets } from "../payroll/timesheet/routes/Timesheets";
import { PayrollDashboard } from "./PayrollDashboard";
import { Roles } from "../payroll/roles/routes/Roles";
import { Role } from "../payroll/roles/routes/Role";
import { CreateRole } from "../payroll/roles/routes/CreateRole";
import { Overview } from "../payroll/overview/routes";

export const PayrollRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<PayrollDashboard />} />
      <Route path="timesheet/*" element={<Timesheets />} />
      <Route path="claims/*" element={<ClaimRoutes />} />
      <Route path="roles" element={<Roles />} />
      <Route path="roles/:id" element={<Role />} />
      <Route path="roles/add" element={<CreateRole />} />
      <Route path="overview" element={<Overview />} />
      {/* <Route path="reports" element={<Reports />} /> */}
    </Routes>
  );
};
