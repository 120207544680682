export const AreaBadge = ({ image, name }: any) => {
  return (
    <div className="border-b border-neutral-200">
      <div className="flex items-center space-x-2 px-3 py-3 hover:cursor-pointer hover:bg-neutral-200 hover:bg-opacity-50">
        <img src={image} alt="Area Avatar" className="h-10 w-10 rounded-full" />
        <p className="text-[16px] font-semibold text-neutral-700">{name}</p>
      </div>
    </div>
  )
}
