import { Link } from "react-router-dom";
import { Button, LoadingOverlay } from "@mantine/core";
import { PencilSquareIcon } from "@heroicons/react/20/solid";

import { useGroups } from "features/group/api/getGroups";
import { useAuth } from "stores/Auth";

export const AreasList = () => {
  const groupsQuery = useGroups();
  const userLevel = useAuth((state) => state.userLevel);

  if (groupsQuery.isLoading) {
    return (
      <div className="relative flex h-full w-full">
        <LoadingOverlay visible={true} />
      </div>
    );
  }

  if (groupsQuery.error) {
    return <div>There was an error fetching groups</div>;
  }

  if (!groupsQuery.data) {
    return <p>No areas</p>;
  }

  return (
    <div className="flex h-full w-full items-start justify-center">
      <div className="relative mt-5 w-full max-w-5xl space-y-8 md:col-span-2 md:mt-0">
        {groupsQuery.data?.groups?.map((group: any, index: any) =>
          groupsQuery.data?.areas?.some((e: any) => e.group_id === group.id) ? (
            <div className="space-y-5">
              <div className="space-y-2">
                <div className="flex justify-between">
                  <p className="text-lg font-semibold text-gray-800">
                    {group.name}
                  </p>
                  {userLevel && userLevel === "admin" && (
                    <Button
                      component={Link}
                      size="sm"
                      to={`/groups/${group.id}`}
                      variant="subtle"
                      leftIcon={<PencilSquareIcon className="h-4 w-4" />}
                      className=" text-sm text-jira-button hover:bg-[#fafbfc] hover:text-[#0047B3]"
                    >
                      Edit Group
                    </Button>
                  )}
                </div>

                <div className="border-b-2 border-[#DFE1E6] bg-white font-bold text-[#5e6c84]">
                  <div className="grid grid-cols-12 gap-8 border-b-2 border-[#DFE1E6] py-1 font-bold text-[#5e6c84]">
                    <p className="col-span-3 text-[13px]">Name</p>
                  </div>
                  {groupsQuery.data?.areas?.map((area: any) => {
                    return (
                      area.group_id === group.id && (
                        <Link
                          className="grid grid-cols-12 items-center gap-8 py-2 hover:bg-[#fafbfc]"
                          to={`/areas/${area.id}`}
                        >
                          <div className="col-span-3 flex items-center space-x-2">
                            <img
                              src={area?.avatar_url ? area?.avatar_url : "area"}
                              alt=""
                              className="h-8 w-8 rounded-full"
                            />
                            <p className="overflow-hidden text-ellipsis text-sm font-semibold text-[#091e42]">
                              {area.name}
                            </p>
                          </div>
                          <p className="col-span-9 inline-block space-x-1 truncate text-sm font-medium text-[#091e42]">
                            {area.name}
                          </p>
                        </Link>
                      )
                    );
                  })}
                </div>
              </div>
            </div>
          ) : (
            userLevel &&
            userLevel === "admin" && (
              <div className="space-y-5">
                <div className="space-y-2">
                  <div className="flex justify-between">
                    <p className="text-lg font-semibold text-gray-800">
                      {group.name}
                    </p>
                    {userLevel && userLevel === "admin" && (
                      <Button
                        component={Link}
                        size="sm"
                        to={`/groups/${group.id}`}
                        variant="subtle"
                        leftIcon={<PencilSquareIcon className="h-4 w-4" />}
                        className=" text-sm text-jira-button hover:bg-[#fafbfc] hover:text-[#0047B3]"
                      >
                        Edit Group
                      </Button>
                    )}
                  </div>

                  <div className="">
                    <p className="text-gray-700">
                      No areas here.{" "}
                      <Link
                        className="cursor-pointer text-jira-button"
                        to={`/groups/${group.id}`}
                      >
                        Add one?
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            )
          )
        )}

        {groupsQuery.data.areas ? (
          <NoGroup areas={groupsQuery.data?.areas} />
        ) : (
          <p>You have no areas</p>
        )}
      </div>
    </div>
  );
};

const NoGroup = ({ areas }: any) => {
  if (areas.filter((area: any) => area.group_id === null).length < 1) {
    return null;
  }

  return (
    areas?.filter((area: any) => area.group_id === null) && (
      <div className="space-y-5">
        <div className="space-y-2">
          <div className="flex justify-between">
            <p className="text-lg font-semibold text-gray-800">No Group</p>
          </div>

          <div className="border-b-2 border-[#DFE1E6] bg-white">
            <div className="grid grid-cols-12 gap-8 border-b-2 border-[#DFE1E6] py-1 font-bold text-[#5e6c84]">
              <p className="col-span-3 text-[13px]">Name</p>
              <p className="col-span-9 text-[13px]">Users</p>
            </div>
            {areas
              ?.filter((area: any) => area.group_id === null)
              .map((area: any) => (
                <Link
                  className="grid grid-cols-12 items-center gap-8 py-2 hover:bg-[#fafbfc]"
                  to={`/areas/${area.id}`}
                >
                  <div className="col-span-3 flex items-center space-x-2">
                    <img
                      src={area?.avatar_url ? area?.avatar_url : "area"}
                      alt=""
                      className="h-8 w-8 rounded-full"
                    />
                    <p className="overflow-hidden text-ellipsis text-sm font-semibold text-[#091e42]">
                      {area.name}
                    </p>
                  </div>
                  <p className="col-span-9 text-gray-600">{area.name}</p>
                </Link>
              ))}
          </div>
        </div>
      </div>
    )
  );
};
