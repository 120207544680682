import { useState, useEffect } from "react";
import {
  Checkbox,
  Group,
  Avatar,
  LoadingOverlay,
  TextInput,
  Button,
} from "@mantine/core";
import { useNavigate, Link } from "react-router-dom";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { axios } from "lib/axios";

import { DisableUsers } from "../components/DisableUsers";
import { EnableUsers } from "../components/EnableUsers";
import { useUsers } from "../api/getUsers";
import { useUserStore } from "stores/userStore";
import { TUser } from "../types";
import { CreateUser } from "./CreateUser";
import { NewFilters } from "./UsersFilter";
import { UserRow } from "./UserRow";

export const UsersList = () => {
  const usersQuery = useUsers();

  console.log("UsersList usersQuery: ", usersQuery);

  // const usersQuery = useUsers();
  const [userList, setUserList] = useState<TUser[]>([]);

  useEffect(() => {
    if (usersQuery?.data) {
      setUserList(usersQuery.data);
      removeAllUsers();
    }
    console.log("useEffect running");
  }, [usersQuery.data]);

  const setFilters = useUserStore((state) => state.setFilters);

  // //! Tests

  const {
    name: userName,
    role: userRole,
    type,
  } = useUserStore((state) => state.filters);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const enableCheckedList = useUserStore((state) => state.enableCheckedList);
  const disableCheckedList = useUserStore((state) => state.disableCheckedList);
  const addAllUsers = useUserStore((state) => state.addAllUsers);
  const removeAllUsers = useUserStore((state) => state.removeAllUsers);

  useEffect(() => {
    console.log("ArchiveList", enableCheckedList, disableCheckedList);
  }, [enableCheckedList, disableCheckedList]);

  // useEffect(() => {
  //   console.log(
  //     "checked type",
  //     type,
  //     type.length,
  //     type.length > 0,
  //     userList?.filter(({ full_name, user_level }) =>
  //       type.length > 0
  //         ? type.includes(user_level)
  //         : user_level.toLowerCase().includes("")
  //     )
  //   );
  // }, [type]);

  const handleSelectAll = () => {
    setIsAllChecked(!isAllChecked);
    const enableUsers = userList.filter((user) => user.disabled === true);
    const disableUsers = userList.filter((user) => user.disabled === false);
    addAllUsers(enableUsers, disableUsers);
    if (isAllChecked) {
      removeAllUsers();
    }
  };

  useEffect(() => {
    console.log("userList: ", userList);
  }, [userList]);

  const [open, setOpen] = useState(false);

  return (
    <div className="flex h-full min-h-0 w-full flex-col items-center overflow-y-auto">
      <div className="flex w-full max-w-5xl flex-col">
        <div className="flex flex-col space-y-4">
          <div className="flex w-full justify-between">
            <p className="text-xl font-semibold text-gray-900">Users</p>

            <Button
              className="h-[28px] rounded bg-jira-button py-1 px-2 hover:bg-jira-button-hover"
              compact
              onClick={() => setOpen(true)}
              size="xs"
            >
              Invite user
            </Button>
            <CreateUser open={open} setOpen={setOpen} />
          </div>

          <div className="flex justify-between">
            <div className="flex space-x-2">
              <TextInput
                classNames={{
                  input: "w-[250px]",
                }}
                icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                value={userName}
                onChange={(e) => setFilters("name", e.target.value)}
              />
              <NewFilters />
            </div>
            <div className="flex items-center justify-between space-x-2">
              <div className="flex items-center space-x-2">
                <DisableUsers />
                <EnableUsers />
              </div>
            </div>
          </div>
          <table className="flex flex-col border-b-2 border-[#DFE1E6]">
            <thead className="sticky inset-0 z-10 border-b-2 border-[#DFE1E6] bg-white pb-1 text-left">
              <tr className="inset-0 grid flex-1 grid-cols-12 text-left text-[#687076]">
                <th className="col-span-1 px-3">
                  <Checkbox
                    className="flex items-center xl:row-span-1"
                    checked={isAllChecked}
                    onChange={handleSelectAll}
                    disabled={usersQuery.isError}
                  />
                </th>
                <th className="col-span-5 px-3">
                  <p className="3xs:order-3 3xs:row-span-2 flex items-center font-semibold xl:order-2 xl:col-span-5 xl:row-span-1">
                    User
                  </p>
                </th>
                <th className="col-span-2 px-3 font-semibold">
                  <p>Status</p>
                </th>
                <th className="col-span-3 px-3 font-semibold">
                  <p>Last Sign In</p>
                </th>
              </tr>
            </thead>
            <tbody className="relative grid text-left xs:grid-cols-1 md:grid-cols-2 xl:grid-cols-1">
              <LoadingOverlay
                visible={usersQuery?.isFetching || usersQuery.isLoading}
                zIndex={1}
              />
              {usersQuery.data &&
                usersQuery?.data?.map((user: TUser) => (
                  <UserRow {...user} key={user.id} />
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
