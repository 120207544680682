import { useEffect, useState } from "react";
import { Modal, Button, ActionIcon, NumberInput, Textarea, Tooltip } from "@mantine/core";
import { PencilSquareIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { DateTime } from "luxon";
import { useParams } from "react-router-dom";
import { Logs } from "./Logs";

import { useMe } from "features/users/api/getMe";
import { useRecordStore } from "../store/recordStore";
import { useUpdateRecord } from "../api/updateRecord";

export const UpdateCount = () => {
  const { areaId } = useParams();

  const user = useMe();

  const selectedDate = useRecordStore((state) => state.selectedDate);
  const currentDate = useRecordStore((state) => state.currentDate);
  const isUpdateCountModalOpen = useRecordStore((state) => state.isUpdateCountModalOpen);
  const setIsUpdateCountModalOpen = useRecordStore((state) => state.setIsUpdateCountModalOpen);
  const selectedMedication = useRecordStore((state) => state.selectedMedication);
  const updateCountInformation = useRecordStore((state) => state.updateCountInformation);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [notes, setNotes] = useState<string>("");

  const updateCountMutation = useUpdateRecord({
    areaId: areaId,
    start: DateTime.fromSeconds(selectedDate, { zone: "Europe/London" }).startOf("month").toUnixInteger(),
    end: DateTime.fromSeconds(selectedDate, { zone: "Europe/London" }).endOf("month").toUnixInteger(),
  });

  useEffect(() => {
    if (updateCountInformation) {
      setCount(updateCountInformation.count);
      setNotes(updateCountInformation.notes ? updateCountInformation.notes : "");
    }
  }, [updateCountInformation]);

  const handleNewCount = async () => {
    await updateCountMutation.mutateAsync({
      data: {
        id: updateCountInformation.id,
        type: updateCountInformation.type,
        submitted_date: DateTime.now().setZone("Europe/London").toUnixInteger(),
        medication_id: selectedMedication.id,
        area_id: areaId,
        previous_notes: notes,
        previous_count: count,
        logs: updateCountInformation.logs,
        start: DateTime.fromSeconds(selectedDate, { zone: "Europe/London" }).startOf("month").toUnixInteger(),
        end: DateTime.fromSeconds(selectedDate, { zone: "Europe/London" }).endOf("month").toUnixInteger(),
      },
    });
  };

  console.log("selectedMedication", selectedMedication, selectedDate);

  if (!updateCountInformation) {
    return null;
  }

  return (
    <Modal
      opened={isUpdateCountModalOpen}
      onClose={() => {
        setIsUpdateCountModalOpen(false);
        setIsEditing(false);
      }}
      centered
      withCloseButton={false}
    >
      <div className="flex flex-col space-y-4">
        <div className="flex items-center justify-between">
          <div className="flex space-x-2">
            <p className="text-[16px] font-semibold text-gray-900">{selectedMedication.name} </p>
            <p className="text-[16px] font-semibold text-[#6b778c]">{`${DateTime.fromSeconds(Number(selectedDate), {
              zone: "Europe/London",
            }).toLocaleString({
              year: "numeric",
              month: "numeric",
              day: "numeric",
            })}`}</p>
          </div>
          <div className="flex space-x-1">
            {user?.data?.level !== "user" && !isEditing && (
              <ActionIcon
                onClick={() => {
                  setIsEditing(true);
                }}
              >
                <PencilSquareIcon className="h-6 w-6 text-[#42526E]" />
              </ActionIcon>
            )}
            <ActionIcon
              onClick={() => {
                setIsUpdateCountModalOpen(false);
                setIsEditing(false);
              }}
            >
              <XMarkIcon className="h-6 w-6 text-[#42526E]" />
            </ActionIcon>
          </div>
        </div>
        <div>
          {user?.data?.level !== "user" && !isEditing ? (
            <div className="flex items-center space-x-2">
              <p className="text-sm font-semibold text-gray-500">Medication Count</p>
              <p className="font-medium text-gray-900">{updateCountInformation.count}</p>
            </div>
          ) : (
            <NumberInput
              label="Medication Count"
              value={count}
              onChange={(val: number) => setCount(val)}
              hideControls
              min={0}
              max={1000}
            />
          )}
        </div>
        <div>
          {user?.data?.level !== "user" && !isEditing ? (
            <div className="flex items-center space-x-2">
              <p className="text-sm font-semibold text-gray-500">Notes</p>
              <p className="font-medium text-gray-900">{updateCountInformation.notes}</p>
            </div>
          ) : (
            <Textarea label="Notes" value={notes} onChange={(e) => setNotes(e.currentTarget.value)} />
          )}
        </div>

        {updateCountInformation.logs.length > 0 && <Logs logs={updateCountInformation.logs} type="count" />}

        {user?.data?.level !== "user" && !isEditing ? null : (
          <div className="flex space-x-2">
            <Button
              className="bg-jira-button hover:bg-jira-button-hover"
              onClick={() => {
                handleNewCount();
                setIsUpdateCountModalOpen(false);
              }}
              loading={updateCountMutation.isLoading}
            >
              Save
            </Button>

            <Button
              className="bg-none font-medium text-slate-600 hover:bg-jira-button-subtle"
              variant="subtle"
              color="gray"
              onClick={() => {
                setIsEditing(false);
              }}
            >
              Cancel
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};
