import { DateTime } from "luxon";

export const ShiftPay = ({ shift, rate }: any) => {
  const hourlyRate = rate;
  const sleepRate = 70;
  const sleepDuration = 9;

  const handleShiftPay = (shift: any) => {
    const start = DateTime.fromSeconds(Number(shift.start_time), {
      zone: "Europe/London",
    });
    const end = DateTime.fromSeconds(Number(shift.end_time), {
      zone: "Europe/London",
    });
    const shiftDuration: any = end.diff(start, ["hours", "minutes"]).toObject();

    let pay = 0;

    if (shift.sleep) {
      pay = (shiftDuration.minutes * hourlyRate) / 60 + (shiftDuration.hours - sleepDuration) * hourlyRate + sleepRate;
    } else {
      pay = (shiftDuration.minutes * hourlyRate) / 60 + shiftDuration.hours * hourlyRate;
    }

    return pay;
  };

  return (
    <div className="col-span-1 font-medium">
      <p className="flex p-1">£{handleShiftPay(shift).toFixed(2)}</p>
    </div>
  );
};
