import { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { TextInput, LoadingOverlay, Button } from "@mantine/core";

import logo from "assets/supported_logo.png";
import { supabase } from "lib/supabase";

export const ForgotForm = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [heading, setHeading] = useState("Forgot Password?");

  const handlePasswordReset = useCallback(
    async (e: any) => {
      e.preventDefault();

      try {
        setLoading(true);
        setMessage("");
        setError("");
        const { data, error } = await supabase.auth.resetPasswordForEmail(
          email,
          {
            redirectTo: `https://vocal-cranachan-e417e5.netlify.app/auth/reset?email=${email}`,
          }
        );

        if (error) {
          setError(
            "There was an error sending the email, please check your email address and re-try"
          );
        } else {
          setHeading("Email Sent!");
          setMessage(
            `An email has been sent to ${email} containing a password reset link`
          );
        }

        setLoading(false);
      } catch (err: any) {
        setLoading(false);
      }
    },
    [email]
  );

  function MessageContainer() {
    return (
      <div className="rounded-lg border border-blue-500 bg-blue-100 p-6 font-medium text-blue-500">
        <p>{message}</p>
      </div>
    );
  }

  function ErrorContainer() {
    return (
      <div className="rounded-lg border border-red-500 bg-red-100 p-6 font-medium text-red-500">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="flex h-screen w-screen">
      <div className="flex flex-1 items-center justify-center">
        <div className="max-w-sm space-y-4">
          <form
            className="3xs:h-full 3xs:w-full 3xs:p-6 3xs:justify-center relative flex flex-col xs:h-5/6 xs:w-10/12 sm:h-auto sm:w-auto sm:max-w-[512px] sm:px-8 sm:py-6"
            onSubmit={handlePasswordReset}
          >
            <LoadingOverlay visible={loading} />

            <div className="flex flex-col items-center space-y-10">
              <div className="flex flex-col items-center space-y-4">
                <img src={logo} alt="Supported Logo" className="h-24" />

                <div className="3xs:w-full flex flex-col space-y-3 sm:w-80">
                  <div className="space-y-2">
                    <p className="text-center text-2xl font-semibold text-gray-900">
                      {heading}
                    </p>
                    <p className="text-center font-medium text-gray-500">
                      No worries, we'll send you reset instructions
                    </p>
                    {error && <ErrorContainer />}
                  </div>
                </div>
              </div>

              <div className="flex w-full flex-1">
                {message ? (
                  <MessageContainer />
                ) : (
                  <TextInput
                    label="Email address"
                    placeholder="Your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full"
                  />
                )}
              </div>

              {!message && (
                <div className="3xs:flex-col 3xs:space-y-4 3xs:items-center flex w-full items-center justify-between md:flex-row md:space-y-0">
                  <Link
                    className="font-medium text-jira-button transition-all duration-200 ease-in-out hover:bg-jira-button-hover"
                    to="/auth/login"
                  >
                    Back to Login
                  </Link>
                  <Button
                    className="bg-jira-button hover:bg-jira-button-hover"
                    type="submit"
                  >
                    Reset
                  </Button>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
