import { useMutation } from "react-query";
import { queryClient } from "lib/react-query";

import { axios } from "lib/axios";
import { useNotification } from "hooks/useNotification";

export const deleteRole = ({ roleId }: { roleId: number }) => {
  return axios.delete(`/roles/${roleId}`);
};

export const useDeleteRole = ({ roleId }: any) => {
  const { addNotification } = useNotification();
  return useMutation({
    onMutate: async (deletedRole) => {
      await queryClient.cancelQueries(["roles"]);

      const previousRoles = queryClient.getQueryData<any[]>(["roles"]);

      queryClient.setQueryData(
        ["roles"],
        previousRoles?.filter((role) => role.id !== deletedRole.roleId)
      );

      return { previousRoles };
    },
    onError: (_, __, context: any) => {
      if (context?.previousRoles) {
        queryClient.setQueryData(["roles"], context.previousRoles);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["roles"]);
      addNotification({
        disallowClose: false,
        type: "success",
        title: "Role Deleted Successfully",
        message: "",
      });
    },
    mutationFn: deleteRole,
  });
};
