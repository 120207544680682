import { useQuery } from "react-query";
import { AxiosError } from "axios";

import { axios } from "lib/axios";
import { useAuth } from "stores/Auth";

export const getExpenses = async ({ userId, start, end }: { userId: string; start: any; end: any }): Promise<any> => {
  try {
    const response = await axios.get(`/expenses/${userId}/${start}/${end}`);
    if (response.status === 200) {
      console.log("response 200", response.status);
    }
    // Don't forget to return something
    return response.data;
  } catch (err) {
    console.log("getArea", err);
    if (err instanceof AxiosError) {
      if (err.response?.status === 401) {
        throw new Error("Not authorised");
      } else if (err.response?.status === 403) {
        throw new Error("Not admin");
      }
    }
    throw new Error("There was an error fetching the request");
  }
};

export const useExpenses = ({ start, end }: any) => {
  const currentUser = useAuth((state) => state.currentUser);
  console.log("useClaims: ", currentUser);
  return useQuery({
    queryKey: ["expenses"],
    queryFn: () => getExpenses({ userId: currentUser.id, start: start, end: end }),
  });
};
