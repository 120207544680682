import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";
import { DateTime } from "luxon";

import { MedicationPreview } from "components/MedicationPreview";
import { ArchiveBoxIcon } from "@heroicons/react/24/outline";

import { CreateRecord } from "features/record/components/CreateRecord";
import { UpdateRecord } from "features/record/components/UpdateRecord";
import { useRecords } from "features/record/api/getRecords";
import { SegmentedControl, Skeleton } from "@mantine/core";
import { CreateCount } from "./CreateCount";
import { Record } from "./Record";
import { UpdateCount } from "./UpdateCount";
import { CreateReturn } from "./CreateReturn";
import { UpdateReturn } from "./UpdateReturn";
import { CreateReceived } from "./CreateReceived";
import { UpdateReceived } from "./UpdateReceived";
import { MedicationRecordsMobile } from "../mobile/components/MedicationRecordsMobile";

export const Records = ({ selectedMonth, medications }: any) => {
  const { areaId } = useParams();

  const [isMedicationPreviewOpen, setIsMedicationPreviewOpen] = useState(false);

  const recordsQuery: any = useRecords({
    areaId,
    start: selectedMonth.start.toUnixInteger(),
    end: selectedMonth.end.toUnixInteger(),
  });

  const isMobile = useMediaQuery("(max-width: 640px)");

  // if (medicationsQuery.isLoading) {
  //   return <p>Loading</p>;
  // }

  // if (medicationsQuery.isError) {
  //   return <p>Error</p>;
  // }

  // if (medicationsQuery.isLoading || recordsQuery.isLoading) {
  //   return <p>Loading</p>;
  // }

  // if (medicationsQuery.isError || recordsQuery.isError) {
  //   return <p>Error</p>;
  // }

  // if (medicationsQuery.data.medications.length < 1) {
  //   return (
  //     <div className="flex h-80 w-full flex-col items-center justify-center space-y-1">
  //       <ArchiveBoxIcon className="h-12 w-12 text-neutral-500" />
  //       <p className="font-medium text-neutral-500">No medication entries</p>
  //     </div>
  //   );
  // }

  if (isMobile) {
    <MedicationRecordsMobile />;
  }

  if (recordsQuery.isLoading) {
    return (
      <div className="flex">
        <div className="flex min-h-[95px] w-[350px] min-w-[350px] flex-col justify-between border-r border-b border-gray-200">
          <div className="flex w-full justify-between">
            <div className="space-y-1 px-2 pt-2">
              <p className="font-semibold text-jira-button hover:cursor-pointer hover:underline">
                <Skeleton height={21} width={100} />
              </p>
              <p className="text-sm font-semibold text-gray-500">
                <Skeleton height={19} width={80} />
              </p>
            </div>
          </div>
          <div className="flex min-h-[43px] items-center justify-between bg-[#FCFCFC] p-2">
            <div className="">
              <p className=" cursor-pointer text-sm font-semibold text-jira-button">+ Add row</p>
            </div>
            <p className="text-sm font-medium text-gray-900">End Medication Count</p>
          </div>
        </div>
      </div>
    );
  }

  if (!medications) {
    return (
      <div>
        <p>No medications</p>
      </div>
    );
  }

  return (
    <>
      <MedicationPreview open={isMedicationPreviewOpen} setOpen={setIsMedicationPreviewOpen} />

      <CreateRecord />

      <UpdateRecord selectedMonth={selectedMonth} />

      <CreateCount />

      <UpdateCount />

      <CreateReturn />

      <UpdateReturn selectedMonth={selectedMonth} />

      <CreateReceived />

      <UpdateReceived selectedMonth={selectedMonth} />

      <div className="flex flex-col space-y-3 p-4">
        <div className=" inline-flex flex-col items-start space-y-3 overflow-hidden">
          {medications.length > 0 ? (
            medications.map((medication: any) => {
              //* filter out all records not related to the medication

              const records = recordsQuery.data.filter((record: any) => record.medication_id === medication.id);
              const counts = recordsQuery.data.filter(
                (count: any) => count.type === "count" && count.medication_id === medication.id
              );
              const returns = recordsQuery.data.filter(
                (_return: any) => _return.type === "returned" && _return.medication_id === medication.id
              );
              const received = recordsQuery.data.filter(
                (received: any) => received.type === "received" && received.medication_id === medication.id
              );

              return (
                <Record
                  selectedMonth={selectedMonth}
                  medication={medication}
                  records={records}
                  counts={counts}
                  returns={returns}
                  received={received}
                  setIsMedicationPreviewOpen={setIsMedicationPreviewOpen}
                />
              );
            })
          ) : (
            <div className="flex h-80 w-full flex-col items-center justify-center space-y-1">
              <ArchiveBoxIcon className="h-12 w-12 text-neutral-500" />
              <p className="font-medium text-neutral-500">No medications</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
