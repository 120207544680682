import { useQuery } from "react-query";
import { AxiosError } from "axios";

import { axios } from "lib/axios";
import { useAuth } from "stores/Auth";

export const getRoles = async (user_id?: string): Promise<any[]> => {
  try {
    let response;
    if (user_id) {
      response = await axios.get(`/roles?rotacloud=false&user=${user_id}`);
    } else {
      response = await axios.get(`/roles?rotacloud=false`);
    }
    if (response.status === 200) {
      console.log("response 200", response.status);
    }
    // Don't forget to return something
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
    if (err instanceof AxiosError) {
      if (err.response?.status === 401) {
        throw new Error("Not authorised");
      } else if (err.response?.status === 403) {
        throw new Error("Not admin");
      }
    }
    throw new Error("There was an error fetching the request");
  }
};

export const useRoles = (success: any, user_id?: string) => {
  const currentUser = useAuth((state) => state.currentUser);
  return useQuery({
    onSuccess: () => {
      success();
    },
    queryKey: ["roles"],
    queryFn: () => getRoles(user_id),
    enabled: !!currentUser?.id,
  });
};
