import { Button } from "@mantine/core";
import {
  LockClosedIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";

import { getDate } from "../utils";

export const ExpenseInformation = ({ selectedClaim, deleteClaim }: any) => {
  return (
    <div className="flex w-1/2 flex-col border-r border-gray-200 bg-white">
      <div className="flex min-h-[40px] items-center justify-between border-b border-gray-200 px-4 py-1">
        <p className="font-semibold text-gray-900">{selectedClaim?.supplier}</p>
        <div className="flex items-end justify-end space-x-1">
          {selectedClaim.expense_id ? (
            <div className="flex items-center space-x-1">
              <span>
                <LockClosedIcon className="h-3.5 w-3.5 text-jira-button" />
              </span>
              <p className="flex text-sm font-medium text-jira-button">
                Expense locked
              </p>
            </div>
          ) : (
            <>
              <Button
                variant="outline"
                size="xs"
                className="text-sm font-medium text-[#11181c] shadow"
                leftIcon={
                  <PencilSquareIcon className="h-4 w-4 text-[#11181c]" />
                }
              >
                Edit claim
              </Button>
              <Button
                variant="outline"
                size="xs"
                className="text-sm font-medium text-[#11181c] shadow"
                leftIcon={<TrashIcon className="h-4 w-4 text-[#11181c]" />}
                onClick={() =>
                  deleteClaim.mutateAsync({ claimId: selectedClaim?.id })
                }
                loading={deleteClaim.isLoading}
              >
                Delete claim
              </Button>
            </>
          )}
        </div>
      </div>
      <div className="flex flex-col space-y-2 py-2 px-4">
        <div>
          <p className="font-semibold text-[#889096]">
            {getDate(selectedClaim)}
          </p>
        </div>
        <div>
          <p className="text-xs font-semibold text-[#889096]">Amount</p>
          <p className="font-medium text-gray-900">£{selectedClaim?.amount}</p>
        </div>
        <div>
          <p className="text-xs font-semibold text-[#889096]">Type</p>
          <p className="font-medium text-gray-900">{selectedClaim?.type}</p>
        </div>
        <div>
          <p className="text-xs font-semibold text-[#889096]">Category</p>
          <p className="font-medium text-gray-900">{selectedClaim?.category}</p>
        </div>
        <div>
          <p className="text-xs font-semibold text-[#889096]">Supplier</p>
          <p className="font-medium text-gray-900">{selectedClaim?.supplier}</p>
        </div>
      </div>
    </div>
  );
};
