import { Route, Routes } from "react-router-dom";
import { AdminDashboard } from "./AdminDashboard";
import { PayrollRoutes } from "./PayrollRoutes";
import { Settings } from "./Settings";

export const AdminRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<AdminDashboard />} />
      <Route path="payroll/*" element={<PayrollRoutes />} />
      <Route path="settings" element={<Settings />} />
    </Routes>
  );
};
