import { Controller } from "react-hook-form";
import { TextInput, NumberInput, Select, Button } from "@mantine/core";

export const UserWage = ({ control }: { control: any }) => {
  return (
    <section>
      <div className="border-b border-[#e6e8eb]">
        <div className="flex items-center px-6 py-4">
          <p className="text-[16px] font-semibold text-gray-900">Wage</p>
        </div>
      </div>
      <div className="px-6 py-4">
        <div className="space-y-2">
          <Controller
            control={control}
            name={`payrollId`}
            render={({ field: { onChange, value } }: any) => (
              <TextInput label="Payroll Id" value={value} onChange={onChange} />
            )}
          />
          <div className="flex flex-col space-y-1">
            <p className="text-sm font-semibold text-gray-500">Default wage/salary</p>
            <div className="flex items-center space-x-1">
              <span>£</span>
              <Controller
                control={control}
                name={`salary`}
                render={({ field: { onChange, value } }: any) => (
                  <NumberInput className="w-[100px]" value={value} onChange={onChange} precision={2} hideControls />
                )}
              />
              <Controller
                control={control}
                name={`salary_type`}
                render={({ field: { onChange, value } }: any) => (
                  <Select
                    data={[
                      { label: "per annum", value: "salary" },
                      { label: "per hour", value: "hourly" },
                    ]}
                    className="w-[120px]"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="border-t border-gray-200" />
      <div className="flex px-6 py-4">
        <div className="flex w-full items-center justify-end gap-2">
          <div className="flex items-center gap-2">
            <Button type="reset" className="bg-none font-medium text-slate-600 hover:bg-jira-button-subtle">
              Cancel
            </Button>
            <Button className="bg-jira-button hover:bg-jira-button-hover" type="submit">
              Save
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};
