import { Indicator } from "@mantine/core";
import { CheckIcon } from "@heroicons/react/24/solid";

import male from "assets/maleBodymap.svg";
import female from "assets/female_front.svg";

type SelectBodymapType = {
  selectedBodymap: string;
  setSelectedBodymap: Function;
};

export const SelectBodymap = ({
  selectedBodymap,
  setSelectedBodymap,
}: SelectBodymapType) => {
  return (
    <div className="space-y-2">
      <div className="flex space-x-5 pt-1">
        <div className="flex flex-col space-y-1">
          <Indicator
            size={22}
            disabled={selectedBodymap === "female"}
            offset={4}
            label={<CheckIcon className="h-3 w-3" />}
            classNames={{
              indicator: "bg-jira-button",
            }}
          >
            <img
              src={male}
              alt="male bodymap"
              className={`${
                selectedBodymap === "male" && "border-jira-button "
              } h-40 w-60 cursor-pointer rounded border-2 border-stone-200 bg-[#f8f9fa] shadow-select transition-all duration-200 hover:border-2 hover:border-jira-button`}
              onClick={() => setSelectedBodymap("male")}
            />
          </Indicator>

          <p className="text-sm font-medium text-gray-500">Male</p>
        </div>

        <div className="flex flex-col space-y-1">
          <Indicator
            size={22}
            disabled={selectedBodymap === "male"}
            offset={4}
            label={<CheckIcon className="h-3 w-3" />}
            classNames={{
              indicator: "bg-jira-button",
            }}
          >
            <img
              src={female}
              alt="female bodymap"
              className={`${
                selectedBodymap === "female" && " border-jira-button "
              } h-40 w-60 cursor-pointer rounded border-2 border-stone-200 bg-[#f8f9fa]  shadow-select transition-all duration-200 hover:border-2 hover:border-jira-button`}
              onClick={() => setSelectedBodymap("female")}
            />
          </Indicator>

          <p className="text-sm font-medium text-gray-500">Female</p>
        </div>
      </div>
    </div>
  );
};
