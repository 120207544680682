import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

type TLinkCard = {
  to: string;
  name: string;
  title: string;
  subtitle: string;
  admin_subtitle?: string;
};

export const DashboardLink = ({
  link,
  isAdmin,
}: {
  link: TLinkCard;
  isAdmin: boolean;
}) => {
  return (
    <Link
      key={link.name}
      to={link.to}
      replace={true}
      className="group relative inline-block w-full  cursor-pointer space-y-2 rounded border border-zinc-200 bg-white p-4 transition-all duration-200 hover:bg-zinc-200 sm:w-[400px]"
    >
      <div>
        <p className="text-lg font-semibold text-gray-900">{link.title}</p>
        <p className="text-[15px] text-gray-500">
          {isAdmin && link.admin_subtitle ? link.admin_subtitle : link.subtitle}
        </p>
        <div className=" absolute right-4 top-4 transition-all duration-200 group-hover:right-3">
          <ChevronRightIcon className="h-4 w-4" />
        </div>
      </div>
    </Link>
  );
};
