import { useEffect } from "react";
import { Checkbox, CheckboxGroup } from "@mantine/core";

import { useUsers } from "features/users/api/getUsers";
import { TUser } from "features/users/types";
import { useAreaUsers } from "../api/getAreaUsers";

type UsersSelectTypes = {
  area?: any;
  selectedUsers: string[];
  setSelectedUsers: (value: string[]) => void;
};

export const UsersSelect = ({ area, selectedUsers, setSelectedUsers }: UsersSelectTypes) => {
  const areaId = area ? area?.id : null;
  const areaUsersQuery = useAreaUsers({ areaId: areaId });
  const usersQuery = useUsers();

  useEffect(() => {
    if (areaUsersQuery.data) {
      const currentUsers = areaUsersQuery.data.map((user: any) => user.id);
      setSelectedUsers(currentUsers);
      console.log("current users: ", currentUsers);
    }
  }, [areaUsersQuery.data]);

  const selectAllUsers = () => {
    const allUsers = usersQuery.data?.map((user: TUser) => user.id);

    setSelectedUsers(allUsers as Array<string>);
  };

  const unselectAllUsers = () => {
    setSelectedUsers([]);
  };

  return (
    <div className="flex flex-col space-y-3">
      <div className="flex flex-col space-y-1">
        <div className="flex space-x-1">
          <button type="button" onClick={selectAllUsers} className="text-sm font-semibold text-jira-button">
            Select all
          </button>
          <span className="">-</span>
          <button type="button" onClick={unselectAllUsers} className="text-sm font-semibold text-jira-button">
            Unselect all
          </button>
        </div>
        <div className="flex w-[600px]">
          <CheckboxGroup
            value={selectedUsers}
            onChange={setSelectedUsers}
            spacing={10}
            size="xs"
            classNames={{
              input: "checked:bg-jira-button checked:border-jira-button",
            }}
          >
            {usersQuery.data?.map(
              (user: TUser) =>
                user.verified === true && (
                  <Checkbox
                    value={user.id}
                    label={
                      <div className="flex w-full items-center space-x-3 text-sm font-medium text-[#091e42] ">
                        <p>{user.full_name}</p>
                      </div>
                    }
                  />
                )
            )}
          </CheckboxGroup>
        </div>
      </div>
    </div>
  );
};
