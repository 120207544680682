import { useRecordStore } from "../../store/recordStore";

type TCountCell = {
  type: "end" | "return" | "received";
  day: any;
  medication: any;
  count: any;
  currentDate: number;
  isMobile?: boolean;
  title?: string;
};

export const CountCellMobile = ({ type, day, medication, count, currentDate, isMobile, title }: TCountCell) => {
  const setSelectedDate = useRecordStore((state) => state.setSelectedDate);
  const setSelectedMedication = useRecordStore((state) => state.setSelectedMedication);
  const useNotify = useRecordStore((state) => state.useNotify);

  const setIsCreateCountModalOpen = useRecordStore((state) => state.setIsCreateCountModalOpen);
  const setIsUpdateCountModalOpen = useRecordStore((state) => state.setIsUpdateCountModalOpen);
  const setUpdateCountInformation = useRecordStore((state) => state.setUpdateCountInformation);

  const setIsCreateReturnModalOpen = useRecordStore((state) => state.setIsCreateReturnModalOpen);
  const setIsUpdateReturnModalOpen = useRecordStore((state) => state.setIsUpdateReturnModalOpen);
  const setUpdateReturnInformation = useRecordStore((state) => state.setUpdateReturnInformation);

  const setIsCreateReceivedModalOpen = useRecordStore((state) => state.setIsCreateReceivedModalOpen);
  const setIsUpdateReceivedModalOpen = useRecordStore((state) => state.setIsUpdateReceivedModalOpen);
  const setUpdateReceivedInformation = useRecordStore((state) => state.setUpdateReceivedInformation);

  const { notify } = useNotify();

  const handleCreate = () => {
    setSelectedDate(day);
    setSelectedMedication(medication);

    if (type === "end") {
      setIsCreateCountModalOpen(true);
    } else if (type === "return") {
      setIsCreateReturnModalOpen(true);
    } else if (type === "received") {
      setIsCreateReceivedModalOpen(true);
    }
  };

  const handleUpdate = (count: any) => {
    setSelectedDate(day);
    setSelectedMedication(medication);

    if (type === "end") {
      setIsUpdateCountModalOpen(true);
      setUpdateCountInformation(count);
    } else if (type === "return") {
      setIsUpdateReturnModalOpen(true);
      setUpdateReturnInformation(count);
    } else if (type === "received") {
      setIsUpdateReceivedModalOpen(true);
      setUpdateReceivedInformation(count);
    }
  };

  const text = () => {
    if (type === "end") {
      return count.count;
    } else if (type === "return") {
      return count.returned_amount;
    } else if (type === "received") {
      return count.received_amount;
    }
  };

  const futureNotificationTypes = {
    end: "futureEnd",
    return: "futureReturn",
    received: "futureReceived",
  };

  if (isMobile) {
    if (!count) {
      return (medication.end_date && day > medication.end_date) || day < medication.start_date ? (
        <div
          className="flex cursor-pointer flex-col items-center border-r border-neutral-200 bg-neutral-50 p-2 hover:bg-neutral-100"
          onClick={() => notify(type)}
        />
      ) : day > currentDate ? (
        <div
          className="flex cursor-pointer flex-col items-center border-r border-neutral-200 bg-yellow-50 p-2"
          onClick={() => notify(futureNotificationTypes[type])}
        >
          <p>{title}</p>
          <div className="flex h-[43px] w-[52px] items-center justify-center p-2 font-semibold text-neutral-900">
            <p className="overflow-hidden overflow-ellipsis whitespace-nowrap">-</p>
          </div>
        </div>
      ) : (
        <div
          className="flex cursor-pointer flex-col items-center border-r border-neutral-200 bg-neutral-50 p-2 hover:bg-neutral-100"
          onClick={() => handleCreate()}
        >
          <p>{title}</p>
          <div className="flex h-[43px] w-[52px] items-center justify-center p-2 font-semibold text-neutral-900">
            <p className="overflow-hidden overflow-ellipsis whitespace-nowrap">-</p>
          </div>
        </div>
      );
    }

    return (
      <div
        className="flex cursor-pointer flex-col items-center border-r border-neutral-200 bg-neutral-50 p-2 hover:bg-neutral-100"
        onClick={() => handleUpdate(count)}
      >
        <p>{title}</p>
        <div className="flex h-[43px] w-[52px] items-center justify-center p-2 font-semibold text-neutral-900">
          <p className="overflow-hidden overflow-ellipsis whitespace-nowrap">{text()}</p>
        </div>
      </div>
    );
  }

  if (!count) {
    return (medication.end_date && day > medication.end_date) || day < medication.start_date ? (
      <div
        className="flex h-[43px] w-[52px] cursor-not-allowed items-center justify-center  bg-neutral-50 p-2 font-semibold text-neutral-900"
        onClick={() => notify(type)}
      />
    ) : day > currentDate ? (
      <div
        className="relative flex h-[43px] w-[52px] cursor-pointer items-center justify-center  bg-yellow-50 p-2 font-semibold text-neutral-900"
        onClick={() => notify(futureNotificationTypes[type])}
      />
    ) : (
      <div
        className="flex h-[43px] w-[52px] cursor-pointer items-center justify-center  bg-neutral-50 p-2 font-semibold text-neutral-900 hover:bg-neutral-100"
        onClick={() => handleCreate()}
      >
        <p>-</p>
      </div>
    );
  }

  return (
    <div
      className="flex h-[43px] w-[52px] cursor-pointer items-center justify-center  bg-neutral-50 p-2 font-semibold text-neutral-900 hover:bg-neutral-100"
      onClick={() => handleUpdate(count)}
    >
      <p className="overflow-hidden overflow-ellipsis whitespace-nowrap">{text()}</p>
    </div>
  );
};
