import React from "react";
import { Button } from "@mantine/core";

import rotacloud from "assets/logos/rotacloud.svg";
import { useRotacloudRoles } from "../api/getRotacloudRoles";
import { RolesList } from "../components/RolesList";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { NavLink } from "react-router-dom";

const crumbs = [
  { title: "Payroll Dashboard", href: "/payroll" },
  { title: "Roles", href: "#" },
];

export const Roles = () => {
  const rotacloudRolesQuery = useRotacloudRoles(() => console.log("success"));

  return (
    <div className="flex h-full min-h-0 w-full flex-col items-center overflow-y-auto">
      <div className="flex w-full flex-col">
        <div className=" relative top-0 right-0 flex">
          <div className="flex h-full w-full flex-col p-6">
            <Breadcrumbs items={crumbs} />
            <div className="flex flex-col space-y-4">
              <div className="flex justify-between">
                <p className="text-2xl font-semibold text-gray-900">Roles</p>
                <div className="flex space-x-2">
                  <Button
                    className="bg-jira-button hover:bg-jira-button-hover"
                    onClick={() => rotacloudRolesQuery.refetch()}
                    loading={
                      rotacloudRolesQuery.isLoading ||
                      rotacloudRolesQuery.isFetching
                    }
                    leftIcon={
                      <img
                        src={rotacloud}
                        alt="Rotacloud logo"
                        className="h-6 w-6"
                      />
                    }
                  >
                    Sync Rotacloud Roles
                  </Button>
                  <NavLink
                    className="inline-flex h-[32px] min-h-[32px] items-center justify-center rounded-[3px] bg-jira-button px-3 text-[14.5px] font-semibold text-white hover:bg-jira-button-hover"
                    key="CreateRole"
                    to={`add`}
                  >
                    New Role
                  </NavLink>
                </div>
              </div>
              <div className="pb-8">
                <RolesList />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
