import React, { useState, useEffect } from "react";
import { DateTime } from "luxon";
import {
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleRightIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { Popover, Input, InputWrapper, ActionIcon } from "@mantine/core";

const Calendar: any = ({ label, value, onChange, required, error }: any) => {
  const currentDate = DateTime.local().setZone("Europe/London").startOf("day");

  const [opened, setOpened] = useState(false);
  const [yearAndMonth, setYearAndMonth] = useState(currentDate);
  const [month, setMonth] = useState<number>(currentDate.month);
  const [year, setYear] = useState<number>(currentDate.year);

  const daysInMonth: number = DateTime.local(year, month, 1).setZone("Europe/London").daysInMonth;
  const startOfMonth: number = DateTime.local(year, month, 1).setZone("Europe/London").weekday - 1;

  const weeks: Array<Array<number | null>> = [];
  let days: Array<number | null> = [];

  for (let i = 0; i < startOfMonth; i++) {
    days.push(null);
  }

  for (let i = 1; i <= daysInMonth; i++) {
    days.push(i);
  }

  while (days.length > 0) {
    weeks.push(days.splice(0, 7));
  }

  const handlePrevYear = (): void => {
    const newDate: DateTime = yearAndMonth.minus({ year: 1 });
    setYearAndMonth(newDate.setZone("Europe/London"));
    setMonth(newDate.month);
    setYear(newDate.year);
  };

  const handlePrevMonth = (): void => {
    const newDate: DateTime = yearAndMonth.minus({ months: 1 });
    setYearAndMonth(newDate.setZone("Europe/London"));
    setMonth(newDate.month);
    setYear(newDate.year);
  };

  const handleNextYear = (): void => {
    const newDate: DateTime = yearAndMonth.plus({ year: 1 });
    setYearAndMonth(newDate.setZone("Europe/London"));
    setMonth(newDate.month);
    setYear(newDate.year);
  };

  const handleNextMonth = (): void => {
    const newDate: DateTime = yearAndMonth.plus({ months: 1 });
    setYearAndMonth(newDate.setZone("Europe/London"));
    setMonth(newDate.month);
    setYear(newDate.year);
  };

  return (
    <InputWrapper label={label} className="w-64" error={error && error} required={required ? required : false}>
      <Popover
        opened={opened}
        onClose={() => setOpened(false)}
        gutter={8}
        target={
          <Input
            onClick={() => setOpened((o) => !o)}
            placeholder="Select date"
            value={
              value
                ? DateTime.fromSeconds(value, {
                    zone: "Europe/London",
                  }).toFormat("MMMM d',' yyyy")
                : ""
            }
            classNames={{
              input: "cursor-pointer",
            }}
            rightSection={
              <ActionIcon onClick={() => onChange(null)}>
                <XMarkIcon className="h-4 w-4" />
              </ActionIcon>
            }
          />
        }
        position="bottom"
        className="w-full"
      >
        <div>
          <div className="flex items-center justify-between rounded-md bg-[#f8f8f8] p-1">
            <div>
              <button onClick={handlePrevYear} className="rounded-md border border-gray-200 bg-white p-1 text-gray-500">
                <ChevronDoubleLeftIcon className="h-4 w-4" />
              </button>
              <button
                onClick={handlePrevMonth}
                className="rounded-md border border-gray-200 bg-white p-1 text-gray-500"
              >
                <ChevronLeftIcon className="h-4 w-4" />
              </button>
            </div>
            <p className="font-semibold text-gray-900">{yearAndMonth.toFormat("MMMM yyyy")}</p>
            <div>
              <button
                onClick={handleNextMonth}
                className="rounded-md border border-gray-200 bg-white p-1 text-gray-500"
              >
                <ChevronRightIcon className="h-4 w-4" />
              </button>
              <button onClick={handleNextYear} className="rounded-md border border-gray-200 bg-white p-1 text-gray-500">
                <ChevronDoubleRightIcon className="h-4 w-4" />
              </button>
            </div>
          </div>
          <table className="flex flex-col">
            <thead>
              <tr className="flex text-[#b9b9b9]">
                <th className="w-8 font-medium">Mo</th>
                <th className="w-8 font-medium">Tu</th>
                <th className="w-8 font-medium">We</th>
                <th className="w-8 font-medium">Th</th>
                <th className="w-8 font-medium">Fr</th>
                <th className="w-8 font-medium">Sa</th>
                <th className="w-8 font-medium">Su</th>
              </tr>
            </thead>
            <tbody>
              {weeks.map((week: Array<number | null>, i: number) => (
                <tr key={i} className="flex">
                  {week.map((day: number | null, j: number) => (
                    <td
                      key={j}
                      className={
                        day === null
                          ? "empty h-8 w-8"
                          : "h-8 w-8 cursor-pointer rounded-sm p-1 text-center font-medium text-gray-900 hover:bg-[#f8f8f8]"
                      }
                      onClick={() => {
                        day !== null &&
                          setYearAndMonth(
                            DateTime.fromObject({
                              year,
                              month,
                              day,
                            })
                          );
                        day !== null &&
                          onChange(
                            DateTime.fromObject({
                              year,
                              month,
                              day,
                            }).toSeconds()
                          );
                        setOpened(false);
                      }}
                    >
                      {day}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Popover>
    </InputWrapper>
  );
};

export default Calendar;
