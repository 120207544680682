import React from "react";
import { Select } from "@mantine/core";

export const MedicationRoute = ({ value, onChange, error }: any) => {
  return (
    <div className="flex flex-col space-y-2">
      <div>
        <p className="text-sm font-semibold text-[#5e6c84]">
          Route{" "}
          <span className="text-[#f03e3e]" aria-hidden="true">
            {" "}
            *
          </span>
        </p>
        <p className="mb-2 text-xs font-medium text-[#868e96]">
          How this medication is administered
        </p>
        <Select
          data={[
            { value: "Orally", label: "Orally" },
            { value: "Topical Application", label: "Topical Application" },
            { value: "Inhalation", label: "Inhalation" },
            { value: "Instillation", label: "Instillation" },
            { value: "Intramuscular", label: "Intramuscular" },
            { value: "Intravenous", label: "Intravenous" },
            { value: "Subcutaneous", label: "Subcutaneous" },
            { value: "Transdermal", label: "Transdermal" },
            { value: "Rectal", label: "Rectal" },
            { value: "Vaginal", label: "Vaginal" },
          ]}
          placeholder="Select route"
          value={value}
          onChange={onChange}
        />
        <p className="text-xs font-medium text-[#f03e3e]">
          {error && error[0]}
        </p>
      </div>
    </div>
  );
};
