export const roleColors = {
  f48893: "#f48893",
  ef5464: "#ef5464",
  "95b7de": "#95b7de",
  a73b46: "#a73b46",
  "6798d0": "#6798d0",
  "486a91": "#486a91",
  f79986: "#f79986",
  f36d54: "#f36d54",
  aa4c3b: "#aa4c3b",
  ffdd89: "#ffdd89",
  ffcf56: "#ffcf56",
  b2903c: "#b2903c",
  "8dd4c2": "#8dd4c2",
  "5bc2a7": "#5bc2a7",
  "408775": "#408775",
  f0acce: "#f0acce",
  ea88b9: "#ea88b9",
  a35f81: "#a35f81",
};
