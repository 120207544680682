import { useQuery } from "react-query";
import { AxiosError } from "axios";

import { axios } from "lib/axios";
import { TUser } from "../types";
import { useAuth } from "stores/Auth";
import { supabase } from "lib/supabase";

export const getMe = async (userId: string): Promise<any> => {
  try {
    console.log("getting me", userId);
    if (!!userId) {
      const { data, error } = await supabase.from("profile").select("*, user_role ( role )").eq("id", userId).single();

      console.log("useMe", data);

      // const response = await axios.get(`/me`);
      // if (response.status === 200) {
      //   console.log("response 200", response.status);
      // }
      // Don't forget to return something
      return data;
    }
    throw new Error("The user is not defined");
  } catch (err) {
    console.log("getUser", err);
    if (err instanceof AxiosError) {
      if (err.response?.status === 401) {
        throw new Error("Not authorised");
      } else if (err.response?.status === 403) {
        throw new Error("Not admin");
      }
    }
    throw new Error("There was an error fetching the request");
  }
};

export const useMe = () => {
  const currentUser = useAuth((state) => state.currentUser);
  console.log("useUser", !!currentUser?.id, currentUser?.id);
  return useQuery({
    queryKey: ["me", currentUser?.id],
    queryFn: () => getMe(currentUser?.id),
    staleTime: 10 * (60 * 1000),
    cacheTime: 15 * (60 * 2000),
    enabled: !!currentUser?.id,
  });
};
