import { useState } from "react";
import { Popover, Button } from "@mantine/core";

import { XMarkIcon } from "@heroicons/react/20/solid";
import { useDeleteGroup } from "../api/deleteGroup";

export const DeleteGroup = ({ id }: any) => {
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);

  const deleteGroupMutation = useDeleteGroup(() => setIsDeleteConfirmationOpen(false));

  return (
    <Popover
      opened={isDeleteConfirmationOpen}
      onClose={() => setIsDeleteConfirmationOpen(false)}
      target={
        <XMarkIcon
          onClick={(e: any) => {
            e.stopPropagation();
            e.preventDefault();
            setIsDeleteConfirmationOpen((o) => !o);
          }}
          className="h-5 w-5 bg-red-100 text-red-700"
        />
      }
      position="bottom"
      withArrow
      width={250}
      gutter={1}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <div className="flex flex-col space-y-2">
        <div className="flex flex-col space-y-1">
          <p className="text-[14px] font-medium text-zinc-900">Are you sure you want to delete this group?</p>
          <p className="text-[13px] font-medium text-zinc-700">Once you delete, it's gone for good.</p>
        </div>
        <Button
          className="bg-red-500 hover:bg-red-600"
          onClick={() => {
            deleteGroupMutation.mutateAsync({ groupId: id });
          }}
          loading={deleteGroupMutation.isLoading}
        >
          Delete Group
        </Button>
      </div>
    </Popover>
  );
};
