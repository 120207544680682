import { useState, useEffect } from "react";
import { DateTime } from "luxon";
import { useParams } from "react-router-dom";
import { Select } from "@mantine/core";

import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { MonthPicker } from "features/record/components/MonthPicker";
import { Records } from "features/record/components/Records";
import { useArea } from "features/area/api/getArea";
import { useMedications } from "features/medication/api/getMedications";
import { MedicationPreview } from "components/MedicationPreview";
import { useRecordStore } from "features/record/store/recordStore";
import { useRecords } from "features/record/api/getRecords";
import { UpdateRecord } from "features/record/components/UpdateRecord";
import { CreateCount } from "features/record/components/CreateCount";
import { CreateReturn } from "features/record/components/CreateReturn";
import { UpdateReturn } from "features/record/components/UpdateReturn";
import { CreateReceived } from "features/record/components/CreateReceived";
import { UpdateReceived } from "features/record/components/UpdateReceived";
import { UpdateCount } from "features/record/components/UpdateCount";
import { CreateRecord } from "features/record/components/CreateRecord";
import { CountCellMobile } from "./CountCellMobile";

export const MedicationRecordsMobile = () => {
  const { areaId } = useParams();

  const crumbs = [
    { title: "Medication Dashboard", href: `/areas/${areaId}/medication` },
    { title: "Medication Records", href: "#" },
  ];

  //* Get and set the areas timezone from companies settings
  const timezone = "Europe/London";

  // get first day of calendar
  const currentDate = DateTime.now().setZone(timezone);

  //! TODO:
  //! - Set the selected date, picked by clicking on a RecordCell
  //! - Set the selectedMonth, pass the current date as the default date to the MonthPicker and allow MonthPicker to change the selectedMonth
  const [selectedMonth, setSelectedMonth] = useState({
    start: currentDate.startOf("month"),
    end: currentDate.endOf("month"),
  });

  //! - Use the areas settings for beginning of medication records
  const areaQuery = useArea({ areaId: areaId as string });

  const medicationsQuery: any = useMedications({ areaId });

  const [selectedMedication, setSelectedMedication] = useState<any>(
    medicationsQuery?.data?.medications ? medicationsQuery?.data?.medications[0]?.id : null
  );

  const currentMedication = medicationsQuery?.data?.medications?.find(
    (medication: any) => medication.id === selectedMedication
  );

  const [isMedicationPreviewOpen, setIsMedicationPreviewOpen] = useState(false);
  const setMedicationInformation = useRecordStore((state) => state.setMedicationInformation);

  const recordsQuery: any = useRecords({
    areaId,
    start: selectedMonth.start.toUnixInteger(),
    end: selectedMonth.end.toUnixInteger(),
  });

  const records = recordsQuery?.data?.filter(
    (record: any) => record.medication_id === currentMedication?.id && record.type === "record"
  );
  const countRecords = recordsQuery?.data?.filter(
    (record: any) => record.medication_id === currentMedication?.id && record.type === "count"
  );
  const returnedRecords = recordsQuery?.data?.filter(
    (record: any) => record.medication_id === currentMedication?.id && record.type === "returned"
  );
  const receivedRecords = recordsQuery?.data?.filter(
    (record: any) => record.medication_id === currentMedication?.id && record.type === "received"
  );

  const [rowData, setRowData] = useState<any>([]);

  useEffect(() => {
    //* If there are no medication doses set and no medication_records, set as a single row
    if (records && currentMedication) {
      if (!currentMedication?.doses && records?.length <= 0) {
        setRowData([{ id: 1, row: 1, medication_id: currentMedication?.id }]);
      } else {
        const doses = Math.max(...records.map((record: any) => record.dose));
        //* If there less records than doses, set number of rows to number of doses
        if (records?.length > 0 && doses > currentMedication?.doses) {
          const rows = [];
          for (let i = 1; i <= doses; i++) {
            rows.push({
              id: i,
              row: i,
              medication_id: currentMedication?.id,
            });
          }

          setRowData(rows);
        } else {
          //* If there are more records than doses, set number of rows to number of records
          const rows = [];
          for (let i = 1; i <= currentMedication?.doses; i++) {
            rows.push({
              id: i,
              row: i,
              medication_id: currentMedication?.id,
            });
          }
          setRowData(rows);
        }
      }
    }
  }, [currentMedication, selectedMonth]);

  if (!medicationsQuery?.data) {
    return <p>Loadings</p>;
  }

  return (
    <div className="flex flex-1 bg-white">
      <div className="flex flex-1 flex-col space-y-4 overflow-hidden">
        <div className="flex flex-col space-y-2">
          <div className="flex max-h-9 items-center border-b border-gray-200 py-2 px-5">
            <Breadcrumbs items={crumbs} />
          </div>
          <CreateRecord />

          <UpdateRecord selectedMonth={selectedMonth} />

          <CreateCount />

          <UpdateCount />

          <CreateReturn />

          <UpdateReturn selectedMonth={selectedMonth} />

          <CreateReceived />

          <UpdateReceived selectedMonth={selectedMonth} />
          <div className="px-4">
            {(!areaQuery.isLoading || !areaQuery.isFetching) && (
              <div className="flex justify-between space-x-4">
                <Select
                  data={medicationsQuery?.data?.medications.map((medication: any) => {
                    return { value: medication.id, label: medication.name };
                  })}
                  value={selectedMedication}
                  onChange={setSelectedMedication}
                />
                <MonthPicker
                  selectedMonth={selectedMonth}
                  setSelectedMonth={setSelectedMonth}
                  backdateTo={DateTime.fromSeconds(Number(areaQuery?.data?.medication_records_backdate), {
                    zone: "Europe/London",
                  })}
                />
              </div>
            )}
          </div>
        </div>

        <MedicationPreview open={isMedicationPreviewOpen} setOpen={setIsMedicationPreviewOpen} />

        {currentMedication && (
          <div
            className="border-t border-gray-200 px-4 pt-2"
            onClick={() => {
              setIsMedicationPreviewOpen(true);
              setMedicationInformation(currentMedication);
            }}
          >
            <p className="font-semibold text-jira-button hover:cursor-pointer hover:underline">
              {currentMedication?.name} {currentMedication?.dose}
            </p>
            <p className="text-sm font-semibold text-gray-500">
              {currentMedication?.type} / {currentMedication?.administered_dose}
            </p>
          </div>
        )}

        <div className="flex flex-col space-y-2 overflow-auto">
          <div className="flex flex-col ">
            <div className="sticky top-0 z-50 flex">
              <div className="sticky left-0 flex h-[52px] w-full items-center justify-between justify-self-start border-t border-r border-b border-gray-200 bg-gray-50">
                <div className="flex h-[52px] w-[52px] items-center justify-center border-r border-gray-200 p-2">
                  <p className="font-semibold text-gray-900">Date</p>
                </div>
                <div className="flex flex-1">
                  {rowData.map((row: any, index: any) => (
                    <div
                      className="flex h-[52px] w-[52px] items-center justify-center border-r border-gray-200 p-2"
                      key={row.row}
                    >
                      <p className="font-semibold text-gray-900">{row.row}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              {records && (
                <RecordDayRow
                  selectedMonth={selectedMonth}
                  areaId={areaId}
                  records={records}
                  countRecords={countRecords}
                  medication={currentMedication}
                  doseColumn={rowData}
                  currentDate={currentDate}
                  returnedRecords={returnedRecords}
                  receivedRecords={receivedRecords}
                />
              )}
            </div>
            {/* <Records selectedMonth={selectedMonth} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const RecordDayRow = ({
  selectedMonth,
  records,
  medication,
  countRecords,
  doseColumn,
  currentDate,
  returnedRecords,
  receivedRecords,
}: any) => {
  const timezone = "Europe/London";

  const [days, setDays] = useState<any>([]);

  const daysInMonth = Array.from(Array(Number(selectedMonth.start.daysInMonth)).keys());

  useEffect(() => {
    const allDays = daysInMonth.map((day: number) => {
      return {
        value: DateTime.local(selectedMonth.start.c.year, selectedMonth.start.c.month, day + 1, {
          zone: timezone,
        }).toUnixInteger(),
        label: DateTime.local(selectedMonth.start.c.year, selectedMonth.start.c.month, day + 1, {
          zone: timezone,
        }).toLocaleString({
          weekday: "short",
          day: "2-digit",
        }),
      };
    });

    setDays(allDays);
  }, [selectedMonth]);

  function countRecord(day: any) {
    const record = countRecords?.find((record: any) => record.date === day.value.toString());

    return record;
  }

  function returnRecord(day: any) {
    const record = returnedRecords?.find((record: any) => record.date === day.value.toString());

    console.log("return record", record);

    return record;
  }

  function receivedRecord(day: any) {
    const record = receivedRecords?.find((record: any) => record.date === day.value.toString());

    console.log("return record", record);

    return record;
  }

  if (!medication) {
    return <p>None</p>;
  }

  return (
    <div className="flex flex-col bg-white">
      {days.map((day: any) => (
        <div className="flex">
          <RecordDay key={day.value} day={day.label} />
          <div className="flex w-full flex-col">
            <div className="flex">
              {records &&
                doseColumn.map((dose: any) => (
                  <RecordRow day={day} records={records} dose={dose} medication={medication} />
                ))}
            </div>
            <div className="grid grid-cols-3 border-r border-t border-b border-gray-200 bg-gray-50">
              <CountCellMobile
                type="end"
                medication={medication}
                day={day.value}
                count={countRecord(day)}
                isMobile
                title="End count"
                currentDate={currentDate.toUnixInteger()}
              />
              <CountCellMobile
                type="return"
                medication={medication}
                day={day.value}
                count={returnRecord(day)}
                isMobile
                title="Returned count"
                currentDate={currentDate.toUnixInteger()}
              />
              <CountCellMobile
                type="received"
                medication={medication}
                day={day.value}
                count={receivedRecord(day)}
                isMobile
                title="Received count"
                currentDate={currentDate.toUnixInteger()}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const RecordDay = ({ day }: any) => {
  return (
    <div className="flex w-[52px] min-w-[52px] flex-1 items-center justify-center border-b border-r border-gray-200 p-2 font-semibold text-gray-900">
      <p>{day}</p>
    </div>
  );
};

const RecordRow = ({ day, records, dose, medication }: any) => {
  const fullyTaken = <div className="h-[30px] w-[30px] rounded-full border-2 border-[#309A30] bg-[#D2F9D2]"></div>;
  const partiallyTaken = (
    <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full border-2 border-[#98671D] bg-[#FFE0A4]">
      <p className="text-sm font-semibold text-[#98671D]">p/t</p>
    </div>
  );
  const actualRefusal = (
    <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full border-2 border-[#AF251C] bg-[#FABCBB]">
      <p className="text-sm font-semibold text-[#AF251C]">a/r</p>
    </div>
  );
  const passiveRefusal = (
    <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full border-2 border-[#AF251C] bg-[#FABCBB]">
      <p className="text-sm font-semibold text-[#AF251C]">p/r</p>
    </div>
  );
  const notObserved = (
    <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full border-2 border-[#AF251C] bg-[#FABCBB]">
      <p className="text-sm font-semibold text-[#AF251C]">n/o</p>
    </div>
  );
  const destroyedMedication = (
    <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full border-2 border-[#AF251C] bg-[#FABCBB]">
      <p className="text-sm font-semibold text-[#AF251C]">d/m</p>
    </div>
  );
  const missedMedication = (
    <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full border-2 border-[#AF251C] bg-[#FABCBB]">
      <p className="text-sm font-semibold text-[#AF251C]">m/m</p>
    </div>
  );
  const administeredByHospital = (
    <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full border-2 border-[#5B5B5B] bg-[#F3F3F3]">
      <p className="text-sm font-semibold text-[#5B5B5B]">a/h</p>
    </div>
  );
  const administeredByFamily = (
    <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full border-2 border-[#5B5B5B] bg-[#F3F3F3]">
      <p className="text-sm font-semibold text-[#5B5B5B]">a/f</p>
    </div>
  );
  const selfAdministered = (
    <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full border-2 border-[#5B5B5B] bg-[#F3F3F3]">
      <p className="text-sm font-semibold text-[#5B5B5B]">s/a</p>
    </div>
  );

  const otherOptionsList: any = {
    "Fully taken": fullyTaken,
    "Partially taken": partiallyTaken,
    "Actual refusal": actualRefusal,
    "Passive refusal": passiveRefusal,
    "Not observed": notObserved,
    "Destroyed medication": destroyedMedication,
    "Missed medication": missedMedication,
    "Administered by hospital": administeredByHospital,
    "Administered by family": administeredByFamily,
    "Self-administered": selfAdministered,
  };

  const dayRecord = records.find((record: any) => record.date === day.value.toString() && record.dose === dose.row);

  const setIsCreateRecordModalOpen = useRecordStore((state) => state.setIsCreateRecordModalOpen);
  const setSelectedRow = useRecordStore((state) => state.setSelectedRow);
  const setIsUpdateRecordModalOpen = useRecordStore((state) => state.setIsUpdateRecordModalOpen);
  const setUpdateInformation = useRecordStore((state) => state.setUpdateInformation);
  const setSelectedDate = useRecordStore((state) => state.setSelectedDate);
  const setSelectedMedication = useRecordStore((state) => state.setSelectedMedication);
  const useNotify = useRecordStore((state) => state.useNotify);

  const { notify } = useNotify();

  if ((medication.end_date && day.value > medication.end_date) || day.value < medication.start_date) {
    return (
      <div
        className="flex h-[52px] w-full cursor-not-allowed items-center justify-center bg-gray-100"
        onClick={() => {
          // handleNotAllowed("record");
          notify("record");
        }}
      />
    );
  }

  if (!dayRecord) {
    return (
      <div
        className="flex h-[52px] w-[52px] cursor-pointer items-center justify-center border-r border-gray-200"
        onClick={() => {
          setSelectedDate(day.value);
          setSelectedMedication(medication);
          setSelectedRow(dose.row);
          setIsCreateRecordModalOpen(true);
        }}
      >
        <p className="">-</p>
      </div>
    );
  }

  return (
    <div
      className="flex h-[52px] w-[52px] items-center justify-center border-r border-gray-200"
      onClick={() => {
        setIsUpdateRecordModalOpen(true);
        setUpdateInformation(dayRecord);
      }}
    >
      {otherOptionsList[dayRecord?.outcome]}
    </div>
  );
};
