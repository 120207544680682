import { useState } from "react";
import { DateTime } from "luxon";
import { Routes, Route, NavLink } from "react-router-dom";

import { useUsers } from "features/users/api/getUsers";
import { Timesheet } from "components/Timesheet/Timesheet";
import { TimesheetList } from "./TimesheetList";

export const Timesheets = () => {
  const usersQuery = useUsers();

  if (usersQuery.isError) {
    <p>Error</p>;
  }

  return (
    <div className="flex h-full min-h-0 w-full flex-col items-center overflow-y-hidden">
      <Routes>
        <Route path="" element={<TimesheetList />} />
        <Route path=":id/:start/:end" element={<Timesheet />} />
      </Routes>
    </div>
  );
};
