import React from "react";
import { Button } from "@mantine/core";
import { Link } from "react-router-dom";

import { GroupType } from "../types";
import { useGroups } from "../api/getGroups";

export const Group = () => {
  const groupsQuery = useGroups();

  return (
    <div className="flex h-full w-full items-start justify-center bg-gray-100 p-6">
      <div className="relative mt-5 w-full space-y-8 md:col-span-2 md:mt-0">
        {groupsQuery.data?.map((group: GroupType) => (
          <div className="space-y-4">
            <div className="flex justify-between">
              <p className="text-xl font-semibold text-gray-900">{group.name}</p>
              <Button
                className="inline-flex justify-center rounded-md border border-transparent bg-blue-500 text-sm font-medium text-white shadow-sm hover:bg-blue-600 focus:outline-none"
                component={Link}
                to={`${group.id}`}
              >
                Edit Group
              </Button>
            </div>
            <div className="rounded-lg border border-[#e0e1e3] bg-white shadow-sm">
              <div className="grid grid-cols-5 gap-8 rounded-t-lg border-b border-[#e0e1e3] bg-[#f9fafb] px-6 py-3">
                <p className="col-span-1"></p>
                <p className="col-span-2 font-semibold text-gray-900">Name</p>
                <p className="col-span-2 font-semibold text-gray-900">Users</p>
              </div>
              {group.areas.map((area: any) => (
                <Link
                  className="grid cursor-pointer grid-cols-5 items-center gap-8 border-b border-[#e0e1e3] px-6 py-3 hover:bg-gray-50 hover:bg-opacity-50"
                  to={`${area.id}`}
                >
                  <img
                    src={area?.avatar ? area?.avatar : "area"}
                    alt=""
                    className="col-span-1 h-10 w-10 rounded-full"
                  />
                  <p className="col-span-2 font-medium text-gray-900">{area.name}</p>
                  <p className="col-span-2 text-gray-600">{area.name}</p>
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
