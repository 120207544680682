import { Route, Routes } from "react-router-dom";

import { FormsDashboard } from "./FormsDashboard";
import { Forms } from "./Forms";
import { CreateForm } from "./CreateForm";
import { FormSubmission } from "./FormSubmission";
import { Form } from "./Form";
import { Response } from "./Response";

export const FormsRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<FormsDashboard />} />
      <Route path="add" element={<CreateForm />} />
      <Route path="list" element={<Forms />} />
      <Route path=":formId" element={<Form />} />
      <Route path=":formId/add" element={<FormSubmission />} />
      <Route path=":formId/:responseId" element={<Response />} />
    </Routes>
  );
};
