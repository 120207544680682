import { memo, useEffect, useState } from "react";
import { DateTime, DurationObjectUnits } from "luxon";
import { Button, Select, Modal } from "@mantine/core";
import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";

import { CreateShiftModal } from "./CreateShiftModal";
import { useRotacloudShifts } from "features/admin/payroll/timesheet/api/getRotacloudShifts";
import rotacloud from "assets/logos/rotacloud.svg";
import { useNotification } from "hooks/useNotification";
import { useUser } from "features/users/api/getUser";
import { useUserRoles } from "features/admin/payroll/timesheet/api/getUserRoles";
import { useCreateTimesheet } from "features/admin/payroll/timesheet/api/createTimesheet";
import { TimesheetSideMenu } from "./TimesheetSideMenu";
import { CloudArrowUpIcon } from "@heroicons/react/24/outline";
import { ShiftList } from "./ShiftList";

export const Timesheet = () => {
  const { addNotification } = useNotification();

  const { id, start, end } = useParams();

  const userQuery = useUser(id);
  const rolesQuery = useUserRoles(() => console.log("success"), id as string);

  const dateStart = DateTime.local(2023, 3, 15, { zone: "Europe/London" });
  const dateEnd = DateTime.local(2023, 4, 15, { zone: "Europe/London" });

  const [dateDiff, setDateDiff] = useState({
    start: Number(start),
    end: Number(end),
  });

  const dateRange = DateTime.fromSeconds(dateDiff.end, {
    zone: "Europe/London",
  })
    .diff(DateTime.fromSeconds(dateDiff.start, { zone: "Europe/London" }), ["days"])
    .toObject();

  const days = Array.from(Array(dateRange.days).keys());

  const [isCreateShiftOpen, setIsCreateShiftOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState(dateStart);

  const rotacloudShiftsQuery = useRotacloudShifts({
    start: dateDiff.start,
    end: dateDiff.end,
    id: id,
    success: () => {
      addNotification({
        disallowClose: false,
        type: "success",
        title: "Retrieved Rotacloud shifts successfully",
        message: "",
      });
    },
  });

  const createTimesheetMutation = useCreateTimesheet({
    start: dateDiff.start,
    end: dateDiff.end,
    id: id,
    success: () => {
      addNotification({
        disallowClose: false,
        type: "success",
        title: "Timesheet created successfully",
        message: "",
      });
    },
  });

  const [rotacloudShiftModal, setRotacloudShiftModal] = useState<boolean>(false);

  const handleRotacloudShifts = () => {
    console.log("WHY IS THIS RUNNING? 1");
    rotacloudShiftsQuery.refetch();
  };

  return (
    <div className="flex w-full flex-col items-center overflow-y-hidden">
      <CreateShiftModal
        open={isCreateShiftOpen}
        setOpen={setIsCreateShiftOpen}
        selectedDate={selectedDate}
        start={dateDiff.start}
        end={dateDiff.end}
        roles={rolesQuery.data}
      />
      <Modal
        opened={rotacloudShiftModal}
        onClose={() => {
          setRotacloudShiftModal(false);
        }}
        centered
        withCloseButton={false}
      >
        <div className="space-y-4">
          <div className="space-y-1">
            <p className="text-lg font-semibold text-neutral-900">Sync Rotacloud Shifts</p>
            <p className="text-neutral-500">
              Syncing Rotacloud shifts will remove any previously synced shifts, even if you have updated these. Are you
              happy to proceed?
            </p>
          </div>
          <div className="flex space-x-2">
            <Button variant="default" onClick={() => setRotacloudShiftModal(false)}>
              Cancel
            </Button>
            <Button
              variant="filled"
              onClick={() => handleRotacloudShifts()}
              loading={rotacloudShiftsQuery.isLoading || rotacloudShiftsQuery.isFetching}
            >
              Yes, sync my shifts
            </Button>
          </div>
        </div>
      </Modal>
      <div className="flex w-full overflow-y-hidden">
        <TimesheetSideMenu id={id} start={dateDiff.start} end={dateDiff.end} admin={false} />
        <div className="flex w-full flex-col overflow-y-auto">
          <div className="flex flex-col">
            <div className="flex h-10 max-h-10 justify-between border-b border-neutral-300 bg-neutral-50 px-5">
              <div className="flex gap-6">
                <NavLink
                  to={`../../timesheet/${id}/${start}/${end}`}
                  className={({ isActive }: any) =>
                    isActive
                      ? "flex cursor-pointer items-center space-x-2 border-b-2 border-jira-button py-2 text-sm font-medium text-neutral-900 transition hover:opacity-100"
                      : "flex cursor-pointer items-center space-x-2 py-2 text-sm font-medium text-neutral-900 opacity-50 transition hover:opacity-100"
                  }
                >
                  <p>Timesheet</p>
                </NavLink>
                <NavLink
                  to={`../../expenses/${id}/${start}/${end}`}
                  end
                  className={({ isActive }: any) =>
                    isActive
                      ? "flex cursor-pointer items-center space-x-2 border-b-2 border-jira-button py-2 text-sm font-medium text-neutral-900 transition hover:opacity-100"
                      : "flex cursor-pointer items-center space-x-2 py-2 text-sm font-medium text-neutral-900 opacity-50 transition hover:opacity-100"
                  }
                >
                  <p>Expenses</p>
                </NavLink>
              </div>
              <div className="flex items-center">
                <Button
                  variant="filled"
                  onClick={() => setRotacloudShiftModal(true)}
                  loading={rotacloudShiftsQuery.isLoading || rotacloudShiftsQuery.isFetching}
                  leftIcon={<img src={rotacloud} alt="das" className="h-6 w-6" />}
                >
                  Sync Rotacloud Shifts
                </Button>
              </div>
            </div>

            <div className="flex h-10 items-center justify-between px-5 py-1.5">
              <div className="flex items-center gap-4">
                <div>
                  <MonthRangePicker setDateRange={setDateDiff} start={start} />
                </div>
              </div>

              <div className="flex">
                <div>
                  <Button
                    variant="default"
                    onClick={async () => {
                      const data = {
                        user_id: id,
                        salary: userQuery?.data?.salary ? userQuery.data.salary : 0,
                        submitted: false,
                        locked: false,
                        start: dateDiff.start,
                        end: dateDiff.end,
                      };
                      await createTimesheetMutation.mutateAsync({ data });
                    }}
                    loading={createTimesheetMutation.isLoading}
                    leftIcon={<CloudArrowUpIcon className="h-5 w-5 text-neutral-500" />}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>

            <ShiftList
              userId={id ? id : ""}
              rate={userQuery?.data ? (userQuery?.data?.salary as number) : 0}
              start={dateDiff.start}
              end={dateDiff.end}
              days={days}
              setIsCreateShiftOpen={setIsCreateShiftOpen}
              setSelectedDate={setSelectedDate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const MonthRangePicker = memo(({ setDateRange, start }: any) => {
  const [data, setData] = useState<any>([]);
  const [value, setValue] = useState<any>("");

  const date = data.find(
    (item: { value: string; label: string; date: { start: number; end: number } }) => item.value === value
  )?.date;

  useEffect(() => {
    if (date) {
      setDateRange(date);
    }
  }, [date]);

  const now = DateTime.now().setZone("Europe/London");

  const backdateTo = DateTime.local(2020, 5, 15, { zone: "Europe/London" });

  const startMonth = DateTime.local(now.year, now.month, 15, {
    zone: "Europe/London",
  });
  const endMonth = startMonth.plus({ month: 1 });

  //* Get the current payroll date, if the current day is before the start of the next payroll, show previous month, if not, show next (e.g if on the 14th of Feb, show Jan-Feb if after the 15th of Feb, show Feb-Mar)

  const handleData = () => {
    const difference: DurationObjectUnits = endMonth.diff(backdateTo, "months").toObject();

    let data = [];

    if (difference.months) {
      for (let i = 0; i < difference.months; i++) {
        const start = backdateTo.plus({ month: i });
        const end = backdateTo.plus({ month: i + 1 });

        data.push({
          value: start.toUnixInteger().toString(),
          label: `${start.toLocaleString({
            day: "2-digit",
            month: "short",
          })} - ${end.toLocaleString({
            day: "2-digit",
            month: "short",
            year: "numeric",
          })}`,
          date: { start: start.toUnixInteger(), end: end.toUnixInteger() },
        });
      }
    }
    const reversedList = data.reverse();

    console.log(reversedList);

    return reversedList;
  };

  useEffect(() => {
    const data = handleData();
    setData(data);
    setValue(start);
  }, []);

  return (
    <div className="inline-block">
      {/* <p onClick={setData}>{startMonth.toString()}</p>
      <p>{endMonth.toString()}</p>
      <p>{startMonth.toUnixInteger().toString()}</p>
      <p>{endMonth.toUnixInteger().toString()}</p>
      <div>
        <p>Selected Date Range</p>
        <p>{date && date.start.toString()}</p>
        <p>{date && date.end.toString()}</p>
      </div> */}
      <Select
        data={data}
        value={value}
        onChange={setValue}
        classNames={{
          root: "h-[28px] min-h-[28px]",
          wrapper: "h-[28px] min-h-[28px]",
          input: "h-[28px] min-h-[28px]",
          defaultVariant: "h-[28px] min-h-[28px]",
        }}
        className="h-[28px] min-h-[28px]"
      />
    </div>
  );
});
