import { useEffect, useState } from "react";
import { SubmitHandler } from "react-hook-form";

import { Views } from "./Views";
import { View } from "./View";
import { CreateMarker } from "./CreateMarker";
import { CreateNotes } from "./CreateNotes";
import { BodypartType } from "./Bodypart";
import { BodypartList } from "./BodypartList";

type BodymapType = {
  closeBodymap: () => void;
  NotesForm: React.FC;
  markerToUpdate?: any | null;
  view?: number | null;
  defaultValues: object;
  description: string;
  saveItem: Function;
  addItem: Function;
};

export const Bodymap = ({
  markerToUpdate,
  defaultValues,
  closeBodymap,
  NotesForm,
  view,
  description,
  saveItem,
  addItem,
}: BodymapType) => {
  const [selectedBodypart, setSelectedBodypart] = useState<null | BodypartType>(null);
  const [activeView, setActiveView] = useState(0);
  const [marker, setMarker] = useState<any | null>(null);
  const [instruction, setInstruction] = useState("");
  const [id, setId] = useState("");

  useEffect(() => {
    // set if active view is passed
    if (view) {
      setActiveView(view);
    } else {
      setActiveView(0);
    }
  }, [view]);

  // if prop markerToUpdate has been passed, make this the selected bodypart. This is used for editing rather than creating
  useEffect(() => {
    if (markerToUpdate) {
      setActiveView(1);
      setSelectedBodypart({
        id: markerToUpdate.bodypart_id,
        name: markerToUpdate.bodypart_name,
        image: markerToUpdate.bodypart_image,
      });
      setMarker({
        x: markerToUpdate.x,
        y: markerToUpdate.y,
        width: markerToUpdate.width,
        height: markerToUpdate.height,
        type: markerToUpdate.type,
      });
      setInstruction(markerToUpdate.notes.instructions);
      setId(markerToUpdate.id);
      console.log("selectedBodypoart", markerToUpdate, markerToUpdate.notes.instruction);
    } else {
      setInstruction("");
      setId("");
    }
  }, [markerToUpdate]);

  const prevView = () => {
    if (activeView > 0) {
      setActiveView(activeView - 1);
    } else {
      closeBodymap();
    }
  };

  const nextView = () => {
    if (activeView < 2) {
      setActiveView(activeView + 1);
    } else {
      closeBodymap();
    }
  };

  const submitForm = (values: any) => {
    nextView();
    setMarker(null);
  };

  return (
    <div
      className={`
      flex h-full min-h-0 w-full max-w-xl flex-col bg-white p-1`}
    >
      <Views activeView={activeView}>
        <View title="Select Bodypart" prevView={prevView} closeBodymap={closeBodymap}>
          <BodypartList setSelectedBodypart={setSelectedBodypart} nextView={nextView} />
        </View>
        <View
          title="Create Instruction"
          prevView={prevView}
          closeBodymap={closeBodymap}
          markerToUpdate={markerToUpdate}
        >
          <CreateMarker selectedBodypart={selectedBodypart} nextView={nextView} marker={marker} setMarker={setMarker} />
        </View>
        <View title="Add Notes" prevView={prevView} closeBodymap={closeBodymap}>
          <CreateNotes
            NotesForm={NotesForm}
            defaultValues={defaultValues}
            onSubmit={submitForm}
            description={description}
            selectedBodypart={selectedBodypart}
            instruction={instruction}
            marker={marker}
            markerToUpdate={markerToUpdate}
            id={id}
            saveItem={saveItem}
            addItem={addItem}
          />
        </View>
      </Views>
    </div>
  );
};
