import { useState, useEffect } from "react";
import { Modal, Select, Button, ActionIcon, Textarea } from "@mantine/core";
import { DateTime } from "luxon";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { z } from "zod";
import { useParams } from "react-router-dom";

import { TimeInput } from "components/TimeInput/TimeInput";
import { useRecordStore } from "../store/recordStore";
import { useCreateRecord } from "features/record/api/createRecord";

export const CreateRecord = () => {
  const { areaId } = useParams();

  const isCreateRecordModalOpen = useRecordStore(
    (state) => state.isCreateRecordModalOpen
  );
  const setIsCreateRecordModalOpen = useRecordStore(
    (state) => state.setIsCreateRecordModalOpen
  );
  const selectedRow = useRecordStore((state) => state.selectedRow);
  const selectedDate = useRecordStore((state) => state.selectedDate);
  const selectedMedication = useRecordStore(
    (state) => state.selectedMedication
  );

  const otherOptionsList = [
    "Fully taken",
    "Partially taken",
    "Actual refusal",
    "Passive refusal",
    "Not observed",
    "Destroyed medication",
    "Missed medication",
    "Administered by hospital",
    "Administered by family",
    "Self-administered",
  ];

  const createRecordMutation = useCreateRecord(() =>
    setIsCreateRecordModalOpen(false)
  );

  const handleNewRecord = async ({
    outcome,
    timeAdministered,
    notes,
  }: {
    outcome: string | null;
    timeAdministered: number;
    notes?: string | null;
  }) => {
    await createRecordMutation.mutateAsync({
      data: {
        medication_id: selectedMedication.id,
        type: "record",
        date: selectedDate,
        submitted_date: currentDate.toUnixInteger(),
        outcome: outcome,
        dose: selectedRow,
        area_id: areaId,
        time_administered: timeAdministered,
        notes: notes,
      },
    });
  };

  const [formErrors, setFormErrors] = useState<any>(null);

  console.log("selectedMedication", selectedMedication, selectedDate);

  const defaultTime = DateTime.fromSeconds(selectedDate, {
    zone: "Europe/London",
  });

  const [outcome, setOutcome] = useState<string | null>(otherOptionsList[0]);
  const [time, setTime] = useState<any>(defaultTime);
  const [timeAdministered, setTimeAdministered] = useState<number>(0);
  const [notes, setNotes] = useState<string | undefined>(undefined);

  useEffect(() => {
    setTimeAdministered(time.toSeconds());
  }, [time]);

  useEffect(() => {
    setTime(defaultTime);
  }, [selectedDate]);

  const currentDate: any = DateTime.now().setZone("Europe/London");

  const RecordForm = z.object({
    outcome: z.string().min(1, "Outcome is required"),
    time: z
      .number()
      .max(
        currentDate.ts,
        "You cannot administer medication past the current time"
      ),
  });

  return (
    <Modal
      opened={isCreateRecordModalOpen}
      onClose={() => {
        setIsCreateRecordModalOpen(false);
        setFormErrors(null);
      }}
      centered
      withCloseButton={false}
    >
      <div className="flex flex-col space-y-4">
        <div className="flex items-center justify-between">
          <div className="flex space-x-2">
            <p className="text-[16px] font-semibold text-gray-900">
              {selectedMedication.name}{" "}
            </p>
            <p className="text-[16px] font-semibold text-[#6b778c]">{`${DateTime.fromSeconds(
              Number(selectedDate),
              {
                zone: "Europe/London",
              }
            ).toLocaleString({
              year: "numeric",
              month: "numeric",
              day: "numeric",
            })}`}</p>
          </div>
          <div className="flex space-x-1">
            <ActionIcon
              onClick={() => {
                setIsCreateRecordModalOpen(false);
              }}
            >
              <XMarkIcon className="h-6 w-6 text-[#42526E]" />
            </ActionIcon>
          </div>
        </div>
        <Select
          data={otherOptionsList}
          value={outcome}
          onChange={setOutcome}
          label="Outcome"
        />
        <div className="flex flex-col space-y-1">
          <TimeInput
            defaultTime={defaultTime}
            value={time}
            onChange={setTime}
            showLabel
          />
          <p className="text-xs font-medium text-red-500">
            {formErrors?.fieldErrors &&
              formErrors.fieldErrors?.time &&
              formErrors.fieldErrors?.time[0]}
          </p>
        </div>
        <div>
          <Textarea
            value={notes}
            onChange={(e: any) => setNotes(e.currentTarget.value)}
            label="Notes"
          />
        </div>
        <div>
          <Button
            className="bg-jira-button hover:bg-jira-button-hover"
            onClick={() => {
              console.log("Outcome: ", currentDate);

              try {
                RecordForm.parse({
                  time: time.ts,
                  outcome: outcome,
                });
                handleNewRecord({
                  outcome: outcome,
                  timeAdministered: timeAdministered,
                  notes: notes,
                });
              } catch (error: any) {
                console.log("error: ", error);
                setFormErrors(error.flatten());
              }
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};
