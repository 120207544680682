import { CountCell } from "./CountCell";

type TCountRowProps = {
  type: "end" | "return" | "received";
  days: any;
  counts: any[];
  medication: any;
  currentDate: any;
};

export const CountRow = ({ type, days, counts, medication, currentDate }: TCountRowProps) => {
  const countTypes = {
    end: "End count",
    return: "Return count",
    received: "Received count",
  };

  return (
    <div className="flex w-full border-b border-neutral-300 bg-neutral-50 bg-opacity-70">
      <div className="sticky left-0 z-10 flex w-32 items-center bg-neutral-50 p-2">
        <p className="font-medium text-neutral-400">{countTypes[type]}</p>
      </div>
      <div className="flex">
        {days.map((day: any) => (
          <CountCell
            type={type}
            day={day.day}
            count={counts.find((count: any) => Number(count.date) === day.day)}
            medication={medication}
            currentDate={currentDate}
          />
        ))}
      </div>
    </div>
  );
};
