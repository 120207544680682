import { clamp } from "@mantine/hooks";
import { Text } from "@mantine/core";
import { useRef } from "react";

export const TimeField = ({ onChange, setValue, value, withSeparator }: any) => {
  const hoursRef = useRef<any>();

  const handleClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    event.stopPropagation();
    hoursRef.current.select();
  };

  return (
    <div className="flex items-center">
      <input
        type="text"
        ref={hoursRef}
        onChange={(event) => onChange(event.currentTarget.value, true)}
        onClick={handleClick}
        value={value}
        placeholder="--"
        className="inline-block w-[23px] border-none bg-transparent p-0 text-center outline-none"
      />

      {withSeparator && (
        <div className="text-center">
          <p>:</p>
        </div>
      )}
    </div>
  );
};
