import { useEffect, useState } from "react";
import {
  Drawer,
  TextInput,
  Select,
  Button,
  ActionIcon,
  LoadingOverlay,
  Modal,
} from "@mantine/core";
import { PlusIcon } from "@heroicons/react/24/solid";
import { z } from "zod";

import { useCreateUser } from "../api/createUser";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { useInviteUser } from "../api/inviteUser";

type UserInformation = {
  email: string;
  name: string;
  level: string;
  role: string;
  password?: string;
};

export const CreateUser = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const inviteUserMutation = useInviteUser(() => setOpen(false));

  const [email, setEmail] = useState<string>("");
  const [formErrors, setFormErrors] = useState<any>([]);

  const inviteForm = z.object({
    email: z
      .string()
      .min(1, { message: "Please enter a valid email" })
      .email("Please enter a valid email"),
  });

  const handleInviteUser = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setFormErrors([]);

    try {
      inviteForm.parse({
        email,
      });

      inviteUserMutation.mutateAsync({ email: email });
    } catch (error: any) {
      console.log(error.flatten());
      setFormErrors(error.flatten());
    }
  };

  return (
    <Modal
      opened={open}
      onClose={() => setOpen(false)}
      withCloseButton={false}
      centered
      trapFocus={false}
      classNames={{
        modal: "p-0",
      }}
      size="sm"
    >
      <div className="rounded-t-md border-b border-gray-100 bg-zinc-50 px-4 py-3">
        <p className="text-[15px] font-medium text-gray-900">
          Invite a new user
        </p>
      </div>
      <form
        className="space-y-4 rounded-full bg-white py-6 px-4"
        onSubmit={(e) => handleInviteUser(e)}
      >
        <TextInput
          label="User email"
          icon={<EnvelopeIcon className="h-5 w-5" />}
          classNames={{
            input: "pl-8",
          }}
          error={
            formErrors.fieldErrors?.email && formErrors.fieldErrors?.email[0]
          }
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
        />
        <Button
          className="w-full bg-jira-button hover:bg-jira-button-hover"
          type="submit"
          loading={inviteUserMutation.isLoading}
        >
          Invite user
        </Button>
      </form>
    </Modal>
  );
};

const Old = () => {
  const createUserMutation = useCreateUser();

  const [open, setOpen] = useState(false);

  const [email, setEmail] = useState("leon.boulbin@outlook.com");
  const [name, setName] = useState("Leon Test");
  const [level, setLevel] = useState("user");
  const [role, setRole] = useState("Support and Education Mentor");

  const values = { email: email, name: name, level: level, role: role };

  const [roleList, setRoleList] = useState([
    {
      value: "Support and Education Mentor",
      label: "Support and Education Mentor",
    },
    { value: "Team Leader", label: "Team Leader" },
    { value: "Developer", label: "Developer" },
  ]);

  const [formErrors, setFormErrors] = useState({
    email: "",
    name: "",
    password: "",
  });

  const [showNextPage, setShowNextPage] = useState(false);
  const [accountInformation, setAccountInformation] =
    useState<UserInformation | null>();

  const handleCancel = () => {
    setEmail("");
    setName("");
    setLevel("");
    setRole("");
    setOpen(false);
    setTimeout(() => {
      setShowNextPage(false);
      setAccountInformation(null);
    }, 1000);
  };

  useEffect(() => {
    if (typeof createUserMutation.data !== "undefined") {
      console.log("userMutation", createUserMutation.data);
      setAccountInformation({
        name: name,
        email: email,
        level: level,
        role: role,
        password: createUserMutation.data.data.temporaryPassword,
      });
    }
  }, [createUserMutation.data]);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setShowNextPage(false);
      setAccountInformation(null);
    }, 1000);
  };

  return (
    <>
      <Button
        className="bg-jira-button hover:bg-jira-button-hover"
        onClick={() => setOpen(true)}
      >
        Create user
      </Button>

      <Drawer
        opened={open}
        onClose={handleClose}
        position="right"
        withCloseButton={false}
        overlayOpacity={0.25}
        className=" top-[45px]"
        trapFocus={false}
        size="25%"
      >
        <div className="flex min-h-full max-w-full flex-col justify-between space-y-2 py-6 px-10">
          <div className="flex flex-grow flex-col justify-start">
            {showNextPage ? (
              <div className="flex flex-grow flex-col justify-between space-y-6 text-gray-800">
                <div className="space-y-6">
                  <p className=" text-2xl font-bold text-gray-900">
                    {name} added to active users
                  </p>
                  <p className="text-[15px]">
                    The user {name} has now been created, they have been sent a
                    verification email to their inbox (please make sure to check
                    spam/junk).
                  </p>
                  <div className="space-y-2 text-[15px]">
                    <p className="font-semibold">User Details</p>
                    <p className="">
                      Name:{" "}
                      <span className="font-medium">
                        {accountInformation?.name}
                      </span>
                    </p>
                    <p>
                      Email:{" "}
                      <span className="font-medium">
                        {accountInformation?.email}
                      </span>
                    </p>
                    <p>
                      User Type:{" "}
                      <span className="font-medium">
                        {accountInformation?.level}
                      </span>
                    </p>
                    <p>
                      User Type:{" "}
                      <span className="font-medium">
                        {accountInformation?.role}
                      </span>
                    </p>
                    <p>
                      Temp Password:{" "}
                      <span className="font-medium">
                        {accountInformation?.password}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="flex min-w-full">
                  <Button
                    onClick={handleCancel}
                    className="bg-blue-500 hover:bg-blue-600"
                  >
                    Close
                  </Button>
                </div>
              </div>
            ) : (
              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                  await createUserMutation.mutateAsync({ data: values });
                  setShowNextPage(true);
                }}
                className="relative flex flex-grow flex-col justify-between"
              >
                <LoadingOverlay visible={createUserMutation.isLoading} />
                <div className="space-y-4">
                  <p className="text-lg font-semibold text-gray-900">
                    Create user
                  </p>
                  <TextInput
                    label="Email"
                    placeholder="Your email address"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    error={formErrors.email}
                  />
                  <TextInput
                    label="Full Name"
                    placeholder="Your name"
                    value={name}
                    required
                    onChange={(e) => setName(e.target.value)}
                    error={formErrors.name}
                  />
                  <Select
                    label="User Type"
                    placeholder="Select a user type"
                    data={[
                      { value: "user", label: "User" },
                      { value: "manager", label: "Manager" },
                      { value: "administrator", label: "Administrator" },
                    ]}
                    required
                    value={level}
                    onChange={(value: string) => setLevel(value)}
                  />
                  <Select
                    label="User Role"
                    placeholder="Select or create a user role"
                    data={roleList}
                    required
                    creatable
                    searchable
                    allowDeselect
                    clearable
                    getCreateLabel={(query) => `+ Create ${query}`}
                    onCreate={(query) =>
                      setRoleList((current: any) => [...current, query])
                    }
                    value={role}
                    onChange={(value: string) => setRole(value)}
                  />
                </div>
                <div className="mt-6 space-x-3">
                  <Button
                    className="bg-jira-button hover:bg-jira-button-hover"
                    type="submit"
                  >
                    Create user
                  </Button>
                  <Button
                    type="reset"
                    className="bg-none font-medium text-slate-600 hover:bg-jira-button-subtle"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            )}
          </div>
        </div>
      </Drawer>
    </>
  );
};
