import { ChevronLeftIcon, XMarkIcon } from "@heroicons/react/24/outline";

export const Header = ({
  title,
  prevView,
  closeBodymap,
}: {
  title: string;
  prevView: Function;
  closeBodymap: Function;
}) => {
  return (
    <div className="flex w-full justify-between p-4">
      <div className="flex w-1/4 items-center space-x-1 text-jira-button">
        <ChevronLeftIcon className="h-6 w-6" />
        <p className="cursor-pointer text-[17px] font-medium" onClick={() => prevView()}>
          Back
        </p>
      </div>
      <p className="w-2/4 text-center font-medium text-neutral-900">{title}</p>
      <div className="flex w-1/4 cursor-pointer items-end justify-end">
        <XMarkIcon className="h-6 w-6 rounded hover:bg-neutral-200" onClick={() => closeBodymap()} />
      </div>
    </div>
  );
};
