import { useMutation } from "react-query";
import { queryClient } from "lib/react-query";

import { axios } from "lib/axios";
import { useNotification } from "hooks/useNotification";

export type CreateRecordType = {
  data: {
    type: "record" | "count" | "received" | "returned";
    date: number;
    submitted_date: number;
    medication_id: number;
    area_id: string | undefined;
    notes?: string | null;
    dose?: number;
    time_administered?: number;
    outcome?: string | null;
    count?: number | null;
    returned_to?: string | null;
    returned_amount?: string | null;
    received_from?: string | null;
    received_amount?: string | null;
  };
};

export const createRecord = ({ data }: CreateRecordType) => {
  console.log("createRecord", data);

  return axios.post(`/records/${data.area_id}`, data);
};

export const useCreateRecord = (successFunction: Function) => {
  const { addNotification } = useNotification();

  return useMutation({
    onError: (_, __, context) => {
      addNotification({
        disallowClose: false,
        type: "error",
        title: "Could not add record",
        message: "",
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("records");
      successFunction();
      addNotification({
        disallowClose: false,
        type: "success",
        title: "Record added",
        message: "",
      });
    },
    mutationFn: createRecord,
  });
};
