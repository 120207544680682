import { useMutation } from "react-query";
import { queryClient } from "lib/react-query";

import { axios } from "lib/axios";
import { useNotification } from "hooks/useNotification";

export type TDeleteClaimType = {
  data: {
    name: string;
  };
};

export const deleteClaim = ({ claimId }: { claimId: number }) => {
  console.log("deleteClaim", claimId);
  return axios.delete(`/claims/${claimId}`, { data: { claimId } });
};

export const useDeleteClaim = (successFunction?: Function) => {
  const { addNotification } = useNotification();

  return useMutation({
    onMutate: async (deletedClaim) => {
      await queryClient.cancelQueries("claims");

      const previousClaims = queryClient.getQueryData<any[]>("claims");

      queryClient.setQueryData(
        "claims",
        previousClaims?.filter((claim) => claim.id !== deletedClaim.claimId)
      );

      return { previousClaims };
    },
    onError: (_, __, context) => {
      addNotification({
        disallowClose: true,
        type: "error",
        title: "Medicine Not Deleted",
        message: "Could not delete medicine!",
      });
      if (context?.previousClaims) {
        queryClient.setQueryData("claims", context.previousClaims);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries("claims");
      successFunction && successFunction();
      addNotification({
        disallowClose: true,
        type: "success",
        title: "Medicine Deleted",
        message: "Medicine Deleted Successfully!",
      });
    },
    mutationFn: deleteClaim,
  });
};
