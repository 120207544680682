import { Marker } from "./Marker";

export const MarkerList = ({ bodymap }: any) => {
  console.log("bodymap BodymapList: ", bodymap);
  return (
    bodymap && (
      <div className="">
        <p className="border-b-2 border-[#DFE1E6] pb-1 text-sm font-bold uppercase text-gray-500">
          Bodymap
        </p>
        <div className="border-b-2 border-[#DFE1E6]">
          {bodymap.map((bodymap: any) => (
            <Marker marker={bodymap} />
          ))}
        </div>
      </div>
    )
  );
};
