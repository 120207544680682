import { useState } from "react";
import { Checkbox, NumberInput, Select, TextInput } from "@mantine/core";

import { useAuth } from "stores/Auth";
import {
  TAccount,
  TCurrency,
  TWeekStarts,
  TTimeFormat,
  TPayFrequency,
} from "../types";
import { DateTime } from "luxon";

export const Settings = () => {
  // const account = useAuth((state) => state.account);

  // const [companyName, setCompanyName] = useState<string | undefined>(
  //   account ? account.name : undefined
  // );
  // const [companyTimezone, setCompanyTimezone] = useState<string | null>(
  //   account ? account.timezone.zone : null
  // );
  // const [companyIndustry, setCompanyIndustry] = useState<string | null>(
  //   account ? account.industry : null
  // );
  // const [companyCurrency, setCompanyCurrency] = useState<string | null>(
  //   account ? account.currency : null
  // );
  // const [companyWeekStart, setCompanyWeekStart] = useState<string | null>(
  //   account ? account.week_starts : null
  // );
  // const [companyTimeFormat, setCompanyTimeFormat] = useState<string | null>(
  //   account ? account.time_format : null
  // );
  // const [companyHolidayAccural, setCompanyHolidayAccrual] = useState<
  //   number | undefined
  // >(account ? account.holiday_accrual_rate : undefined);
  // const [companyShiftsClash, setCompanyShiftsClash] = useState<
  //   boolean | undefined
  // >(account ? account.shifts_clash_default : undefined);
  // const [companyPayFrequency, setCompanyPayFrequency] = useState<string | null>(
  //   account ? account.pay_frequency : null
  // );

  // const data: TAccount = {
  //   name: companyName,
  //   timezone: companyTimezone,
  //   industry: companyIndustry,
  //   currency: companyCurrency,
  //   week_starts: companyWeekStart,
  //   time_format: companyTimeFormat,
  //   shifts_clash_default: companyShiftsClash,
  //   pay_frequency: companyPayFrequency,
  //   holiday_accural_rate: companyHolidayAccural,
  // };

  // // onSubmit={async (e) => {
  // //   e.preventDefault();
  // //   await createUserMutation.mutateAsync({ data: values });
  // //   setShowNextPage(true);
  // // }}

  // const currency: { value: TCurrency; label: TCurrency }[] = [
  //   { value: "£", label: "£" },
  //   { value: "€", label: "€" },
  //   { value: "$", label: "$" },
  // ];

  // if (!account) {
  //   return null;
  // }

  return (
    <div className="flex h-screen w-screen flex-col overflow-y-scroll bg-gray-100 p-6">
      <div className="flex w-[800px] flex-col space-y-2 rounded-md border border-gray-200 bg-white p-6">
        <p className="text-2xl font-semibold text-gray-900">Company Details</p>
        <div className="grid grid-cols-2 gap-x-10 gap-y-2">
          {/* <TextInput
            label="Company name"
            value={companyName}
            onChange={(e) => setCompanyName(e.currentTarget.value)}
          />
          <Select
            data={[{ value: "Europe/London", label: "London" }]}
            label="Time zone"
            value={companyTimezone}
            onChange={setCompanyTimezone}
          />
          <Select
            data={[{ value: "Supported Living", label: "Supported Living" }]}
            label="Industry"
            value={companyIndustry}
            onChange={setCompanyIndustry}
          />
          <Select
            data={currency}
            label="Currency"
            value={companyCurrency}
            onChange={setCompanyCurrency}
          /> */}
        </div>
        <div className="flex space-x-4">
          {/* <Select
            data={[
              { value: "mon", label: "Monday" },
              { value: "tue", label: "Tuesday" },
              { value: "wed", label: "Wednesday" },
              { value: "thu", label: "Thursday" },
              { value: "fri", label: "Friday" },
              { value: "sat", label: "Saturday" },
              { value: "sun", label: "Sunday" },
            ]}
            className="w-[120px]"
            label="Week starts"
            value={companyWeekStart}
            onChange={setCompanyWeekStart}
          />
          <Select
            data={[
              { value: "24_hour", label: "24 Hour" },
              { value: "12_hour", label: "12 Hour" },
            ]}
            className="w-[100px]"
            label="Time format"
            value={companyTimeFormat}
            onChange={setCompanyTimeFormat}
          /> */}
        </div>
        <div className="flex items-end space-x-1">
          {/* <NumberInput
            className="w-[70px]"
            label="Holiday accrual rate"
            value={companyHolidayAccural}
            onChange={setCompanyHolidayAccrual}
            precision={2}
            hideControls
          /> */}
          <p className="py-2">%</p>
        </div>
        {/* <Checkbox
          label="Shifts clash by default"
          checked={companyShiftsClash}
          onChange={(event) =>
            setCompanyShiftsClash(event.currentTarget.checked)
          }
        />
        <Select
          data={[
            { value: "weekly", label: "Weekly" },
            { value: "monthly", label: "Monthly" },
          ]}
          className="w-[120px]"
          label="Pay frequency"
          value={companyPayFrequency}
          onChange={setCompanyPayFrequency}
        />
        <Leave
          month={account.leave_year_starts.month}
          day={account.leave_year_starts.day}
        /> */}
      </div>
    </div>
  );
};

const Leave = ({ month, day }: any) => {
  const [selectedMonth, setSelectedMonth] = useState(month ? month : 1);
  const [selectedDay, setSelectedDay] = useState(day ? day : 1);

  const getDaysInMonth = ({ month }: any) => {
    const date = DateTime.local().set({ month });
    const daysInMonth = date.daysInMonth;
    const daysArray = [];

    for (let i = 1; i <= daysInMonth; i++) {
      daysArray.push(i.toString());
    }

    return daysArray;
  };

  return (
    <div className="flex flex-col space-y-1">
      <p className="text-sm font-semibold text-gray-500">Leave year starts</p>
      <div className="flex space-x-1">
        <Select
          data={getDaysInMonth({ month: selectedMonth })}
          className="w-[80px]"
          value={selectedDay.toString()}
          onChange={(e) => setSelectedDay(Number(e))}
        />
        <Select
          data={[
            { label: "January", value: "1" },
            { label: "February", value: "2" },
            { label: "March", value: "3" },
            { label: "April", value: "4" },
            { label: "May", value: "5" },
            { label: "June", value: "6" },
            { label: "July", value: "7" },
            { label: "August", value: "8" },
            { label: "September", value: "9" },
            { label: "October", value: "10" },
            { label: "November", value: "11" },
            { label: "December", value: "12" },
          ]}
          className="w-[140px]"
          value={selectedMonth.toString()}
          onChange={(e) => setSelectedMonth(Number(e))}
        />
      </div>
    </div>
  );
};
