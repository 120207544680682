import { useState, useEffect } from "react";
import { TextInput, NumberInput, Textarea, ActionIcon } from "@mantine/core";
import { XMarkIcon } from "@heroicons/react/20/solid";

export const MileageLeg = ({
  leg,
  legIndex,
  removeLeg,
  onValueChange,
}: any) => {
  const [note, setNote] = useState<string | undefined>(undefined);
  const [from, setFrom] = useState<string | undefined>(undefined);
  const [to, setTo] = useState<string | undefined>(undefined);
  const [distance, setDistance] = useState<number | undefined>(undefined);

  const currentLeg = legIndex + 1;

  useEffect(() => {
    onValueChange({
      id: leg.id,
      note,
      from,
      to,
      distance: distance ? distance : 0,
    });
  }, [note, from, to, distance]);

  return (
    <div className="border-b border-gray-200">
      <div className="pb-4">
        <div className="py-2">
          <div className="flex items-center justify-between">
            <div className="flex space-x-2">
              <p className="font-semibold text-gray-900">
                Leg <span>{currentLeg}</span>
              </p>
            </div>
            {currentLeg !== 1 && (
              <div className="flex space-x-1">
                <ActionIcon
                  onClick={() => {
                    console.log("deleteLeg");
                    removeLeg(leg.id);
                  }}
                  size="sm"
                >
                  <XMarkIcon className="h-5 w-5 text-[#42526E]" />
                </ActionIcon>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col space-y-2">
          <TextInput
            label="From"
            value={from}
            onChange={(e) => setFrom(e.currentTarget.value)}
          />
          <TextInput
            label="To"
            value={to}
            onChange={(e) => setTo(e.currentTarget.value)}
          />
          <NumberInput
            label="Distance (miles)"
            value={distance}
            onChange={setDistance}
            hideControls
            precision={2}
          />
          <Textarea
            label="Notes"
            value={note}
            onChange={(e) => setNote(e.currentTarget.value)}
          />
        </div>
      </div>
    </div>
  );
};
