import { useEffect, useState } from "react";
import { Button, TextInput, LoadingOverlay } from "@mantine/core";
import { Link } from "react-router-dom";
import { Route, Routes, NavLink, useLocation } from "react-router-dom";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";

import { AreaPicture } from "../components/AreaPicture";
import { ADMIN } from "config";
import { UsersSelect } from "../components/UsersSelect";
import { Sections } from "../components/Sections";
import { AreaGroup } from "../components/AreaGroup";
import { FormSection } from "components/Form/FormSection";

type TSettingsGeneralProps = {
  control: any;
  errors: any;
  area: any;
};

export const SettingsGeneral = ({
  control,
  errors,
  area,
}: TSettingsGeneralProps) => {
  return (
    <div className="flex flex-1 flex-col space-y-6 ">
      <FormSection
        title="Area details"
        subtitle="This is the basic information of the area"
      >
        <div className="flex flex-col space-y-3">
          <div>
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <TextInput
                  {...field}
                  placeholder="Enter the area name"
                  label="Name"
                  description="The areas name, this might be the of a person, a place or anything else you like!"
                  error={errors.name && errors.name?.message}
                />
              )}
            />
          </div>

          <Controller
            control={control}
            name="avatar"
            render={({ field: { value, onChange } }) => (
              <AreaPicture avatar={value} setAvatar={onChange} />
            )}
          />
        </div>
      </FormSection>

      <FormSection
        title="Area sections"
        subtitle="Choose the sections you want your area to have, or for the default experince, leave it as is!"
      >
        <Controller
          control={control}
          name="sections"
          render={({ field: { value, onChange } }) => (
            <Sections
              previousSections={area.sections}
              selectedSections={value}
              setSelectedSections={onChange}
              isDefaultSections={false}
            />
          )}
        />
      </FormSection>

      <FormSection
        title="Area group"
        subtitle="Select the group to assign this area to"
      >
        <Controller
          control={control}
          name="group"
          render={({ field: { value, onChange } }) => (
            <AreaGroup selectedGroup={value} setSelectedGroup={onChange} />
          )}
        />
      </FormSection>

      <FormSection
        title="Area users"
        subtitle="Select users to give them access to this area"
      >
        <Controller
          control={control}
          name="selectedUsers"
          render={({ field: { value, onChange } }) => (
            <UsersSelect
              area={area}
              selectedUsers={value}
              setSelectedUsers={onChange}
            />
          )}
        />
      </FormSection>
    </div>
  );
};
