import { useState, useRef, useEffect } from "react";

import { BodypartType } from "./Bodypart";
import { ControlBar } from "./ControlBar";

export const CreateMarker = ({
  selectedBodypart,
  nextView,
  marker,
  setMarker,
}: {
  selectedBodypart: BodypartType | null;
  nextView?: Function;
  marker: any;
  setMarker: Function;
}) => {
  const [markerType, setMarkerType] = useState<"draw" | "place">("place");

  const { svg, x, y } = useMousePosition();

  const [startCoordinates, setStartCoordinates] = useState({ x: 0, y: 0 });

  const [isMouseDown, setIsMouseDown] = useState(false);

  useMarkerType(marker, setMarkerType);

  // draw box
  const startDrawing = (e: any, drawingType: "touch" | "mouse") => {
    setIsMouseDown(true);
    setStartCoordinates({ x: x, y: y });

    if (drawingType === "mouse") {
      if (markerType === "draw") {
        setMarker({
          type: "rectangle",
          x: x,
          y: y,
          width: 0,
          height: 0,
        });
      } else {
        setMarker({
          type: "pin",
          x: x,
          y: y,
        });
      }
    } else {
      if (markerType === "draw") {
        setMarker({
          type: "rectangle",
          x: x,
          y: y,
          width: 0,
          height: 0,
        });
      } else {
        setMarker({
          type: "pin",
          x: x,
          y: y,
        });
      }
    }
  };

  const stopDrawing = (e: any, drawingType: "touch" | "mouse") => {
    setIsMouseDown(false);
  };

  const addRectangle = () => {
    if (isMouseDown) {
      const width = Math.abs(x - startCoordinates.x);
      const height = Math.abs(y - startCoordinates.y);

      const rx = x < startCoordinates.x ? x : startCoordinates.x;
      const ry = y < startCoordinates.y ? y : startCoordinates.y;

      if (marker.x !== rx) setMarker({ ...marker, x: rx, type: "rectangle" });
      if (marker.y !== ry) setMarker({ ...marker, y: ry, type: "rectangle" });

      if (marker.width !== width) {
        setMarker({ ...marker, width: width, type: "rectangle" });
      }
      if (marker.height !== height) setMarker({ ...marker, height: height, type: "rectangle" });

      return (
        <rect
          x={marker.x}
          y={marker.y}
          width={marker.width}
          height={marker.height}
          fill="#ef4444"
          fillOpacity={0.4}
          stroke="#ef4444"
        />
      );
    }
  };

  return (
    <div className="flex h-full max-h-[900px] w-full max-w-[800px] flex-col justify-between">
      <div className="flex h-full flex-col overflow-hidden">
        <ControlBar selectedBodypart={selectedBodypart} markerType={markerType} setMarkerType={setMarkerType} />

        <div className="flex h-full min-h-0 items-center justify-center px-2">
          <div className="grid h-full max-h-[800px] w-full max-w-[800px] grid-cols-1 grid-rows-1 overflow-hidden bg-[#fafcff]">
            <div className="z-50 col-start-1 row-start-1 h-full max-h-[800px] w-full max-w-[800px]">
              <svg
                className={`flex aspect-square h-full max-h-[800px] w-full max-w-[800px] touch-none`}
                onTouchStart={(e) => {
                  e.preventDefault();
                  startDrawing(e, "touch");
                }}
                onTouchEnd={(e) => {
                  e.preventDefault();
                  stopDrawing(e, "touch");
                }}
                onMouseDown={(e) => startDrawing(e, "mouse")}
                onMouseUp={(e) => stopDrawing(e, "mouse")}
                // used to stop the dragging of a ghost image
                onDrag={(e) => {
                  console.log("drag");
                  e.preventDefault();
                }}
                onDragStart={(e) => {
                  console.log("drag");
                  e.preventDefault();
                }}
                viewBox="0 0 800 800"
                ref={svg}
              >
                {marker ? (
                  marker.type === "rectangle" ? (
                    addRectangle() ? (
                      addRectangle()
                    ) : (
                      <rect
                        x={marker.x}
                        y={marker.y}
                        width={marker.width}
                        height={marker.height}
                        fill="#ef4444"
                        fillOpacity={0.4}
                        stroke="#ef4444"
                      />
                    )
                  ) : (
                    <circle cx={marker.x} cy={marker.y} r={20} fill="#ef4444" fillOpacity={0.4} stroke="#ef4444" />
                  )
                ) : null}
              </svg>
            </div>
            <div className="z-10 col-start-1 row-start-1 h-full max-h-[800px] w-full max-w-[800px]">
              {selectedBodypart && (
                <img
                  src={selectedBodypart?.image}
                  alt="bodypart"
                  className="h-full max-h-[800px] w-full max-w-[800px]"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {nextView && (
        <div className="flex w-full p-4">
          <button
            className="hover:bg-jira-button-hover w-full rounded bg-jira-button px-4 py-2 font-medium text-white"
            onClick={() => nextView()}
          >
            Continue
          </button>
        </div>
      )}
    </div>
  );
};

function useMousePosition() {
  const svg = useRef<any>();
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const rounded = (toRound: number) => {
    return Math.round((toRound + Number.EPSILON) * 100) / 100;
  };

  const setMousePosition = (event: any) => {
    let svgPoint = svg?.current.createSVGPoint();

    if (event) {
      if (event.type === "touchstart" || event.type === "touchmove") {
        console.log("touch event: ", event);
        const touch = event.touches[0];

        svgPoint.x = touch.pageX;
        svgPoint.y = touch.pageY;
        const cursorLocation = svgPoint.matrixTransform(svg.current.getScreenCTM().inverse());

        setPosition({ x: rounded(cursorLocation.x), y: rounded(cursorLocation.y) });
      } else {
        svgPoint.x = event.clientX;
        svgPoint.y = event.clientY;
        const cursorLocation = svgPoint.matrixTransform(svg.current.getScreenCTM().inverse());

        setPosition({
          x: rounded(cursorLocation.x),
          y: rounded(cursorLocation.y),
        });
      }
    } else {
      // setPosition({ x: event.clientX, y: event.clientY });
    }
  };

  useEffect(() => {
    const element = svg?.current ? svg.current : document;
    element.addEventListener("mousemove", setMousePosition as any);
    element.addEventListener("touchstart", setMousePosition as any);
    element.addEventListener("touchmove", setMousePosition as any);
    // if (options.resetOnExit) element.addEventListener('mouseleave', resetMousePosition as any);

    return () => {
      element.removeEventListener("mousemove", setMousePosition as any);
      element.removeEventListener("touchstart", setMousePosition as any);
      element.removeEventListener("touchmove", setMousePosition as any);
      // if (options.resetOnExit) element.removeEventListener('mouseleave', resetMousePosition as any);
    };
  }, [svg.current]);

  return { svg, ...position };
}

function useMarkerType(marker: any, setMarkerType: any) {
  useEffect(() => {
    console.log("CreateMarker marker: ", marker);

    if (marker && marker.type === "rectangle") {
      setMarkerType("draw");
    } else if (marker) {
      setMarkerType("place");
    }
  }, [marker]);
}
