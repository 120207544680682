import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import Compressor from "compressorjs";
import {
  Button,
  NumberInput,
  Select,
  Textarea,
  TextInput,
} from "@mantine/core";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import Calendar from "components/Calendar/Calendar";
import { useCreateClaim } from "../api/createClaim";
import { useAuth } from "stores/Auth";
import { getPayrollBackdate } from "utils/getPayrollBackdate";

export const CreateExpense = () => {
  const currentUser = useAuth((state) => state.currentUser);

  const navigate = useNavigate();

  const createClaimMutation = useCreateClaim(() => {
    navigate("../..");
    console.log("created claim");
  });

  const [files, setFiles] = useState<any>([]);

  const [compressedFile, setCompressedFile] = useState<any>(null);

  const handleCompressedUpload = () => {
    new Compressor(files[0], {
      quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
        setCompressedFile(compressedResult);
      },
    });
  };

  useEffect(() => {
    if (files.length > 0) {
      handleCompressedUpload();
    }
  }, [files]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    multiple: false,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file: any) => (
    <div key={file.name} className="flex flex-1">
      <div
        className="flex h-full w-full items-center justify-center bg-contain bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${file.preview})` }}
        onLoad={() => {
          URL.revokeObjectURL(file.preview);
        }}
      />
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      files.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, []);

  useEffect(() => {
    console.log("thumbs", thumbs);
  }, [thumbs]);

  const [startMonth, endMonth] = getPayrollBackdate("", 15);

  const validationSchema = z
    .object({
      date: z
        .number({ required_error: "Date is required" })
        .min(1, "Date is required"),
      supplier: z
        .string({ required_error: "Supplier is required" })
        .min(1, "Supplier is required"),
      amount: z.number({ required_error: "Amount is required" }).min(1),
      category: z
        .string({ required_error: "Category is required" })
        .min(1, "Category is required"),
      notes: z.string({ required_error: "Notes is required" }),
    })
    .refine((data) => data.date < endMonth.toSeconds(), {
      message: "Expense date cannot be more than one payroll period in advance",
      path: ["date"],
    })
    .refine((data) => data.date >= startMonth.toSeconds(), {
      message: "Expense date cannot be before the payroll backdate period",
      path: ["date"],
    });

  const [data, setData] = useState({});

  const onSubmit = async (data: any) => {
    console.log("onSubmit", data);
    setData(data);

    await createClaimMutation.mutateAsync({
      data: {
        user_id: currentUser.id,
        file: compressedFile,
        type: "Expense",
        ...data,
      },
    });
  };

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    resolver: zodResolver(validationSchema),
  });

  return (
    <div className="flex flex-1 flex-col overflow-y-auto border-r border-gray-200 bg-white">
      <div className="flex items-center justify-between border-b border-gray-200 p-2">
        <p className="font-semibold text-gray-900">New expense</p>
      </div>
      <p> Data: {JSON.stringify(startMonth.toSeconds())}</p>
      <p> Data: {JSON.stringify(endMonth)}</p>
      <p> Data: {JSON.stringify(data)}</p>
      <p> Data: {JSON.stringify(errors)}</p>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-row space-x-4 p-2"
      >
        <section className="w-1/2 max-w-lg space-y-4 self-start rounded-md bg-white p-2">
          <div className="flex max-w-lg flex-col">
            <div className="mb-4 flex flex-col space-y-2">
              <Controller
                control={control}
                name="date"
                render={({ field: { value, onChange } }) => (
                  <Calendar
                    label="Date"
                    value={value}
                    onChange={onChange}
                    required={true}
                    error={errors?.date?.message as string}
                  />
                )}
              />
              <Controller
                control={control}
                name="supplier"
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label="Supplier"
                    placeholder="Enter supplier name"
                    required={true}
                    className="w-full"
                    error={errors?.supplier?.message as string}
                  />
                )}
              />
              <Controller
                control={control}
                name="amount"
                render={({ field }) => (
                  <NumberInput
                    {...field}
                    label="Amount"
                    hideControls
                    placeholder="0.00"
                    precision={2}
                    error={errors?.amount?.message as string}
                  />
                )}
              />
              <Controller
                control={control}
                name="category"
                render={({ field }) => (
                  <Select
                    {...field}
                    data={[
                      {
                        label: "Food",
                        value: "food",
                      },
                      {
                        label: "Office Equipment",
                        value: "office equipment",
                      },
                    ]}
                    label="Category"
                    error={errors?.category?.message as string}
                  />
                )}
              />
              <Controller
                control={control}
                name="notes"
                render={({ field }) => (
                  <Textarea
                    {...field}
                    label="Notes"
                    error={errors?.notes?.message as string}
                  />
                )}
              />
            </div>
            <div className="flex flex-1 justify-end space-x-2">
              <Button
                type="reset"
                component={Link}
                to="../.."
                variant="outline"
                size="xs"
                className="text-sm font-medium shadow"
              >
                Cancel
              </Button>
              <Button
                className="bg-jira-button hover:bg-jira-button-hover"
                size="xs"
                type="submit"
              >
                Save Claim
              </Button>
            </div>
          </div>
        </section>
        <section className="flex h-full w-1/2 max-w-xl flex-col self-start rounded-md bg-white p-2">
          {files.length > 0 && (
            <div className="flex h-3/4 border border-gray-200">
              <aside className="flex flex-1">{thumbs}</aside>
            </div>
          )}
          <div
            {...getRootProps({ className: "dropzone" })}
            className=" mt-1 rounded-md border-2 border-dashed border-gray-300 p-2"
          >
            <input {...getInputProps()} />
            <p className="text-sm font-medium text-gray-500">
              Drag and drop a receipt here
            </p>
          </div>
        </section>
      </form>
    </div>
  );
};
