import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { useParams } from "react-router-dom";
import { useRole } from "../api/getRole";
import { Button, LoadingOverlay, Skeleton, TextInput } from "@mantine/core";
import { UsersSelect } from "features/area/components/UsersSelect";
import { useUpdateRole } from "../api/updateRole";

export const Role = () => {
  const { id } = useParams();

  const roleQuery = useRole(() => console.log("success"), id);
  const updateRoleMutation = useUpdateRole();

  const crumbs = [
    { title: "Payroll Dashboard", href: "/payroll" },
    { title: "Roles", href: "/payroll/roles" },
    { title: `${roleQuery ? roleQuery?.data?.name : "Role"}`, href: "#" },
  ];

  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [name, setName] = useState<string>(
    roleQuery?.data ? roleQuery?.data?.name : ""
  );
  const [color, setColor] = useState<string>("");
  const [payCode, setPayCode] = useState<string>("");

  useEffect(() => {
    if (roleQuery?.data) {
      setName(roleQuery?.data?.name);
      setColor(roleQuery?.data?.colour);
      setPayCode(roleQuery?.data?.pay_code);

      if (roleQuery?.data?.users) {
        const usersArray = roleQuery?.data?.users.map((user: any) => user.id);
        setSelectedUsers(usersArray);
      }
    }
  }, [roleQuery.data]);

  const submitRole = async () => {
    try {
      // AreaForm.parse({
      //   name,
      //   avatar,
      //   selectedBodymap,
      //   selectedSections,
      //   selectedGroup,
      //   selectedUsers,
      // });

      await updateRoleMutation.mutateAsync({
        data: {
          id: Number(id),
          name: name,
          colour: color,
          deleted: false,
          pay_code: "",
          users: selectedUsers,
        },
        roleId: id as string,
      });
    } catch (error: any) {
      // setFormErrors(error.flatten());
      // console.log("form submission error", error, error.flatten());
      // addNotification({
      //   disallowClose: false,
      //   type: "error",
      //   title: "Form not submitted",
      //   message: "There is an error in this form, check if you are missing any required fields and try again",
      // });
    }
  };

  if (roleQuery.isError) {
    <div className="flex h-full min-h-0 w-full flex-col items-center overflow-y-auto">
      <div className="flex w-full flex-col">
        <div className=" relative top-0 right-0 flex">
          <div className="flex h-full w-full flex-col p-6">
            <Breadcrumbs items={crumbs} />
            <div className="flex flex-col space-y-4">
              <div className="flex justify-between">
                <Skeleton width={100} height={36} />
              </div>
              <div className="pb-8"></div>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }

  return (
    <div className="flex h-full min-h-0 w-full flex-col items-center overflow-y-auto">
      <div className="flex w-full flex-col">
        <div className=" relative top-0 right-0 flex">
          <div className="flex h-full w-full flex-col p-6">
            <Breadcrumbs items={crumbs} />
            <form
              className="flex flex-col space-y-4"
              onSubmit={async (e: any) => {
                e.preventDefault();
                await submitRole();
              }}
            >
              <div className="flex justify-between">
                {roleQuery.isLoading || roleQuery.isFetching ? (
                  <Skeleton width={100} height={36} />
                ) : (
                  <p className="text-2xl font-semibold text-gray-900">
                    {roleQuery?.data?.name}
                  </p>
                )}
              </div>
              <div className="pb-8">
                <div className="relative flex max-w-2xl flex-col">
                  <LoadingOverlay
                    visible={roleQuery.isLoading || roleQuery.isFetching}
                  />
                  <div className="flex flex-col space-y-4">
                    <div className="inline-block">
                      <TextInput
                        value={name}
                        onChange={(e: any) => setName(e.currentTarget.value)}
                        label="Name"
                      />
                    </div>
                    {/* <ColorPicker color={color} setColor={setColor} /> */}
                    <div className="inline-block">
                      <TextInput
                        value={payCode}
                        onChange={(e: any) => setPayCode(e.currentTarget.value)}
                        label="Pay Code"
                      />
                    </div>
                    <UsersSelect
                      selectedUsers={selectedUsers}
                      setSelectedUsers={setSelectedUsers}
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center space-x-3">
                <Button
                  type="submit"
                  className="bg-jira-button hover:bg-jira-button-hover"
                  loading={roleQuery.isLoading || roleQuery.isFetching}
                >
                  Save
                </Button>
                <Button
                  className="bg-none font-medium text-slate-600 hover:bg-jira-button-subtle"
                  variant="subtle"
                  color="gray"
                  component={Link}
                  to="/payroll/roles"
                >
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
