import React from "react";
import { Header } from "./Header";

export const View = ({
  children,
  title,
  prevView,
  closeBodymap,
  markerToUpdate,
}: {
  children: React.ReactNode;
  title: string;
  prevView: Function;
  closeBodymap: Function;
  markerToUpdate?: any | null;
}) => {
  return (
    <div className="flex h-full w-full flex-grow flex-col overflow-hidden">
      <Header
        title={markerToUpdate && markerToUpdate.is_editing ? "Update Instruction" : title}
        prevView={prevView}
        closeBodymap={closeBodymap}
      />
      <div className="flex h-full min-h-0">{children}</div>
    </div>
  );
};
