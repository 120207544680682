import { useLocation } from "react-router-dom";

import { useAuth } from "stores/Auth";
import { Navbar } from "components/Layout/Navbar";

export const AppLayout = ({ children }: any) => {
  const path = useLocation();

  const currentUser = useAuth((state) => state.currentUser);

  if (currentUser === null) {
    return <div className="flex h-screen w-screen flex-col overflow-hidden font-sans">{children}</div>;
  }

  return (
    <div className="flex h-screen w-screen flex-col overflow-hidden font-sans">
      {!path.pathname.includes("/auth") && currentUser && <Navbar />}
      {children}
    </div>
  );
};
