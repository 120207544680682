import { nanoid } from "nanoid";
import { useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";

export type NotesProps = {
  NotesForm: React.FC<{ control: any; errors: any; instruction: string }>;
  defaultValues: Object;
  onSubmit: SubmitHandler<any>;
  description?: string;
  instruction: string;
  selectedBodypart: null | {
    id: any;
    name: string;
    image: string;
  };
  marker?: any;
  id?: string;
  saveItem: Function;
  markerToUpdate?: any | null;
  addItem: Function;
};

export const CreateNotes = ({
  NotesForm,
  defaultValues,
  onSubmit,
  description,
  selectedBodypart,
  instruction,
  marker,
  id,
  saveItem,
  markerToUpdate,
  addItem,
}: NotesProps) => {
  console.log("form", selectedBodypart, instruction, marker);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<any>({ defaultValues: { ...defaultValues } });

  useEffect(() => {
    reset({ instructions: instruction });
  }, [instruction]);

  console.log("instruction", instruction);

  useEffect(() => {
    console.log("errors", errors);
  }, [errors]);

  return (
    <div className="flex h-full min-h-full w-full flex-col justify-between overflow-y-auto">
      <form
        onSubmit={handleSubmit((data) => {
          console.log("markerToUpdate", markerToUpdate);
          // onSubmit({
          //   ...(id ? { id: id } : { id: nanoid() }),
          //   bodypart_id: selectedBodypart?.id,
          //   bodypart_name: selectedBodypart?.name,
          //   bodypart_image: selectedBodypart?.image,
          //   ...marker,
          //   notes: { ...data },
          // });

          if (markerToUpdate && markerToUpdate.is_editing) {
            saveItem(markerToUpdate.index, {
              ...(id ? { id: id } : { id: nanoid() }),
              bodypart_id: selectedBodypart?.id,
              bodypart_name: selectedBodypart?.name,
              bodypart_image: selectedBodypart?.image,
              ...marker,
              notes: { ...data },
              is_editing: false,
            });
          } else {
            addItem({
              is_editing: false,
              bodypart_id: selectedBodypart?.id,
              bodypart_name: selectedBodypart?.name,
              bodypart_image: selectedBodypart?.image,
              ...marker,
              notes: { ...data },
            });
          }
        })}
        className="flex h-full w-full flex-col justify-between"
      >
        <div className="space-y-3 px-4 py-1">
          <div className="flex space-x-2">
            <svg className="h-[70px] w-[70px]" viewBox="0 0 800 800">
              <image href={selectedBodypart?.image} className="h-full max-h-[800px] w-full max-w-[800px]" />
              {marker &&
                (marker.type === "rectangle" ? (
                  <rect
                    x={marker.x}
                    y={marker.y}
                    width={marker.width}
                    height={marker.height}
                    fill="#ef4444"
                    fillOpacity={0.4}
                    stroke="#ef4444"
                  />
                ) : (
                  <circle cx={marker.x} cy={marker.y} r={20} fill="#ef4444" fillOpacity={0.4} stroke="#ef4444" />
                ))}
            </svg>
            <div className="flex flex-col space-y-1">
              <p>{selectedBodypart?.name}</p>
              {description && <p className="text-[13px] font-medium text-neutral-400">{description}</p>}
            </div>
          </div>
          <NotesForm control={control} errors={errors} instruction={instruction} />
        </div>
        <div className="flex w-full p-4">
          <button
            className="w-full rounded bg-jira-button px-4 py-2 font-medium text-white shadow transition duration-200 hover:bg-jira-button-hover hover:shadow-lg"
            type="submit"
          >
            {markerToUpdate && markerToUpdate.is_editing ? "Update" : "Add"}
          </button>
        </div>
      </form>
    </div>
  );
};
