import { AreasList } from "features/area/components/AreasList";

export const Dashboard = () => {
  return (
    <div className="flex h-full min-h-0 w-full flex-col items-center overflow-y-auto px-12 py-6">
      <div className="flex w-full max-w-5xl flex-col space-y-8">
        <p className="text-2xl font-semibold text-gray-900">Home</p>

        <AreasList />
      </div>
    </div>
  );
};
