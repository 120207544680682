import { Button, Skeleton } from "@mantine/core";
import { useNavigate } from "react-router-dom";

import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";

export const LoadingForm = ({ type }: { type: "update" | "create" }) => {
  const navigate = useNavigate();

  const createCrumbs = [
    { title: "Roles", href: "../" },
    { title: "Add Role", href: "#" },
  ];

  const updateCrumbs = [
    { title: "Roles", href: "../" },
    { title: "Update Role", href: "#" },
  ];

  return (
    <div className="flex h-full min-h-0 w-full flex-col items-center overflow-y-auto bg-gray-50">
      <div className="flex h-full min-h-0 w-full flex-col">
        <div className=" relative top-0 right-0 flex h-full min-h-0">
          <div className="flex h-full min-h-0 w-full flex-col">
            <div className="flex max-h-9 items-center border-b border-gray-200 py-2 px-5">
              <Breadcrumbs items={type === "create" ? createCrumbs : updateCrumbs} />
            </div>
            <div className="flex flex-col">
              <div className="flex justify-between px-6 pt-2 pb-4">
                <div className="w-full max-w-4xl rounded border border-gray-200 bg-white shadow">
                  <div>
                    <div className="space-y-4 border-b border-gray-200 px-4 py-4">
                      <Skeleton height={36} width="100%" />
                      <Skeleton height={36} width="100%" />
                      <Skeleton height={36} width="100%" />
                      <Skeleton height={110} width={230} />
                      <Skeleton height={20} width={180} />
                      <Skeleton height={20} width={180} />
                      <Skeleton height={20} width={180} />
                      <Skeleton height={100} width={600} />
                    </div>
                    <div className="flex justify-end space-x-2 py-2 px-4">
                      <Button
                        size="xs"
                        compact
                        variant="outline"
                        className="text-xs font-medium shadow"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                      <Button
                        size="xs"
                        compact
                        className="rounded bg-jira-button py-1 px-2 hover:bg-jira-button-hover"
                        loading={true}
                      >
                        {type === "create" ? "Create role" : "Update role"}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
