import { Button } from "@mantine/core";
import {
  ArrowSmallRightIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";

import { getDate } from "../utils";

export const MileageInformation = ({
  selectedClaim,
  deleteClaim,
}: {
  selectedClaim: any | null;
  deleteClaim: any;
}) => {
  if (!selectedClaim) {
    return <p>No claim selected</p>;
  }

  return (
    <div className="flex w-1/2 flex-col border-r border-gray-200 bg-white">
      <div className="flex justify-between border-b border-gray-200 px-4 py-1">
        <div className="flex items-center">
          <p className="font-semibold text-gray-900">
            {selectedClaim?.mileage_legs[0].from}
          </p>
          <span>
            <ArrowSmallRightIcon className="text-jira-[#889096] h-5 w-5" />
          </span>
          <p className="font-semibold text-gray-900">
            {selectedClaim?.mileage_legs?.at(-1).to}
          </p>
        </div>
        <div className="flex items-end justify-end space-x-1">
          <Button
            variant="outline"
            size="xs"
            className="text-sm font-medium text-[#11181c] shadow"
            leftIcon={<PencilSquareIcon className="h-4 w-4 text-[#11181c]" />}
          >
            Edit claim
          </Button>
          <Button
            variant="outline"
            size="xs"
            className="text-sm font-medium text-[#11181c] shadow"
            leftIcon={<TrashIcon className="h-4 w-4 text-[#11181c]" />}
            onClick={() =>
              deleteClaim.mutateAsync({ claimId: selectedClaim?.id })
            }
            loading={deleteClaim.isLoading}
          >
            Delete claim
          </Button>
        </div>
      </div>
      <div className="flex flex-col space-y-2">
        <div className="flex flex-col space-y-2 border-b border-gray-200 py-2 px-4">
          <div>
            <p className="font-semibold text-[#889096]">
              {getDate(selectedClaim)}
            </p>
          </div>
          <div>
            <p className="text-xs font-semibold text-[#889096]">Type</p>
            <p className="font-medium text-gray-900">{selectedClaim?.type}</p>
          </div>
          <div>
            <p className="text-xs font-semibold text-[#889096]">Amount</p>
            <p className="font-medium text-gray-900">
              £{selectedClaim?.amount}
            </p>
          </div>
          <div>
            <p className="text-xs font-semibold text-[#889096]">Miles</p>
            <p className="font-medium text-gray-900">
              {selectedClaim?.total_miles}
            </p>
          </div>
        </div>
        <div>
          {selectedClaim?.mileage_legs.map((leg: any) => (
            <div className="border-b border-gray-200">
              <div className="flex flex-1 flex-col space-y-1 px-4 py-2">
                <div className="flex flex-1 justify-between">
                  <div>
                    <p className="text-sm font-semibold text-[#889096]">From</p>
                    <p className="text-gray-900">{leg?.from}</p>
                  </div>
                  <div className="text-end">
                    <p className="text-sm font-semibold text-[#889096]">To</p>
                    <p className="text-gray-900">{leg?.to}</p>
                  </div>
                </div>
                <div className="flex flex-1 justify-between">
                  <div>
                    <p className="text-sm font-semibold text-[#889096]">
                      Distance
                    </p>
                    <p className="text-gray-900">{leg?.distance}</p>
                  </div>
                  <div className="text-end">
                    <p className="text-sm font-semibold text-[#889096]">
                      Amount
                    </p>
                    <p className="text-gray-900">£{leg?.distance * 0.4}</p>
                  </div>
                </div>
                <div>
                  <p className="text-sm font-semibold text-[#889096]">Note</p>
                  <p className="text-gray-900">{leg?.note}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
