import React from "react";
import { Link, useParams } from "react-router-dom";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { useForm } from "../api/getForm";
import { DateTime } from "luxon";
import { TResponse } from "../types";

export const Form = () => {
  const { areaId, formId } = useParams();
  const formQuery = useForm({ formId });

  const crumbs = [
    { title: "Forms Dashboard", href: ".." },
    { title: "Forms", href: `/areas/${areaId}/forms/list` },
    { title: "Form", href: "#" },
  ];

  const actionLinks = [{ title: "Add Response", href: "add" }];

  return (
    <div className="flex flex-1 flex-col overflow-hidden bg-[#f8f9fa]">
      <div className="flex max-h-12 items-center border-b border-gray-200 py-1.5 px-5">
        <Breadcrumbs items={crumbs} />
      </div>
      <div className="flex h-full min-h-0 w-full flex-col items-start overflow-y-auto p-4 pt-0">
        <div className="mt-4">
          <p className="text-lg font-semibold text-gray-900">
            {formQuery?.data?.title}
          </p>
          <p className="font-medium text-gray-500">
            {formQuery?.data?.description}
          </p>
        </div>

        <div className="mt-4 flex w-full max-w-lg flex-col border-b border-gray-300">
          <div className="border-b border-gray-300 py-1">
            <p className="font-medium text-gray-500">Actions</p>
          </div>
          {actionLinks.map((link: { title: string; href: string }) => (
            <Link
              to={link.href}
              className="w-full max-w-lg flex-1 space-y-3 px-4 py-2 transition-all duration-150 ease-out hover:bg-gray-200"
            >
              <div>
                <p>{link.title}</p>
              </div>
            </Link>
          ))}
        </div>

        <div className="mt-4 flex w-full max-w-lg flex-col border-b border-gray-300">
          {formQuery?.data?.responses ? (
            <div className="border-b border-gray-300 py-1">
              <p className="font-medium text-gray-500">Recent Entries</p>
            </div>
          ) : (
            <div className="border-b border-gray-300 py-1">
              <p className="font-medium text-gray-500">No Responses Yet</p>
            </div>
          )}
          {formQuery?.data?.responses?.map((response: TResponse) => (
            <Link
              to={response.id}
              className="w-full max-w-lg flex-1 space-y-3 p-4 transition-all duration-150 ease-out hover:bg-gray-200"
            >
              <div>
                <p className="text-sm font-semibold text-gray-900">
                  {response.profile?.full_name}
                </p>
                <p className="text-sm font-medium text-gray-500">
                  {DateTime.fromISO(response.created_at, {
                    zone: "Europe/London",
                  }).toFormat("dd MMM',' yyyy HH:mm")}
                </p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};
