import { useMutation } from 'react-query';
import { queryClient } from 'lib/react-query';

import { axios } from "lib/axios";
import { useNotification } from "hooks/useNotification";

export const disableUsers = ({ data }) => {
    return axios.post(`/users/delete`, data);
};

export const useDisableUsers = (closeModal) => {
    const { addNotification } = useNotification();

    return useMutation({
        onMutate: async (disabledUsers) => {
            await queryClient.cancelQueries('users');

            const previousUsers = queryClient.getQueryData('users');

            console.log('deletedUsers', disabledUsers.data.length)

            if (disabledUsers.data.length <= 1) {
                console.log('1', previousUsers.users)
                queryClient.setQueryData('users', previousUsers?.users.filter(
                    (user) => user.id !== disabledUsers.id
                ));
            } else {
                console.log('2')
                let disabledUsersList = []
                disabledUsers.data.map((user) => {
                    disabledUsersList = [
                        ...disabledUsersList,
                        user
                    ]
                });

                console.log('deletedUsersList', disabledUsersList)

                queryClient.setQueryData('users', disabledUsersList);
            }

            return { previousUsers };
        },
        onError: (_, __, context) => {
            if (context?.previousUsers) {
                queryClient.setQueryData('users', context.previousUsers);
            }
            addNotification({
                disallowClose: true,
                type: 'error',
                title: 'Unexpected Error',
                message: 'Could not disable user(s)',
            });
        },
        onSuccess: () => {
            closeModal();
            queryClient.invalidateQueries('users');
            addNotification({
                disallowClose: true,
                type: 'success',
                title: 'Disabled',
                message: 'User(s) disabled Successfully!',
            });
        },
        mutationFn: disableUsers,
    });
};