import { useEffect } from "react";
import { Checkbox, CheckboxGroup } from "@mantine/core";
import { Controller } from "react-hook-form";

import { useUsers } from "features/users/api/getUsers";
import { TUser } from "features/users/types";

type UsersSelectTypes = {
  selectedUsers: string[];
  setSelectedUsers: (value: string[]) => void;
};

export const UsersSelect = ({ value, onChange, previousUsers }: any) => {
  const usersQuery = useUsers();

  const selectAllUsers = () => {
    const allUsers = usersQuery.data?.map((user: TUser) => user.id);

    onChange(allUsers as Array<string>);
  };

  const unselectAllUsers = () => {
    onChange([]);
  };

  useEffect(() => {
    if (previousUsers) {
      const previousUsersArray = previousUsers.map((user: TUser) => user.id);
      console.log("previousUsers", previousUsers, previousUsersArray);
      onChange(previousUsersArray);
    }
  }, [previousUsers, onChange]);

  return (
    <div className="flex flex-col space-y-3">
      <div className="flex flex-col space-y-1">
        <div className="flex space-x-1">
          <button type="button" onClick={() => selectAllUsers()} className="text-sm font-semibold text-jira-button">
            Select all
          </button>
          <span className="">-</span>
          <button type="button" onClick={() => unselectAllUsers()} className="text-sm font-semibold text-jira-button">
            Unselect all
          </button>
        </div>
        <div className="flex w-[600px]">
          <CheckboxGroup
            value={value}
            onChange={onChange}
            spacing={10}
            size="xs"
            classNames={{
              input: "checked:bg-jira-button checked:border-jira-button",
            }}
          >
            {usersQuery.data?.map((user: TUser) => (
              <Checkbox
                value={user.id}
                label={
                  <div className="flex w-full items-center space-x-3 text-sm font-medium text-[#091e42] ">
                    <p>{user.full_name}</p>
                  </div>
                }
              />
            ))}
          </CheckboxGroup>
        </div>
      </div>
    </div>
  );
};
