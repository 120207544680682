import { useState, useEffect } from "react";
import { DateTime, Duration, Interval } from "luxon";
import { Select } from "@mantine/core";

export const MonthPicker = ({ selectedMonth, setSelectedMonth, backdateTo }: any) => {
  console.log("testing selectedMonth: ", selectedMonth, selectedMonth.start.toUnixInteger().toString());

  const timezone = "Europe/London";

  //! create months from the current date
  const { months } = useMonthPicker({
    selectedMonth: selectedMonth,
    setSelectedMonth: setSelectedMonth,
    onChange: setSelectedMonth,
    backdateTo: backdateTo,
  });

  const [pickedDate, setPickedDate] = useState<any>(selectedMonth.start.toUnixInteger().toString());

  useEffect(() => {
    const go = DateTime.fromSeconds(Number(pickedDate), { zone: timezone });

    console.log("pickedDate", pickedDate, go, selectedMonth);

    setSelectedMonth({
      start: go.startOf("month"),
      end: go.endOf("month"),
    });
  }, [pickedDate]);

  return (
    <div className="flex flex-col">
      {months.length > 0 && (
        <div className="flex">
          <Select data={months} value={pickedDate} onChange={setPickedDate} />
        </div>
      )}
    </div>
  );
};

const useMonthPicker = ({ selectedMonth, setSelectedMonth, onChange, backdateTo }: any) => {
  const timezone = "Europe/London";

  const currentDate: any = DateTime.now().setZone(timezone);

  const [totalMonths, setTotalMonths] = useState<any>([]);

  let months: any = [];

  const backdateToMonths: any = Interval.fromDateTimes(backdateTo, currentDate);
  const poo: any = backdateToMonths.end.diff(backdateToMonths.start, ["months"]).toObject();

  console.log("aasdasdas", currentDate, Math.ceil(poo.months));

  useEffect(() => {
    months = [];

    for (let i = 0; i < poo.months; i++) {
      const go: any = DateTime.local(backdateToMonths.start.c.year, backdateToMonths.start.c.month, { zone: timezone })
        .plus({ month: i })
        .startOf("month");

      console.log("aasdasdaszfsda", backdateToMonths.start, currentDate);

      months.push({
        label: DateTime.local(go.c.year, go.c.month, go.c.day, { zone: timezone }).toLocaleString({
          month: "long",
          year: "numeric",
        }),
        value: go.toUnixInteger().toString(),
      });
    }

    const reversedMonths = months.reverse();

    setTotalMonths(reversedMonths);

    return () => setTotalMonths([]);
  }, []);

  // useEffect(() => {
  //   const start = Number(selectedMonth);
  //   const end = DateTime.fromSeconds(Number(selectedMonth), { zone: timezone }).endOf("month").toUnixInteger();
  //   onChange([start, end]);

  //   console.log("start time", start, end);
  // }, [selectedMonth]);

  return {
    months: totalMonths,
  };
};
