import { DateTime } from "luxon";

import { useShifts } from "features/admin/payroll/timesheet/api/getShifts";
import { ShiftRow } from "./ShiftRow";

type TShiftListProps = {
  userId: string;
  rate: number;
  start: number;
  end: number;
  days: any;
  setIsCreateShiftOpen: any;
  setSelectedDate: any;
};

export const ShiftList = ({
  userId,
  rate,
  start,
  end,
  days,
  setIsCreateShiftOpen,
  setSelectedDate,
}: TShiftListProps) => {
  const shiftsQuery = useShifts({
    user_id: userId,
    start: start,
    end: end,
  });

  return (
    <div className="flex flex-col px-5 pb-2">
      <div className="rounded border border-neutral-300">
        <div className="relative grid w-full grid-cols-9 overflow-hidden border-b border-neutral-300 bg-neutral-100 text-sm font-semibold text-neutral-800">
          <div className="col-span-1">
            <div className="p-1">
              <p>Date</p>
            </div>
          </div>
          <div className="col-span-1">
            <div className="p-1">
              <p>Start</p>
            </div>
          </div>
          <div className="col-span-1">
            <div className="p-1">
              <p>Finish</p>
            </div>
          </div>
          <div className="col-span-1">
            <div className="p-1">
              <p>Role</p>
            </div>
          </div>
          <div className="col-span-1">
            <div className="p-1">
              <p>Break</p>
            </div>
          </div>
          <div className="col-span-1">
            <div className="p-1">
              <p>Hours Paid</p>
            </div>
          </div>
          <div className="col-span-1">
            <div className="p-1">
              <p>Pay</p>
            </div>
          </div>
          <div className="col-span-1">
            <div className="p-1">
              <p>Sleep</p>
            </div>
          </div>
          <div className="col-span-1">
            <div className="p-1">
              <p>Notes</p>
            </div>
          </div>
        </div>
        <div className="relative w-full flex-grow overflow-auto font-medium">
          {shiftsQuery.isLoading ? (
            <p>Loading</p>
          ) : (
            days.map((day: number) => {
              const date = DateTime.fromSeconds(start, {
                zone: "Europe/London",
              }).plus({ days: day });
              const otherShifts = shiftsQuery.data?.filter(
                (shift: any) =>
                  shift.start_time >= date.toUnixInteger() && shift.start_time <= date.plus({ day: 1 }).toUnixInteger()
              );
              // const shifts = rotacloudShiftsQuery.data?.filter(
              //   (shift: any) =>
              //     shift.start_time >= date.toUnixInteger() &&
              //     shift.start_time <= date.plus({ day: 1 }).toUnixInteger()
              // );

              return (
                <ShiftRow
                  rate={rate}
                  date={date}
                  shifts={otherShifts ? otherShifts : null}
                  openCreateShift={() => setIsCreateShiftOpen(true)}
                  selectDate={(date: any) => setSelectedDate(date)}
                />
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};
