import { useUsers } from "features/users/api/getUsers";
import { Route, Routes } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { DateTime } from "luxon";
import { useExpenses } from "features/admin/api/getExpenses";

const fullyTaken = (
  <div className="h-[30px] w-[30px] rounded-full border-2 border-[#309A30] bg-[#D2F9D2]"></div>
);
const partiallyTaken = (
  <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full border-2 border-[#98671D] bg-[#FFE0A4]">
    <p className="text-sm font-semibold text-[#98671D]">p/t</p>
  </div>
);
const actualRefusal = (
  <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full border-2 border-[#AF251C] bg-[#FABCBB]">
    <p className="text-sm font-semibold text-[#AF251C]">a/r</p>
  </div>
);
const passiveRefusal = (
  <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full border-2 border-[#AF251C] bg-[#FABCBB]">
    <p className="text-sm font-semibold text-[#AF251C]">p/r</p>
  </div>
);
const notObserved = (
  <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full border-2 border-[#AF251C] bg-[#FABCBB]">
    <p className="text-sm font-semibold text-[#AF251C]">n/o</p>
  </div>
);
const destroyedMedication = (
  <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full border-2 border-[#AF251C] bg-[#FABCBB]">
    <p className="text-sm font-semibold text-[#AF251C]">d/m</p>
  </div>
);
const missedMedication = (
  <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full border-2 border-[#AF251C] bg-[#FABCBB]">
    <p className="text-sm font-semibold text-[#AF251C]">m/m</p>
  </div>
);
const administeredByHospital = (
  <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full border-2 border-[#5B5B5B] bg-[#F3F3F3]">
    <p className="text-sm font-semibold text-[#5B5B5B]">a/h</p>
  </div>
);
const administeredByFamily = (
  <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full border-2 border-[#5B5B5B] bg-[#F3F3F3]">
    <p className="text-sm font-semibold text-[#5B5B5B]">a/f</p>
  </div>
);
const selfAdministered = (
  <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full border-2 border-[#5B5B5B] bg-[#F3F3F3]">
    <p className="text-sm font-semibold text-[#5B5B5B]">s/a</p>
  </div>
);

export const Overview = () => {
  return (
    <div className="flex flex-1 flex-col bg-zinc-50">
      <OverviewTable />
    </div>
  );
};

const OverviewTable = () => {
  const usersQuery = useUsers();

  const months = [
    "Jan-Feb",
    "Feb-Mar",
    "Mar-Apr",
    "Apr-May",
    "May-Jun",
    "Jun-Jul",
    "Jul-Aug",
    "Aug-Sep",
    "Sep-Oct",
    "Oct-Nov",
    "Nov-Dec",
    "Dec-Jan",
  ];

  return (
    <div>
      <div className="flex">
        <div className="w-full max-w-xs border border-gray-200 p-2">
          <p>Payroll</p>
        </div>
        {months.map((month) => (
          <div className="h-14 w-14 border-b border-t border-r border-gray-200 bg-zinc-50 p-1 text-sm font-medium">
            <p>{month}</p>
          </div>
        ))}
      </div>
      {usersQuery?.data?.map(
        (user: any) => user.verified && <OverviewRow user={user} />
      )}
    </div>
  );
};

const OverviewRow = ({ user }: any) => {
  // create list of months within the year using luxon
  const yearMonths: { start: DateTime; end: DateTime }[] = [];

  const payrollDay = 15;

  const currentDate: DateTime = DateTime.local()
    .startOf("year")
    .setZone("Europe/London");

  const startMonth: DateTime = DateTime.local(
    currentDate.year,
    currentDate.month,
    payrollDay,
    {
      zone: "Europe/London",
    }
  );

  for (let i = 0; i < 12; i++) {
    yearMonths.push({
      start: startMonth.plus({ months: i }),
      end: startMonth.plus({ months: i + 1 }),
    });
  }

  const expensesQuery = useExpenses({
    start: yearMonths?.at(0)?.start.toSeconds(),
    end: yearMonths?.at(-1)?.end.toSeconds(),
  });

  const claims = expensesQuery?.data?.filter(
    (expense: any) => expense.user_id === user.id
  );

  return (
    <div className="flex">
      <div className="flex w-full max-w-xs justify-between space-x-2 border-b border-r bg-zinc-50">
        <p>{user.full_name}</p>
        <div className="flex flex-col justify-between">
          <div className="flex h-full items-center p-2">
            <p className="text-sm font-semibold text-gray-500">Timesheet</p>
          </div>
          <div className="flex h-full items-center justify-end p-2">
            <p className="text-sm font-semibold text-gray-500">Expenses</p>
          </div>
        </div>
      </div>
      <div className="flex">
        {yearMonths?.map((month) => (
          <div className="flex flex-col">
            <OverviewCell />
            <OverviewCell
              claim={
                claims?.filter(
                  (claim: any) =>
                    claim.start_date === month.start.toSeconds() &&
                    claim.end_date === month.end.toSeconds()
                )[0]
              }
              month={month}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const OverviewCell = ({ claim, month }: any) => {
  const otherOptionsList: any = {
    "Fully taken": fullyTaken,
    "Partially taken": partiallyTaken,
    "Actual refusal": actualRefusal,
    "Passive refusal": passiveRefusal,
    "Not observed": notObserved,
    "Destroyed medication": destroyedMedication,
    "Missed medication": missedMedication,
    "Administered by hospital": administeredByHospital,
    "Administered by family": administeredByFamily,
    "Self-administered": selfAdministered,
  };

  return (
    <div className=" flex h-14 w-14 items-center justify-center border-b border-r border-gray-200">
      {claim ? otherOptionsList["Fully taken"] : <p>-</p>}
    </div>
  );
};
