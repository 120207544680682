import React from "react";
import { Button } from "@mantine/core";

import rotacloud from "assets/logos/rotacloud.svg";
import { useRoles } from "../api/getRoles";
import { RolesTable } from "../components/RolesTable";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { NavLink } from "react-router-dom";

const crumbs = [
  { title: "Payroll Dashboard", href: "/payroll" },
  { title: "Roles", href: "#" },
];

export const Roles = () => {
  const rotacloudRolesQuery = useRoles(() => console.log("success"));

  return (
    <div className="flex h-full min-h-0 w-full flex-col items-center overflow-y-auto bg-gray-50">
      <div className="flex h-full min-h-0 w-full flex-col">
        <div className=" relative top-0 right-0 flex h-full min-h-0">
          <div className="flex h-full min-h-0 w-full flex-col">
            <div className="flex max-h-9 items-center border-b border-gray-200 py-2 px-5">
              <Breadcrumbs items={crumbs} />
            </div>
            <div className="flex flex-col">
              <div className="flex justify-between px-6 pt-2 pb-4">
                <div className="flex space-x-2">
                  <Button
                    size="xs"
                    compact
                    className="rounded bg-jira-button py-1 px-2 hover:bg-jira-button-hover"
                    onClick={() => rotacloudRolesQuery.refetch()}
                    loading={
                      rotacloudRolesQuery.isLoading ||
                      rotacloudRolesQuery.isFetching
                    }
                    leftIcon={
                      <img
                        src={rotacloud}
                        alt="Rotacloud logo"
                        className="h-6 w-6"
                      />
                    }
                  >
                    Sync Rotacloud Roles
                  </Button>
                  <Button
                    size="xs"
                    compact
                    className="rounded bg-jira-button py-1 px-2 hover:bg-jira-button-hover"
                    key="CreateRole"
                    to={`add`}
                    component={NavLink}
                  >
                    New Role
                  </Button>
                </div>
              </div>
              <div className="px-6">
                <RolesTable />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
