import { useMutation } from "react-query";

import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useNotification } from "hooks/useNotification";
import { TUser } from "../types";

export const updateUser = ({ data, userId }: { data: any; userId: string }) => {
  console.log("updateUser data", data);
  return axios.put(`/users/${userId}`, data);
};

export const useUpdateUser = () => {
  const { addNotification } = useNotification();

  return useMutation({
    onMutate: async (updatingUser: any) => {
      await queryClient.cancelQueries(["user", updatingUser?.userId]);

      const previousUser = queryClient.getQueryData<TUser>(["user", updatingUser?.userId]);

      queryClient.setQueryData(["user", updatingUser?.userId], {
        ...previousUser,
        ...updatingUser.data,
        id: updatingUser.userId,
      });

      return { previousUser };
    },
    onError: (_, __, context: any) => {
      if (context?.previousUser) {
        queryClient.setQueryData(["user", context.previousUser.id], context.previousUser);
      }
    },
    onSuccess: (data) => {
      queryClient.refetchQueries(["user", data.data.id]);
      addNotification({
        type: "success",
        title: "User updated",
        disallowClose: false,
      });
    },
    mutationFn: updateUser,
  });
};
