import { useState, useEffect } from "react";
import { DateTime } from "luxon";
import { PencilSquareIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { useParams } from "react-router-dom";

import {
  Modal,
  Button,
  ActionIcon,
  Textarea,
  Tooltip,
  TextInput,
} from "@mantine/core";
import { useMe } from "features/users/api/getMe";
import { useRecordStore } from "../store/recordStore";
import { useUpdateRecord } from "../api/updateRecord";
import { Logs } from "./Logs";

export const UpdateReturn = ({ selectedMonth }: { selectedMonth: any }) => {
  const { areaId } = useParams();

  const isUpdateReturnModalOpen = useRecordStore(
    (state) => state.isUpdateReturnModalOpen
  );
  const setIsUpdateReturnModalOpen = useRecordStore(
    (state) => state.setIsUpdateReturnModalOpen
  );
  const selectedDate = useRecordStore((state) => state.selectedDate);
  const updateReturnInformation = useRecordStore(
    (state) => state.updateReturnInformation
  );
  const selectedMedication = useRecordStore(
    (state) => state.selectedMedication
  );

  const user = useMe();

  const [returnedTo, setReturnedTo] = useState<string | null>(
    updateReturnInformation ? updateReturnInformation.returnedTo : null
  );
  const [returnedAmount, setReturnedAmount] = useState<string | null>(
    updateReturnInformation ? updateReturnInformation.returnedAmount : null
  );
  const [notes, setNotes] = useState<string | null>(
    updateReturnInformation ? updateReturnInformation.notes : null
  );

  const timezone = "Europe/London";

  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (updateReturnInformation) {
      setReturnedTo(updateReturnInformation.returned_to);
      setReturnedAmount(updateReturnInformation.returned_amount);
      setNotes(updateReturnInformation.notes);
    }
  }, [updateReturnInformation]);

  const currentDate = DateTime.now().setZone(timezone);

  const updateReturnMutation = useUpdateRecord({
    areaId,
    start: selectedMonth.start.toUnixInteger(),
    end: selectedMonth.end.toUnixInteger(),
  });

  const handleUpdateRecord = async () => {
    console.log("outcome: ", updateReturnInformation);

    await updateReturnMutation.mutateAsync({
      data: {
        id: updateReturnInformation.id,
        type: "returned",
        submitted_date: currentDate.toUnixInteger(),
        medication_id: updateReturnInformation.medication_id,
        area_id: areaId,
        previous_notes: notes as string,
        previous_returned_to: returnedTo as string,
        previous_returned_amount: returnedAmount as string,
        logs: updateReturnInformation.logs,
        start: selectedMonth.start.toUnixInteger(),
        end: selectedMonth.end.toUnixInteger(),
      },
    });
  };

  const closeModal = () => {
    setIsUpdateReturnModalOpen(false);
    setIsEditing(false);
    setNotes(null);
  };

  if (!updateReturnInformation) {
    return null;
  }

  return (
    <Modal
      opened={isUpdateReturnModalOpen}
      onClose={closeModal}
      centered
      withCloseButton={false}
    >
      <div className="flex flex-col space-y-4">
        <div className="flex items-center justify-between">
          <div className="flex space-x-2">
            <p className="text-[16px] font-semibold text-gray-900">
              {selectedMedication.name}{" "}
            </p>
            <p className="text-[16px] font-semibold text-gray-500">{`${DateTime.fromSeconds(
              Number(updateReturnInformation.date),
              {
                zone: timezone,
              }
            ).toLocaleString({
              year: "numeric",
              month: "numeric",
              day: "numeric",
            })}`}</p>
          </div>
          <div className="flex space-x-1">
            {user?.data?.level !== "user" && !isEditing && (
              <ActionIcon
                onClick={() => {
                  setIsEditing(true);
                }}
              >
                <PencilSquareIcon className="h-6 w-6 text-[#42526E]" />
              </ActionIcon>
            )}
            <ActionIcon
              onClick={() => {
                setIsUpdateReturnModalOpen(false);
                setIsEditing(false);
              }}
            >
              <XMarkIcon className="h-6 w-6 text-[#42526E]" />
            </ActionIcon>
          </div>
        </div>
        <div className="flex flex-col space-y-1">
          <p className="text-[16px] font-semibold text-gray-900">Result</p>
          <div className="flex flex-col space-y-1">
            {updateReturnInformation.returned_to ? (
              <div
                className={`${
                  isEditing
                    ? "flex-col items-start"
                    : "flex-row items-center space-x-2"
                } flex`}
              >
                <p className="text-sm font-semibold text-gray-500">
                  Returned to
                </p>
                {user?.data?.level !== "user" && isEditing ? (
                  <TextInput
                    value={returnedTo ? returnedTo : ""}
                    onChange={(e: any) => setReturnedTo(e.currentTarget.value)}
                  />
                ) : (
                  <p className="font-medium text-gray-900">
                    {updateReturnInformation.returned_to}
                  </p>
                )}
              </div>
            ) : (
              user?.data?.level !== "user" &&
              isEditing && (
                <div className="flex">
                  <Textarea
                    className="w-full"
                    label="Notes"
                    value={notes ? notes : ""}
                    onChange={(e: any) => setNotes(e.currentTarget.value)}
                  />
                </div>
              )
            )}
            {updateReturnInformation.returned_amount ? (
              <div
                className={`${
                  isEditing
                    ? "flex-col items-start"
                    : "flex-row items-center space-x-2"
                } flex`}
              >
                <p className="text-sm font-semibold text-gray-500">
                  Returned amount
                </p>
                {user?.data?.level !== "user" && isEditing ? (
                  <Textarea
                    value={returnedAmount ? returnedAmount : ""}
                    onChange={(e: any) =>
                      setReturnedAmount(e.currentTarget.value)
                    }
                  />
                ) : (
                  <p className="font-medium text-gray-900">
                    {updateReturnInformation.returned_amount}
                  </p>
                )}
              </div>
            ) : (
              user?.data?.level !== "user" &&
              isEditing && (
                <div className="flex">
                  <Textarea
                    className="w-full"
                    label="Notes"
                    value={notes ? notes : ""}
                    onChange={(e: any) => setNotes(e.currentTarget.value)}
                  />
                </div>
              )
            )}
            {updateReturnInformation.notes ? (
              <div
                className={`${
                  isEditing
                    ? "flex-col items-start"
                    : "flex-row items-center space-x-2"
                } flex`}
              >
                <p className="text-sm font-semibold text-gray-500">Notes</p>
                {user?.data?.level !== "user" && isEditing ? (
                  <Textarea
                    value={notes ? notes : ""}
                    onChange={(e: any) => setNotes(e.currentTarget.value)}
                  />
                ) : (
                  <p className="font-medium text-gray-900">
                    {updateReturnInformation.notes}
                  </p>
                )}
              </div>
            ) : (
              user?.data?.level !== "user" &&
              isEditing && (
                <div className="flex">
                  <Textarea
                    className="w-full"
                    label="Notes"
                    value={notes ? notes : ""}
                    onChange={(e: any) => setNotes(e.currentTarget.value)}
                  />
                </div>
              )
            )}
          </div>
        </div>

        {updateReturnInformation.logs.length > 0 && (
          <Logs logs={updateReturnInformation.logs} type="returned" />
        )}
        {isEditing && (
          <div className="flex space-x-2">
            <Button
              className="bg-jira-button hover:bg-jira-button-hover"
              onClick={() => {
                console.log("Outcome: ");
                handleUpdateRecord();
                setIsUpdateReturnModalOpen(false);
                setIsEditing(false);
              }}
            >
              Save
            </Button>

            <Button
              className="bg-none font-medium text-slate-600 hover:bg-jira-button-subtle"
              variant="subtle"
              color="gray"
              onClick={() => {
                console.log("Outcome: ");
                setIsEditing(false);
                setNotes(null);
              }}
            >
              Cancel
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};
