import { useState, useEffect } from "react";
import { RadioGroup, Radio } from "@mantine/core";

import { useGroups } from "features/group/api/getGroups";

export const AreaGroup = ({
  selectedGroup,
  setSelectedGroup,
}: {
  selectedGroup: string;
  setSelectedGroup: any;
}) => {
  const groupQuery = useGroups();

  useEffect(() => {
    console.log("selectedGroup: ", selectedGroup);
  }, [selectedGroup]);

  if (!groupQuery.data) {
    return (
      <div className="space-y-2">
        <div>
          <p className="text-[16px] font-semibold text-gray-900">Area group</p>
          <p className="text-[13px] font-medium text-gray-900">
            Select the group to assign this area to
          </p>
        </div>

        <div className="py-1">
          <p>No groups</p>
        </div>
      </div>
    );
  }

  if (groupQuery.data.groups.length < 0) {
    return (
      <div className="space-y-2">
        <div>
          <p className="text-[16px] font-semibold text-gray-900">Area group</p>
          <p className="text-[13px] font-medium text-gray-900">
            Select the group to assign this area to
          </p>
        </div>

        <div className="py-1">
          <p>No groups</p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <button
        type="button"
        onClick={() => setSelectedGroup("null")}
        className="text-sm font-semibold text-jira-button"
      >
        Clear group
      </button>

      <div className="py-1">
        <RadioGroup
          value={selectedGroup}
          onChange={setSelectedGroup}
          required
          size="sm"
          classNames={{
            label: "text-sm font-medium text-[#091e42]",
            radio:
              "text-yellow-500 checked:bg-jira-button checked:border-jira-button",
          }}
          orientation="vertical"
        >
          {groupQuery.data.groups.map((group: any) => (
            <Radio value={group.id.toString()} label={group.name} />
          ))}
        </RadioGroup>
      </div>
    </div>
  );
};
