import { useEffect, useState, useRef } from "react";
import {
  Button,
  Drawer,
  Checkbox,
  CheckboxGroup,
  LoadingOverlay,
} from "@mantine/core";
import { TrashIcon } from "@heroicons/react/24/outline";

import { useAreas } from "../api/getAreas";
import { ADMIN } from "config";
import { AreaType } from "../types";
import { useDeleteArea } from "../api/deleteArea";
import { useAuth } from "stores/Auth";

export const DeleteAreas = () => {
  const userLevel = useAuth((state) => state.userLevel);

  const areasQuery = useAreas("");

  const [open, setOpen] = useState(false);

  const [selectedAreas, setSelectedAreas] = useState<string[]>([]);

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  useEffect(() => {
    console.log("selectedAreas", selectedAreas);
  }, [selectedAreas]);

  const deleteAreaMutation = useDeleteArea();

  const cancelForm = () => {
    setOpen(false);
    if (confirmDeleteOpen === true) {
      setTimeout(() => setConfirmDeleteOpen(false), 100);
    }
    setTimeout(() => setSelectedAreas([]), 100);
  };

  useEffect(() => {
    if (open === true || confirmDeleteOpen === true) {
      cancelForm();
    }
  }, [deleteAreaMutation.isSuccess]);

  if (areasQuery.isLoading) {
    return (
      <Button
        variant="subtle"
        color="gray"
        radius="xs"
        className="font-normal text-neutral-700 hover:bg-stone-200"
        leftIcon={<TrashIcon className="h-5 w-5" />}
        classNames={{
          icon: "text-blue-500",
        }}
        disabled
      >
        Delete areas
      </Button>
    );
  }

  if (areasQuery.isError) {
    return null;
  }

  return userLevel === "admin" ? (
    <>
      <Button
        variant="subtle"
        className="inline-flex h-[32px] items-center rounded bg-[#f4f5f7] px-3 text-[#42526e] transition-all duration-100 ease-in-out hover:bg-[#ebecf0]"
        rightIcon={<TrashIcon className="h-5 w-5" />}
        classNames={{
          icon: "text-[#42526e]",
        }}
        onClick={() => setOpen(true)}
      >
        Delete areas
      </Button>

      <Drawer
        opened={open}
        onClose={cancelForm}
        padding="xl"
        size="xl"
        position="right"
        withOverlay={false}
        className="mt-[45px] shadow-2xl"
        withCloseButton={false}
      >
        {confirmDeleteOpen ? (
          <div className="flex h-full flex-1 flex-col space-y-2 overflow-hidden">
            <div className="flex h-full flex-1 flex-col space-y-2 overflow-hidden">
              <p className="text-[22px] font-semibold text-neutral-900">
                {selectedAreas.length > 1
                  ? "Delete these areas?"
                  : "Delete this area?"}
              </p>

              <div className="flex min-h-0 flex-col overflow-y-auto">
                <p className="text-[14px] leading-5 text-neutral-700">
                  Deleting an area will erase all data, including any records
                  and medications. This action cannot be undone.
                </p>
              </div>

              <div className="space-y-2">
                <div>
                  <p className="text-[16px] font-semibold text-gray-900">
                    Deleting areas:
                  </p>
                </div>

                <div className="">
                  {areasQuery?.data?.map((area) => {
                    return selectedAreas.map((selectedArea) => {
                      if (selectedArea === area.id) {
                        return (
                          <div className="col-span-3 flex items-center space-x-3 py-1">
                            <img
                              src={area?.avatar ? area?.avatar : "area"}
                              alt=""
                              className="h-8 w-8 rounded-full"
                            />
                            <p className="overflow-hidden text-ellipsis text-sm font-semibold text-[#091e42]">
                              {area.name}
                            </p>
                          </div>
                        );
                      }
                    });
                  })}
                </div>
              </div>
            </div>

            <div className="flex space-x-4">
              <Button
                className="bg-jira-button hover:bg-jira-button-hover"
                disabled={selectedAreas.length < 1}
                loading={deleteAreaMutation.isLoading}
                onClick={() =>
                  deleteAreaMutation.mutateAsync({
                    deletedAreas: selectedAreas,
                  })
                }
              >
                {selectedAreas.length > 1 ? "Delete areas" : "Delete area"}
              </Button>
              <Button
                onClick={cancelForm}
                variant="light"
                color="gray"
                disabled={deleteAreaMutation.isLoading}
                className="bg-neutral-100 font-medium text-neutral-800 hover:bg-neutral-200"
              >
                Cancel
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex h-full flex-1 flex-col space-y-2 overflow-hidden">
            <p className="text-[17px] font-semibold text-neutral-900">
              Delete Area
            </p>

            <div className="flex min-h-0 flex-grow flex-col overflow-y-auto">
              <CheckboxGroup
                value={selectedAreas}
                onChange={setSelectedAreas}
                orientation="vertical"
                spacing={0}
              >
                {areasQuery.data &&
                  areasQuery?.data?.map((area: AreaType) => (
                    <Checkbox
                      key={area.id}
                      value={area?.id || undefined}
                      label={
                        <div className="flex w-full items-center space-x-3">
                          <img
                            src={area?.avatar ? area?.avatar : "area"}
                            alt=""
                            className="h-10 w-10 rounded-full"
                          />
                          <p>{area.name}</p>
                        </div>
                      }
                      // className={`${
                      //   selectedAreas.includes(area?.id?.toString()) && "bg-neutral-100"
                      // } space-x-4 border-b border-b-neutral-200 p-3 hover:bg-neutral-100`}
                    />
                  ))}
              </CheckboxGroup>
            </div>

            <div className="flex space-x-4">
              <Button
                className="bg-jira-button hover:bg-jira-button-hover"
                disabled={selectedAreas.length < 1}
                onClick={() => setConfirmDeleteOpen(true)}
              >
                Delete{" "}
                {selectedAreas.length >= 1 && `(${selectedAreas.length})`}
              </Button>
              <Button
                onClick={cancelForm}
                variant="light"
                color="gray"
                className="bg-neutral-100 font-medium text-neutral-800 hover:bg-neutral-200"
              >
                Cancel
              </Button>
            </div>
          </div>
        )}
      </Drawer>
    </>
  ) : null;
};
