import { useState, useEffect, memo } from "react";
import { NavLink } from "react-router-dom";
import { LockClosedIcon, MinusSmallIcon } from "@heroicons/react/20/solid";
import { DateTime, DurationObjectUnits } from "luxon";
import { Select } from "@mantine/core";

import { useUsers } from "features/users/api/getUsers";
import { useTimesheets } from "../api/getTimesheets";

export const TimesheetList = () => {
  const usersQuery = useUsers();
  const dateStart = DateTime.local(2023, 3, 15, { zone: "Europe/London" });
  const dateEnd = DateTime.local(2023, 4, 15, { zone: "Europe/London" });

  const [dateDiff, setDateDiff] = useState({
    start: dateStart.toUnixInteger(),
    end: dateEnd.toUnixInteger(),
  });

  const timesheetsQuery = useTimesheets({ start: dateDiff.start, end: dateDiff.end });

  if (!usersQuery.data) {
    return null;
  }

  return (
    <div className="flex w-full flex-col">
      <p>TimesheetList</p>
      <MonthRangePicker setDateRange={setDateDiff} />
      <div className="flex w-full flex-col">
        <div className="col-span-1 grid grid-cols-11 border-t border-b border-neutral-300 py-1">
          <div className="col-span-1 px-2 font-medium text-neutral-400">
            <p>Payroll Id</p>
          </div>
          <div className="col-span-2 px-2 font-medium text-neutral-400">
            <p>Name</p>
          </div>
          <div className="col-span-1 px-2 font-medium text-neutral-400">
            <p>Type</p>
          </div>
          <div className="col-span-1 px-2 font-medium text-neutral-400">
            <p>Regular</p>
          </div>
          <div className="col-span-1 px-2 font-medium text-neutral-400">
            <p>Overtime</p>
          </div>
          <div className="col-span-1 px-2 font-medium text-neutral-400">
            <p>Paid holiday</p>
          </div>
          <div className="col-span-1 px-2 font-medium text-neutral-400">
            <p>Total hours</p>
          </div>
          <div className="col-span-1 px-2 font-medium text-neutral-400">
            <p>Sleeps</p>
          </div>
          <div className="col-span-1 px-2 font-medium text-neutral-400">
            <p>Claims</p>
          </div>
          <div className="col-span-1 px-2 font-medium text-neutral-400" />
        </div>
        {usersQuery.data.map((user: any) => (
          <TimesheetRow
            key={user.id}
            user={user}
            timesheet={timesheetsQuery?.data?.find(({ user_id }) => user_id === user.id)}
            start={dateDiff.start}
            end={dateDiff.end}
          />
        ))}
      </div>
    </div>
  );
};

const MonthRangePicker = memo(({ setDateRange }: any) => {
  const [data, setData] = useState<any>([]);
  const [value, setValue] = useState<any>("");

  const date = data.find(
    (item: { value: string; label: string; date: { start: number; end: number } }) => item.value === value
  )?.date;

  useEffect(() => {
    if (date) {
      setDateRange(date);
    }
  }, [date]);

  const now = DateTime.now().setZone("Europe/London");

  const backdateTo = DateTime.local(2020, 5, 15, { zone: "Europe/London" });

  const startMonth = DateTime.local(now.year, now.month, 15, {
    zone: "Europe/London",
  });
  const endMonth = startMonth.plus({ month: 1 });

  //* Get the current payroll date, if the current day is before the start of the next payroll, show previous month, if not, show next (e.g if on the 14th of Feb, show Jan-Feb if after the 15th of Feb, show Feb-Mar)

  const handleData = () => {
    const difference: DurationObjectUnits = endMonth.diff(backdateTo, "months").toObject();

    let data = [];

    if (difference.months) {
      for (let i = 0; i < difference.months; i++) {
        const start = backdateTo.plus({ month: i });
        const end = backdateTo.plus({ month: i + 1 });

        data.push({
          value: start.toUnixInteger().toString(),
          label: `${start.toLocaleString({
            day: "2-digit",
            month: "short",
          })} - ${end.toLocaleString({
            day: "2-digit",
            month: "short",
            year: "numeric",
          })}`,
          date: { start: start.toUnixInteger(), end: end.toUnixInteger() },
        });
      }
    }
    const reversedList = data.reverse();

    console.log(reversedList);

    return reversedList;
  };

  useEffect(() => {
    const data = handleData();
    setData(data);
    setValue(startMonth.toUnixInteger().toString());
  }, []);

  return (
    <div className="inline-block">
      {/* <p onClick={setData}>{startMonth.toString()}</p>
      <p>{endMonth.toString()}</p>
      <p>{startMonth.toUnixInteger().toString()}</p>
      <p>{endMonth.toUnixInteger().toString()}</p>
      <div>
        <p>Selected Date Range</p>
        <p>{date && date.start.toString()}</p>
        <p>{date && date.end.toString()}</p>
      </div> */}
      <Select data={data} value={value} onChange={setValue} />
    </div>
  );
});

const TimesheetRow = ({ user, timesheet, start, end }: any) => {
  return (
    <div className="grid grid-cols-11 items-center border-b border-neutral-300 py-2">
      <div className="col-span-1 px-2 font-medium">
        <p>{user.payroll_id}</p>
      </div>
      <NavLink to={`${user.id}/${start}/${end}`} className="col-span-2 px-2 font-medium">
        <div>
          <p>{user.full_name}</p>
          <p className="text-sm text-neutral-400">{user.email}</p>
        </div>
      </NavLink>
      <div className="col-span-1 px-2 font-medium">
        <div>
          <p>Fulltime</p>
          <p className="text-sm capitalize text-neutral-400">{user.salary_type}</p>
        </div>
      </div>
      <div className="col-span-1 px-2 font-medium">
        {timesheet ? <p>{timesheet.base_hours} hours</p> : <MinusSmallIcon className="h-[18px] w-[18px]" />}
      </div>
      <div className="col-span-1 px-2 font-medium">
        <MinusSmallIcon className="h-[18px] w-[18px]" />
      </div>
      <div className="col-span-1 px-2 font-medium">
        <MinusSmallIcon className="h-[18px] w-[18px]" />
      </div>
      <div className="col-span-1 px-2 font-medium">
        {timesheet ? <p>{timesheet.total_hours} hours</p> : <MinusSmallIcon className="h-[18px] w-[18px]" />}
      </div>
      <div className="col-span-1 px-2 font-medium">
        {timesheet ? <p>{timesheet.sleep_amount}</p> : <MinusSmallIcon className="h-[18px] w-[18px]" />}
      </div>
      <div className="col-span-1 px-2 font-medium">
        <MinusSmallIcon className="h-[18px] w-[18px]" />
      </div>
      {timesheet ? (
        timesheet.submitted ? (
          <div className="col-span-1 px-2 font-medium">
            <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full border-2 border-[#309A30] bg-[#D2F9D2]">
              <LockClosedIcon className="h-[18px] w-[18px] text-[#309A30]" />
            </div>
          </div>
        ) : (
          <div className="col-span-1 px-2 font-medium">
            <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full border-2 border-[#309A30] bg-[#D2F9D2]"></div>
          </div>
        )
      ) : (
        <div className="col-span-1 px-2 font-medium">
          <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full border-2 border-neutral-400 bg-neutral-100">
            <MinusSmallIcon className="h-[18px] w-[18px] text-neutral-400" />
          </div>
        </div>
      )}
    </div>
  );
};
