import { useMutation } from "react-query";

import { axios } from "lib/axios";
import { useNotification } from "hooks/useNotification";
import { queryClient } from "lib/react-query";
import { TMedicationLogs } from "../types";

export type UpdateRecordType = {
  data: {
    id: number;
    type: "record" | "count" | "received" | "returned";
    submitted_date: number;
    medication_id: number;
    area_id: string | undefined;
    previous_notes?: string | null;
    previous_outcome?: any;
    previous_time_administered?: number;
    previous_count?: number | null;
    previous_returned_to?: string | null;
    previous_returned_amount?: string | null;
    previous_received_from?: string | null;
    previous_received_amount?: string | null;
    logs?: TMedicationLogs[];
    start: number;
    end: number;
  };
};

export const updateRecord = ({ data }: UpdateRecordType) => {
  console.log("update medication data: ", data);
  return axios.put(`/records/${data.id}`, data);
};

export const useUpdateRecord = ({ areaId, start, end }: any) => {
  const { addNotification } = useNotification();

  return useMutation({
    onMutate: async (updatingRecord: any) => {
      console.log("error: ", updatingRecord, areaId, start, end);

      await queryClient.cancelQueries(["records", areaId, start, end]);

      const previousRecords = queryClient.getQueryData<any>(["records", areaId, start, end]);

      queryClient.setQueryData(
        ["records", areaId, start, end],
        [
          ...previousRecords.filter((record: any) => record.id !== updatingRecord.data.id),
          {
            ...updatingRecord.data,
            id: updatingRecord.data.id,
            submitted_date: updatingRecord.data.submitted_date,
            submitted_user_id: updatingRecord.data.submitted_user_id,
            outcome: updatingRecord.data.outcome,
            dose: updatingRecord.data.dose,
            area_id: updatingRecord.data.area_id,
            notes: updatingRecord.data.notes,
            logs: [
              ...updatingRecord.data.logs,
              {
                record_id: updatingRecord.data.id,
                submitted_date: updatingRecord.data.submitted_date,
                user_id: "o3x0LtCIjnTwhoHEnxYjcWec2py2",
                previous_outcome: updatingRecord.data.outcome,
                time_administered: updatingRecord.data.time_administered,
                notes: updatingRecord.data.notes,
              },
            ],
          },
        ]
      );

      return { previousRecords };
    },
    onError: (_, __, context: any) => {
      console.log("error updating record", _, __);
      if (context?.previousRecord) {
        queryClient.setQueryData(["records", areaId, start, end], context.previousRecord);
      }
      addNotification({
        disallowClose: true,
        type: "error",
        title: "",
        message: "Record update failed",
      });
    },
    onSuccess: (data: any) => {
      queryClient.refetchQueries(["records", areaId, start, end]);
      queryClient.invalidateQueries("records");

      addNotification({
        disallowClose: true,
        type: "success",
        title: "",
        message: "Record updated successfully",
      });
    },
    mutationFn: updateRecord,
  });
};
