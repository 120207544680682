import { useState, useEffect } from 'react'
import { Badge, Button, LoadingOverlay, Menu, ActionIcon, Loader } from '@mantine/core'
import { Link, useParams } from 'react-router-dom'
import { ArchiveBoxIcon, FunnelIcon } from '@heroicons/react/24/outline'
import { DateTime } from 'luxon'
import { EllipsisHorizontalIcon, EnvelopeIcon } from '@heroicons/react/24/outline'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faFilter } from '@fortawesome/free-solid-svg-icons'

import { useMedications } from '../api/getMedications'
import { DeleteMedication } from './DeleteMedication'
import { useAuth } from 'stores/Auth'
import { MedicationPreview } from 'components/MedicationPreview'
import { useArea } from 'features/area/api/getArea'

export const MedicationsTable = () => {
  const [offset, setOffset] = useState(0)

  const currentUser = useAuth((state) => state.currentUser)
  const userLevel = useAuth((state) => state.userLevel)

  const loading = useAuth((state) => state.loading)
  const { areaId: id } = useParams()
  // sets the area id to either the useParams id or an empty string to avoid typescript error
  const areaId = id || ''
  const areaQuery = useArea({ areaId, loading })

  const medicationsQuery: any = useMedications({ areaId })

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)

  // sets medication for values used within the UpdateMedication modal
  const [selectedMedication, setSelectedMedication] = useState({})

  const selectMedication = (medication: any) => {
    setSelectedMedication(medication)
    setIsUpdateModalOpen(true)
  }

  const timezone = 'Europe/London'

  const currentDate = DateTime.now().setZone(timezone).toSeconds()

  const [medications, setMedications] = useState(medicationsQuery?.data?.medications)

  useEffect(() => {
    console.log('medicationsQuery', medicationsQuery.data)
    setMedications(medicationsQuery?.data?.medications)
  }, [medicationsQuery.data])

  const filterMedications = () => {
    const newMedications = medicationsQuery?.data.medications.filter((medication: any) => medication.type === 'ROA&P')

    setMedications(newMedications)
  }

  if (!medications || medicationsQuery.isLoading) {
    return (
      <div className="border-b-2 border-[#DFE1E6] bg-white">
        <div className="flex items-center justify-between py-2 ">
          <p className="text-2xl font-semibold text-jira-base">Medications {userLevel} 0</p>
          {userLevel === 'admin' && (
            <Link to="../add">
              <Button className="bg-jira-button hover:bg-jira-button-hover">New Medication</Button>
            </Link>
          )}
        </div>

        <div className="grid grid-cols-5 border-b-2 border-[#DFE1E6] px-4 py-1 text-[13px] font-semibold text-zinc-500 md:grid-cols-8">
          <p>Name</p>
          <p>Dose</p>
          <p className="hidden md:flex">Administered Dose</p>
          <p className="hidden md:flex">Frequency</p>
          <p className="hidden md:flex">Route</p>
          <p>Type</p>
          <p>Start/End Date</p>
        </div>
        <div className="relative h-40">
          <LoadingOverlay visible />
        </div>
      </div>
    )
  }

  if (medicationsQuery.isError) {
    return (
      <div className="border-b-2 border-[#DFE1E6] bg-white">
        <div className="flex items-center justify-between py-2 ">
          <p className="text-2xl font-semibold text-jira-base">Medications 1</p>
          {userLevel === 'admin' && (
            <Link to="../add">
              <Button className="bg-jira-button hover:bg-jira-button-hover">New Medication</Button>
            </Link>
          )}
        </div>

        <div className="grid grid-cols-8 border-b-2 border-[#DFE1E6] px-4 py-1 text-[13px] font-semibold text-zinc-500">
          <p>Name</p>
          <p>Dose</p>
          <p>Administered Dose</p>
          <p>Frequency</p>
          <p>Route</p>
          <p>Type</p>
          <p>Start/End Date</p>
        </div>
        <div className="flex h-80 w-full flex-col items-center justify-center space-y-1">
          <ArchiveBoxIcon className="h-12 w-12 text-neutral-500" />
          <p className="font-medium text-neutral-500">There was an error fetching medications</p>
        </div>
      </div>
    )
  }

  if (!medicationsQuery.data || medicationsQuery.data.medications.length < 1) {
    return (
      <div className="border-b-2 border-[#DFE1E6] bg-white">
        <div className="flex items-center justify-between border-b border-gray-200 px-5 py-1.5">
          <div className="flex items-center space-x-2.5">
            {/* <div className="flex items-center space-x-2.5">
            <Button
              variant="outline"
              size="xs"
              className="border-none bg-none text-sm font-medium transition-all duration-200 hover:bg-gray-200"
              leftIcon={
                <FontAwesomeIcon
                  icon={faFilter}
                  className="h-3.5 w-3.5 text-[#5e6c84] transition-all duration-150 ease-in-out group-hover:text-jira-base"
                />
              }
            >
              Filter
            </Button>
            <div className="h-[24px] w-px border-r border-gray-200" />
          </div> */}
            {userLevel === 'admin' && (
              <div className="flex">
                <Link to="../add">
                  <Button variant="filled">Add Medication</Button>
                </Link>
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-1 flex-col">
          <div className="grid grid-cols-8 border-b-2 border-[#DFE1E6] bg-zinc-50 px-4 py-2 text-[13px] font-semibold text-zinc-500">
            <p>Name</p>
            <p>Dose</p>
            <p>Administered Dose</p>
            <p>Frequency</p>
            <p>Route</p>
            <p>Type</p>
            <p>Start/End Date</p>
          </div>
          <div className="flex h-80 w-full flex-col items-center justify-center space-y-1">
            <ArchiveBoxIcon className="h-12 w-12 text-neutral-500" />
            <p className="font-medium text-neutral-500">No medication entries</p>
            <p className="font-medium text-neutral-500">
              Click 'Add Medication' above to begin creating a new medication for this area
            </p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="flex h-full min-h-0 w-full flex-col items-start overflow-y-auto py-4 pt-0 md:px-6">
      <div className="flex items-center justify-between pt-3 pb-2">
        <div className="flex items-center">
          {/* <div className="flex items-center space-x-2.5">
            <Button
              variant="outline"
              size="xs"
              className="border-none bg-none text-sm font-medium transition-all duration-200 hover:bg-gray-200"
              leftIcon={
                <FontAwesomeIcon
                  icon={faFilter}
                  className="h-3.5 w-3.5 text-[#5e6c84] transition-all duration-150 ease-in-out group-hover:text-jira-base"
                />
              }
            >
              Filter
            </Button>
            <div className="h-[24px] w-px border-r border-gray-200" />
          </div> */}
          {/* <div onClick={() => filterMedications()}>filter</div> */}
          {(userLevel === 'admin' || areaQuery?.data?.role === 'manager') && (
            <div className="flex">
              <Link to="../add">
                <Button>Add medication</Button>
              </Link>
            </div>
          )}
        </div>
      </div>

      <MedicationPreview open={isUpdateModalOpen} setOpen={setIsUpdateModalOpen} medication={selectedMedication} />

      <table className="relative w-full border-separate border-spacing-0 rounded border border-neutral-200 bg-white">
        {/* <LoadingOverlay
          visible={formsQuery.isLoading || formsQuery.isFetching}
        /> */}
        <thead className="rounded-t">
          <tr className="grid grid-cols-5 rounded-t border-b border-neutral-200 bg-neutral-50 text-xs font-medium text-neutral-500 md:grid-cols-9">
            <th className="col-span-2 w-full justify-self-start p-2 text-start font-medium">Name</th>
            <th className="col-span-1 hidden w-full justify-self-start p-2 text-start font-medium md:inline-block">
              Dose
            </th>
            <th className="col-span-1 hidden w-full justify-self-start p-2 text-start font-medium md:inline-block">
              Administered Dose
            </th>
            <th className=" col-span-1 w-full justify-self-start p-2 text-start font-medium">Frequency</th>
            <th className="col-span-1 hidden w-full justify-self-start p-2 text-start font-medium md:inline-block">
              Route
            </th>
            <th className=" col-span-1 w-full justify-self-start p-2 text-start font-medium">Type</th>
            <th className=" col-span-1 w-full justify-self-start p-2 text-start font-medium">Start/End Date</th>
          </tr>
        </thead>
        <tbody>
          {medications.map((medication: any) => (
            <>
              <div className="group">
                <div
                  className="grid grid-cols-5 text-sm font-medium text-jira-base group-hover:cursor-pointer group-hover:bg-neutral-50 md:grid-cols-9"
                  onClick={() => selectMedication(medication)}
                >
                  <div className="col-span-2 flex items-center space-x-3 p-2">
                    <div
                      className={`${
                        medication.end_date < currentDate && medication.end_date !== null
                          ? 'bg-red-500 '
                          : 'bg-emerald-500 '
                      } h-2 w-2 rounded-full`}
                    />
                    <p className="text-jira-base group-hover:underline">{medication.name}</p>
                  </div>
                  <div className="hidden items-center p-2 md:flex">
                    <p>{medication.dose}</p>
                  </div>
                  <div className="hidden items-center p-2 md:flex">
                    <p>{medication.administered_dose}</p>
                  </div>
                  <div className="flex items-center p-2">
                    <p>{medication.frequency}</p>
                  </div>
                  <div className="hidden items-center p-2 md:flex">
                    <p>{medication.route}</p>
                  </div>
                  <div className="flex items-center p-2">
                    <Badge className="">{medication.type}</Badge>
                  </div>
                  <MedicationDates startDate={Number(medication.start_date)} endDate={Number(medication.end_date)} />
                  {userLevel === 'admin' && (
                    <div className="hidden items-center p-2 md:flex">
                      <Menu
                        control={
                          <ActionIcon className="hover:bg-white">
                            <EllipsisHorizontalIcon className="h-5 w-5 text-gray-500" />
                          </ActionIcon>
                        }
                        onClick={(e) => e.stopPropagation()}
                        closeOnItemClick={false}
                        placement="center"
                        classNames={{
                          body: 'py-1',
                          item: ' py-[6px] px-3',
                        }}
                      >
                        <Menu.Item className="group transition-all duration-150 ease-in-out hover:bg-[#f4f5f7]">
                          <div className="flex items-center space-x-2 p-1">
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              className="h-4 w-4 text-[#5e6c84] transition-all duration-150 ease-in-out group-hover:text-jira-base"
                            />
                            <p className="text-[12px] font-semibold text-[#5e6c84] transition-all duration-150 ease-in-out group-hover:text-jira-base">
                              Delete medication
                            </p>
                          </div>
                        </Menu.Item>
                      </Menu>
                    </div>
                  )}
                </div>
              </div>
            </>
          ))}
          <div className="flex items-center justify-between border-t border-gray-200 p-2">
            <p className="text-xs font-medium text-gray-400">
              Showing <span className="font-semibold text-gray-500">{offset * 10 + 1} </span>
              to{' '}
              <span className="font-semibold text-gray-500">
                {offset * 10 + (medicationsQuery?.data?.medications ? medicationsQuery?.data?.medications.length : 0)}
              </span>{' '}
              of <span className="font-semibold text-gray-500">{medicationsQuery?.data?.total}</span> results
            </p>
            <div className="flex space-x-1">
              {offset > 0 && (
                <Button
                  onClick={() => setOffset((prevOffset) => prevOffset - 1)}
                  size="xs"
                  className="border-none bg-gray-900 p-0 px-2 text-xs font-medium text-white hover:bg-gray-900 hover:text-gray-300"
                  classNames={{
                    root: 'h-[24px]',
                    label: 'p-0 overflow-hidden overflow-ellipsis whitespace-nowrap',
                  }}
                >
                  Previous
                </Button>
              )}
              {offset * 10 + (medicationsQuery?.data?.medications ? medicationsQuery?.data?.medications.length : 0) !==
                Number(medicationsQuery?.data?.total) && (
                <Button
                  onClick={() => setOffset((prevOffset) => prevOffset + 1)}
                  size="xs"
                  className="border-none bg-gray-900 p-0 px-2 text-xs font-medium text-white hover:bg-gray-900 hover:text-gray-300"
                  classNames={{
                    root: 'h-[24px]',
                    label: 'p-0 overflow-hidden overflow-ellipsis whitespace-nowrap',
                  }}
                >
                  Next
                </Button>
              )}
            </div>
          </div>
        </tbody>
      </table>

      {/* <div className="flex flex-1 flex-col">
        <div className="grid grid-cols-8 border-b-2 border-[#DFE1E6] bg-zinc-50 px-4 py-2 text-[13px] font-semibold text-zinc-500">
          <p>Name</p>
          <p>Dose</p>
          <p>Administered Dose</p>
          <p>Frequency</p>
          <p>Route</p>
          <p>Type</p>
          <p>Start/End Date</p>
        </div>
        {medicationsQuery?.data?.map((medication: any) => (
          <>
            <div className="group">
              <div
                className="grid grid-cols-8 px-4 py-3 text-sm font-medium text-jira-base group-hover:cursor-pointer group-hover:bg-zinc-50"
                onClick={() => selectMedication(medication)}
              >
                <div className="flex items-center space-x-3">
                  <div
                    className={`${
                      medication.end_date < currentDate &&
                      medication.end_date !== null
                        ? "bg-red-500 "
                        : "bg-emerald-500 "
                    } h-2 w-2 rounded-full`}
                  />
                  <p className="text-jira-base group-hover:underline">
                    {medication.name}
                  </p>
                </div>
                <div className="flex items-center">
                  <p>{medication.dose}</p>
                </div>
                <div className="flex items-center">
                  <p>{medication.administered_dose}</p>
                </div>
                <div className="flex items-center">
                  <p>{medication.frequency}</p>
                </div>
                <div className="flex items-center">
                  <p>{medication.route}</p>
                </div>
                <div className="flex items-center">
                  <Badge className="">{medication.type}</Badge>
                </div>
                <MedicationDates
                  startDate={Number(medication.start_date)}
                  endDate={Number(medication.end_date)}
                />
                {userLevel === "admin" && (
                  <Menu
                    control={
                      <ActionIcon className="hover:bg-white">
                        <EllipsisHorizontalIcon className="h-5 w-5 text-gray-500" />
                      </ActionIcon>
                    }
                    onClick={(e) => e.stopPropagation()}
                    closeOnItemClick={false}
                    placement="center"
                    classNames={{
                      body: "py-1",
                      item: " py-[6px] px-3",
                    }}
                  >
                    <Menu.Item className="group transition-all duration-150 ease-in-out hover:bg-[#f4f5f7]">
                      <div className="flex items-center space-x-2 p-1">
                        <FontAwesomeIcon
                          icon={faTrashCan}
                          className="h-4 w-4 text-[#5e6c84] transition-all duration-150 ease-in-out group-hover:text-jira-base"
                        />
                        <p className="text-[12px] font-semibold text-[#5e6c84] transition-all duration-150 ease-in-out group-hover:text-jira-base">
                          Delete medication
                        </p>
                      </div>
                    </Menu.Item>
                  </Menu>
                )}
              </div>
            </div>
          </>
        ))}
      </div> */}
    </div>
  )
}

const MedicationDates = ({ startDate, endDate }: { startDate: number; endDate: number }) => {
  return (
    <div className="flex flex-col p-2 text-xs">
      {startDate && endDate ? (
        <div className="flex flex-col">
          <p className="font-medium text-[#6f6f6f]">
            {DateTime.fromSeconds(startDate).setZone('Europe/London').setLocale('en-gb').toLocaleString({
              day: '2-digit',
              month: 'short',
              year: 'numeric',
            })}
          </p>
          <p className="font-medium text-[#6f6f6f]">
            {DateTime.fromSeconds(endDate).setZone('Europe/London').setLocale('en-gb').toLocaleString({
              day: '2-digit',
              month: 'short',
              year: 'numeric',
            })}
          </p>
        </div>
      ) : startDate ? (
        <p className="font-medium text-[#6f6f6f]">
          {DateTime.fromSeconds(startDate).setZone('Europe/London').setLocale('en-gb').toLocaleString({
            day: '2-digit',
            month: 'short',
            year: 'numeric',
          })}
        </p>
      ) : (
        <p className="text-sm italic text-gray-500">No start date provided</p>
      )}
    </div>
  )
}
