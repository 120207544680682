import { useSearchParams, useNavigate } from "react-router-dom";
import { TextInput, PasswordInput, Button } from "@mantine/core";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import z from "zod";

import { supabase } from "lib/supabase";
import image from "assets/supported_logo.png";
import { useAuth } from "stores/Auth";
import { axios } from "lib/axios";

export const Invite = () => {
  const navigate = useNavigate();

  let [searchParams, setSearchParams] = useSearchParams();
  const email = searchParams.get("email");

  const currentUser = useAuth((state) => state.currentUser);
  const signOut = useAuth((state) => state.supabaseSignOut);

  const validationSchema = z
    .object({
      name: z.string().nonempty({ message: "Name is required" }),
      password: z.string().nonempty({ message: "Password is required" }),
      confirmPassword: z.string().nonempty({ message: "Confirm Password is required" }),
    })
    .refine((data) => data.password === data.confirmPassword, {
      message: "Passwords do not match",
      path: ["confirmPassword"],
    });

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<any>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      email: email ? email : "",
      name: "",
      password: "",
      confirmPassword: "",
    },
  });

  const onSubmit = async (formData: any) => {
    console.log("data: ", formData);

    const { data: userData, error: userError } = await supabase
      .from("profile")
      .select(`verified, user_role (role)`)
      .eq("id", currentUser?.id)
      .single();

    console.log("verified: ", userData?.verified);

    if (userData?.verified) {
      setError("validationError", {
        type: "custom",
        message: "You have already accepted your invitation, please login using your password",
      });
      return;
    } else {
      const { data: updateData, error: updateError } = await supabase.auth.updateUser({ password: formData.password });

      if (!updateError) {
        const user = await axios.post("/acceptInvite", { full_name: formData.name });

        console.log("updateUser: ", user);

        if (user.status === 200) {
          signOut();
          navigate("/auth/login");
        } else {
          setError("validationError", {
            type: "custom",
            message:
              "There was an error accepting the invite, please try again, if this issue continues, please contact your administrator",
          });
        }
      } else {
        switch (updateError?.message) {
          case "Auth session missing!":
            setError("validationError", {
              type: "custom",
              message: "This invite is invalid, please request a new invite from your admin",
            });
            break;
          default:
            setError("validationError", { type: "custom", message: updateError ? updateError.message : "" });
        }
      }
    }
  };

  return (
    <div className="flex h-screen w-screen">
      <div className="flex flex-1 items-center justify-center">
        <div className="max-w-sm space-y-4">
          <form
            className="flex w-96 flex-col space-y-5 rounded-md bg-white px-8 py-6"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex h-full min-h-0 w-full items-center justify-center">
              <img src={image} alt="Area" className="h-24" />
            </div>

            <div className="flex w-full flex-col space-y-1">
              {errors.validationError && (
                <div className="flex w-full rounded-md border border-red-600 bg-red-100 p-2">
                  <p className="font-medium text-red-600">{errors.validationError.message as string}</p>
                </div>
              )}
              <Controller
                control={control}
                name={`email`}
                render={({ field }: any) => <TextInput label="Email" disabled={true} {...field} />}
              />

              <Controller
                control={control}
                name={`name`}
                render={({ field }: any) => (
                  <TextInput
                    label="Name"
                    placeholder="Enter your name"
                    {...field}
                    error={errors.name?.message as string}
                  />
                )}
              />

              <Controller
                control={control}
                name={`password`}
                render={({ field }: any) => (
                  <PasswordInput
                    label="Password"
                    placeholder="Please enter a secure password"
                    error={errors.password?.message as string}
                    {...field}
                  />
                )}
              />

              <Controller
                control={control}
                name={`confirmPassword`}
                render={({ field }: any) => (
                  <PasswordInput
                    label="Confirm Password"
                    placeholder="Please confirm your secure password"
                    error={errors.confirmPassword?.message as string}
                    {...field}
                  />
                )}
              />
            </div>
            <Button className="bg-jira-button hover:bg-jira-button-hover" type="submit">
              Create account
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};
