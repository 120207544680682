import { useState } from "react";
import {
  TextInput,
  Checkbox,
  CheckboxGroup,
  LoadingOverlay,
  Button,
} from "@mantine/core";
import { Link } from "react-router-dom";
import { z } from "zod";
import { useNotification } from "hooks/useNotification";

import { useAreas } from "features/area/api/getAreas";
import { AreaType } from "features/area/types/index";
import { useCreateGroup } from "../api/createGroup";

export const CreateGroup = () => {
  const { addNotification } = useNotification();

  const [name, setName] = useState("");
  const [selectedAreas, setSelectedAreas] = useState<any[]>([]);

  const areasQuery = useAreas("?group=false");
  const createGroupMutation = useCreateGroup();

  const [formErrors, setFormErrors] = useState<any>([]);

  const GroupForm = z.object({
    name: z
      .string({ required_error: "Group name is required" })
      .min(1, "Group name is required"),
  });

  const postGroup = async () => {
    const data = {
      name: name,
      areas: selectedAreas,
    };

    try {
      GroupForm.parse({
        name,
      });
      await createGroupMutation.mutateAsync({ data });
    } catch (error: any) {
      setFormErrors(error.flatten());
      console.log("form submission error", error, error.flatten());
      addNotification({
        disallowClose: false,
        type: "error",
        title: "Form not submitted",
        message:
          "There is an error in this form, check if you are missing required fields and try again",
      });
    }
    console.log("should work");
  };

  return (
    <div className="flex h-full min-h-0 w-full flex-col items-center overflow-y-auto px-12 py-6">
      <div className="flex w-full max-w-5xl flex-col">
        <div className="flex flex-col space-y-4">
          <div className="flex flex-col">
            <p className="text-2xl font-semibold text-gray-900">New group</p>
            <Link
              className="cursor-pointer text-sm font-semibold text-jira-button hover:text-[#0065FF]"
              to="/groups"
            >
              Back to groups
            </Link>
          </div>

          <form
            onSubmit={async (e: any) => {
              e.preventDefault();

              await postGroup();
            }}
            className="relative"
          >
            <LoadingOverlay
              visible={areasQuery.isLoading || areasQuery.isFetching}
            />

            <div className="flex flex-col space-y-8">
              <div className="flex flex-col space-y-6">
                <div className="space-y-2">
                  <div>
                    <p className="text-[16px] font-semibold text-gray-900">
                      Group details
                    </p>
                    <p className="text-[13px] font-medium text-gray-900">
                      This is the basic information of the group
                    </p>
                  </div>

                  <TextInput
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter the group name"
                    label="Name"
                    description="The groups name, this might be the of a person, a place or anything else!"
                    error={
                      formErrors.fieldErrors?.name &&
                      formErrors.fieldErrors?.name[0]
                    }
                  />
                </div>

                <div className="space-y-2">
                  <div>
                    <p className="text-[16px] font-semibold text-gray-900">
                      Group areas
                    </p>
                    <p className="text-[13px] font-medium text-gray-900">
                      Select areas to be allocated to this group
                    </p>
                  </div>

                  {areasQuery.data &&
                    (areasQuery?.data?.length > 0 ? (
                      <CheckboxGroup
                        value={selectedAreas}
                        onChange={setSelectedAreas}
                        spacing={20}
                        size="xs"
                        classNames={{
                          label: " pl-2",
                          input:
                            "checked:bg-jira-button checked:border-jira-button",
                        }}
                      >
                        {areasQuery?.data &&
                          areasQuery?.data?.map((area: AreaType) => {
                            console.log("area", area);
                            return (
                              <Checkbox
                                value={area.id}
                                label={<p>{area.name}</p>}
                              />
                            );
                          })}
                      </CheckboxGroup>
                    ) : (
                      <p className="text-[13px] font-medium italic text-gray-900">
                        No areas are avalible for allocation
                      </p>
                    ))}
                </div>
              </div>

              <div className="flex items-center space-x-3">
                <Button
                  type="submit"
                  className="bg-jira-button hover:bg-jira-button-hover"
                  // disabled={!ADMIN}
                >
                  Save
                </Button>
                <Button
                  className="bg-none font-medium text-slate-600 hover:bg-jira-button-subtle"
                  // disabled={!ADMIN}
                  variant="subtle"
                  color="gray"
                  component={Link}
                  to="/groups"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
