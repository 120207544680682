import { PlusIcon } from '@heroicons/react/20/solid'
import { BookmarkIcon } from '@heroicons/react/24/outline'
import { Button } from '@mantine/core'
import { useRecordStore } from '../store/recordStore'

export const RecordInformation = ({ medication, rows, setRows, setIsMedicationPreviewOpen }: any) => {
  const setMedicationInformation = useRecordStore((state) => state.setMedicationInformation)

  const addRow = () => {
    const lastRow = rows.at(-1)
    setRows([
      ...rows,
      {
        id: lastRow.row + 1,
        row: lastRow.row + 1,
        medication_id: medication.id,
      },
    ])
  }

  return (
    <>
      <div className="flex">
        <div className="flex w-full flex-col justify-between">
          <div className="flex w-full justify-between">
            <div className="flex w-full justify-between px-3 py-3">
              <div className="flex flex-col">
                <p
                  className="text-lg font-semibold text-jira-button hover:cursor-pointer hover:underline"
                  onClick={() => {
                    setIsMedicationPreviewOpen(true)
                    setMedicationInformation(medication)
                  }}
                >
                  {medication.name}
                </p>
                <p className="text-sm font-semibold text-neutral-400">
                  {medication.dose} / {medication.administered_dose}
                </p>
              </div>
              <Button
                variant="default"
                leftIcon={<PlusIcon className="h-4 w-4 text-neutral-400" />}
                onClick={() => addRow()}
              >
                Add row
              </Button>
            </div>
            {/* <div className="flex flex-col">
              {rows &&
                rows.map((row: any) => (
                  <div className="flex h-[52px] items-center justify-center pr-2">
                    <p className="font-semibold text-[#5e6c84]">
                      {row.row.toString()}
                    </p>
                  </div>
                ))}
            </div> */}
          </div>
        </div>
      </div>
    </>
  )
}
