import { useMutation } from "react-query";
import { queryClient } from "lib/react-query";

import { axios } from "lib/axios";
import { useNotification } from "hooks/useNotification";
import { Medication } from "../types";

export type DeleteMedicationType = {
  data: {
    name: string;
  };
};

export const deleteMedication = ({ medicationId }: { medicationId: number }) => {
  console.log("deleteMedication", medicationId);
  return axios.delete("/medications/:id", { data: { medicationId } });
};

export const useDeleteMedication = (successFunction?: Function) => {
  const { addNotification } = useNotification();

  return useMutation({
    onMutate: async (deletedMedication) => {
      await queryClient.cancelQueries("medications");

      const previousMedications = queryClient.getQueryData<Medication[]>("medications");

      queryClient.setQueryData(
        "medications",
        previousMedications?.filter((medication) => medication.id !== deletedMedication.medicationId)
      );

      return { previousMedications };
    },
    onError: (_, __, context) => {
      addNotification({
        disallowClose: true,
        type: "error",
        title: "Medicine Not Deleted",
        message: "Could not delete medicine!",
      });
      if (context?.previousMedications) {
        queryClient.setQueryData("medications", context.previousMedications);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries("medications");
      successFunction && successFunction();
      addNotification({
        disallowClose: true,
        type: "success",
        title: "Medicine Deleted",
        message: "Medicine Deleted Successfully!",
      });
    },
    mutationFn: deleteMedication,
  });
};
