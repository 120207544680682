import { useMutation } from "react-query";
import { queryClient } from "lib/react-query";

import { axios } from "lib/axios";
import { useNotification } from "hooks/useNotification";

export type CreateShiftType = {
  data: {
    user_id: string | undefined;
    start_time: number;
    end_time: number;
    sleep: boolean;
    role: string;
    notes: string;
  };
};

export const createShift = ({ data }: CreateShiftType) => {
  console.log("createShift: ", data);

  return axios.post(`/shift?user=${data.user_id}`, data);
};

export const useCreateShift = (id: string, start: number, end: number, successFunction: Function) => {
  const { addNotification } = useNotification();

  return useMutation({
    onError: (_, __, context) => {
      addNotification({
        disallowClose: true,
        type: "error",
        title: "Shift not added",
        message: "There was an issue adding this shift, please try again",
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["shifts", id, start, end]);
      console.log("create shift invalidate: ", id, start, end);
      successFunction();
      addNotification({
        disallowClose: true,
        type: "success",
        title: "Shift added",
        message: "",
      });
    },
    mutationFn: createShift,
  });
};
