import { useState } from "react";
import { Popover, Button, Modal } from "@mantine/core";

import { TrashIcon } from "@heroicons/react/24/outline";

export const DeleteArea = ({
  deleteModalOpen,
  setDeleteModalOpen,
}: {
  deleteModalOpen: boolean;
  setDeleteModalOpen: (value: boolean) => void;
}) => {
  return (
    <Modal
      opened={deleteModalOpen}
      onClose={() => setDeleteModalOpen(!deleteModalOpen)}
      withCloseButton={false}
      centered={true}
    >
      <div className="flex flex-col space-y-2">
        <div className="flex flex-col space-y-1">
          <p className="text-[14px] font-medium text-zinc-900">Are you sure you want to delete this area?</p>
          <p className="text-[13px] font-medium text-zinc-700">Once you delete, it's gone for good.</p>
        </div>
        <Button
          className="bg-red-500 hover:bg-red-600"
          // onClick={() => {
          //   deleteMedicationMutation.mutateAsync({ medicationId: id });
          // }}
          // loading={deleteMedicationMutation.isLoading}
        >
          Delete Area
        </Button>
      </div>
    </Modal>
  );
};

const OldDeleteArea = () => {
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);

  return (
    <Popover
      opened={isDeleteConfirmationOpen}
      onClose={() => setIsDeleteConfirmationOpen(false)}
      target={
        <TrashIcon
          onClick={(e: any) => {
            e.stopPropagation();
            e.preventDefault();
            setIsDeleteConfirmationOpen((o) => !o);
          }}
          className="h-5 w-5 bg-red-100 text-red-700"
        />
      }
      position="bottom"
      withArrow
      width={250}
      gutter={1}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      className=""
    >
      <div className="flex flex-col space-y-2">
        <div className="flex flex-col space-y-1">
          <p className="text-[14px] font-medium text-zinc-900">Are you sure you want to delete this area?</p>
          <p className="text-[13px] font-medium text-zinc-700">Once you delete, it's gone for good.</p>
        </div>
        <Button
          className="bg-red-500 hover:bg-red-600"
          // onClick={() => {
          //   deleteMedicationMutation.mutateAsync({ medicationId: id });
          // }}
          // loading={deleteMedicationMutation.isLoading}
        >
          Delete Area
        </Button>
      </div>
    </Popover>
  );
};
