import { Route, Routes, useParams } from "react-router-dom";

import { AreaLayout } from "components/Layout/AreaLayout/AreaLayout";
import { MedicationRoutes } from "features/medication";
import { Settings } from "../routes/Settings";
import { useArea } from "../api/getArea";
import { ADMIN } from "config";
import { UpdateArea } from "./UpdateArea";
import { useAuth } from "stores/Auth";
import { FormsRoutes } from "features/forms/routes";

export const Area = () => {
  const loading = useAuth((state) => state.loading);
  const { areaId: id } = useParams();
  // sets the area id to either the useParams id or an empty string to avoid typescript error
  const areaId = id || "";
  const areaQuery = useArea({ areaId, loading });

  if (!areaQuery?.data) {
    <div>
      <p>Error</p>
    </div>;
  }

  return (
    <AreaLayout area={areaQuery?.data} loading={areaQuery?.isLoading}>
      <Routes>
        <Route path="medication/*" element={<MedicationRoutes />} />
        <Route path="forms/*" element={<FormsRoutes />} />
        {ADMIN && (
          <Route
            path="settings/*"
            element={<Settings area={areaQuery?.data!} />}
          />
        )}
      </Routes>
    </AreaLayout>
  );
};
