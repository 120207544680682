import { useEffect, useState } from "react";
import {
  TextInput,
  Checkbox,
  CheckboxGroup,
  LoadingOverlay,
  Button,
} from "@mantine/core";
import { useParams, Link, useNavigate } from "react-router-dom";
import { z } from "zod";
import { useNotification } from "hooks/useNotification";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { useAreas } from "features/area/api/getAreas";
import { AreaType } from "features/area/types/index";
import { useGroup } from "../api/getGroup";
import { useUpdateGroup } from "../api/updateGroup";

export const UpdateGroup = () => {
  const { addNotification } = useNotification();

  const { groupId: id } = useParams();
  const groupId = id || "";

  const navigate = useNavigate();

  const areasQuery = useAreas(`?group=${groupId}`);
  const groupQuery = useGroup({ groupId });
  const updateGroupMutation = useUpdateGroup(() =>
    navigate(`/groups`, { replace: true })
  );

  useEffect(() => {
    if (areasQuery.data) {
      const filteredAreas = areasQuery?.data?.filter(
        (area) => area.group_id === groupId
      );
      const newSelectedAreas = filteredAreas?.map((area) => area.id);
      console.log("areasQuery", newSelectedAreas, filteredAreas);
      setValue("areas", newSelectedAreas);
    }
  }, [areasQuery.data, groupId]);

  const updateGroup = async () => {
    try {
      console.log("form submission");
      // await updateGroupMutation.mutateAsync({ data, groupId });
      areasQuery.refetch();
    } catch (error: any) {
      addNotification({
        disallowClose: false,
        type: "error",
        title: "Form not submitted",
        message:
          "There is an error in this form, check if you are missing required fields and try again",
      });
    }
    console.log("should work");
  };

  const validationSchema = z.object({
    name: z.string().min(1, "Area name is required"),
    areas: z.string().array(),
  });

  const [data, setData] = useState({});

  const onSubmit = (data: any) => {
    console.log("onSubmit", data);
    setData(data);
  };

  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({ resolver: zodResolver(validationSchema) });

  if (!groupQuery.data) {
    <div>
      <p>Loading</p>
    </div>;
  }

  return (
    <div className="flex h-full min-h-0 w-full flex-col items-center overflow-y-auto px-12 py-6">
      <div className="flex w-full max-w-5xl flex-col">
        <div className="flex flex-col space-y-4">
          <div className="flex flex-col">
            <p className="text-2xl font-semibold text-gray-900">
              Update {groupQuery?.data?.name}
            </p>
            <Link
              className="cursor-pointer text-sm font-semibold text-jira-button hover:text-[#0065FF]"
              to="/groups"
            >
              Back to groups
            </Link>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="relative">
            <LoadingOverlay
              visible={areasQuery.isLoading || areasQuery.isFetching}
            />

            <div className="flex flex-col space-y-8">
              <div className="flex flex-col space-y-6">
                <div className="space-y-2">
                  <div>
                    <p className="text-[16px] font-semibold text-gray-900">
                      Group details
                    </p>
                    <p className="text-[13px] font-medium text-gray-900">
                      This is the basic information of the group
                    </p>
                    <p> Data: {JSON.stringify(data)}</p>
                    <p> Data: {JSON.stringify(errors)}</p>
                  </div>

                  <Controller
                    control={control}
                    name="name"
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        placeholder="Enter the group name"
                        label="Name"
                        description="The groups name, this might be the of a person, a place or anything else!"
                      />
                    )}
                  />
                </div>

                <div className="space-y-2">
                  <div>
                    <p className="text-[16px] font-semibold text-gray-900">
                      Group areas
                    </p>
                    <p className="text-[13px] font-medium text-gray-900">
                      Select areas to be allocated to this group
                    </p>
                  </div>

                  <Controller
                    control={control}
                    name="areas"
                    render={({ field }: any) => (
                      <CheckboxGroup
                        {...field}
                        spacing={20}
                        size="xs"
                        classNames={{
                          label: " pl-2",
                          input:
                            "checked:bg-jira-button checked:border-jira-button",
                        }}
                      >
                        {areasQuery.data &&
                          areasQuery.data.map((area: AreaType) => {
                            return (
                              <Checkbox
                                value={area.id}
                                label={<p>{area.name}</p>}
                              />
                            );
                          })}
                      </CheckboxGroup>
                    )}
                  />
                </div>
              </div>

              <div className="flex items-center space-x-3">
                <Button
                  type="submit"
                  className="bg-jira-button hover:bg-jira-button-hover"
                >
                  Save
                </Button>
                <Button
                  className="bg-none font-medium text-slate-600 hover:bg-jira-button-subtle"
                  variant="subtle"
                  color="gray"
                  component={Link}
                  to="/groups"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
