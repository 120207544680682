import { useQuery } from "react-query";
import { AxiosError } from "axios";

import { axios } from "lib/axios";
import { TUser } from "../types";
import { useAuth } from "stores/Auth";

export const getUser = async (userId: string): Promise<TUser> => {
  try {
    console.log("getting user", userId);
    if (!!userId) {
      const response = await axios.get(`/users/${userId}`);
      if (response.status === 200) {
        console.log("response 200", response.status);
      }
      // Don't forget to return something
      return response.data;
    }
    throw new Error("The user is not defined");
  } catch (err) {
    console.log("getUser", err);
    if (err instanceof AxiosError) {
      if (err.response?.status === 401) {
        throw new Error("Not authorised");
      } else if (err.response?.status === 403) {
        throw new Error("Not admin");
      }
    }
    throw new Error("There was an error fetching the request");
  }
};

export const useUser = (userId: any) => {
  const currentUser = useAuth((state) => state.currentUser);
  console.log("useUser", !!currentUser?.uid, userId);
  return useQuery({
    queryKey: ["user", userId],
    queryFn: () => getUser(userId),
    enabled: !!currentUser?.id,
  });
};
