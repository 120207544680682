import { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import { CheckboxGroup, Checkbox, Button } from "@mantine/core";

export const UserRoles = ({ user, roles, control }: any) => {
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);

  useEffect(() => {
    if (roles) {
      const roles: any = [];

      roles.forEach((role: any) => {
        if (role.users.includes(user?.id)) {
          roles.push(role.id.toString());
        }
      });

      setSelectedRoles(roles);

      console.log("User Roles: ", roles);
    }
  }, [roles]);

  return (
    <section>
      <div className="border-b border-[#e6e8eb]">
        <div className="flex items-center px-6 py-4">
          <p className="text-[16px] font-semibold text-gray-900">Roles</p>
        </div>
      </div>
      <div className="px-6 py-4">
        <div className="space-y-2">
          <Controller
            control={control}
            name={`roles`}
            render={({ field: { onChange, value } }: any) => (
              <CheckboxGroup
                value={value}
                onChange={onChange}
                spacing={10}
                size="xs"
                classNames={{
                  input: "checked:bg-jira-button checked:border-jira-button",
                }}
              >
                {roles?.map((role: any) => (
                  <Checkbox
                    value={role.id.toString()}
                    label={
                      <div className="flex w-full items-center space-x-3 text-sm font-medium text-[#091e42] ">
                        <p>{role.name}</p>
                      </div>
                    }
                  />
                ))}
              </CheckboxGroup>
            )}
          />
        </div>
      </div>
      <div className="border-t border-gray-200" />
      <div className="flex px-6 py-4">
        <div className="flex w-full items-center justify-end gap-2">
          <div className="flex items-center gap-2">
            <Button type="reset" className="bg-none font-medium text-slate-600 hover:bg-jira-button-subtle">
              Cancel
            </Button>
            <Button className="bg-jira-button hover:bg-jira-button-hover" type="submit">
              Save
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};
