import { useState, useEffect } from 'react'
import { DateTime } from 'luxon'
import { NavLink, useParams } from 'react-router-dom'
import { useMediaQuery } from '@mantine/hooks'
import { SegmentedControl } from '@mantine/core'

import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs'
import { MonthPicker } from 'features/record/components/MonthPicker'
import { Records } from 'features/record/components/Records'
import { useArea } from 'features/area/api/getArea'
import { useMedications } from 'features/medication/api/getMedications'
import { MedicationRecordsMobile } from 'features/record/mobile/components/MedicationRecordsMobile'

export const MedicationRecords = () => {
  const { areaId } = useParams()

  //* Get and set the areas timezone from companies settings
  const timezone = 'Europe/London'

  // get first day of calendar
  const currentDate = DateTime.now().setZone(timezone)

  //! TODO:
  //! - Set the selected date, picked by clicking on a RecordCell
  //! - Set the selectedMonth, pass the current date as the default date to the MonthPicker and allow MonthPicker to change the selectedMonth
  const [selectedMonth, setSelectedMonth] = useState({
    start: currentDate.startOf('month'),
    end: currentDate.endOf('month'),
  })

  //! - Use the areas settings for beginning of medication records
  const areaQuery = useArea({ areaId: areaId as string })

  const isMobile = useMediaQuery('(max-width: 640px)')

  const medicationsQuery: any = useMedications({ areaId })

  const filterMedications = (e: any) => {
    const newMedications = medicationsQuery.data.medications.filter((medication: any) => medication.type === e)
    console.log(e)

    setMedications(newMedications)
  }

  const [medications, setMedications] = useState(null)

  useEffect(() => {
    if (medicationsQuery?.data?.medications) {
      const newMedications = medicationsQuery.data.medications.filter((medication: any) => medication.type === 'ROA&P')
      setMedications(newMedications)
    }
  }, [medicationsQuery?.data])

  if (isMobile) {
    return <MedicationRecordsMobile />
  }

  return (
    <div className="flex max-w-full bg-white">
      <div className="flex w-full max-w-full flex-col">
        <div className="sticky top-0 z-20 flex flex-col">
          <div className="flex h-10 items-center justify-between border-b border-neutral-200 bg-white px-4 py-2">
            <div className="flex items-center gap-4">
              <div>
                <SegmentedControl
                  data={[
                    { label: 'ROA&P', value: 'ROA&P' },
                    { label: 'PRN', value: 'PRN' },
                  ]}
                  size="xs"
                  transitionDuration={300}
                  transitionTimingFunction="linear"
                  p="1px"
                  classNames={{
                    root: 'border border-neutral-200 bg-white rounded-md',
                    label: 'text-[12px] font-semibold text-neutral-400 hover:text-neutral-800',
                    labelActive: '!text-neutral-800',
                    control: 'w-[80px]',
                    active:
                      'shadow-[0_0_3px_0_rgba(0,0,0,.2)] rounded-md bg-neutral-50 ml-[3px] mt-[3px] border border-neutral-300',
                  }}
                  onChange={(e: any) => filterMedications(e)}
                />
              </div>

              <div className="h-[20px] w-px border-r border-neutral-300" />

              {(!areaQuery.isLoading || !areaQuery.isFetching) && (
                <MonthPicker
                  selectedMonth={selectedMonth}
                  setSelectedMonth={setSelectedMonth}
                  backdateTo={DateTime.fromSeconds(Number(areaQuery?.data?.medication_records_backdate), {
                    zone: 'Europe/London',
                  })}
                />
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-1 overflow-hidden">
          <div className="flex flex-col space-y-2 overflow-auto">
            <div className="flex flex-col ">
              <Records selectedMonth={selectedMonth} medications={medications} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
