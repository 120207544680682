import { useMutation } from "react-query";

import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useNotification } from "hooks/useNotification";

export type UpdateGroupType = {
  data: {
    name: string;
    selectedAreas: Array<any>;
  };
  groupId: string;
};

export const updateGroup = ({ data, groupId }: UpdateGroupType): Promise<any> => {
  return axios.put(`/groups/${groupId}`, data);
};

export const useUpdateGroup = ({ config }: any = {}) => {
  const { addNotification } = useNotification();

  return useMutation({
    onMutate: async (updatingGroup: any) => {
      await queryClient.cancelQueries(["group", updatingGroup?.groupId]);

      const previousGroup = queryClient.getQueryData<any>(["group", updatingGroup?.groupId]);

      queryClient.setQueryData(["group", updatingGroup?.groupId], {
        ...previousGroup,
        name: updatingGroup.data.name,
        areas: updatingGroup.data.selectedAreas.length < 1 ? null : updatingGroup.data.selectedAreas,
        id: updatingGroup.groupId,
      });

      return { previousGroup };
    },
    onError: (_, __, context: any) => {
      if (context?.previousGroup) {
        queryClient.setQueryData(["group", context.previousGroup.id], context.previousGroup);
      }
    },
    onSuccess: (data) => {
      queryClient.refetchQueries(["group", data.id]);
      addNotification({
        disallowClose: true,
        type: "success",
        title: "Area Updated",
        message: "Area Updated Successfully!",
      });
    },
    mutationFn: updateGroup,
  });
};
