import React from "react";
import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { useMe } from "features/users/api/getMe";
import { DashboardLink } from "components/Dashboard/DashboardLink";

const items = [{ title: "Payroll Dashboard", href: "#" }];

export const PayrollDashboard = () => {
  const me = useMe();

  const links = [
    {
      to: "overview",
      name: "Go to Payroll Overview",
      admin_subtitle: "View, Update and Add Payroll",
      subtitle: "View Payroll",
      title: "Overview",
    },
    {
      to: "timesheet",
      name: "Go to Timesheet",
      subtitle: "Check and Add to a users timesheet",
      title: "Timesheets",
    },
    {
      to: "roles",
      name: "Go to Roles",
      subtitle: "View, Edit and Delete Roles",
      title: "Roles",
    },
    {
      to: "claims",
      name: "Go to Claims",
      subtitle: "Check and Add to a users claims",
      title: "Claims",
    },
  ];

  return (
    <div className="flex h-full w-full flex-col p-6">
      <Breadcrumbs items={items} />

      <p>These pages are a work in progress, please don't take this as representative of any final product</p>

      <div className="flex h-full w-full items-center justify-center">
        <div className=" grid grid-cols-2 gap-6">
          {links.map((link) => (
            <DashboardLink link={link} isAdmin={me?.data?.user_role.role === "admin"} />
          ))}
        </div>
      </div>
    </div>
  );
};
