import { useMutation } from "react-query";
import { queryClient } from "lib/react-query";

import { axios } from "lib/axios";
import { useNotification } from "hooks/useNotification";

export const createRole = async ({ data }: any) => {
  return axios.post(`/roles`, data);
};

export const useCreateRole = (successFunction: Function) => {
  const { addNotification } = useNotification();

  return useMutation({
    onError: (_, __, context) => {
      addNotification({
        disallowClose: false,
        type: "error",
        title: "Role Could Not Be Created",
        message: "",
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("role");
      addNotification({
        disallowClose: false,
        type: "success",
        title: "Role Created Successfully",
        message: "",
      });
      successFunction();
    },
    mutationFn: createRole,
  });
};
