import { Button } from "@mantine/core";
import React from "react";

type TFormSection = {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
};

export const FormSection = ({ title, subtitle, children }: TFormSection) => {
  return (
    <section className="max-w-4xl space-y-2">
      <div>
        <h4 className="text-[16px] font-medium text-[#11181c]">{title}</h4>
        {subtitle && <p className="font-medium text-[#11181c] text-opacity-50">{subtitle}</p>}
      </div>
      <div className="relative bg-[#fbfcfd]">
        <div className="transition-opacity duration-300">
          <div className="overflow-hidden rounded-md border border-gray-200 shadow-sm">
            <div className="p-6">{children}</div>
            <div className="border-t border-gray-200" />
            <div className="flex py-3 px-6">
              <div className="flex w-full items-center justify-end gap-2">
                <div className="flex items-center gap-2">
                  <Button variant="outline" size="xs" className="text-xs font-medium shadow">
                    Cancel
                  </Button>
                  <Button
                    size="xs"
                    compact
                    type="submit"
                    className="rounded bg-jira-button py-1 px-2 hover:bg-jira-button-hover"
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
