import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { DashboardLink } from "components/Dashboard/DashboardLink";
import React from "react";
import { NavLink, useParams } from "react-router-dom";
import { useAuth } from "stores/Auth";
import { useForms } from "../api/getForms";

const crumbs = [{ title: "Forms Dashboard", href: "#" }];

export const FormsDashboard = () => {
  const userLevel = useAuth((state) => state.userLevel);
  const links = [
    {
      to: "list",
      name: "Go to Forms",
      admin_subtitle: "View, Update and Add Forms",
      subtitle: "View Forms",
      title: "Forms",
    },
    {
      to: "records",
      name: "Go to Records",
      subtitle: "View and Add Form Records",
      title: "Records",
    },
  ];

  return (
    <div className="flex flex-1 flex-col overflow-hidden bg-[#f8f9fa]">
      <div className="flex max-h-12 items-center border-b border-gray-200 py-1.5 px-5">
        <Breadcrumbs items={crumbs} />
      </div>
      <div className="flex h-full w-full flex-col items-center justify-center    space-x-0 space-y-4 p-0 sm:flex-row sm:space-x-10 sm:space-y-0 sm:p-40 ">
        {links.map((link) => (
          <DashboardLink link={link} isAdmin={userLevel === "admin"} />
        ))}
      </div>
    </div>
  );
};
