import { useState, useEffect } from "react";
import { Checkbox, Group, Avatar, Menu, ActionIcon, Loader } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";
import { axios } from "lib/axios";

import { useUserStore } from "stores/userStore";
import { TUser } from "../types";
import { EllipsisHorizontalIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import { useInviteUser } from "../api/inviteUser";
import { useNotification } from "hooks/useNotification";
import { DateTime } from "luxon";

export const UserRow = (user: TUser) => {
  const { addNotification } = useNotification();

  const inviteUserMutation = useInviteUser(() => console.log("reinvited user"));

  const handleInviteUser = async () => {
    // setFormErrors([]);

    try {
      // inviteForm.parse({
      //   email,
      // });

      inviteUserMutation.mutateAsync({ email: user.email });
    } catch (error: any) {
      console.log(error.flatten());
      // setFormErrors(error.flatten());
    }
  };

  const [userType, setUserType] = useState("");
  const [userTypeColor, setUserTypeColor] = useState(" bg-amber-100 text-amber-600 ");
  const [userInitials, setUserInitials] = useState("");

  function composeUserType() {
    const userTypeString = user.user_level ? user.user_level : "User";
    const userTypeCapitalized = userTypeString.charAt(0).toUpperCase() + userTypeString.slice(1);
    setUserType(userTypeCapitalized);
  }

  //   function composeUserInitials() {
  //     const userNameSplit = user?.full_name?.split(" ");
  //     if (userNameSplit.length > 1) {
  //       let userInitialsList: string[] = [];
  //       let userInitials = "";
  //       for (let i = 0; i < userNameSplit.length; i++) {
  //         const userInitial = userNameSplit[i].charAt(0);
  //         userInitialsList = [...userInitialsList, userInitial];
  //       }
  //       for (let i = 0; i < userInitialsList.length; i++) {
  //         userInitials = userInitials + userInitialsList[i];
  //       }
  //       setUserInitials(userInitials);
  //       return;
  //     }
  //     const userInitials = user?.full_name?.charAt(0);
  //     setUserInitials(userInitials);
  //   }

  useEffect(() => {
    if (user) {
      composeUserType();
      //   composeUserInitials();
    }
  }, [user]);

  function composeUserRoleColor() {
    if (userType === "Manager") {
      setUserTypeColor(" bg-blue-100 text-blue-600 ");
    } else if (userType === "admin") {
      setUserTypeColor(" bg-emerald-100 text-emerald-600 ");
    }
  }

  useEffect(() => {
    composeUserRoleColor();
  }, [user, userType]);

  const navigate = useNavigate();

  //! Test

  const enableCheckedList = useUserStore((state) => state.enableCheckedList);
  const disableCheckedList = useUserStore((state) => state.disableCheckedList);
  const addUser = useUserStore((state) => state.addUser);
  const removeUser = useUserStore((state) => state.removeUser);

  const handleClick = (e: any) => {
    const checked = e.target.checked;
    console.log(checked);

    if (user.disabled) {
      addUser("enableCheckedList", user.id);
      if (!checked) {
        removeUser("enableCheckedList", user.id);
      }
    } else {
      addUser("disableCheckedList", user.id);
      if (!checked) {
        removeUser("disableCheckedList", user.id);
      }
    }
  };

  const handleNavigate = (e: any, location: string) => {
    const clickedElement = e.target.nodeName;
    if (clickedElement !== "INPUT") {
      navigate(`${location}/information`);
    }
  };

  const signOutUser = (e: any, userId: any) => {
    return axios.post<any>(`/signOutUser`);
  };

  const UserStatus = () => {
    if (user.disabled) {
      return (
        <div>
          <p>{user.disabled ? "Disabled" : "Active"}</p>
        </div>
      );
    }

    return user.verified ? (
      <div className="items-center rounded-md border border-green-700 bg-green-50 py-0.5 px-1 text-xs font-semibold text-green-700">
        <p>Verified</p>
      </div>
    ) : (
      <div className="items-center rounded-md border border-yellow-700 bg-yellow-50 py-0.5 px-1 text-xs font-semibold text-yellow-700">
        <p>Waiting for verification</p>
      </div>
    );
  };

  const changeCustomClaims = (type: "user" | "admin") => {
    if (type === "user") {
      const claims = {
        user_level: "user",
        can_set_claims: false,
      };
      return axios.post<any>(`/setCustomClaims`, { claims, id: user.id });
    } else {
      const claims = {
        user_level: "admin",
        can_set_claims: true,
      };
      return axios.post<any>(`/setCustomClaims`, { claims, id: user.id });
    }
  };

  return (
    <tr className="grid grid-cols-12 bg-white text-sm text-gray-700 hover:bg-gray-50">
      <td className="col-span-1 flex items-center p-3">
        <Checkbox
          className="3xs:hidden self-center justify-self-end indeterminate:bg-gray-300 2xs:hidden sm:inline xl:order-1 xl:col-span-1 xl:mb-0 xl:justify-self-center"
          checked={enableCheckedList.includes(user.id) || disableCheckedList.includes(user.id)}
          onChange={(e) => {
            handleClick(e);
          }}
        />
      </td>

      <td className="group col-span-5 flex cursor-pointer p-3" onClick={(e) => handleNavigate(e, user.id)}>
        <Group>
          <Avatar radius="xl" color="blue">
            {userInitials}
          </Avatar>
          <div className="flex flex-col">
            <div className="flex space-x-2">
              {user.full_name && (
                <p className="3xs:order-4 3xs:col-span-3 font-medium text-[#091e42] decoration-current group-hover:underline xl:order-3 xl:col-span-2 xl:self-center">
                  {user?.full_name && user?.full_name}
                </p>
              )}
              <div
                className={`3xs:order-1 3xs:col-start-1 3xs:col-end-3 xl:order-8 xl:col-span-1 ${userTypeColor} 3xs:mb-2 self-center justify-self-start rounded-sm px-[4px] pt-[2px] pb-[3px] text-[11px] font-bold uppercase xl:mb-0`}
              >
                <p>{userType}</p>
              </div>
            </div>
            <p className="text-[12px] font-medium text-[#6f6f6f] decoration-[#091e42] group-hover:underline">
              {user.email}
            </p>
          </div>
        </Group>
      </td>
      <td className="col-span-2 flex items-center truncate p-3">
        <UserStatus />
      </td>
      <td className="col-span-3 flex items-center truncate p-3">
        {user.last_sign_in_at && (
          // convert postgres timestamp to date using luxon
          <p className="font-medium text-[#6f6f6f]">
            {DateTime.fromISO(user.last_sign_in_at, {
              zone: "Europe/London",
            }).toFormat("dd MMM',' yyyy HH:mm")}
          </p>
        )}
      </td>
      <td className="col-span-1 flex items-center justify-center p-3">
        <Menu
          control={
            <ActionIcon className="hover:bg-white">
              <EllipsisHorizontalIcon className="h-5 w-5 text-gray-500" />
            </ActionIcon>
          }
          closeOnItemClick={false}
          placement="center"
          classNames={{
            body: "py-1",
            item: " py-[6px] px-3",
          }}
        >
          {!user.verified && (
            <Menu.Item
              className="group transition-all duration-150 ease-in-out hover:bg-[#f4f5f7]"
              onClick={handleInviteUser}
            >
              <div className="flex items-center space-x-2 p-1">
                {inviteUserMutation.isLoading ? (
                  <Loader color="gray" className="h-4 w-4" />
                ) : (
                  <EnvelopeIcon className="h-4 w-4 text-[#5e6c84] transition-all duration-150 ease-in-out group-hover:text-gray-900" />
                )}
                <p className="text-[11px] font-semibold text-[#5e6c84] transition-all duration-150 ease-in-out group-hover:text-gray-900">
                  Re-send invite email
                </p>
              </div>
            </Menu.Item>
          )}
          <Menu.Item
            className="group transition-all duration-150 ease-in-out hover:bg-[#f4f5f7]"
            onClick={() => changeCustomClaims("admin")}
          >
            <div className="space-y-3 p-1">
              <p className="text-[11px] font-semibold text-[#5e6c84] transition-all duration-150 ease-in-out group-hover:text-gray-900">
                Change to admin
              </p>
            </div>
          </Menu.Item>
          <Menu.Item
            className="group transition-all duration-150 ease-in-out hover:bg-[#f4f5f7]"
            onClick={() => changeCustomClaims("user")}
          >
            <div className="space-y-3 p-1">
              <p className="text-[11px] font-semibold text-[#5e6c84] transition-all duration-150 ease-in-out group-hover:text-gray-900">
                Change to user
              </p>
            </div>
          </Menu.Item>
          <Menu.Item className="group transition-all duration-150 ease-in-out hover:bg-[#f4f5f7]">
            <div className="space-y-3 p-1">
              <p className="text-[11px] font-semibold text-[#5e6c84] transition-all duration-150 ease-in-out group-hover:text-gray-900">
                Profile
              </p>
            </div>
          </Menu.Item>
        </Menu>
      </td>
    </tr>
  );
};
