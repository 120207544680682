import create from "zustand";
import { queryClient } from "lib/react-query";

import { supabase } from "lib/supabase";
import { axios } from "lib/axios";

interface AuthState {
  status: "idle" | "signedIn" | "signedOut";
  currentUser: null | any;
  userLevel: null | string;
  loading: boolean;
  supabaseSignIn: (email: string, password: string, setError: any) => void;
  supabaseSignOut: () => void;
  supabaseHydrate: () => void;
}

export const useAuth = create<AuthState>((set, get) => ({
  status: "idle",
  currentUser: null,
  userLevel: null,
  loading: true,
  supabaseSignIn: async (email, password, setError): Promise<any> => {
    set({ loading: true });
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email: email,
        password: password,
      });

      if (error) {
        throw new Error("Your email and password do not match. Please check and try again");
      }

      const token = await axios.post("/verifyToken", {
        token: data.session?.access_token,
      });
      console.log("supabase signin data", data, error, token.data.decodedToken.app_metadata.user_level);

      set({ userLevel: token.data.decodedToken.app_metadata.user_level });
      set({ currentUser: { ...token.data.decodedToken, id: token.data.decodedToken.sub } });
      console.log("supabaseHydrate token: ", token.data.decodedToken);
      set({ loading: false });
    } catch (error) {
      console.log("supabase signin error", error);
      set({ loading: false });
      throw error;
    }
  },
  supabaseSignOut: async () => {
    set({ loading: true });
    const { error } = await supabase.auth.signOut();
    console.log("supabase signOut error: ", error);
    set({ currentUser: null });
    set({ status: "signedOut" });
    set({ loading: false });
  },
  supabaseHydrate: async () => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange(async (event, session) => {
      set({ loading: false });

      if (session?.user) {
        console.log("supabaseHydrate", session);
        set({ loading: true });
        set({ status: "signedIn" });
        set({ currentUser: session.user });
        const token = await axios.post("/verifyToken", {
          token: session?.access_token,
        });
        const claim = token.data.decodedToken.app_metadata;
        set({ userLevel: claim.user_level });
        console.log("should be getting accountQuery");
        set({ loading: false });
      } else {
        set({ loading: true });
        set({ status: "signedOut" });
        set({ currentUser: null });
        set({ userLevel: null });
        queryClient.removeQueries();
        set({ loading: false });
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  },
}));
