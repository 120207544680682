import create from "zustand";

interface UserStoreTypes {
  defaultFilters: { name: string; role: string; type: string[] };
  filters: { name: string; role: string; type: string[] };
  setFilters: (type: any, filter: any) => void;
  resetFilters: () => void;
  isFiltersMenuOpen: boolean;
  toggleFiltersMenuOpen: () => void;
  enableCheckedList: Array<any>;
  disableCheckedList: Array<any>;
  addAllUsers: (enableUsers: any, disableUsers: any) => void;
  removeAllUsers: () => void;
  addUser: (list: any, user_id: any) => void;
  removeUser: (list: any, user_id: any) => void;
}

export const useUserStore = create<UserStoreTypes>((set, get) => ({
  defaultFilters: {
    name: "",
    role: "",
    type: [],
  },
  filters: {
    name: "",
    role: "",
    type: [],
  },
  setFilters: (type, filter) =>
    set((state) => ({
      filters: {
        ...state.filters,
        [type]: filter,
      },
    })),
  resetFilters: () => set((state) => ({ filters: state.defaultFilters })),
  isFiltersMenuOpen: false,
  toggleFiltersMenuOpen: () => set((state) => ({ isFiltersMenuOpen: !state.isFiltersMenuOpen })),

  enableCheckedList: [],
  disableCheckedList: [],
  addAllUsers: (enableUsers, disableUsers) =>
    set(() => ({
      enableCheckedList: enableUsers.map((user: any) => user.id),
      disableCheckedList: disableUsers.map((user: any) => user.id),
    })),
  removeAllUsers: () =>
    set(() => ({
      enableCheckedList: [],
      disableCheckedList: [],
    })),
  addUser: (list, user_id) =>
    set((state: any) => ({
      [list]: [...state[list], user_id],
    })),
  removeUser: (list, user_id) =>
    set(
      (state: any) => (
        console.log(
          "lists: ",
          list,
          [list].filter((user) => user === user_id)
        ),
        {
          [list]: state[list].filter((user: any) => user !== user_id),
        }
      )
    ),
}));
