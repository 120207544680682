import { useQuery } from "react-query";
import { AxiosError } from "axios";

import { axios } from "lib/axios";
import { AreaType } from "../types";
import { useAuth } from "stores/Auth";
import { supabase } from "lib/supabase";

export const getAreas = async (query: string): Promise<any[]> => {
  try {
    const response = await axios.get(`/areas${query}`);
    if (response.status === 200) {
      console.log("response 200", response.status);
    }
    // Don't forget to return something
    return response.data;
  } catch (err) {
    console.log("error: ", err);
    if (err instanceof AxiosError) {
      if (err.response?.status === 401) {
        throw new Error("Not authorised");
      } else if (err.response?.status === 403) {
        throw new Error("Not admin");
      }
    }
    throw new Error("There was an error fetching the request");
  }
};

export const useAreas = (query: string) => {
  const currentUser = useAuth((state) => state.currentUser);
  return useQuery({
    queryKey: ["areas"],
    queryFn: () => getAreas(query),
    enabled: !!currentUser?.id,
  });
};
