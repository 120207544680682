import { useState } from "react";
import { Modal, Button, ActionIcon, Textarea, TextInput } from "@mantine/core";
import { DateTime } from "luxon";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useParams } from "react-router-dom";

import { useRecordStore } from "../store/recordStore";
import { useCreateRecord } from "../api/createRecord";

export const CreateReceived = () => {
  const { areaId } = useParams();

  const selectedDate = useRecordStore((state) => state.selectedDate);
  const currentDate = useRecordStore((state) => state.currentDate);
  const isCreateReceivedModalOpen = useRecordStore(
    (state) => state.isCreateReceivedModalOpen
  );
  const setIsCreateReceivedModalOpen = useRecordStore(
    (state) => state.setIsCreateReceivedModalOpen
  );
  const selectedMedication = useRecordStore(
    (state) => state.selectedMedication
  );

  const [receivedFrom, setReceivedFrom] = useState("");
  const [receivedAmount, setReceivedAmount] = useState("");
  const [notes, setNotes] = useState("");

  const createRecordMutation = useCreateRecord(() =>
    setIsCreateReceivedModalOpen(false)
  );

  const handleNewCount = async () => {
    await createRecordMutation.mutateAsync({
      data: {
        type: "received",
        date: selectedDate,
        submitted_date: DateTime.now().setZone("Europe/London").toUnixInteger(),
        area_id: areaId,
        medication_id: selectedMedication.id,
        notes: notes,
        received_from: receivedFrom,
        received_amount: receivedAmount,
      },
    });
  };

  console.log("selectedMedication", selectedMedication, selectedDate);

  return (
    <Modal
      opened={isCreateReceivedModalOpen}
      onClose={() => setIsCreateReceivedModalOpen(false)}
      centered
      withCloseButton={false}
    >
      <div className="flex flex-col space-y-4">
        <div className="flex items-center justify-between">
          <div className="flex space-x-2">
            <p className="text-[16px] font-semibold text-gray-900">
              {selectedMedication.name}
            </p>
            <p className="text-[16px] font-semibold text-[#6b778c]">{`${DateTime.fromSeconds(
              Number(selectedDate),
              {
                zone: "Europe/London",
              }
            ).toLocaleString({
              year: "numeric",
              month: "numeric",
              day: "numeric",
            })}`}</p>
          </div>
          <div className="flex space-x-1">
            <ActionIcon
              onClick={() => {
                setIsCreateReceivedModalOpen(false);
              }}
            >
              <XMarkIcon className="h-6 w-6 text-[#42526E]" />
            </ActionIcon>
          </div>
        </div>
        <TextInput
          label="Received from"
          value={receivedFrom}
          onChange={(e) => setReceivedFrom(e.currentTarget.value)}
        />
        <TextInput
          label="Received amount"
          value={receivedAmount}
          onChange={(e) => setReceivedAmount(e.currentTarget.value)}
        />
        <Textarea
          label="Notes"
          value={notes}
          onChange={(e) => setNotes(e.currentTarget.value)}
        />
        <div>
          <Button
            className="bg-jira-button hover:bg-jira-button-hover"
            onClick={() => {
              handleNewCount();
            }}
            loading={createRecordMutation.isLoading}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};
