import { useState } from "react";
import { Select, SegmentedControl, TextInput } from "@mantine/core";

export const MedicationDose = ({ value, onChange, error }: any) => {
  const [selected, setSelected] = useState("Quantity");
  const [amount, setAmount] = useState<string>("");
  const [type, setType] = useState("");
  const [other, setOther] = useState("");

  const get = (label: string) => {
    if (Number(amount) > 1) {
      return label + "s";
    }

    return label;
  };

  const handleChange = ({ amount, type, other }: any) => {
    if (selected === "quantity") {
      setAmount(amount);
      setType(type);

      const string = amount + " " + type;

      onChange(string);
    } else if (selected === "other") {
      setOther(other);
      onChange(other);
    }
  };

  const handleSelected = (value: string) => {
    setSelected(value);

    setOther("");
    setAmount("");
    setType("");
    onChange("");
  };

  return (
    <div className="flex flex-col space-y-2">
      <div>
        <p className="text-sm font-semibold text-[#5e6c84]">
          Dose{" "}
          <span className="text-[#f03e3e]" aria-hidden="true">
            {" "}
            *
          </span>
        </p>
        <p className="mb-2 text-xs font-medium text-[#868e96]">
          The dose given when administering this medication - (e.g paracetamol
          would usually be 2 tablets)
        </p>
        <SegmentedControl
          // value={frequency}
          // onChange={setFrequency}
          data={[
            { value: "quantity", label: "Quantity" },
            { value: "other", label: "Other" },
          ]}
          classNames={{
            labelActive: "bg-jira-button text-white",
            active: "bg-jira-button",
            controlActive: "text-red-500",
          }}
          color="blue"
          value={selected}
          onChange={(e: string) => handleSelected(e)}
        />
      </div>

      {selected === "quantity" ? (
        <div className="inline-flex space-x-2">
          <div className="flex w-16">
            <Select
              data={[
                { value: "1", label: "1" },
                { value: "2", label: "2" },
                { value: "3", label: "3" },
                { value: "4", label: "4" },
                { value: "5", label: "5" },
                { value: "6", label: "6" },
                { value: "7", label: "7" },
                { value: "8", label: "8" },
                { value: "9", label: "9" },
                { value: "10", label: "10" },
                { value: "11", label: "11" },
                { value: "12", label: "12" },
                { value: "13", label: "13" },
                { value: "14", label: "14" },
                { value: "15", label: "15" },
              ]}
              value={amount}
              onChange={(e: string) => handleChange({ amount: e, type: type })}
            />
          </div>
          <Select
            placeholder="Select type"
            data={[
              { value: get("Tablet"), label: get("Tablet") },
              { value: get("Capsule"), label: get("Capsule") },
              { value: get("Drop"), label: get("Drop") },
              { value: get("Sachet"), label: get("Sachet") },
            ]}
            value={type}
            onChange={(e: string) => handleChange({ amount: amount, type: e })}
          />
        </div>
      ) : selected === "other" ? (
        <div className="flex space-x-2">
          <div className="flex w-full">
            <TextInput
              value={other}
              onChange={(e: any) =>
                handleChange({ other: e.currentTarget.value })
              }
              placeholder="Enter other dose amount"
              className="w-full"
            />
          </div>
        </div>
      ) : null}

      <p className="text-xs font-medium text-[#f03e3e]">{error && error}</p>
    </div>
  );
};
