import { useState, useEffect } from "react";
import { DateTime } from "luxon";
import { Button, LoadingOverlay } from "@mantine/core";
import { NavLink, useParams } from "react-router-dom";

import { TClaim } from "../types";
import { supabase } from "lib/supabase";
import { useUsers } from "features/users/api/getUsers";
import { CreateClaim } from "../components/CreateClaim";
import { useClaims } from "../components/getClaims";

export const Claims = () => {
  const { userId } = useParams();

  const [selectedClaim, setSelectedClaim] = useState<TClaim | null>(null);
  const [newClaimIsOpen, setNewClaimIsOpen] = useState<boolean>(false);
  const [claims, setClaims] = useState<TClaim[] | null>([]);

  const usersQuery = useUsers();
  const claimsQuery = useClaims({ userId: userId });

  if (claimsQuery.isLoading) {
    return null;
  }

  if (claimsQuery.isError) {
    return null;
  }

  return (
    <div className="relative h-screen w-screen bg-gray-100">
      {usersQuery.isLoading ? (
        <p>Loading</p>
      ) : (
        <UsersList users={usersQuery.data ? usersQuery.data : []} />
      )}
      <div className="absolute top-0 right-0 box-border flex w-[calc(100%-201px)] items-center justify-between border-b border-gray-200 bg-white py-2 px-4">
        <p className="text-lg font-semibold text-gray-900">Claims</p>
        <div className="flex">
          {newClaimIsOpen ? (
            <Button
              type="reset"
              className="bg-none font-medium text-slate-600 hover:bg-jira-button-subtle"
              onClick={() => setNewClaimIsOpen(false)}
            >
              Cancel
            </Button>
          ) : (
            <Button
              className="bg-jira-button hover:bg-jira-button-hover"
              onClick={() => setNewClaimIsOpen(true)}
            >
              New Claim
            </Button>
          )}
        </div>
      </div>
      <div className="absolute bottom-0 right-0 flex h-[calc(100%-65px)] w-[calc(100%-201px)]">
        <div className=""></div>
        <div className="flex h-full w-[300px] flex-col justify-between overflow-y-scroll">
          <div className="relative flex flex-col space-y-2 p-2">
            <LoadingOverlay
              visible={claimsQuery.isLoading || claimsQuery.isFetching}
            />
            {claimsQuery.data &&
              claimsQuery.data.map((claim: TClaim) => (
                <Claim
                  claim={claim}
                  selectedClaim={selectedClaim}
                  setSelectedClaim={setSelectedClaim}
                />
              ))}
          </div>
          <div className="border-200 sticky bottom-0 flex border-t border-r bg-white p-2">
            <p className="relative font-semibold text-gray-900">
              <LoadingOverlay
                visible={claimsQuery.isLoading || claimsQuery.isFetching}
              />
              Total £
              {claimsQuery.data &&
                claimsQuery.data.length > 0 &&
                claimsQuery.data
                  .map((i: any) => i.amount)
                  .reduce((a: any, b: any) => a + b)}
            </p>
          </div>
        </div>
        <div className="w-full space-y-2 overflow-y-scroll p-2">
          {newClaimIsOpen ? (
            <CreateClaim />
          ) : (
            <ClaimInformation claim={selectedClaim} />
          )}
        </div>
      </div>
    </div>
  );
};

const UsersList = ({ users }: any) => {
  return (
    <div className="fixed bottom-0 left-0 h-[calc(100vh-45px)] w-[200px] overflow-hidden border-t border-r border-gray-200 bg-white">
      <div className="absolute bottom-0 left-0 box-border h-[calc(100%)] w-full overflow-y-auto overflow-x-hidden">
        {users.map((user: any) => (
          <NavLink
            key={user.id}
            to={`/admin/payroll/claims/${user.id}`}
            className={({ isActive }: any) =>
              isActive
                ? "duration-250 relative flex w-full flex-col rounded-md px-2 py-2 text-sm font-semibold text-jira-button transition-all ease-in-out"
                : "duration-250 relative flex w-full flex-col rounded-md px-2 py-2 text-sm font-semibold text-gray-900 transition-all ease-in-out hover:text-jira-button-hover"
            }
          >
            <div className="flex items-center space-x-3">
              <span className="overflow-hidden text-ellipsis whitespace-nowrap text-[14px]">
                {user.full_name}
              </span>
            </div>
          </NavLink>
        ))}
      </div>
    </div>
  );
};

const Claim = ({
  claim,
  selectedClaim,
  setSelectedClaim,
}: {
  claim: TClaim;
  selectedClaim: TClaim | null;
  setSelectedClaim: (claim: TClaim) => void;
}) => {
  const getDate = () => {
    if (claim.date) {
      const date = DateTime.fromSeconds(claim.date, { zone: "Europe/London" })
        .setLocale("en-gb")
        .toLocaleString({
          day: "2-digit",
          month: "short",
          year: "numeric",
        });

      return date;
    }
  };

  return (
    <div
      className="max-w-[500px] rounded-md bg-white p-2"
      onClick={() => setSelectedClaim(claim)}
    >
      <div className="flex justify-between">
        <p
          className={`${
            selectedClaim && claim.id === selectedClaim.id
              ? "text-jira-button"
              : "text-gray-900"
          } font-semibold capitalize`}
        >
          {claim.supplier}
        </p>
        <p className="font-semibold text-gray-900">£{claim.amount}</p>
      </div>
      <div className="flex justify-between">
        <p>{getDate()}</p>
        <p>{claim.category}</p>
      </div>
    </div>
  );
};

const ClaimInformation = ({ claim }: { claim: TClaim | null }) => {
  const [receiptUrl, setReceiptUrl] = useState<string | null>(null);

  const getReceipt = async () => {
    if (claim?.receipt_url) {
      const { data, error } = await supabase.storage
        .from("receipts")
        .download(claim?.receipt_url as string);

      console.log("receipt data: ", data);

      if (data) {
        const url = webkitURL.createObjectURL(data);
        setReceiptUrl(url);
      }
    } else {
      setReceiptUrl(null);
    }
  };

  useEffect(() => {
    getReceipt();
  }, [claim]);

  const getDate = () => {
    if (claim?.date) {
      const date = DateTime.fromSeconds(claim.date, { zone: "Europe/London" })
        .setLocale("en-gb")
        .toLocaleString({
          day: "2-digit",
          month: "short",
          year: "numeric",
        });

      return date;
    }
  };

  return claim ? (
    <div className="flex flex-row ">
      <div className="w-2/3 max-w-[900px] space-y-3 rounded-md bg-white">
        <div className="flex justify-between border-b border-gray-200 p-4">
          <p className="text-lg font-semibold text-gray-900">
            {claim.supplier}
          </p>
          <p className="text-lg font-semibold text-gray-900">£{claim.amount}</p>
        </div>
        <div className="flex flex-col space-y-2 px-4 pb-4">
          <p>{getDate()}</p>

          <div>
            <p className="font-medium text-gray-500">Claim Type</p>
            <p className="capitalize">{claim.type}</p>
          </div>
          <div>
            <p className="font-medium text-gray-500">Category</p>
            <p className="capitalize">{claim.category}</p>
          </div>
          <div>
            <p className="font-medium text-gray-500">Supplier</p>
            <p className="capitalize">{claim.supplier}</p>
          </div>
        </div>
      </div>
      <div>{receiptUrl && <img src={receiptUrl} />}</div>
    </div>
  ) : (
    <div>
      <p>Select a claim from the left</p>
    </div>
  );
};
