import { useEffect, useState } from "react";
import {
  HomeIcon,
  Square2StackIcon,
  RectangleGroupIcon,
  UsersIcon,
  ArrowTopRightOnSquareIcon,
  FingerPrintIcon,
  QuestionMarkCircleIcon,
  Cog6ToothIcon,
  BanknotesIcon,
} from "@heroicons/react/20/solid";
import { LockClosedIcon, LockOpenIcon } from "@heroicons/react/24/outline";
import { Drawer, Burger, Button, Menu, Divider, Skeleton } from "@mantine/core";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faPenToSquare,
  faLock,
  faUnlock,
  faFingerprint,
} from "@fortawesome/free-solid-svg-icons";

import logo from "assets/supported_logo_sm_alt.png";
import { useAuth } from "stores/Auth";
import { useMe } from "features/users/api/getMe";
import { axios } from "lib/axios";
import { supabase } from "lib/supabase";
import { useAccount } from "features/admin/api/getAccount";

export const Navbar = () => {
  return (
    <div className="flex h-[45px] w-full items-center justify-between space-x-4 bg-[#0747a6] px-2 py-1 text-white shadow-md">
      <div className="flex h-[45px] items-center space-x-4 px-2 py-1">
        <NavbarDrawer />
        <img
          src={logo}
          alt={`Support'ed Logo`}
          className="flex max-h-full min-h-0 min-w-0 max-w-full 2xs:invisible sm:visible"
        />
      </div>

      <div className="flex items-center space-x-3">
        {/* <Help /> */}

        <LoginBadge />
      </div>
    </div>
  );
};

const NavbarDrawer = () => {
  const [open, setOpen] = useState(false);
  const meQuery = useMe();

  const navbarItems = [
    {
      name: "HOME",
      icon: <HomeIcon className="h-5 w-5" />,
      link: "/home",
      permissions: "all",
    },
    {
      name: "PAY",
      icon: <BanknotesIcon className="h-5 w-5" />,
      link: `/pay/timesheet`,
      permissions: "all",
    },
    {
      name: "AREAS",
      icon: <Square2StackIcon className="h-5 w-5" />,
      link: "/areas",
      permissions: "admin",
    },
    {
      name: "GROUPS",
      icon: <RectangleGroupIcon className="h-5 w-5" />,
      link: "/groups",
      permissions: "admin",
    },
    {
      name: "USERS",
      icon: <UsersIcon className="h-5 w-5" />,
      link: "/users",
      permissions: "admin",
    },
    {
      name: "PAYROLL",
      icon: <BanknotesIcon className="h-5 w-5" />,
      link: "/admin/payroll",
      permissions: "admin",
    },
    {
      name: "SETTINGS",
      icon: <Cog6ToothIcon className="h-5 w-5" />,
      link: "/admin/settings",
      permissions: "admin",
    },
  ];

  console.log("meQuery", meQuery.data);

  return (
    <>
      <Burger opened={open} onClick={() => setOpen(!open)} color="white" />

      <Drawer
        opened={open}
        onClose={() => setOpen(false)}
        className="mt-[45px] p-2"
        overlayOpacity={0.4}
        withCloseButton={false}
        size="sm"
        classNames={{
          drawer: "bg-[#0747a6]",
        }}
        shadow="lg"
      >
        {meQuery.data?.user_role?.role === "admin" ? (
          <div className="flex flex-col space-y-3">
            <div>
              {navbarItems
                .filter(({ permissions }) => permissions === "all")
                .map(({ name, icon, link }) => {
                  return (
                    <NavLink
                      key={link}
                      to={`${link}`}
                      className={({ isActive }) =>
                        isActive
                          ? "duration-250 flex w-full min-w-[180px] flex-col rounded-md bg-[#205aaf] px-2 py-2 text-xs font-semibold text-blue-50 transition-all ease-in-out"
                          : "duration-250 flex w-full min-w-[180px] flex-col rounded-md px-2 py-2 text-xs font-semibold text-blue-50 transition-all ease-in-out hover:bg-[#205aaf]"
                      }
                      onClick={() => setOpen(false)}
                    >
                      <div className="flex space-x-4">
                        {icon}
                        <span> {name}</span>
                      </div>
                    </NavLink>
                  );
                })}
            </div>
            <div>
              <p className="flex w-full flex-col rounded-md px-1 py-2.5 text-[12px] font-semibold text-blue-100">
                Admin
              </p>
              {navbarItems
                .filter(({ permissions }) => permissions === "admin")
                .map(({ name, icon, link }) => {
                  return (
                    <NavLink
                      key={link}
                      to={`${link}`}
                      className={({ isActive }) =>
                        isActive
                          ? "duration-250 flex w-full min-w-[180px] flex-col rounded-md bg-[#205aaf] px-2 py-2 text-xs font-semibold text-blue-50 transition-all ease-in-out"
                          : "duration-250 flex w-full min-w-[180px] flex-col rounded-md px-2 py-2 text-xs font-semibold text-blue-50 transition-all ease-in-out hover:bg-[#205aaf]"
                      }
                      onClick={() => setOpen(false)}
                    >
                      <div className="flex space-x-4">
                        {icon}
                        <span> {name}</span>
                      </div>
                    </NavLink>
                  );
                })}
            </div>
          </div>
        ) : (
          navbarItems
            .filter(({ name, icon, link, permissions }) => permissions === "all")
            .map(({ name, icon, link }) => {
              return (
                <NavLink
                  key={link}
                  to={`${link}`}
                  className="duration-250 flex w-full min-w-[180px] flex-col rounded-md px-2 py-2 text-xs font-semibold text-blue-50 transition-all ease-in-out hover:bg-[#205aaf]"
                  onClick={() => setOpen(false)}
                >
                  <div className="flex space-x-4">
                    {icon}
                    <span> {name}</span>
                  </div>
                </NavLink>
              );
            })
        )}
      </Drawer>
    </>
  );
};

const LoginBadge = () => {
  const status = useAuth((state) => state.status);
  const currentUser = useAuth((state) => state.currentUser);
  const signOut = useAuth((state) => state.supabaseSignOut);
  const userLevel = useAuth((state) => state.userLevel);
  const userQuery = useMe();

  const userTypeColor = {
    admin: "bg-amber-100 text-amber-600",
    user: "bg-green-100 text-green-600",
  };

  console.log("loginbadge", userQuery);

  const navigate = useNavigate();

  //! JUST FOR TESTING, DELETE THIS
  const changePermissions = (level: "user" | "admin") => {
    const user = { user: "leon@support-ed.uk", level: level };
    return axios.post(`/setAdmin`, user);
  };
  const revokeToken = () => {
    return axios.post(`/revokeToken`);
  };

  const changeCustomClaims = () => {
    const claims = {
      user_level: "admin",
      can_set_claims: true,
    };
    return axios.post<any>(`/setCustomClaims`, claims);
  };

  const setAccountToUser = () => {
    const claims = {
      user_level: "user",
      can_set_claims: true,
    };
    return axios.post<any>(`/setCustomClaims`, claims);
  };

  const setAccountToAdmin = () => {
    const claims = {
      user_level: "admin",
      can_set_claims: true,
    };
    return axios.post<any>(`/setCustomClaims`, claims);
  };
  //! JUST FOR TESTING, DELETE THIS

  if (userQuery.isLoading) {
    return (
      <div className="flex items-center space-x-3 px-3">
        <Skeleton className="h-7 w-7 rounded-full" />
        <div className="flex flex-col space-y-1">
          <Skeleton className="h-2 w-20" />
          <Skeleton className="h-2 w-28" />
        </div>
      </div>
    );
  }

  return status !== "signedIn" ? (
    <Button onClick={() => navigate("/auth/login")}>Login</Button>
  ) : (
    <Menu
      gutter={0}
      control={
        <div className="flex h-[45px] items-center">
          <div className="flex h-[38px] items-center space-x-3 rounded px-1 py-1 transition-all duration-100 hover:bg-[#205aaf]">
            <img
              src={`https://ui-avatars.com/api/?name=${userQuery?.data?.full_name}&rounded=true&background=fff&color=111827&bold=true&uppercase=true`}
              alt="avatar"
              className="h-7 w-7 rounded-full"
            />
            {/* <div className="h-7 w-7 rounded-full bg-gray-300"></div> */}
            <div className="flex flex-col">
              <p className="text-sm font-medium">{userQuery.data?.full_name}</p>
              <p className=" text-xs">{userQuery.data?.email}</p>
            </div>
          </div>
        </div>
      }
    >
      <Menu.Item>
        <div className="space-y-3 p-1">
          <p className="text-[11px] font-extrabold uppercase text-[#5e6c84]">Profile</p>
          {userLevel && (
            <div
              className={`3xs:order-1 3xs:col-start-1 3xs:col-end-3 xl:order-8 xl:col-span-1 ${
                userTypeColor[userLevel as keyof typeof userTypeColor]
              } 3xs:mb-2 self-center justify-self-start rounded-sm px-[4px] pt-[2px] pb-[3px] text-[11px] font-bold uppercase xl:mb-0`}
            >
              <p>{userLevel}</p>
            </div>
          )}
        </div>
      </Menu.Item>

      <Menu.Item
        className="group transition-all duration-150 ease-in-out hover:bg-[#f4f5f7]"
        component={Link}
        to={`profile/information`}
      >
        <div className="flex w-full items-center space-x-2 px-1">
          <FontAwesomeIcon
            icon={faPenToSquare}
            className="h-3.5 w-3.5 text-[#5e6c84] transition-all duration-150 ease-in-out group-hover:text-gray-900"
          />
          <p className="text-[12px] font-semibold text-[#5e6c84] transition-all duration-150 ease-in-out group-hover:text-gray-900">
            Edit profile
          </p>
        </div>
      </Menu.Item>
      {/* <Divider />
      <Menu.Item>
        <div className="space-y-2 p-1">
          <p className="text-[11px] font-extrabold uppercase text-[#5e6c84]">
            Admin Options
          </p>
        </div>
      </Menu.Item>
      <Menu.Item
        className="group transition-all duration-150 ease-in-out hover:bg-[#f4f5f7]"
        onClick={async () => {
          await changeCustomClaims();
          const { data, error } = await supabase.auth.refreshSession();
          console.log("set custom claim", data, error);
        }}
      >
        <div className="flex w-full items-center space-x-2 px-1">
          <FontAwesomeIcon
            icon={faLock}
            className="h-3.5 w-3.5 text-[#5e6c84] transition-all duration-150 ease-in-out group-hover:text-gray-900"
          />
          <p className="text-[12px] font-semibold text-[#5e6c84] transition-all duration-150 ease-in-out group-hover:text-gray-900">
            Set custom claim
          </p>
        </div>
      </Menu.Item>
      <Menu.Item
        className="group transition-all duration-150 ease-in-out hover:bg-[#f4f5f7]"
        onClick={async () => {
          console.log("make admin");
          await setAccountToAdmin();
        }}
      >
        <div className="flex w-full items-center space-x-2 px-1">
          <FontAwesomeIcon
            icon={faUnlock}
            className="h-3.5 w-3.5 text-[#5e6c84] transition-all duration-150 ease-in-out group-hover:text-gray-900"
          />
          <p className="text-[12px] font-semibold text-[#5e6c84] transition-all duration-150 ease-in-out group-hover:text-gray-900">
            Change to admin
          </p>
        </div>
      </Menu.Item>

      <Menu.Item
        className="group transition-all duration-150 ease-in-out hover:bg-[#f4f5f7]"
        onClick={() => {
          revokeToken();
        }}
      >
        <div className="flex w-full items-center space-x-2 px-1">
          <FontAwesomeIcon
            icon={faFingerprint}
            className="h-3.5 w-3.5 text-[#5e6c84] transition-all duration-150 ease-in-out group-hover:text-gray-900"
          />
          <p className="text-[12px] font-semibold text-[#5e6c84] transition-all duration-150 ease-in-out group-hover:text-gray-900">
            Revoke token
          </p>
        </div>
      </Menu.Item> */}

      <Divider />
      <Menu.Item
        className="group transition-all duration-150 ease-in-out hover:bg-[#f4f5f7]"
        onClick={() => {
          console.log("signout");
          signOut();
        }}
      >
        <div className="flex w-full items-center space-x-2 px-1">
          <FontAwesomeIcon
            icon={faArrowRightFromBracket}
            className="h-3.5 w-3.5 text-[#5e6c84] transition-all duration-150 ease-in-out group-hover:text-gray-900"
          />
          <p className="text-[12px] font-semibold text-[#5e6c84] transition-all duration-150 ease-in-out group-hover:text-gray-900">
            Log out
          </p>
        </div>
      </Menu.Item>
    </Menu>
  );
};

const Help = () => {
  return (
    <div className="h-8 w-8 cursor-pointer rounded-full bg-transparent p-1 transition-all duration-150 ease-in-out hover:bg-[#2c64b8] ">
      <QuestionMarkCircleIcon className="h-6 w-6" />
    </div>
  );
};
