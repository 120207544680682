import { Route, Routes } from 'react-router-dom'
import { MedicationDashboard } from './MedicationDashboard'
import { Medications } from './Medications'
import { CreateMedication } from './CreateMedication'
import { UpdateMedication } from './UpdateMedication'
import { MedicationRecords } from './MedicationRecords'

export const MedicationRoutes = () => {
  return (
    <Routes>
      <Route path="/*" element={<MedicationDashboard />} />
      <Route path="add" element={<CreateMedication />} />
      <Route path="update/:medicationId" element={<UpdateMedication />} />
    </Routes>
  )
}
