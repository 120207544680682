import React from "react";

export const Marker = ({ marker, editMarker }: any) => {
  console.log("Marker Component", marker);
  return (
    <div
      className="flex cursor-pointer space-x-2 rounded hover:bg-[#fafbfc] active:bg-[#fafbfc]"
      onClick={() => editMarker(marker)}
    >
      <div className="flex h-[70px] w-[70px] items-center justify-center sm:h-[100px] sm:w-[100px]">
        <div className="grid h-full w-full grid-cols-1 grid-rows-1 overflow-hidden">
          <div className="z-50 col-start-1 row-start-1 h-full w-full">
            <svg
              className={`flex aspect-square h-full w-full touch-none`}
              // used to stop the dragging of a ghost image
              onDrag={(e) => {
                console.log("drag");
                e.preventDefault();
              }}
              onDragStart={(e) => {
                console.log("drag");
                e.preventDefault();
              }}
              viewBox="0 0 800 800"
            >
              <g className="cursor-grab active:cursor-grabbing">
                {marker ? (
                  marker.type === "rectangle" ? (
                    <rect
                      x={marker.x}
                      y={marker.y}
                      width={marker.width}
                      height={marker.height}
                      fill="#ef4444"
                      fillOpacity={0.4}
                      stroke="#ef4444"
                    />
                  ) : (
                    <circle
                      cx={marker.x}
                      cy={marker.y}
                      r="20"
                      fill="#ef4444"
                      fillOpacity={0.4}
                      stroke="#ef4444"
                    />
                  )
                ) : null}
              </g>
            </svg>
          </div>
          <div className="z-10 col-start-1 row-start-1 h-full w-full">
            <img
              src={marker.bodypart_image}
              alt="bodymap"
              className="h-full max-h-[800px] w-full max-w-[800px]"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col space-y-1 py-2">
        <p className="text-base font-semibold text-[#172b4d]">
          {marker.bodypart_name}
        </p>
        {Object.keys(marker.notes).map((keyName, i) => (
          <div className="text-[13px] font-medium text-gray-500" key={i}>
            <p className="capitalize text-neutral-600">{keyName}</p>
            <p>{marker.notes[keyName]}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
