import { useState, useEffect } from "react";
import { useRoutes, Navigate, useParams, Link, NavLink } from "react-router-dom";
import {
  Button,
  LoadingOverlay,
  Skeleton,
  Checkbox,
  CheckboxGroup,
  TextInput,
  Select,
  NumberInput,
} from "@mantine/core";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import { useAuth } from "stores/Auth";
import { useRoles } from "features/admin/payroll/roles/api/getRoles";

import { useUser } from "../api/getUser";
import { useUpdateUser } from "../api/updateUser";
import { UserWage } from "../components/UserWage";
import { UserRoles } from "../components/UserRoles";
import { UserInformation } from "../components/UserInformation";
import { useUserRoles } from "features/admin/payroll/timesheet/api/getUserRoles";
import { useUpdateRotacloudId } from "../api/updateRotacloudId";

export const User = () => {
  const { userId } = useParams();

  const currentUser = useAuth((state: any) => state.currentUser);
  const userLevel = useAuth((state: any) => state.userLevel);

  const userQuery = useUser(userId);

  const updateUserMutation = useUpdateUser();

  const rolesQuery = useRoles(() => console.log("admin"));
  const userRolesQuery = useUserRoles(() => console.log("success"), userQuery.data?.id as string);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: userQuery.data?.full_name,
      email: userQuery.data?.email,
      salary_type: userQuery.data?.salary_type,
      salary: userQuery.data?.salary,
      roles: userRolesQuery?.data?.map((role) => role.id),
    },
  });

  const redirectToLogin = [
    {
      path: "/roles",
      element: <Navigate to="/profile/information" />,
    },
    {
      path: "/admin",
      element: <Navigate to="/profile/information" />,
    },
  ];

  const protectedRoutes = [
    {
      path: "/roles",
      element: <UserRoles user={userQuery?.data} roles={rolesQuery?.data} control={control} />,
    },
    {
      path: "/admin",
      element: <AdminOptions user={userQuery?.data} />,
    },
  ];

  const publicRoutes = [
    {
      path: "/wage",
      element: <UserWage control={control} />,
    },
    {
      path: "/information",
      element: <UserInformation control={control} />,
    },
  ];

  useEffect(() => {
    if (userQuery.data && userRolesQuery.data) {
      reset({
        name: userQuery.data.full_name,
        email: userQuery.data.email,
        salary_type: userQuery.data.salary_type,
        salary: userQuery.data.salary,
        roles: userRolesQuery?.data?.map((role) => role.id.toString()),
      });
    }
  }, [userQuery.data, userRolesQuery.data, reset]);

  const routes = userLevel === "admin" ? protectedRoutes : redirectToLogin;

  const element = useRoutes([...routes, ...publicRoutes]);

  const onSubmit = async (data: any) => {
    console.log("data", data);
    await updateUserMutation.mutateAsync({ data, userId: userQuery.data?.id as string });
  };

  if (!userQuery.data) {
    return (
      <div className="flex h-full min-h-0 w-full flex-col items-center overflow-y-auto px-12 py-6">
        <div className="flex w-full max-w-5xl flex-col">
          <div className="relative flex flex-col">
            <p className="text-2xl font-semibold text-gray-900">{}</p>
            <LoadingOverlay visible={userQuery.isLoading} />
            <div className="space-y-8">
              <div className="flex flex-col">
                <p className="text-2xl font-semibold text-gray-900">User profile</p>
                <Link
                  className="cursor-pointer text-sm font-semibold text-jira-button hover:text-[#0065FF]"
                  to="/users"
                >
                  Back to users
                </Link>
              </div>

              <div className="flex flex-col space-y-6">
                <div className="space-y-2">
                  <div>
                    <p className="text-[16px] font-semibold text-gray-900">User information</p>
                  </div>

                  <div className="space-y-5 rounded-sm border border-[#ebecf0] bg-white p-4">
                    <div className="space-y-10">
                      <div className="flex flex-col space-y-3">
                        <div>
                          <p className="text-[13px] font-semibold text-gray-500">Name</p>
                          <Skeleton className="flex w-full" width="100%" height="36px" />
                        </div>
                        <div>
                          <p className="text-[13px] font-semibold text-gray-500">Email</p>
                          <Skeleton className="flex w-full" width="100%" height="36px" />
                        </div>
                        <div>
                          <p className="text-[13px] font-semibold text-gray-500">Level</p>
                          <Skeleton className="flex w-full" width="100%" height="36px" />
                        </div>
                        <div>
                          <p className="text-[13px] font-semibold text-gray-500">Role</p>
                          <Skeleton className="flex w-full" width="100%" height="36px" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-center space-x-3">
                <Button
                  type="submit"
                  className="bg-jira-button hover:bg-jira-button-hover"
                  loading={updateUserMutation.isLoading}
                >
                  Save
                </Button>
                <Button
                  className="bg-none font-medium text-slate-600 hover:bg-jira-button-subtle"
                  variant="subtle"
                  color="gray"
                  component={Link}
                  to="/users"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-full w-full bg-gray-50">
      <div className="flex h-full w-full flex-1 flex-col overflow-y-auto">
        <div className="flex h-full max-h-full">
          <div className="h-full max-h-full w-64 border-r border-gray-200 bg-white">
            <div className="mb-2">
              <div className="flex h-12 max-h-12 items-center border-b border-gray-200 px-6">
                <h4 className="mb-0 truncate text-lg font-semibold text-gray-900">Account</h4>
              </div>
            </div>
            <nav className="flex flex-col rounded-md">
              <div className="border-b border-gray-200 py-5 px-5">
                <NavLink
                  key="information"
                  to="information"
                  className={({ isActive }) =>
                    isActive
                      ? "duration-250 text-sm font-medium text-jira-button transition-all ease-in-out hover:text-jira-button"
                      : "duration-250 text-sm font-medium text-gray-500 transition-all ease-in-out hover:text-jira-button"
                  }
                >
                  <div className="flex items-center space-x-3">
                    <span className="text-[14px]">My Information</span>
                  </div>
                </NavLink>
              </div>
              <div className="border-b border-gray-200 py-5 px-5">
                <div className="mb-2 font-medium text-gray-900">
                  <span>Payroll Information</span>
                </div>
                <div className="flex flex-col space-y-1">
                  {userLevel === "admin" && (
                    <NavLink
                      key="roles"
                      to="roles"
                      className={({ isActive }) =>
                        isActive
                          ? "duration-250 text-sm font-medium text-jira-button transition-all ease-in-out hover:text-jira-button"
                          : "duration-250 text-sm font-medium text-gray-500 transition-all ease-in-out hover:text-jira-button"
                      }
                    >
                      <div className="flex items-center space-x-3">
                        <span className="text-[14px]">Roles</span>
                      </div>
                    </NavLink>
                  )}
                  <NavLink
                    key="wage"
                    to="wage"
                    className={({ isActive }) =>
                      isActive
                        ? "duration-250 text-sm font-medium text-jira-button transition-all ease-in-out hover:text-jira-button"
                        : "duration-250 text-sm font-medium text-gray-500 transition-all ease-in-out hover:text-jira-button"
                    }
                  >
                    <div className="flex items-center space-x-3">
                      <span className="text-[14px]">Wages & Salary</span>
                    </div>
                  </NavLink>
                </div>
              </div>
              {userLevel === "admin" && (
                <div className="border-b border-gray-200 py-5 px-5">
                  <div className="mb-2 font-medium text-gray-900">
                    <span>Admin</span>
                  </div>
                  <NavLink
                    key="admin"
                    to="admin"
                    className={({ isActive }) =>
                      isActive
                        ? "duration-250 text-sm font-medium text-jira-button transition-all ease-in-out hover:text-jira-button"
                        : "duration-250 text-sm font-medium text-gray-500 transition-all ease-in-out hover:text-jira-button"
                    }
                  >
                    <div className="flex items-center space-x-3">
                      <span className="text-[14px]">Admin Options</span>
                    </div>
                  </NavLink>
                </div>
              )}
            </nav>
          </div>
          <div className="flex flex-1 flex-col">
            <div className="max-w-4xl p-4">
              <form className="w-full rounded-md border border-gray-200 bg-white" onSubmit={handleSubmit(onSubmit)}>
                {element}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AdminOptions = ({ user }: { user: any }) => {
  const updateRotacloudIdMutation = useUpdateRotacloudId();

  const [canSetClaims, setCanSetClaims] = useState<boolean>(user?.can_set_claims);

  const onSubmit = async () => {
    const data = {
      name: user.full_name,
    };
    await updateRotacloudIdMutation.mutateAsync({ data, userId: user.id as string });
  };

  return (
    <section>
      <div className="border-b border-[#e6e8eb]">
        <div className="flex items-center px-6 py-4">
          <p className="text-[16px] font-semibold text-gray-900">Admin Options</p>
        </div>
      </div>
      <div className="px-6 py-4">
        <div className="space-y-2">
          <Checkbox
            checked={canSetClaims}
            onChange={(e) => setCanSetClaims(e.currentTarget.checked)}
            label="User can change claims"
            size="xs"
          />
        </div>
        <div>
          <p className="font-medium text-neutral-800" onClick={() => onSubmit()}>
            Sync Rotacloud account
          </p>
        </div>
      </div>
      <div className="border-t border-gray-200" />
      <div className="flex px-6 py-4">
        <div className="flex w-full items-center justify-end gap-2">
          <div className="flex items-center gap-2">
            <Button type="reset" className="bg-none font-medium text-slate-600 hover:bg-jira-button-subtle">
              Cancel
            </Button>
            <Button className="bg-jira-button hover:bg-jira-button-hover" type="submit">
              Save
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

// return (
//   <div className="flex h-full min-h-0 w-full flex-col items-center overflow-y-auto px-12 py-6">
//     <div className="flex w-full max-w-5xl flex-col">
//       <div className="relative flex flex-col">
//         <p className="text-2xl font-semibold text-gray-900">{}</p>
//         <LoadingOverlay visible={userQuery.isLoading} />
//         <form
//           onSubmit={async (e: any) => {
//             e.preventDefault();
//             console.log("updateUserMutation values", name);
//             await updateUserMutation.mutateAsync({
//               data: { name: name },
//               userId: userQuery?.data?.id,
//             });
//           }}
//           className="space-y-8"
//         >
//           <div className="flex flex-col">
//             <p className="text-2xl font-semibold text-gray-900">
//               User profile
//             </p>
//             <Link
//               className="cursor-pointer text-sm font-semibold text-jira-button hover:text-[#0065FF]"
//               to="/users"
//             >
//               Back to users
//             </Link>
//           </div>

//           <div className="flex flex-col space-y-6">
//             <div className="space-y-2">
//               <div>
//                 <p className="text-[16px] font-semibold text-gray-900">
//                   User information
//                 </p>
//               </div>

//               <div className="space-y-5 rounded-sm border border-[#ebecf0] bg-white p-4">
//                 <div className="space-y-10">
//                   <div className="flex flex-col space-y-3">
//                     <div>
//                       <TextInput
//                         value={name}
//                         onChange={(e) => setName(e.target.value)}
//                         placeholder="Your full name"
//                         label="Name"
//                         disabled={
//                           userQuery.isLoading || updateUserMutation.isLoading
//                         }
//                       />
//                     </div>
//                     <div>
//                       <TextInput
//                         value={email}
//                         label="Email"
//                         disabled={true}
//                       />
//                     </div>
//                     <div>
//                       <Select
//                         label="Level"
//                         data={[
//                           { value: "user", label: "User" },
//                           { value: "manager", label: "Manager" },
//                           { value: "administrator", label: "Administrator" },
//                         ]}
//                         value={level}
//                         onChange={(value: string) => setLevel(value)}
//                         disabled={
//                           userQuery.isLoading || updateUserMutation.isLoading
//                         }
//                       />
//                     </div>
//                     <div>
//                       <TextInput
//                         value={role}
//                         onChange={(e) => setRole(e.target.value)}
//                         label="Role"
//                         disabled={
//                           userQuery.isLoading || updateUserMutation.isLoading
//                         }
//                       />
//                     </div>
//                     {/* <AreaPicture avatar={avatar} setAvatar={setAvatar} /> */}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>

//           {/* <p className="text-xl font-medium">Area Settings</p>

//         <div className="flex flex-col space-y-10">
//           <div className="space-y-2">
//             <div>
//               <p className="font-medium text-stone-700">Area details</p>
//               <p className="text-sm text-stone-500">
//                 This is the basic information of the area
//               </p>
//             </div>

//             <div className="flex flex-col space-y-2">
//               <div>
//                 <TextInput
//                   value={name}
//                   onChange={(e) => setName(e.target.value)}
//                   placeholder="Enter the area name"
//                   classNames={{
//                     label: "text-stone-700 text-sm",
//                   }}
//                   label="Name"
//                   description="The areas name, this might be the of a person, a place or anything else you like!"
//                 />
//               </div>
//               <AreaPicture avatar={avatar} setAvatar={setAvatar} />
//             </div>
//           </div>

//           <SelectBodymap
//             selectedBodymap={selectedBodymap}
//             setSelectedBodymap={setSelectedBodymap}
//           />

//           <Sections sections={sections} setSections={setSections} />

//           <UsersSelect
//             selectedUsers={selectedUsers}
//             setSelectedUsers={setSelectedUsers}
//           />
//         </div> */}

//           <div className="flex items-center space-x-3">
//             <Button
//               type="submit"
//               className="bg-jira-button hover:bg-jira-button-hover"
//               loading={updateUserMutation.isLoading}
//             >
//               Save
//             </Button>
//             <Button
//               className="bg-none font-medium text-slate-600 hover:bg-jira-button-subtle"
//               variant="subtle"
//               color="gray"
//               component={Link}
//               to="/users"
//             >
//               Cancel
//             </Button>
//           </div>
//         </form>
//       </div>
//     </div>
//   </div>
// );
