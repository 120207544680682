import { Route, Routes } from "react-router-dom";

import { Mileage } from "../components/Mileage";
import { CreateExpense } from "../components/CreateExpense";

export const CreateClaim = () => {
  return (
    <Routes>
      <Route path="/expense" element={<CreateExpense />} />
      <Route path="/mileage" element={<Mileage />} />
    </Routes>
  );
};
