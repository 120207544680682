import { useEffect, useState } from 'react'
import { Button, TextInput, Textarea, SegmentedControl, Select, MultiSelect } from '@mantine/core'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { z } from 'zod'
import { Controller, useFieldArray, useForm, SetFieldValue, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs'
import { useCreateMedication } from '../api/createMedication'
import { useNotification } from 'hooks/useNotification'

import { Views } from 'components/Views/Views'
import { View } from 'components/Views/View/View'

//! REMOVE
import { Bodymap } from 'features/bodymap/components/Bodymap'
import { InstructionsForm } from 'features/bodymap/forms/InstructionsForm'
import { instructionsForm } from 'features/bodymap/forms/InstructionsForm'
import Calendar from 'components/Calendar/Calendar'
import { PlusSmallIcon } from '@heroicons/react/24/outline'
import { BodymapList } from 'features/bodymap/components/BodymapList'
import { BodypartList } from 'features/bodymap/components/BodypartList'
import { nanoid } from 'nanoid'
import { MedicationDose } from '../components/MedicationForm/MedicationDose'
import { MedicationRoute } from '../components/MedicationForm/MedicationRoute'
import { MedicationFrequency } from '../components/MedicationForm/MedicationFrequency'

export const CreateMedication = ({ id, title }: any) => {
  const { areaId } = useParams()

  const crumbs = [
    { title: 'Medications', href: `../` },
    { title: 'New Medication', href: '#' },
  ]

  // used as success funtion for creating medication to take the user back to the medication list
  const navigate = useNavigate()

  const createMedicationMutation = useCreateMedication(() => navigate(`/areas/${areaId}/medication`, { replace: true }))
  const { addNotification } = useNotification()

  //? For The Bodymap

  const [markerToUpdate, setMarkerToUpdate] = useState(null)
  const [view, setView] = useState<number | null>(null)

  const [activeView, setActiveView] = useState(0)
  const nextStep = () => setActiveView((current) => (current < 2 ? current + 1 : current))
  const prevStep = () => setActiveView((current) => (current > 0 ? current - 1 : current))

  const closeBodymap = () => {
    setMarkerToUpdate(null)
    setView(0)
    prevStep()
  }
  //? For The Bodymap

  const validationSchema = z.object({
    name: z.string({ required_error: 'Medication name is required' }).min(1, 'Medication name is required'),
    dose: z.string(),
    type: z.string(),
    usedFor: z.any(),
    sideEffects: z.any(),
    administeredDose: z.string(),
    route: z.any(),
    frequency: z.any(),
    authorisation: z.any(),
    startDate: z.optional(z.number()),
    endDate: z.optional(z.number()),
    notes: z.optional(z.string()),
  })

  const [data, setData] = useState({})

  const onSubmit = (data: any) => {
    console.log('onSubmit', data)
    setData(data)

    try {
      createMedicationMutation.mutateAsync({
        data: {
          ...data,
          areaId: areaId,
          typeId: id,
          medicationMarkers: currentMarkers,
        },
      })
    } catch (error: any) {
      addNotification({
        disallowClose: false,
        type: 'error',
        title: 'Form not submitted',
        message: 'There is an error in this form, check if you are missing required fields and try again',
      })
    }
  }

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<any>({
    defaultValues: { type: 'ROA&P', frequency: 1 },
    resolver: zodResolver(validationSchema),
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'markers',
  })

  const currentMarkers = useWatch({ control, name: 'markers', defaultValue: [] })

  const editItem = (index: any) => {
    // Set the isEditing property of the item to true
    const markerToEdit = { ...currentMarkers[index], is_editing: true }
    setValue(`markers[${index}].is_editing`, true)
    setMarkerToUpdate({ index: index, ...markerToEdit })
    setView(1)
    nextStep()
    console.log('editItem', id, markerToEdit)
  }

  const saveItem = (index: any, values: any) => {
    // Set the isEditing property of the item to false
    setValue(`markers[${index}].is_editing`, false)
    setValue(`markers[${index}].bodypart_name`, values.bodypart_name)
    setValue(`markers[${index}].bodypart_image`, values.bodypart_image)
    setValue(`markers[${index}].notes.instructions`, values.notes.instructions)
    setValue(`markers[${index}].bodypart_id`, values.bodypart_id)
    setValue(`markers[${index}].type`, values.type)
    setValue(`markers[${index}].x`, values.x)
    setValue(`markers[${index}].y`, values.y)
    setValue(`markers[${index}].width`, values.width)
    setValue(`markers[${index}].height`, values.height)

    setMarkerToUpdate(null)
    prevStep()
    setView(0)

    console.log('saveItem', currentMarkers[index], values)
  }

  const addItem = (values: any) => {
    console.log('addItem', values)
    append({
      id: nanoid(),
      is_editing: false,
      bodypart_id: values.bodypart_id,
      bodypart_image: values.bodypart_image,
      bodypart_name: values.bodypart_name,
      type: values.type,
      x: values.x,
      y: values.y,
      width: values.width,
      height: values.height,
      notes: values.notes,
    })
    prevStep()
    setView(0)
  }

  const cancelEdit = (id: any) => {
    // Set the isEditing property of the item to false
    // Reset the field value to its initial value if needed
    setValue(`markers[${id}].is_editing`, false)
  }

  return (
    <div className="flex w-full justify-center">
      <div className="flex flex-1">
        <Views activeView={activeView} prevView={prevStep} nextView={nextStep}>
          <View>
            <div className="flex h-full w-full flex-col">
              <div className="flex max-h-9 items-center border-b border-gray-200 py-2 px-5">
                <Breadcrumbs items={crumbs} />
              </div>

              <div className="flex h-full w-full flex-col">
                <div className="flex w-full min-w-0 flex-col space-y-5 bg-white sm:max-w-2xl">
                  <div className="p-4">
                    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col space-y-4">
                      <section className="space-y-2">
                        <div>
                          <p className="text-[16px] font-semibold text-gray-900">Medication Information</p>
                          <p className="text-[13px] font-medium text-gray-900">
                            The basic information of the medication
                          </p>
                        </div>

                        <div className="space-y-5 rounded-sm border border-[#ebecf0] bg-white p-4">
                          <div className="space-y-10">
                            <div className="flex flex-col space-y-3">
                              <Controller
                                control={control}
                                name="name"
                                render={({ field }) => (
                                  <TextInput
                                    label="Medication Name"
                                    placeholder="Enter Medication Name"
                                    required={true}
                                    {...field}
                                    className="w-full"
                                    error={errors?.name?.message as string}
                                  />
                                )}
                              />

                              <Controller
                                control={control}
                                name="dose"
                                render={({ field }) => (
                                  <TextInput
                                    {...field}
                                    label="Medication Dose"
                                    placeholder="Enter Medication Dose"
                                    description="The dose per one of the medication (e.g paracetamol would usually be 500mg)"
                                    required={true}
                                    className="w-full"
                                    error={errors?.dose?.message as string}
                                  />
                                )}
                              />
                              <div className="flex flex-col space-y-1">
                                <p className="mantine-TextInput-label text-[13px] font-semibold text-gray-500">
                                  Medication Type <span className=" text-red-500">*</span>
                                </p>
                                <span className="text-xs font-medium text-red-500">
                                  {errors?.type?.message as string}
                                </span>
                                <div>
                                  <Controller
                                    control={control}
                                    name="type"
                                    render={({ field }) => (
                                      <SegmentedControl
                                        {...field}
                                        data={[
                                          { label: 'ROA&P', value: 'ROA&P' },
                                          { label: 'PRN', value: 'PRN' },
                                          { label: 'HR', value: 'HR' },
                                        ]}
                                        classNames={{
                                          labelActive: 'bg-jira-button text-white',
                                          active: 'bg-jira-button',
                                          controlActive: 'text-red-500',
                                        }}
                                        color="blue"
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="flex min-w-0 max-w-full flex-col space-y-6">
                                <Controller
                                  control={control}
                                  name="usedFor"
                                  render={({ field }) => (
                                    <MultiSelect
                                      {...field}
                                      label="Used For"
                                      placeholder="Enter medication uses"
                                      data={[]}
                                      creatable
                                      searchable
                                      getCreateLabel={(value) => `Create "${value}"`}
                                      description="Begin typing to create a new condition or symptom."
                                    />
                                  )}
                                />

                                <Controller
                                  control={control}
                                  name="sideEffects"
                                  render={({ field }) => (
                                    <MultiSelect
                                      {...field}
                                      label="Side Effects"
                                      placeholder="Enter any side effects"
                                      data={[]}
                                      creatable
                                      searchable
                                      getCreateLabel={(value) => `Create "${value}"`}
                                      description="Begin typing to create a new side effect."
                                    />
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <section className="space-y-2">
                        <div>
                          <p className="text-[16px] font-semibold text-gray-900">Administration Information</p>
                          <p className="text-[13px] font-medium text-gray-900">
                            The basic information of the medication
                          </p>
                        </div>

                        <div className="space-y-5 rounded-sm border border-[#ebecf0] bg-white p-4">
                          <div className="space-y-10">
                            <div className="flex flex-col space-y-3">
                              <div className="flex flex-col space-y-5">
                                <Controller
                                  control={control}
                                  name="administeredDose"
                                  render={({ field }) => (
                                    <MedicationDose {...field} error={errors?.administeredDose?.message as string} />
                                  )}
                                />
                              </div>
                              <div className="flex flex-col space-y-5">
                                <Controller
                                  control={control}
                                  name="route"
                                  render={({ field }) => (
                                    <MedicationRoute {...field} error={errors?.route?.message as string} />
                                  )}
                                />
                                <Controller
                                  control={control}
                                  name="frequency"
                                  render={({ field }) => (
                                    <MedicationFrequency
                                      {...field}
                                      maxValue={10}
                                      error={errors?.frequency?.message as string}
                                    />
                                  )}
                                />
                                <Controller
                                  control={control}
                                  name="authorisation"
                                  render={({ field }) => (
                                    <TextInput
                                      {...field}
                                      label="Authorisation"
                                      placeholder="Enter Authorisation"
                                      description="Who authorised the use of this medication"
                                      required={true}
                                      error={errors?.authorisation?.message as string}
                                    />
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <section className="space-y-2">
                        <div>
                          <p className="text-[16px] font-semibold text-gray-900">Medication Schedule</p>
                          <p className="text-[13px] font-medium text-gray-900">
                            The information of the medications administration schedule
                          </p>
                        </div>

                        <div className="space-y-5 rounded-sm border border-[#ebecf0] bg-white p-4">
                          <div className="space-y-10">
                            <div className="flex flex-col space-y-3">
                              <Controller
                                control={control}
                                name="startDate"
                                render={({ field: { value, onChange } }) => (
                                  <Calendar
                                    label="Start Date"
                                    value={value}
                                    onChange={onChange}
                                    error={errors?.startDate?.message as string}
                                  />
                                )}
                              />
                              <Controller
                                control={control}
                                name="endDate"
                                render={({ field: { value, onChange } }) => (
                                  <Calendar
                                    label="End Date/Discontinued"
                                    value={value}
                                    onChange={onChange}
                                    error={errors?.endDate?.message as string}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </section>

                      <section className="space-y-2">
                        <div>
                          <p className="text-[16px] font-semibold text-gray-900">Additional Information</p>
                          <p className="text-[13px] font-medium text-gray-900">
                            Optional additional information about the medication
                          </p>
                        </div>

                        <div className="space-y-5 rounded-sm border border-[#ebecf0] bg-white p-4">
                          <div className="space-y-10">
                            <div className="flex flex-col space-y-3">
                              <div className="flex w-full flex-col space-y-5">
                                <Controller
                                  control={control}
                                  name="notes"
                                  render={({ field }) => (
                                    <Textarea {...field} placeholder="Any additional information" label="Notes" />
                                  )}
                                />
                              </div>
                              <div className="border-t border-dashed border-neutral-200" />
                              <div className="flex flex-col space-y-2">
                                <p className="text-sm font-medium text-neutral-800">Bodymap</p>
                                <div className="flex flex-col">
                                  {currentMarkers.map((field: any, index: any) => (
                                    <div
                                      key={field.id} // important to include key with field's id
                                      {...register(`marker[${index}].id`)}
                                    >
                                      <BodymapList
                                        marker={field}
                                        editItem={() => editItem(index)}
                                        deleteItem={() => remove(index)}
                                      />
                                    </div>
                                  ))}
                                </div>

                                <div>
                                  <Button
                                    variant="outline"
                                    size="xs"
                                    className="text-sm font-medium shadow"
                                    leftIcon={<PlusSmallIcon className="h-4 w-4 text-gray-400" />}
                                    onClick={() => {
                                      setMarkerToUpdate(null)
                                      nextStep()
                                    }}
                                  >
                                    Add Instruction
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <div className="flex w-full space-x-3">
                        <Button
                          type="submit"
                          className="bg-jira-button hover:bg-jira-button-hover"
                          // onClick={postMedication}
                          // disabled={!ADMIN}
                        >
                          Create Medication
                        </Button>
                        <Button
                          className="bg-none font-medium text-slate-600 hover:bg-jira-button-subtle"
                          // disabled={!ADMIN}
                          variant="subtle"
                          color="gray"
                          component={Link}
                          to={`/areas/${areaId}/medication`}
                        >
                          Cancel
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </View>
          <View>
            <div className="flex h-full w-full flex-grow flex-col">
              <div className="flex h-full w-full items-center justify-center">
                <Bodymap
                  markerToUpdate={markerToUpdate && markerToUpdate}
                  closeBodymap={closeBodymap}
                  NotesForm={InstructionsForm}
                  defaultValues={instructionsForm}
                  view={view && view}
                  description="Please fill out the areas below"
                  saveItem={saveItem}
                  addItem={addItem}
                />
              </div>
            </div>
          </View>
        </Views>
      </div>
    </div>
  )
}
