import { useState } from "react";
import { DndContext } from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
  arrayMove,
} from "@dnd-kit/sortable";
import {
  Accordion,
  TextInput,
  Button,
  Drawer,
  Select,
  Checkbox,
  MultiSelect,
  Textarea,
  NumberInput,
} from "@mantine/core";
import { Controller, useForm } from "react-hook-form";
import { CSS } from "@dnd-kit/utilities";
import { nanoid } from "nanoid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";

import { useCreateForm } from "../api/createCustomForm";
import Calendar from "components/Calendar/Calendar";

export const CreateForm = () => {
  const { areaId } = useParams();

  const customFormMutation = useCreateForm();

  const [questions, setQuestions] = useState<any>([]);

  // This controls the main form information (title, description, etc.)
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<any>({
    // resolver: zodResolver(validationSchema),
  });

  const formTitle = watch("name");
  const formDescription = watch("description");
  const shouldNotify = watch("shouldNotify");

  function testingSubmit(data: any) {
    const {
      name,
      description,
      shouldNotify,
      usersToNotify,
      notificationMessage,
    } = data;
    console.log("testing submit", data, description, "Questions: ", questions);

    customFormMutation.mutate({
      data: {
        title: name,
        description,
        area_id: areaId as string,
        notify: shouldNotify,
        users_to_notify: usersToNotify,
        notification_message: notificationMessage,
        questions,
      },
    });
  }

  return (
    <div className="flex flex-1">
      <div className=" w-80 border-r border-gray-200">
        <div className="sticky top-0 z-10 border-b border-gray-200 bg-white p-4">
          <form onSubmit={handleSubmit(testingSubmit)}>
            <Button
              type="submit"
              size="xs"
              className="w-full border-none bg-jira-button text-sm font-medium text-white hover:bg-jira-button-hover"
            >
              Save Form
            </Button>
          </form>
        </div>
        <FormQuestions
          questions={questions}
          setQuestions={setQuestions}
          controlInformation={control}
          shouldNotify={shouldNotify}
        />
      </div>
      {/* <p>{JSON.stringify(watch(), null, 2)}</p> */}
      <FormPreview
        questions={questions}
        formTitle={formTitle}
        formDescription={formDescription}
      />
    </div>
  );
};

const FormQuestions = ({
  questions,
  setQuestions,
  controlInformation,
  shouldNotify,
}: {
  questions: any;
  setQuestions: (question: any) => void;
  controlInformation: any;
  shouldNotify: boolean;
}) => {
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<any>({
    // resolver: zodResolver(validationSchema),
  });

  function handleDragEnd(event: any) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setQuestions((item: any) => {
        const activeIndex = item.findIndex((x: any) => x.id === active.id);
        const overIndex = item.findIndex((x: any) => x.id === over.id);

        return arrayMove(item, activeIndex, overIndex);
      });
    }
  }

  return (
    <Accordion iconPosition="right" multiple>
      <Accordion.Item label="Information">
        <div className="flex flex-col space-y-2">
          <Controller
            control={controlInformation}
            name="name"
            render={({ field }) => (
              <TextInput
                {...field}
                label="Form name"
                error={errors.name && (errors.name?.message as string)}
              />
            )}
          />
          <Controller
            control={controlInformation}
            name="description"
            render={({ field }) => (
              <TextInput
                {...field}
                label="Form description"
                error={
                  errors.description && (errors.description?.message as string)
                }
              />
            )}
          />
        </div>
      </Accordion.Item>
      <Accordion.Item label="Form Questions">
        <div className="flex flex-col space-y-2">
          <DndContext onDragEnd={handleDragEnd}>
            <SortableContext
              items={questions}
              strategy={verticalListSortingStrategy}
            >
              <div className="rounded border border-gray-200">
                {questions.map((item: any) => (
                  <SortableItem key={item.id} item={item} />
                ))}
              </div>
            </SortableContext>
          </DndContext>

          <CreateQuestion setQuestions={setQuestions} />
        </div>
      </Accordion.Item>
      <Accordion.Item label="Notifications">
        <FormNotifications
          control={controlInformation}
          shouldNotify={shouldNotify}
        />
      </Accordion.Item>
    </Accordion>
  );
};

function SortableItem(props: any) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: props.item.id,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="flex w-full items-center space-x-2 border-b border-gray-200 p-2 last:border-none"
    >
      <span
        {...attributes}
        {...listeners}
        className="rounded-md p-2 hover:cursor-grab hover:bg-[#f2f2f2]"
      >
        <FontAwesomeIcon
          icon={faGripVertical}
          className="h-3.5 w-3.5 text-gray-400 transition-all duration-150 ease-in-out  group-hover:text-gray-900"
        />
      </span>
      <div>
        <p className="font-medium text-gray-900">{props.item.name}</p>
        <p className="text-xs text-gray-500">{props.item.type}</p>
      </div>
    </div>
  );
}

const CreateQuestion = ({
  setQuestions,
}: {
  setQuestions: (question: any) => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      type: "text",
      name: "",
      required: false,
    },
    // resolver: zodResolver(validationSchema),
  });

  const type = watch("type");
  const required = watch("required");
  const name = watch("name");

  const onSubmit = (data: any) => {
    setQuestions((items: any) => [...items, { id: nanoid(), ...data }]);
    console.log(data);
    setIsOpen(false);
  };

  const onCancel = () => {
    reset();
    setIsOpen(false);
  };

  return (
    <>
      <Drawer
        opened={isOpen}
        onClose={() => setIsOpen(false)}
        position="right"
        withCloseButton={false}
        className="flex flex-col overflow-hidden"
        size={600}
      >
        <form
          key={2}
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-1 flex-col"
        >
          <div className="space-y-1 border-b border-gray-200 py-4 px-4">
            <p className="text-[16px] font-medium text-[#11181c]">
              Create a new question
            </p>
            <p className="font-medium text-[#687076]">
              Select a question type to get started creating a new question
            </p>
          </div>
          <div className="relative flex-1 overflow-y-auto">
            <div className="space-y-2 px-4 py-6">
              <Controller
                control={control}
                name="type"
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Question type"
                    data={[
                      { value: "text", label: "Text Input" },
                      { value: "checkbox", label: "Checkbox" },
                      { value: "date", label: "Date Picker" },
                      { value: "singlechoice", label: "Single Choice" },
                      { value: "multiplechoice", label: "Multiple Choice" },
                      { value: "scalerating", label: "Scale Rating" },
                    ]}
                  />
                )}
              />
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <TextInput {...field} label="Question name" />
                )}
              />
              {(type === "scalerating" || type === "scalerating") && (
                <>
                  <Controller
                    control={control}
                    name="minValue"
                    render={({ field }) => (
                      <NumberInput
                        {...field}
                        label="Enter minimum value"
                        placeholder="e.g 1"
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="maxValue"
                    render={({ field }) => (
                      <NumberInput
                        {...field}
                        label="Enter maximum value"
                        placeholder="e.g 10"
                      />
                    )}
                  />
                </>
              )}
              {(type === "multiplechoice" || type === "singlechoice") && (
                <Controller
                  control={control}
                  name="choices"
                  render={({ field }) => (
                    <MultiSelect
                      {...field}
                      label="Choices"
                      placeholder="Enter avaliable options"
                      rightSection={<></>}
                      data={[]}
                      searchable
                      creatable
                      getCreateLabel={(value) => `"Add - ${value}"`}
                    />
                  )}
                />
              )}
              <Controller
                control={control}
                name="required"
                render={({ field: { value, onChange } }) => (
                  <Checkbox
                    checked={value}
                    onChange={onChange}
                    label="Required"
                  />
                )}
              />
            </div>
          </div>
          <div className="flex w-full justify-end space-x-3 border-t border-slate-200 px-3 py-4">
            <Button
              variant="outline"
              size="xs"
              className="text-sm font-medium shadow"
              onClick={() => {
                onCancel();
              }}
              style={{
                marginBottom: "1px",
              }}
            >
              Cancel
            </Button>
            <Button
              size="xs"
              className="border-none bg-jira-button text-sm font-medium text-white hover:bg-jira-button-hover"
              type="submit"
            >
              Save
            </Button>
          </div>
        </form>
      </Drawer>

      <Button
        variant="outline"
        size="xs"
        className="w-full text-sm font-medium shadow"
        leftIcon={
          <FontAwesomeIcon
            icon={faPlus}
            className="h-3.5 w-3.5 text-gray-400 transition-all duration-150 ease-in-out  group-hover:text-gray-900"
          />
        }
        onClick={() => {
          setIsOpen(true);
        }}
        style={{
          marginBottom: "1px",
        }}
      >
        Add question
      </Button>
    </>
  );
};

const FormPreview = ({ questions, formTitle, formDescription }: any) => {
  return (
    <div className="flex flex-1 flex-col items-center justify-start p-4">
      <div className="w-full max-w-lg space-y-3 rounded border border-gray-200 bg-white p-4 shadow">
        <div className="text-sm font-semibold text-gray-500">
          <p className="text-[16px] font-semibold text-gray-900">
            {formTitle ? formTitle : "Form title"}
          </p>
          <p>{formDescription}</p>
        </div>
        {questions.map((question: any) => {
          switch (question.type) {
            case "text":
              return (
                <TextInput
                  label={question.name}
                  required={question.required}
                  disabled
                />
              );
            case "checkbox":
              return (
                <Checkbox
                  label={question.name}
                  required={question.required}
                  disabled
                />
              );
            case "date":
              return (
                <Calendar
                  label={question.name}
                  required={question.required}
                  disabled
                />
              );
            default:
              return null;
          }
        })}
      </div>
    </div>
  );
};

const FormNotifications = ({ control, shouldNotify }: any) => {
  return (
    <div className="flex flex-col space-y-2">
      <Controller
        control={control}
        name="shouldNotify"
        render={({ field }) => (
          <Checkbox {...field} label="Send notification on completion" />
        )}
      />

      {shouldNotify && (
        <Controller
          control={control}
          name="usersToNotify"
          shouldUnregister={true}
          render={({ field }) => (
            <MultiSelect
              {...field}
              data={[]}
              searchable
              label="Select users to notify"
              description="Select a user to notify on completion, or add an email address to notify a non-user"
              creatable
              getCreateLabel={(value) => `Create "${value}"`}
            />
          )}
        />
      )}

      {shouldNotify && (
        <Controller
          control={control}
          name="notificationMessage"
          shouldUnregister={true}
          render={({ field }) => (
            <Textarea {...field} label="Notification message" />
          )}
        />
      )}
    </div>
  );
};
