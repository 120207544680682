import { UsersList } from "../components/UsersList";
import { UsersFilter } from "../components/UsersFilter";
import { CreateUser } from "../components/CreateUser";
import { DisableUsers } from "../components/DisableUsers";
import { EnableUsers } from "../components/EnableUsers";

export const Users = () => {
  return (
    <div className="flex h-full min-h-0 w-full flex-col space-y-3 overflow-y-auto p-6">
      {/* <div className="flex w-full flex-col justify-between space-y-4">
        <div>
          <UsersFilter />
        </div>
      </div> */}
      <UsersList />
    </div>
  );
};
