import { Button, Skeleton } from "@mantine/core";
import { Link } from "react-router-dom";
import { PlusCircleIcon } from "@heroicons/react/24/outline";

import { useGroups } from "../api/getGroups";
import { useAuth } from "stores/Auth";
import { DeleteGroup } from "../components/DeleteGroup";

export const Groups = () => {
  const userLevel = useAuth((state) => state.userLevel);

  const groupsQuery = useGroups();

  // I'm ashamed of this function but I'm tired and it works
  const addCommasToAreas = (areaId: any, groupId: any) => {
    const array = groupsQuery.data?.areas.filter(
      (area: any) => area.group_id === groupId
    );

    if (
      array.findIndex((area: any) => area.id === areaId) ===
      array.length - 1
    ) {
      return false;
    } else {
      return true;
    }
  };

  if (groupsQuery.isLoading) {
    return (
      <div className="flex h-full w-full items-start justify-center py-6 px-20">
        <div className="relative mt-5 w-full max-w-5xl space-y-8 md:col-span-2 md:mt-0">
          <div className="flex flex-col space-y-4">
            <div className="flex w-full justify-between">
              <p className="text-2xl font-semibold text-gray-900">Groups</p>
              {userLevel ? (
                userLevel === "admin" ? (
                  <Button
                    className="bg-jira-button hover:bg-jira-button-hover"
                    component={Link}
                    to="new"
                    disabled={true}
                  >
                    New group
                  </Button>
                ) : null
              ) : null}
            </div>
          </div>
          <div className="border-b-2 border-[#DFE1E6] bg-white font-bold text-[#5e6c84]">
            <div className="grid grid-cols-12 gap-8 border-b-2 border-[#DFE1E6] py-1 font-bold text-[#5e6c84]">
              <p className="col-span-2 text-[13px]">Name</p>
              <p className="col-span-9 text-[13px]">Areas</p>
            </div>
            <LoadingRow />
            <LoadingRow />
            <LoadingRow />
            <LoadingRow />
            <LoadingRow />
          </div>
        </div>
      </div>
    );
  }

  if (groupsQuery.isError) {
    return (
      <div className="flex h-full min-h-0 w-full flex-col items-center overflow-y-auto px-12 py-6">
        <div className="flex w-full max-w-5xl flex-col">
          <div className="flex flex-col space-y-4">
            <div className="flex w-full justify-between">
              <p className="text-2xl font-semibold text-gray-900">Groups</p>
            </div>

            <div className="font-bold text-[#5e6c84]">
              <p>
                There was an error fetching your groups, refresh the page to try
                again.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (groupsQuery?.data && groupsQuery?.data?.groups?.length < 1) {
    return (
      <div className="flex h-full min-h-0 w-full flex-col items-center overflow-y-auto px-12 py-6">
        <div className="flex w-full max-w-5xl flex-col">
          <div className="flex flex-col space-y-4">
            <div className="flex w-full justify-between">
              <p className="text-2xl font-semibold text-gray-900">Groups</p>
              {userLevel && userLevel === "admin" && (
                <Button
                  className="bg-jira-button hover:bg-jira-button-hover"
                  component={Link}
                  to="new"
                >
                  Create group
                </Button>
              )}
            </div>

            <div className="font-bold text-[#5e6c84]">
              <p>
                There are no groups, once you create a group, you will see it
                listed here.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-full w-full items-start justify-center py-6 px-20">
      <div className="relative mt-5 w-full max-w-5xl space-y-8 md:col-span-2 md:mt-0">
        <div className="flex flex-col space-y-4">
          <div className="flex w-full justify-between">
            <p className="text-2xl font-semibold text-gray-900">Groups</p>
            {userLevel ? (
              userLevel === "admin" ? (
                <Button
                  className="bg-jira-button hover:bg-jira-button-hover"
                  component={Link}
                  to="new"
                >
                  Create group
                </Button>
              ) : null
            ) : null}
          </div>
        </div>
        <div className="border-b-2 border-[#DFE1E6] bg-white font-bold text-[#5e6c84]">
          <div className="grid grid-cols-12 gap-8 border-b-2 border-[#DFE1E6] py-1 font-bold text-[#5e6c84]">
            <p className="col-span-2 text-[13px]">Name</p>
            <p className="col-span-9 text-[13px]">Areas</p>
          </div>
          {groupsQuery.data?.groups.map((group: any) => (
            <Link
              className="grid grid-cols-12 items-center gap-8 py-2 hover:bg-[#fafbfc]"
              to={`${group.id}`}
            >
              <p className="col-span-2 overflow-hidden text-ellipsis text-sm font-semibold text-[#091e42]">
                {group.name}
              </p>
              <div className="col-span-9 inline-block flex space-x-2 overflow-hidden truncate overflow-ellipsis text-ellipsis text-sm font-medium text-[#091e42]">
                {groupsQuery.data?.areas?.map(
                  (area: any, index: any) =>
                    area.group_id === group.id && (
                      <div>
                        <p className="text-sm text-gray-600">
                          {area.name}
                          {addCommasToAreas(area.id, group.id) && (
                            <span
                              key={index}
                              className="font-semibold text-gray-500"
                            >
                              ,
                            </span>
                          )}
                        </p>
                      </div>
                    )
                )}
              </div>
              <div className="col-span-1 flex items-end justify-end">
                <DeleteGroup id={group.id} />
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

const LoadingRow = () => {
  return (
    <div className="grid grid-cols-12 items-center gap-8 py-2 hover:bg-[#fafbfc]">
      <Skeleton height={12} width="70%" radius="md" className="col-span-2" />
      <Skeleton height={12} width="70%" radius="md" className="col-span-9" />
      <div className="col-span-1 flex items-end justify-end">
        <Skeleton height={20} width={20} radius="md" />
      </div>
    </div>
  );
};
