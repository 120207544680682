import { useMutation } from "react-query";

import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useNotification } from "hooks/useNotification";

export type UpdateRoleType = {
  data: any & { users: Array<string> };
  roleId: string;
};

export const updateRole = ({ data, roleId }: UpdateRoleType): Promise<any> => {
  return axios.put(`/roles/${roleId}`, data);
};

export const useUpdateRole = () => {
  const { addNotification } = useNotification();

  return useMutation({
    onMutate: async (updatingRole: any) => {
      console.log("useUpdateRole updatingRole: ", updatingRole);

      await queryClient.cancelQueries(["role", updatingRole?.roleId]);

      const previousRole = queryClient.getQueryData<any>(["role", updatingRole?.roleId]);

      queryClient.setQueryData(["role", updatingRole?.roleId], {
        ...previousRole,
        default_break: updatingRole?.data?.defaultBreak,
        pay_code: updatingRole?.data?.payCode,
        shifts_clash: updatingRole?.data?.shouldShiftsClash,
        is_on_call: updatingRole?.data?.isOnCallRole,
        flat_rate_weekday: updatingRole?.data?.flatRateWeekday,
        flat_rate_weekend: updatingRole?.data?.flatRateWeekend,
        is_sleep: updatingRole?.data?.isSleepRole,
        sleep_flat_rate_weekday: updatingRole?.data?.sleepFlatRateWeekday,
        sleep_flat_rate_weekend: updatingRole?.data?.sleepFlatRateWeekend,
        users: updatingRole?.data?.selectedUsers.map((user: any) => {
          return { id: user };
        }),
      });

      return { previousRole };
    },
    onError: (_, __, context: any) => {
      if (context?.previousRole) {
        queryClient.setQueryData(["role", context.previousRole.id], context.previousRole);
      }
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["role", data.data.id.toString()]);
      queryClient.refetchQueries(["role", data.data.id.toString()]);
      addNotification({
        disallowClose: true,
        type: "success",
        title: `Role Updated`,
        message: "",
      });
    },
    mutationFn: updateRole,
  });
};
