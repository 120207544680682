import { useMutation } from "react-query";
import { queryClient } from "lib/react-query";

import { axios } from "lib/axios";
import { useNotification } from "hooks/useNotification";

export const createExpenses = async ({
  data,
  userId,
  date,
}: {
  data: any;
  userId: string;
  date: any;
}) => {
  console.log("createExpenses", data, userId, date);

  const body = {
    claims: data,
    userId,
    date,
  };

  return axios.post(`/expenses`, body);
};

export const useCreateExpenses = (successFunction: Function) => {
  const { addNotification } = useNotification();

  return useMutation({
    onError: (_, __, context) => {
      addNotification({
        disallowClose: true,
        type: "error",
        title: "Could not create expenses",
        message: "There was an error creating the expenses, please try again",
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("expenses");
      console.log("created expenses onSuccess", successFunction);
      successFunction();
      addNotification({
        disallowClose: true,
        type: "success",
        title: "Expenses created successfully",
        message: "",
      });
    },
    mutationFn: createExpenses,
  });
};
