import { ChevronRightIcon } from "@heroicons/react/24/outline";

export type BodypartType = {
  id: number;
  name: string;
  image: string;
};

type BodypartPropsType = {
  bodypart: BodypartType;
  handleSelect: Function;
};

export const Bodypart = ({ bodypart, handleSelect }: BodypartPropsType) => {
  return (
    <div
      className="flex w-full cursor-pointer border-b border-t border-neutral-200 hover:bg-blue-50 lg:border-0"
      onClick={() => handleSelect(bodypart)}
    >
      <div className="flex w-full items-center justify-between px-5">
        <div className="flex w-full items-center space-x-4">
          <img src={bodypart.image} alt={bodypart.name} className="h-[60px] lg:h-[50px]" />
          <p className="font-medium">{bodypart.name}</p>
        </div>
        <ChevronRightIcon className="h-6 w-6 text-blue-800 opacity-80" />
      </div>
    </div>
  );
};
