import Axios from "axios";
import { showNotification } from "@mantine/notifications";
import { XCircleIcon } from "@heroicons/react/24/outline";

import { useAuth } from "stores/Auth";
import { supabase } from "lib/supabase";

const authRequestInteceptor = async (config: any) => {
  const user = useAuth.getState().currentUser;
  const status = useAuth.getState().status;

  console.log("authRequestInteceptor current user", user);

  if (status && user) {
    try {
      // get a new access token if the current is expired
      const session = await supabase.auth.getSession();
      const token = session.data.session?.access_token;
      console.log("token", token);
      // set the old/new access token for the authorization header
      config.headers.authorization = `Bearer ${token}`;
      console.log("authRequestInteceptor secondary", "Token: ", token, "Config: ", config);

      config.headers.Accept = "application/json";
      return config;
    } catch (error) {
      console.log("authRequestInteceptor error", error);
      return Promise.reject(new Error("Request Blocked"));
    }
  } else {
    return Promise.reject(new Error("Request Blocked"));
  }
};

export const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_PROD,
});

// Check the authentication token before sending the request, to make sure that the user is authenticated
axios.interceptors.request.use(authRequestInteceptor);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const user = useAuth.getState().currentUser;

    const message = error.response?.data?.message || error.message;
    const signOut = useAuth.getState().supabaseSignOut;
    showNotification({
      disallowClose: false,
      title: "Error",
      color: "red",
      message: `${message}`,
      icon: <XCircleIcon className="h-full w-full" />,
      autoClose: 6000,
    });

    const originalRequest = error.config;

    // if the error status us 401, refresh the users id token, set the refreshed token and retry the original request.
    // if (error.response.status === 401 && !originalRequest._retry) {
    //   originalRequest._retry = true;
    //   const access_token = await user.getIdToken();
    //   console.log(
    //     "401 Error Retry Block",
    //     error,
    //     "Access Token: ",
    //     access_token,
    //     axios.defaults.headers
    //   );
    //   axios.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
    //   return axios(originalRequest);
    // }

    console.log("Axios Response Inteceptor", error, error.response.data.error);

    if (error.response.data.error === "Invalid token") {
      signOut();
    }

    return Promise.reject(error);
  }
);
