import { useState } from "react";
import { Button, ActionIcon, Menu, LoadingOverlay } from "@mantine/core";
import { Link } from "react-router-dom";
import { TrashIcon, Cog6ToothIcon } from "@heroicons/react/24/outline";
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";

import { DeleteAreas } from "../components/DeleteAreas";
import { useAuth } from "stores/Auth";
import { DeleteArea } from "../components/DeleteArea";
import { useAreas } from "../api/getAreas";
import { useGroups } from "features/group/api/getGroups";

export const Areas = () => {
  const userLevel = useAuth((state) => state.userLevel);

  const areasQuery = useAreas("");
  const groupsQuery = useGroups();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  if (areasQuery.isLoading) {
    return (
      <div className="flex h-full min-h-0 w-full flex-col items-center overflow-y-auto px-12 py-6">
        <div className="flex h-full w-full max-w-5xl flex-col">
          <div className="flex h-full flex-col space-y-4">
            <div className="flex w-full justify-between">
              <p className="text-2xl font-semibold text-gray-900">Areas</p>
            </div>

            <div className="relative flex h-full w-full">
              <LoadingOverlay visible={true} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (areasQuery.isError) {
    return (
      <div className="flex h-full min-h-0 w-full flex-col items-center overflow-y-auto px-12 py-6">
        <div className="flex w-full max-w-5xl flex-col">
          <div className="flex flex-col space-y-4">
            <div className="flex w-full justify-between">
              <p className="text-2xl font-semibold text-gray-900">Areas</p>
            </div>

            <div className="font-bold text-[#5e6c84]">
              <p>
                There was an error fetching your areas, refresh the page to try
                again.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (areasQuery?.data && areasQuery?.data?.length < 1) {
    return (
      <div className="flex h-full min-h-0 w-full flex-col items-center overflow-y-auto px-12 py-6">
        <div className="flex w-full max-w-5xl flex-col">
          <div className="flex flex-col space-y-4">
            <div className="flex w-full justify-between">
              <p className="text-2xl font-semibold text-gray-900">Areas</p>
              {userLevel && userLevel === "admin" && (
                <Button
                  className="bg-jira-button hover:bg-jira-button-hover"
                  component={Link}
                  to="new"
                >
                  Create area
                </Button>
              )}
            </div>

            <div className="font-bold text-[#5e6c84]">
              <p>
                There are no areas, once you create an area, you will see it
                listed here.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-full min-h-0 w-full flex-col items-center overflow-y-auto px-12 py-6">
      <div className="flex w-full max-w-5xl flex-col">
        <DeleteArea
          deleteModalOpen={deleteModalOpen}
          setDeleteModalOpen={setDeleteModalOpen}
        />

        <div className="flex flex-col space-y-4">
          <div className="flex w-full justify-between">
            <p className="text-2xl font-semibold text-gray-900">Areas</p>
            <Button
              className="bg-jira-button hover:bg-jira-button-hover"
              component={Link}
              to="new"
            >
              Create area
            </Button>
          </div>
          {userLevel ? (
            userLevel === "admin" ? (
              <div className="flex justify-end">
                <DeleteAreas />
              </div>
            ) : null
          ) : null}

          <div className="border-b-2 border-[#DFE1E6] font-bold text-[#5e6c84]">
            <div className="grid grid-cols-12 gap-8 border-b-2 border-[#DFE1E6] py-1">
              <p className="col-span-3 text-[13px]">Name</p>
              <p className="col-span-2 text-[13px]">Group</p>
              <p className="col-span-6 text-[13px]">Users</p>
            </div>
            {areasQuery.data?.map((area) => (
              <div
                className="grid grid-cols-12 items-center gap-8 py-2 hover:bg-[#fafbfc]"
                key={area.id}
              >
                <Link
                  className="col-span-3 flex items-center space-x-3 hover:underline"
                  to={`${area.id}`}
                >
                  <img
                    src={area?.avatar_url ? area?.avatar_url : "area"}
                    alt=""
                    className="h-8 w-8 rounded-full"
                  />
                  <p className="overflow-hidden text-ellipsis text-sm font-semibold text-[#091e42]">
                    {area.name}
                  </p>
                </Link>
                <div className="col-span-2 flex items-center text-sm font-medium text-[#091e42]">
                  <p>
                    {area.group_id && area.group_id !== null
                      ? groupsQuery?.data?.groups?.find(
                          (group: any) => group.id === area.group_id
                        ).name
                      : ""}
                  </p>
                </div>
                <Menu
                  gutter={0}
                  className="col-span-6"
                  trigger="hover"
                  control={
                    <p className="inline-block space-x-1 truncate text-sm font-medium text-[#091e42]">
                      {area.users?.map((user: any, index: any) => (
                        <span key={user.id}>
                          {user.name}
                          {index !== area.users.length - 1 && (
                            <span key={index}>,</span>
                          )}
                        </span>
                      ))}
                    </p>
                  }
                >
                  {area.users?.map((user: any, index: any) => (
                    <Menu.Item key={user.id}>
                      <div className="space-y-3 p-1">
                        <p className="text-[11px] font-extrabold uppercase text-[#5e6c84]">
                          {user.name}
                        </p>
                      </div>
                    </Menu.Item>
                  ))}
                </Menu>
                <div className="col-span-1 flex items-end justify-end">
                  <Menu
                    gutter={0}
                    placement="center"
                    control={
                      <ActionIcon
                        classNames={{
                          hover: "hover:bg-[#e6e9ed]",
                        }}
                        onClick={(e: any) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                      >
                        <EllipsisHorizontalIcon className="h-6 w-6 text-[#42526e]" />
                      </ActionIcon>
                    }
                  >
                    <Menu.Item
                      className="hover:bg-[#f4f5f7]"
                      onClick={() => setDeleteModalOpen(true)}
                    >
                      <Link
                        className="flex w-full justify-between px-1"
                        to={`${area.id}/settings`}
                      >
                        <p className="font-medium text-gray-900">
                          Edit settings
                        </p>
                        <Cog6ToothIcon className="h-4 w-4 text-[#7A869A]" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item
                      className="hover:bg-[#f4f5f7]"
                      onClick={() => setDeleteModalOpen(true)}
                    >
                      <div className="flex w-full justify-between px-1">
                        <p className="font-medium text-gray-900">Delete area</p>
                        <TrashIcon className="h-4 w-4 text-[#7A869A]" />
                      </div>
                    </Menu.Item>
                  </Menu>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
