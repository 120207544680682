import { useState, useEffect } from "react";
import { Modal, Select, Button, ActionIcon, Checkbox, TextInput, Textarea } from "@mantine/core";
import { DateTime } from "luxon";
import { GlobeEuropeAfricaIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { z } from "zod";
import { useParams } from "react-router-dom";
import { useSpring, animated } from "@react-spring/web";

import { TimeInput } from "components/TimeInput/TimeInput";
import { useCreateShift } from "features/admin/payroll/timesheet/api/createShift";
import { useUserRoles } from "features/admin/payroll/roles/api/getUserRoles";
import { ClockIcon } from "@heroicons/react/24/outline";
import { calculateHoursPaid } from "features/admin/payroll/timesheet/utils/calculateHoursPaid";

export const CreateShiftModal = ({ open, setOpen, selectedDate, start, end }: any) => {
  const { id } = useParams();

  const [formErrors, setFormErrors] = useState<any>(null);

  const [timeFrom, setTimeFrom] = useState<DateTime>(selectedDate);
  const [timeTo, setTimeTo] = useState<DateTime>(selectedDate);
  const [isSleep, setIsSleep] = useState<boolean>(false);
  const [role, setRole] = useState<any>("");
  const [notes, setNotes] = useState<string>("");

  const clearForm = () => {
    setIsSleep(false);
    setTimeFrom(selectedDate);
    setTimeTo(selectedDate);
    setRole("");
    setNotes("");
  };

  useEffect(() => {
    setTimeFrom(selectedDate);
  }, [selectedDate]);

  const createShiftMutation = useCreateShift(id as string, start, end, () => console.log("createShiftMutation"));
  const rolesQuery = useUserRoles(() => console.log("success"), id as string);
  const roleInformation = rolesQuery?.data?.find((_role) => _role.id.toString() === role);

  const ShiftForm = z.object({
    timeFrom: z.number(),
    timeTo: z
      .number()
      .min(
        timeFrom.toUnixInteger(),
        "The end of a shift cannot be before the start, if this is a sleep, check the 'Sleep shift' box below"
      ),
    isSleep: z.boolean(),
    role: z.string(),
    notes: z.string(),
  });

  const postShift = async () => {
    try {
      ShiftForm.parse({
        timeFrom: timeFrom.toUnixInteger(),
        timeTo: timeTo.toUnixInteger(),
        isSleep: isSleep,
        role: role,
        notes: notes,
      });

      setFormErrors([]);

      const data = {
        user_id: id,
        start_time: timeFrom.toUnixInteger(),
        end_time: timeTo.toUnixInteger(),
        sleep: isSleep,
        role: role,
        notes: notes,
      };

      createShiftMutation.mutateAsync({ data });
    } catch (error: any) {
      console.log(error.flatten());
      setFormErrors(error.flatten());
    }
  };

  const hoursPaid = calculateHoursPaid({ role: roleInformation, timeFrom, timeTo });

  return (
    <Modal
      opened={open}
      onClose={() => {
        setOpen(false);
        setFormErrors(null);
        clearForm();
      }}
      centered
      withCloseButton={false}
    >
      <div className="flex flex-col space-y-4">
        <div>
          <div className="flex items-start justify-between">
            <div className="gap-1">
              <p className="text-lg font-semibold text-neutral-800">Create a new shift</p>
              <div className="flex space-x-2">
                <p className="text-[14px] font-semibold text-neutral-500">{`${selectedDate.toLocaleString({
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })}`}</p>
              </div>
            </div>

            <div className="flex gap-2">
              <Button
                variant="subtle"
                // onClick={() => {
                //   setOpenLeave(true);
                //   setOpen(false);
                // }}
                leftIcon={<GlobeEuropeAfricaIcon className="h-4 w-4" />}
                classNames={{
                  leftIcon: "mr-[4px]",
                }}
              >
                Add leave
              </Button>
              <ActionIcon
                onClick={() => {
                  setOpen(false);
                }}
              >
                <XMarkIcon className="h-6 w-6 text-neutral-800" />
              </ActionIcon>
            </div>
          </div>
        </div>

        <div>
          {/* <p>{role}</p> */}
          {rolesQuery.data && (
            <Select
              data={rolesQuery.data?.map((role) => {
                return {
                  label: role.name.toString(),
                  value: role.id.toString(),
                };
              })}
              label="Role"
              value={role}
              onChange={setRole}
            />
          )}
        </div>

        <div className="flex items-center gap-2">
          <div className="flex flex-col space-y-1">
            {/* <p>time in: {timeFrom.toUnixInteger().toString()}</p> */}
            <TimeInput defaultTime={selectedDate} value={timeFrom} onChange={setTimeFrom} label="Shift start" />
            <p className="text-xs font-medium text-red-500">
              {formErrors?.fieldErrors && formErrors.fieldErrors?.timeFrom && formErrors.fieldErrors?.timeFrom[0]}
            </p>
          </div>
          <div className="mt-5 h-[1px] w-[80px] border-b-2 border-dotted border-neutral-400" />
          <div className="flex flex-col space-y-1">
            {/* <p>time out: {timeTo.toUnixInteger().toString()}</p> */}
            <TimeInput
              defaultTime={isSleep ? selectedDate.plus({ day: 1 }) : selectedDate}
              onChange={setTimeTo}
              label="Shift end"
              isSleep={isSleep}
              value={timeTo}
            />
            <p className="text-xs font-medium text-red-500">
              {formErrors?.fieldErrors && formErrors.fieldErrors?.timeTo && formErrors.fieldErrors?.timeTo[0]}
            </p>
          </div>
        </div>

        {roleInformation && roleInformation?.is_sleep && (
          <div className="rounded-md border border-neutral-300">
            <div className="px-3 py-4">
              <Checkbox
                size="sm"
                label="Sleep shift"
                checked={isSleep}
                onChange={(event: any) => setIsSleep(event.currentTarget.checked)}
              />
            </div>
            <div className="border-b border-neutral-300"></div>
            <div className="flex justify-between px-3 py-4">
              <div className="">
                <p className="text-sm font-semibold text-neutral-500"> Distrubed sleep</p>
                <p className="text-xs font-medium text-neutral-400">Add disturbed hours</p>
              </div>
              <TextInput />
            </div>
          </div>
        )}
        <div>
          <Textarea label="Notes" value={notes} onChange={(e: any) => setNotes(e.currentTarget.value)} />
        </div>
        <div className="mt-6 flex w-full items-end justify-between">
          <div>
            <p className="text-sm text-neutral-500">Hours paid</p>
            <div className="flex items-center space-x-1">
              <ClockIcon className="h-5 w-5 text-jira-button" />
              <p className="text-lg font-semibold text-neutral-800">
                <AnimatedNumber number={hoursPaid.hours} />:<AnimatedNumber number={hoursPaid.minutes} pad />
              </p>
            </div>
          </div>
          <div className="flex justify-end space-x-3">
            <Button
              type="reset"
              variant="default"
              onClick={() => {
                setOpen(false);
                setFormErrors(null);
                clearForm();
              }}
            >
              Cancel
            </Button>
            <Button variant="filled" onClick={postShift} loading={createShiftMutation.isLoading}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const padNumberWithZeros = (number: any, length: any) => {
  return String(number).padStart(length, "0");
};

const AnimatedNumber = ({ number }: any) => {
  const [animatedNumber, setAnimatedNumber] = useState(number);

  // Define the animation spring
  const animationProps = useSpring({
    number: number,
    from: { number: animatedNumber },
    onChange: ({ number }: any) => setAnimatedNumber(number),
    delay: 100,
  });

  return (
    <animated.span>
      {animationProps.number.interpolate((number: any) => padNumberWithZeros(number.toFixed(0), 2))}
    </animated.span>
  );
};
