import { useMutation } from 'react-query';
import { queryClient } from 'lib/react-query';

import { axios } from "lib/axios";
import { useNotification } from "hooks/useNotification";

export const enableUsers = ({ data }) => {
    return axios.post(`/users/enable`, data);
};

export const useEnableUsers = (closeModal) => {
    const { addNotification } = useNotification();

    return useMutation({
        onMutate: async (enabledUsers) => {
            await queryClient.cancelQueries('users');

            const previousUsers = queryClient.getQueryData('users');

            console.log('deletedUsers', enabledUsers.data.length)

            if (enabledUsers.data.length <= 1) {
                console.log('1', previousUsers.users)
                queryClient.setQueryData('users', previousUsers?.users.filter(
                    (user) => user.id !== enabledUsers.id
                ));
            } else {
                console.log('2')
                let enabledUsersList = []
                enabledUsers.data.map((user) => {
                    enabledUsersList = [
                        ...enabledUsersList,
                        user
                    ]
                });

                console.log('deletedUsersList', enabledUsersList)

                queryClient.setQueryData('users', enabledUsersList);
            }

            return { previousUsers };
        },
        onError: (_, __, context) => {
            if (context?.previousUsers) {
                queryClient.setQueryData('users', context.previousUsers);
            }
            addNotification({
                disallowClose: true,
                type: 'error',
                title: 'Unexpected Error',
                message: 'Could not enable user(s)',
            });
        },
        onSuccess: () => {
            closeModal();
            queryClient.invalidateQueries('users');
            addNotification({
                disallowClose: true,
                type: 'success',
                title: 'Enabled',
                message: 'User(s) enabled Successfully!',
            });
        },
        mutationFn: enableUsers,
    });
};