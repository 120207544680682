import { useState, useEffect } from "react";
import { DateTime } from "luxon";

import { RecordInformation } from "features/record/components/RecordInformation";
import { RecordRow } from "features/record/components/RecordRow";
import { CountRow } from "./CountRow";

type TRecord = {
  selectedMonth: any;
  medication: any;
  records: any;
  setIsMedicationPreviewOpen: any;
  counts: any;
  returns: any;
  received: any;
};

export const Record = ({
  selectedMonth,
  medication,
  records,
  setIsMedicationPreviewOpen,
  counts,
  returns,
  received,
}: TRecord) => {
  console.log("RECORD YOOOOOO", returns);

  const currentDate: number = DateTime.now().setZone("Europe/London").toUnixInteger();

  //* Get and set the areas timezone from companies settings
  const timezone = "Europe/London";

  const month: any = DateTime.fromMillis(Number(selectedMonth.start), {
    zone: timezone,
  });

  let totalDays: any = [];

  const [rowData, setRowData] = useState<any>([]);

  useEffect(() => {
    //* If there are no medication doses set and no medication_records, set as a single row
    if (!medication.doses && records.length <= 0) {
      setRowData([{ id: 1, row: 1, medication_id: medication.id }]);
    } else {
      const doses = Math.max(...records.map((record: any) => record.dose));
      //* If there less records than doses, set number of rows to number of doses
      if (records.length > 0 && doses > medication.doses) {
        const rows = [];
        for (let i = 1; i <= doses; i++) {
          rows.push({
            id: i,
            row: i,
            medication_id: medication.id,
          });
        }

        setRowData(rows);
      } else {
        //* If there are more records than doses, set number of rows to number of records
        const rows = [];
        for (let i = 1; i <= medication.doses; i++) {
          rows.push({
            id: i,
            row: i,
            medication_id: medication.id,
          });
        }
        setRowData(rows);
      }
    }
  }, [medication, selectedMonth]);

  for (let i = 0; i < month.daysInMonth; i++) {
    totalDays.push({
      day: DateTime.local(month.year, month.month, i + 1, {
        zone: timezone,
      })
        .startOf("day")
        .toSeconds(),
    });
  }

  return (
    <div className="flex max-w-full flex-col overflow-hidden rounded-md border border-neutral-300 bg-white shadow">
      <RecordInformation
        medication={medication}
        rows={rowData}
        setRows={setRowData}
        setIsMedicationPreviewOpen={setIsMedicationPreviewOpen}
      />

      <div className="flex overflow-x-auto pb-3">
        <div className="flex flex-col">
          <RecordDayRow selectedMonth={selectedMonth.start} />

          <div className="flex border-b border-neutral-300">
            <div className="sticky left-0 z-10 flex flex-col">
              {rowData.map((row: any, index: any) => (
                <div className="sticky left-0 z-10 flex h-[52px] w-32 items-center bg-white p-2">
                  <p className="font-medium text-neutral-700">Dose {row.row}</p>
                </div>
              ))}
            </div>
            <div className="flex flex-col">
              <RecordRow
                days={totalDays}
                rows={rowData}
                medication={medication}
                records={records}
                currentDate={currentDate}
              />
            </div>
          </div>
          <CountRow type="end" days={totalDays} counts={counts} medication={medication} currentDate={currentDate} />
          <CountRow type="return" days={totalDays} counts={returns} medication={medication} currentDate={currentDate} />
          <CountRow
            type="received"
            days={totalDays}
            counts={received}
            medication={medication}
            currentDate={currentDate}
          />
        </div>
      </div>
    </div>
  );
};

const RecordDayRow = ({ selectedMonth }: any) => {
  const timezone = "Europe/London";

  const [days, setDays] = useState<any>([]);

  const daysInMonth = Array.from(Array(Number(selectedMonth.daysInMonth)).keys());

  useEffect(() => {
    const allDays = daysInMonth.map((day: number) => {
      return (
        <p key={day}>
          <span>
            {DateTime.local(selectedMonth.c.year, selectedMonth.c.month, day + 1, { zone: timezone }).toLocaleString({
              weekday: "narrow",
            })}
          </span>
          <span>
            {DateTime.local(selectedMonth.c.year, selectedMonth.c.month, day + 1, { zone: timezone }).toLocaleString({
              day: "2-digit",
            })}
          </span>
        </p>
      );
    });

    setDays(allDays);
  }, [selectedMonth]);

  useEffect(() => {
    console.log("days 123: ", days);
  }, [days]);

  return (
    <div className="flex border-t border-b border-neutral-300 bg-white">
      <div className="sticky left-0 z-10 flex w-32 items-center bg-white p-2" />
      {days.map((day: any, index: number) => (
        <RecordDay key={day} day={day} />
      ))}
    </div>
  );
};

const RecordDay = ({ day }: any) => {
  return (
    <div className="flex h-[52px] w-[52px] min-w-[52px] items-center justify-center p-2 font-semibold text-neutral-400">
      <p>{day}</p>
    </div>
  );
};
