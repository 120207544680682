import { Route, Routes } from "react-router-dom";
import { Areas } from "./Areas";
import { Area } from "./Area";
import { CreateArea } from "./CreateArea";

export const AreaRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Areas />} />
      <Route path="new" element={<CreateArea />} />
      <Route path=":areaId/*" element={<Area />} />
    </Routes>
  );
};
