import { useEffect, useState } from "react";
import { SegmentedControl } from "@mantine/core";
import { useParams } from "react-router-dom";

import { bodyparts } from "lib/bodyparts";
import { Bodypart } from "./Bodypart";
import { useArea } from "features/area/api/getArea";

type BodypartListTypes = {
  setSelectedBodypart: Function;
  nextView?: Function;
};

export const BodypartList = ({
  setSelectedBodypart,
  nextView,
}: BodypartListTypes) => {
  const { areaId: id } = useParams();
  // sets the area id to either the useParams id or an empty string to avoid typescript error
  const areaId = id || "";
  const areaQuery = useArea({ areaId });

  const handleSelect = (bodypart: object) => {
    setSelectedBodypart(bodypart);
    if (nextView) {
      nextView();
    }
  };

  const [view, setView] = useState<string>("front");

  // creates key type for getting the selected_bodymap of the area
  type ObjectKey = keyof typeof bodyparts;
  const areaBodymap = areaQuery.data?.selected_bodymap as ObjectKey;

  const selectedBodymap = bodyparts[areaBodymap];
  type BodymapKey = keyof typeof bodyparts[typeof areaBodymap];

  // const areaBodymap = areaQuery.data?.selected_bodymap
  const areaBodyparts = view as BodymapKey;

  const bodypartList = selectedBodymap[areaBodyparts];

  useEffect(() => {
    setSelectedBodypart(bodypartList[0]);
  }, []);

  return (
    <div className="flex w-full flex-col justify-start overflow-y-auto">
      <SegmentedControl
        value={view}
        onChange={setView}
        data={[
          { label: "Front", value: "front" },
          { label: "Back", value: "back" },
        ]}
        className="overflow-visible"
      />

      {bodypartList.map((bodypart) => (
        <Bodypart
          key={bodypart.name}
          bodypart={bodypart}
          handleSelect={handleSelect}
        />
      ))}
    </div>
  );
};
