import { useMutation } from "react-query";

import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useNotification } from "hooks/useNotification";
import { AreaType } from "../types";

export type UpdateAreaType = {
  data: AreaType;
  areaId: string;
};

export const updateArea = ({ data, areaId }: UpdateAreaType): Promise<AreaType> => {
  console.log("updateArea", data, areaId);
  return axios.put(`/areas/${areaId}`, data);
};

export const useUpdateArea = () => {
  const { addNotification } = useNotification();

  return useMutation({
    onMutate: async (updatingArea: any) => {
      await queryClient.cancelQueries(["area", updatingArea?.areaId]);

      const previousArea = queryClient.getQueryData<AreaType>(["area", updatingArea?.areaId]);

      queryClient.setQueryData(["area", updatingArea?.areaId], {
        ...previousArea,
        ...updatingArea.data,
      });

      return { previousArea };
    },
    onError: (_, __, context: any) => {
      if (context?.previousArea) {
        queryClient.setQueryData(["area", context.previousArea.id], context.previousArea);
      }
    },
    onSuccess: (data) => {
      queryClient.refetchQueries(["area", data.id]);
      addNotification({
        disallowClose: true,
        type: "success",
        title: "Area Updated",
        message: "Area Updated Successfully!",
      });
    },
    mutationFn: updateArea,
  });
};
