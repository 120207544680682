import { Input, InputVariant, InputWrapper } from "@mantine/core";
import { clamp } from "@mantine/hooks";
import { TimeField } from "components/TimeField/TimeField";
import { DateTime } from "luxon";
import { useState, useRef, useEffect } from "react";

export const TimeInput = ({
  variant,
  showLabel,
  previousTime,
  defaultTime,
  value,
  onChange,
  isSleep,
  label,
}: {
  variant?: InputVariant;
  showLabel?: boolean;
  previousTime?: any;
  defaultTime: DateTime;
  value: DateTime;
  onChange: any;
  isSleep?: boolean;
  label?: string;
}) => {
  // TODO: Pass the previous time as prop, for uses where you are updating a previous value
  // TODO:
  // TODO: Fix: padding function for minutes - for example, currently, 01 doesn't work
  // TODO: Refactor components functions, maybe create a custom hook
  // TODO: Remove: unused props/functions
  // TODO: Set types everywhere, remove all any type declarations

  console.log("TIMEINPUT", previousTime);

  const [time, setTime] = useState<{ hours: string; minutes: string }>({
    hours: "",
    minutes: "",
  });

  const hoursRef = useRef<HTMLInputElement>();
  const minutesRef = useRef<HTMLInputElement>();

  function padTime(value: number) {
    return value >= 10 ? value.toString() : `0${value}`;
  }

  const createChangeHandler = ({ onChange, nextRef, min, max, maxValue }: any) => {
    return (value: string, triggerShift: boolean) => {
      if (value === "00") {
        onChange("00");
        triggerShift && nextRef?.current?.focus();
        triggerShift && nextRef?.current?.select();
        return;
      }

      const parsed = parseInt(value, 10);

      if (Number.isNaN(parsed)) {
        onChange("");
        return;
      }

      if (parsed > maxValue) {
        console.log("parsed > maxValue");
        onChange(padTime(clamp({ value: parsed, min, max })));
        triggerShift && nextRef?.current?.focus();
        triggerShift && nextRef?.current?.select();
        return;
      }

      onChange(parsed.toString());
    };
  };

  function getTimeValues() {
    const date = defaultTime.plus({
      hours: Number(time.hours),
      minutes: Number(time.minutes),
    });

    onChange(date);

    console.log("getTimeValues: ", isSleep);
    console.log("getTimeValues", date);
  }

  useEffect(() => {
    getTimeValues();
  }, [time, isSleep]);

  const handleHoursChange = createChangeHandler({
    onChange: (val: any) => {
      console.log("handleHoursChange onChange: ", val);
      setTime((previousTime: { minutes: string; hours: string }) => ({
        ...previousTime,
        hours: val,
      }));
    },
    min: 0,
    max: 23,
    maxValue: 2,
    nextRef: minutesRef,
  });

  const handleMinutesChange = createChangeHandler({
    onChange: (val: any) => {
      console.log(val);
      setTime((previousTime: { minutes: string; hours: string }) => ({
        ...previousTime,
        minutes: val,
      }));
    },
    min: 0,
    max: 59,
    maxValue: 5,
  });

  useEffect(() => {
    if (previousTime) {
      handleHoursChange(previousTime.hours, true);
      if (previousTime.minutes === "0") {
        handleMinutesChange("00", false);
      } else {
        handleMinutesChange(previousTime.minutes, false);
      }
    }
    console.log("why is this running");
  }, []);

  return (
    <InputWrapper label={label}>
      <Input
        component="div"
        className="relative"
        classNames={{
          defaultVariant: "pt-0 pb-0",
          unstyledVariant: "border-none border-0 p-0 pt-0 pb-0 px-0 h-[32px] bg-transparent",
          invalid: "your-invalid-class",
          disabled: "your-disabled-class",
          icon: "your-icon-class",
          withIcon: "your-withIcon-class",
          input: "your-input-class",
          rightSection: "your-rightSection-class",
        }}
        variant={variant && variant}
      >
        <div className="inline-flex items-center">
          <TimeField
            ref={hoursRef}
            value={time.hours}
            setValue={(val: any) => handleHoursChange(val, true)}
            onChange={handleHoursChange}
            withSeparator
            max={23}
          />
          <TimeField
            ref={minutesRef}
            value={time.minutes}
            setValue={(val: any) => handleMinutesChange(val, false)}
            onChange={handleMinutesChange}
            withSeparator={false}
            max={59}
          />
        </div>
      </Input>
    </InputWrapper>
  );
};
