import { useQuery } from "react-query";
import { AxiosError } from "axios";

import { axios } from "lib/axios";

export const getRecords = async ({ areaId, start, end }: any): Promise<any[]> => {
  try {
    const response = await axios.get(`/records/${areaId}?start=${start}&end=${end}`);
    if (response.status === 200) {
      console.log("response 200", response.status);
    }
    // Don't forget to return something
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
    if (err instanceof AxiosError) {
      if (err.response?.status === 401) {
        throw new Error("Not authorised");
      } else if (err.response?.status === 403) {
        throw new Error("Not admin");
      }
    }
    throw new Error("There was an error fetching the request");
  }
};

export const useRecords = ({ areaId, start, end }: any) => {
  return useQuery({
    queryKey: ["records", areaId, start, end],
    queryFn: () => getRecords({ areaId, start, end }),
  });
};
