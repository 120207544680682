import React from "react";
import { Link } from "react-router-dom";

import { useRoles } from "../api/getRoles";
import { roleColors } from "lib/rotacloudColors";
// import { useDeleteRole } from "../api/deleteRole";

export const RolesTable = () => {
  const rolesQuery = useRoles(() => console.log("success"));
  //   const deleteRoleMutation = useDeleteRole(() => console.log(false));

  return (
    <div className="rounded border border-gray-200 bg-white font-bold text-[#5e6c84]">
      <div className="grid grid-cols-12 gap-8 border-b border-gray-200 bg-gray-50 p-2 font-bold text-[#5e6c84]">
        <p className="col-span-2 text-[13px]">Name</p>
      </div>
      {rolesQuery.data?.map((role: any) => (
        <Link
          className="grid grid-cols-12 items-center gap-8 border-b border-gray-200 py-2 px-2 last-of-type:border-none hover:bg-[#fafbfc]"
          to={`${role.id}`}
        >
          <div className="col-span-2 flex items-center space-x-2 ">
            <div
              className="h-4 w-4 rounded-full"
              style={{
                backgroundColor: `${
                  roleColors[role.colour as keyof typeof roleColors]
                    ? roleColors[role.colour as keyof typeof roleColors]
                    : "#eeeeee"
                }`,
              }}
            />
            <p className="overflow-hidden text-ellipsis text-sm font-semibold text-[#091e42]">
              {role.name}
            </p>
          </div>
          <div className=" col-span-9 inline-block flex space-x-2 overflow-hidden truncate overflow-ellipsis text-ellipsis text-sm font-medium text-[#091e42]">
            {/* {groupsQuery.data?.areas?.map(
            (area: any, index: any) =>
              area.group_id === group.id && (
                <div>
                  <p className="text-sm text-gray-600">
                    {area.name}
                    {addCommasToAreas(area.id, group.id) && (
                      <span key={index} className="font-semibold text-gray-500">
                        ,
                      </span>
                    )}
                  </p>
                </div>
              )
          )} */}
          </div>
          <div
            className="col-span-1 flex items-end justify-end"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              //   deleteRoleMutation.mutateAsync({ roleId: role.id });
            }}
          >
            X
          </div>
        </Link>
      ))}
    </div>
  );
};
