import { Textarea } from "@mantine/core";
import { Controller } from "react-hook-form";

export type InstructionFormType = {
  instructions: string;
};

export const instructionsForm: InstructionFormType = {
  instructions: "",
};

export const InstructionsForm = ({ control, instruction }: any) => {
  return (
    <>
      <Controller
        name="instructions"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <Textarea {...field} placeholder="Add instructions to this bodymap..." minRows={4} maxRows={4} />
        )}
      />
    </>
  );
};
