import { Outlet, Navigate } from "react-router-dom";

import { HomeRoutes } from "features/home/routes";
import { AreaRoutes } from "features/area/routes";
import { GroupRoutes } from "features/group/routes";
import { UsersRoutes } from "features/users/routes";
import { ProfileRoutes } from "features/profile/routes";
import { ClaimRoutes } from "features/admin/payroll/claims/routes";
import { AdminRoutes } from "features/admin/routes";
import { PayRoutes } from "features/pay/routes";
import { Roles } from "features/roles/routes/Roles";
import { RolesRoutes } from "features/roles/routes";

const App = () => {
  return <Outlet />;
};

export const protectedRoutes = [
  {
    path: "/",
    element: <App />,
    children: [
      // { path: "/", element: <Navigate to="/home" replace /> },
      { path: "/", element: <HomeRoutes /> },
      { path: "home/*", element: <HomeRoutes /> },
      { path: "areas/*", element: <AreaRoutes /> },
      { path: "groups/*", element: <GroupRoutes /> },
      { path: "users/*", element: <UsersRoutes /> },
      { path: "profile/*", element: <ProfileRoutes /> },
      { path: "claims/*", element: <ClaimRoutes /> },
      { path: "admin/*", element: <AdminRoutes /> },
      { path: "pay/*", element: <PayRoutes /> },
      { path: "roles/*", element: <RolesRoutes /> },
    ],
  },
];
