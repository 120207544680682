import { useQuery } from "react-query";

import { axios } from "lib/axios";
import { useAuth } from "stores/Auth";
import { AxiosError } from "axios";

export const getClaims = async ({ userId, start, end }: { userId: string; start: any; end: any }) => {
  try {
    console.log("getClaims");
    if (!userId) {
      throw new Error("No user id");
    }
    const response = await axios.get(`/claims/${userId}/${start}/${end}`);
    if (response.status === 200) {
      console.log("response 200", response.status);
    }
    // Don't forget to return something
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
    if (err instanceof AxiosError) {
      if (err.response?.status === 401) {
        throw new Error("Not authorised");
      } else if (err.response?.status === 403) {
        throw new Error("Not admin");
      }
    }
    throw new Error("There was an error fetching the request");
  }
};

export const useClaims = ({ date }: any) => {
  const currentUser = useAuth((state) => state.currentUser);
  console.log("useClaims: ", currentUser, date);
  return useQuery({
    queryKey: ["claims", currentUser?.id, date.start, date.end],
    queryFn: () => getClaims({ userId: currentUser?.id, start: date.start, end: date.end }),
    enabled: !!currentUser?.id,
  });
};
