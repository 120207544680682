import React from "react";

export const AdminDashboard = () => {
  return (
    <div>
      <p>AdminDashboard</p>
      <p>Starting place of admin</p>
    </div>
  );
};
