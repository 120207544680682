import React from "react";
import { useParams } from "react-router-dom";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { useForm } from "../api/getForm";
import {
  Button,
  Checkbox,
  MultiSelect,
  Select,
  TextInput,
} from "@mantine/core";
import {
  Controller,
  useForm as useReactHookForm,
  useFieldArray,
} from "react-hook-form";
import { useCreateFormSubmission } from "../api/createFormSubmission";
import Calendar from "components/Calendar/Calendar";
import { ScaleRating } from "../components/ScaleRating";

const crumbs = [
  { title: "Forms Dashboard", href: ".." },
  { title: "Submit Form", href: "#" },
];

export const FormSubmission = () => {
  const { formId } = useParams();
  const formQuery = useForm({ formId });

  const createFormSubmissionMutation = useCreateFormSubmission();

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useReactHookForm<any>({
    // resolver: zodResolver(validationSchema),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "questions",
  });

  const onSubmit = (data: any) => {
    const list: any = [];

    formQuery?.data?.questions?.forEach((question: any) => {
      const questionId = question.id;
      const find = data.questions.find((question: any) => question[questionId]);

      //   console.log("questions", data.questions, find);
      list.push({ ...question, answer: find[questionId] });
    });

    const formData = {
      formId: formQuery?.data?.id,
      result: list,
    };

    console.log("response", formData);

    createFormSubmissionMutation.mutate({
      data: {
        formId: formQuery?.data?.id,
        result: list,
      },
    });
  };

  return (
    <div className="flex flex-1 flex-col overflow-hidden bg-[#f8f9fa]">
      <div className="flex max-h-12 items-center border-b border-gray-200 py-3 px-5">
        <Breadcrumbs items={crumbs} />
      </div>
      <div className="flex h-full min-h-0 w-full flex-col items-start overflow-y-auto p-4 pt-0">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="mt-4 flex max-w-4xl flex-col rounded border border-gray-200 bg-white p-2 shadow"
        >
          <div>
            <p>{JSON.stringify(watch(), null, 2)}</p>
            <p className="text-[18px] font-semibold text-gray-900">
              {formQuery?.data?.title}
            </p>
            <p className="text-sm font-semibold text-gray-500">
              {formQuery?.data?.description}
            </p>
          </div>
          <div>
            {formQuery?.data?.questions?.map((question: any, index: any) => {
              return (
                <Question question={question} control={control} index={index} />
              );
            })}
          </div>
          <div className="flex w-full justify-end space-x-3 border-t border-slate-200 px-3 py-2">
            <Button
              variant="outline"
              size="xs"
              className="text-sm font-medium shadow"
              onClick={() => {}}
              style={{
                marginBottom: "1px",
              }}
            >
              Cancel
            </Button>
            <Button
              size="xs"
              className="border-none bg-jira-button text-sm font-medium text-white hover:bg-jira-button-hover"
              type="submit"
            >
              Create
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

const Question = ({ control, question, index }: any) => {
  switch (question.type) {
    case "text":
      return (
        <Controller
          control={control}
          name={`questions[${index}].${question.id}`}
          render={({ field }: any) => (
            <TextInput
              {...field}
              label={question.label}
              required={question.required}
            />
          )}
        />
      );
    case "date":
      return (
        <Controller
          control={control}
          name={`questions[${index}].${question.id}`}
          render={({ field }: any) => (
            <Calendar
              {...field}
              label={question.label}
              required={question.required}
            />
          )}
        />
      );
    case "scalerating":
      return (
        <Controller
          control={control}
          name={`questions[${index}].${question.id}`}
          render={({ field: { value, onChange } }) => (
            <ScaleRating
              label={question.label}
              required={question.required}
              value={value}
              minValue={question.min_value}
              maxValue={question.max_value}
              onChange={onChange}
            />
          )}
        />
      );
    case "checkbox":
      return (
        <Controller
          control={control}
          name={`questions[${index}].${question.id}`}
          render={({ field }: any) => (
            <Checkbox
              {...field}
              label={question.label}
              required={question.required}
            />
          )}
        />
      );
    case "multiplechoice":
      return (
        <Controller
          control={control}
          name={`questions[${index}].${question.id}`}
          render={({ field }: any) => (
            <MultiSelect
              {...field}
              data={question.choices}
              label={question.label}
            />
          )}
        />
      );
    case "singlechoice":
      return (
        <Controller
          control={control}
          name={`questions[${index}].${question.id}`}
          render={({ field }: any) => (
            <Select {...field} data={question.choices} label={question.label} />
          )}
        />
      );
    default:
      return null;
  }
};
