import { Button } from "@mantine/core";
import { PlusIcon } from "@heroicons/react/20/solid";

export const AddNewShiftButton = ({ onClick }: any) => {
  return (
    <div className="min-h-10 group col-span-8 flex h-10 cursor-pointer items-center" onClick={onClick}>
      <div className="flex items-center space-x-2 px-2 opacity-0 transition-all duration-100 ease-linear group-hover:opacity-100">
        <Button
          variant="subtle"
          leftIcon={<PlusIcon className="h-4 w-4" />}
          classNames={{
            leftIcon: "mr-1",
          }}
        >
          Add shift
        </Button>
      </div>
    </div>
  );
};
