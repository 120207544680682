import { useMutation } from "react-query";
import { queryClient } from "lib/react-query";

import { axios } from "lib/axios";
import { useNotification } from "hooks/useNotification";
import { calculateShiftData } from "../utils/calculateShiftData";

export type TCreateTimesheet = {
  data: any;
};

export const createTimesheet = async ({ data }: TCreateTimesheet) => {
  const shifts = await axios.get(`/shifts?user=${data.user_id}&start=${data.start}&end=${data.end}`);
  const stuff = calculateShiftData(
    shifts.data,
    { salary: data.salary, id: data.user_id },
    { start: data.start, end: data.end }
  );
  console.log("createTimesheet: ", data, shifts.data, stuff);

  return axios.post(`/timesheet`, stuff);
};

export const useCreateTimesheet = ({ id, start, end, successFunction }: any) => {
  const { addNotification } = useNotification();

  return useMutation({
    onError: (_, __, context) => {
      addNotification({
        disallowClose: true,
        type: "error",
        title: "Timesheet not added",
        message: "There was an issue adding this timesheet, please try again",
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["timesheet", id, start, end]);
      console.log("create timesheet invalidate: ", id, start, end);
      successFunction();
      addNotification({
        disallowClose: true,
        type: "success",
        title: "Timesheet added",
        message: "",
      });
    },
    mutationFn: createTimesheet,
  });
};
