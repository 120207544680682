import { useParams } from "react-router-dom";
import { Button, Skeleton } from "@mantine/core";
import { useNavigate } from "react-router-dom";

import { useMedication } from "../api/getMedication";
import { useUpdateMedication } from "../api/updateMedication";
import { UpdateMedicationForm } from "../components/UpdateMedicationForm";

export const UpdateMedication = ({ id }: any) => {
  const { areaId, medicationId } = useParams();
  const navigate = useNavigate();

  const medicationQuery = useMedication({ areaId: areaId, medicationId: medicationId });
  const updateMedicationMutation = useUpdateMedication(() =>
    navigate(`/areas/${areaId}/medication/list`, { replace: true })
  );

  return (
    <div className="flex w-full justify-center">
      <div className="flex flex-1">
        {!medicationQuery.data ? (
          <p>Loading</p>
        ) : (
          <UpdateMedicationForm medication={medicationQuery?.data} onSubmit={updateMedicationMutation} />
        )}
      </div>
    </div>
  );
};

const LoadingForm = () => {
  return (
    <div className="flex h-full w-full flex-col">
      <div className="flex w-full min-w-0 flex-col space-y-5 bg-white sm:max-w-2xl">
        <div className="flex space-x-1">
          <p className="pb-2 text-2xl font-semibold text-gray-900">Update</p>
          <Skeleton height={36} width={200} />
        </div>

        <div className="flex flex-col space-y-4">
          <div className="flex flex-col space-y-4">
            <div className="space-y-2">
              <div>
                <p className="text-[16px] font-semibold text-gray-900">Medication Information</p>
                <p className="text-[13px] font-medium text-gray-900">The basic information of the medication</p>
              </div>

              <div className="space-y-5 rounded-sm border border-[#ebecf0] bg-white p-4">
                <div className="space-y-10">
                  <div className="flex flex-col space-y-3">
                    <div className="flex flex-col space-y-1">
                      <p className=" text-sm font-semibold text-gray-500">Medication Name</p>
                      <Skeleton height={36} width="100%" />
                    </div>

                    <div className="flex flex-col space-y-1">
                      <p className=" text-sm font-semibold text-gray-500">Medication Dose</p>
                      <p className="text-[12px] font-medium text-[#868e96]">
                        The dose per one of the medication (e.g paracetamol would usually be 500mg)
                      </p>
                      <Skeleton height={36} width="100%" />
                    </div>
                    <div className="flex flex-col space-y-1">
                      <p className=" text-sm font-semibold text-gray-500">Medication Type</p>
                      <Skeleton height={39} width={165} />
                    </div>
                    <div className="flex min-w-0 max-w-full flex-col space-y-6">
                      <div className="flex flex-col space-y-1">
                        <p className=" text-sm font-semibold text-gray-500">Used For</p>
                        <p className="text-[12px] font-medium text-[#868e96]">Enter medication uses</p>
                        <Skeleton height={39} width="100%" />
                      </div>
                      <div className="flex flex-col space-y-1">
                        <p className=" text-sm font-semibold text-gray-500">Side Effects</p>
                        <p className="text-[12px] font-medium text-[#868e96]">Enter any side effects</p>
                        <Skeleton height={39} width="100%" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col space-y-4">
              <div className="space-y-2">
                <div>
                  <p className="text-[16px] font-semibold text-gray-900">Administration Information</p>
                  <p className="text-[13px] font-medium text-gray-900">The basic information of the medication</p>
                </div>

                <div className="space-y-5 rounded-sm border border-[#ebecf0] bg-white p-4">
                  <div className="space-y-10">
                    <div className="flex flex-col space-y-3">
                      <div className="flex flex-col space-y-1">
                        <p className=" text-sm font-semibold text-gray-500">Dose</p>
                        <p className="text-[12px] font-medium text-[#868e96]">
                          The dose given when administering this medication - (e.g paracetamol would usually be 2
                          tablets)
                        </p>
                        <Skeleton height={36} width={140} />
                      </div>
                      <div className="flex flex-col space-y-5">
                        <div className="flex flex-col space-y-1">
                          <p className=" text-sm font-semibold text-gray-500">Route</p>
                          <p className="text-[12px] font-medium text-[#868e96]">How this medication is administered</p>
                          <Skeleton height={36} width="100%" />
                        </div>
                        {/* <MedicationFrequency
                                  value={frequency}
                                  onChange={setFrequency}
                                  maxValue={10}
                                  error={formErrors.fieldErrors?.frequency}
                                /> */}
                        <div className="flex flex-col space-y-1">
                          <p className=" text-sm font-semibold text-gray-500">Authorisation</p>
                          <p className="text-[12px] font-medium text-[#868e96]">
                            Who authorised the use of this medication
                          </p>
                          <Skeleton height={36} width="100%" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="space-y-2">
                <div>
                  <p className="text-[16px] font-semibold text-gray-900">Medication Schedule</p>
                  <p className="text-[13px] font-medium text-gray-900">
                    The information of the medications administration schedule
                  </p>
                </div>

                <div className="space-y-5 rounded-sm border border-[#ebecf0] bg-white p-4">
                  <div className="space-y-10">
                    <div className="flex flex-col space-y-3">
                      <div className="flex flex-col space-y-1">
                        <p className=" text-sm font-semibold text-gray-500">Start Date</p>
                        <Skeleton height={36} width="100%" />
                      </div>

                      <div className="flex flex-col space-y-1">
                        <p className=" text-sm font-semibold text-gray-500">End Date/Discontinued</p>
                        <Skeleton height={36} width="100%" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="space-y-2">
                <div>
                  <p className="text-[16px] font-semibold text-gray-900">Additional Information</p>
                  <p className="text-[13px] font-medium text-gray-900">
                    Optional additional information about the medication
                  </p>
                </div>

                <div className="space-y-5 rounded-sm border border-[#ebecf0] bg-white p-4">
                  <div className="space-y-10">
                    <div className="flex flex-col space-y-3">
                      <div className="flex w-full flex-col space-y-5">
                        <div className="flex flex-col space-y-1">
                          <p className=" text-sm font-semibold text-gray-500">Notes</p>
                          <Skeleton height={80} width="100%" />
                        </div>
                      </div>
                      <div className="border-t border-dashed border-neutral-200" />
                      <div className="flex flex-col space-y-2">
                        <p className="text-sm font-medium text-neutral-800">Bodymap</p>
                        <div className="flex flex-col">
                          <Skeleton height={80} width="100%" />
                        </div>

                        <Button
                          className="min-h-[34px] w-full overflow-hidden bg-blue-50 text-[13px] text-blue-600 hover:bg-blue-100 active:bg-blue-100"
                          style={{
                            marginBottom: "1px",
                          }}
                        >
                          Add Instruction
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
