import { useEffect } from "react";
import { AppRoutes } from "./routes/index";
import { AppProvider } from "./providers/app";
import { AppLayout } from "components/Layout/AppLayout";

import { useAuth } from "stores/Auth";
import { LoadingOverlay } from "@mantine/core";
import image from "assets/supported_logo.png";

function App() {
  const supabaseHydrate = useAuth((state) => state.supabaseHydrate);
  const loading = useAuth((state) => state.loading);

  useEffect(() => {
    supabaseHydrate();
  }, []);

  return (
    <AppProvider>
      {loading && (
        <div className="flex h-screen w-screen items-center justify-center">
          <div className="flex h-full min-h-0 w-full items-center justify-center">
            <img src={image} alt="Area" className="relative -top-20 z-[1000] h-24 w-52" />
          </div>
          <LoadingOverlay
            visible={loading}
            overlayOpacity={1.0}
            loaderProps={{ size: "lg", color: "blue", variant: "dots" }}
          ></LoadingOverlay>
        </div>
      )}
      <AppLayout>
        <AppRoutes />
      </AppLayout>
    </AppProvider>
  );
}

export default App;
