import male_front from "assets/male_front.svg";
import male_back from "assets/male_back.svg";
import male_front_face from "assets/male_front_face.svg";
import male_front_chest from "assets/male_front_chest.svg";
import male_front_stomach from "assets/male_front_stomach.svg";
import male_front_right_arm from "assets/male_front_right_arm.svg";
import male_front_right_hand from "assets/male_front_right_hand.svg";
import male_front_left_arm from "assets/male_front_left_arm.svg";
import male_front_left_hand from "assets/male_front_left_hand.svg";
import male_front_pelvis from "assets/male_front_pelvis.svg";
import male_front_right_leg from "assets/male_front_right_leg.svg";
import male_front_left_leg from "assets/male_front_left_leg.svg";
import male_front_left_foot from "assets/male_front_left_foot.svg";
import male_front_right_foot from "assets/male_front_right_foot.svg";
import male_back_head from "assets/male_back_head.svg";
import female_front from "assets/female_front.svg";
import female_front_face from "assets/female_front_face.svg";
import female_front_chest from "assets/female_front_chest.svg";
import female_front_stomach from "assets/female_front_stomach.svg";
import female_front_right_arm from "assets/female_front_right_arm.svg";
import female_front_right_hand from "assets/female_front_right_hand.svg";
import female_front_left_arm from "assets/female_front_left_arm.svg";
import female_front_left_hand from "assets/female_front_left_hand.svg";
import female_front_pelvis from "assets/female_front_pelvis.svg";
import female_front_right_leg from "assets/female_front_right_leg.svg";
import female_front_left_leg from "assets/female_front_left_leg.svg";
import female_front_right_foot from "assets/female_front_foot_right.svg";
import female_front_left_foot from "assets/female_front_foot_left.svg";
import female_back from "assets/female_back.svg";
import female_back_head from "assets/female_back_head.svg";

export const bodyparts = {
  female: {
    front: [
      {
        id: 0,
        name: "View (front)",
        image: female_front,
      },
      {
        id: 1,
        name: "Face",
        image: female_front_face,
      },
      {
        id: 2,
        name: "Chest",
        image: female_front_chest,
      },
      {
        id: 3,
        name: "Stomach",
        image: female_front_stomach,
      },
      {
        id: 4,
        name: "Arm (right)",
        image: female_front_right_arm,
      },
      {
        id: 5,
        name: "Hand (right)",
        image: female_front_right_hand,
      },
      {
        id: 6,
        name: "Arm (left)",
        image: female_front_left_arm,
      },
      {
        id: 7,
        name: "Hand (left)",
        image: female_front_left_hand,
      },
      {
        id: 8,
        name: "Pelvis",
        image: female_front_pelvis,
      },
      {
        id: 9,
        name: "Leg (left)",
        image: female_front_left_leg,
      },
      {
        id: 10,
        name: "Leg (right)",
        image: female_front_right_leg,
      },
      {
        id: 11,
        name: "Foot (right)",
        image: female_front_right_foot,
      },
      {
        id: 12,
        name: "Foot (left)",
        image: female_front_left_foot,
      },
    ],
    back: [
      {
        id: 0,
        name: "View (back)",
        image: female_back,
      },
      {
        id: 1,
        name: "Head",
        image: female_back_head,
      },
    ],
  },
  male: {
    front: [
      {
        id: 0,
        name: "View (front)",
        image: male_front,
      },
      {
        id: 1,
        name: "Face",
        image: male_front_face,
      },
      {
        id: 2,
        name: "Chest",
        image: male_front_chest,
      },
      {
        id: 3,
        name: "Stomach",
        image: male_front_stomach,
      },
      {
        id: 4,
        name: "Arm (left)",
        image: male_front_left_arm,
      },
      {
        id: 5,
        name: "Hand (left)",
        image: male_front_left_hand,
      },
      {
        id: 6,
        name: "Arm (right)",
        image: male_front_right_arm,
      },
      {
        id: 7,
        name: "Hand (right)",
        image: male_front_right_hand,
      },
      {
        id: 8,
        name: "Pelvis",
        image: male_front_pelvis,
      },
      {
        id: 9,
        name: "Leg (left)",
        image: male_front_left_leg,
      },
      {
        id: 10,
        name: "Foot (left)",
        image: male_front_left_foot,
      },
      {
        id: 11,
        name: "Leg (right)",
        image: male_front_right_leg,
      },
      {
        id: 12,
        name: "Foot (right)",
        image: male_front_right_foot,
      },
    ],
    back: [
      {
        id: 0,
        name: "View (back)",
        image: male_back,
      },
    ],
  },
};
