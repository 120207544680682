import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import Compressor from "compressorjs";
import { Button } from "@mantine/core";
import { nanoid } from "nanoid";
import { PlusSmallIcon } from "@heroicons/react/24/outline";

import Calendar from "components/Calendar/Calendar";
import { useCreateClaim } from "../api/createClaim";
import { useAuth } from "stores/Auth";
import { MileageLeg } from "./MileageLeg";

export const Mileage = () => {
  const currentUser = useAuth((state) => state.currentUser);

  const [date, setDate] = useState();

  const navigate = useNavigate();

  const createClaimMutation = useCreateClaim(() => {
    navigate("../..");
    console.log("created claim");
  });

  const [files, setFiles] = useState<any>([]);

  const [compressedFile, setCompressedFile] = useState<any>(null);

  const handleCompressedUpload = () => {
    new Compressor(files[0], {
      quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
        setCompressedFile(compressedResult);
      },
    });
  };

  useEffect(() => {
    if (files.length > 0) {
      handleCompressedUpload();
    }
  }, [files]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    multiple: false,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file: any) => (
    <div key={file.name}>
      <div
        className="flex h-full w-full items-center justify-center bg-contain bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${file.preview})` }}
        onLoad={() => {
          URL.revokeObjectURL(file.preview);
        }}
      />
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      files.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, []);

  useEffect(() => {
    console.log("thumbs", thumbs);
  }, [thumbs]);

  type TLeg = {
    id: string;
    from: string;
    to: string;
    distance: number;
    note?: string;
  };

  const [legs, setLegs] = useState<TLeg[]>([
    { id: nanoid(), to: "", from: "", distance: 0.0, note: "" },
  ]);

  const addLeg = () => {
    setLegs([
      ...legs,
      { id: nanoid(), to: "", from: "", distance: 0.0, note: "" },
    ]);
  };

  const removeLeg = (id: string) => {
    const updatedLegs = legs.filter((leg: TLeg) => leg.id !== id);
    setLegs(updatedLegs);
  };

  const handleLegValueChange = (values: any) => {
    console.log("mileage legs: ", legs, values);
    const updatedLegs = legs.map((leg: TLeg, index: number) => {
      if (leg.id === values.id) {
        return {
          ...leg,
          from: values.from,
          to: values.to,
          distance: values.distance,
          note: values.note,
        };
      } else {
        return leg;
      }
    });

    setLegs(updatedLegs);
  };

  const mileageTotal = () => {
    return legs
      .map((leg) => leg.distance)
      .reduce((a, c) => {
        return a + c;
      });
  };

  const postClaim = async () => {
    const payPerMile = 0.4;

    console.log("image", files[0]);
    await createClaimMutation.mutateAsync({
      data: {
        user_id: currentUser.id,
        date: date,
        type: "Mileage",
        amount: mileageTotal() * payPerMile,
        total_miles: mileageTotal(),
        mileage_legs: legs,
        file: compressedFile,
      },
    });
  };

  return (
    <div className="flex flex-1 flex-col overflow-y-auto border-r border-gray-200 bg-white">
      <div className="flex items-center justify-between border-b border-gray-200 p-2">
        <p className="font-semibold text-gray-900">New mileage</p>
        <Button
          variant="outline"
          size="xs"
          className="text-sm font-medium shadow"
          onClick={addLeg}
          leftIcon={<PlusSmallIcon className="h-4 w-4 text-gray-900" />}
        >
          Add leg
        </Button>
      </div>
      <div className="flex flex-col space-y-2 p-2">
        <div className="flex flex-row space-x-4">
          <div className="w-1/2 max-w-lg space-y-4 self-start rounded-md bg-white p-2">
            <div className="flex flex-col">
              <div className="max-w-lg">
                <div className="flex flex-1 flex-col space-y-4">
                  <Calendar label="Date" value={date} onChange={setDate} />
                  {legs.length > 0 && (
                    <p className="text-sm font-semibold text-jira-button">
                      Total miles: {mileageTotal()}
                    </p>
                  )}
                </div>
                <div className="mb-4">
                  {legs.map((leg: any, index: any) => {
                    return (
                      <MileageLeg
                        leg={leg}
                        legIndex={index}
                        removeLeg={removeLeg}
                        onValueChange={handleLegValueChange}
                      />
                    );
                  })}
                </div>
                <div className="mt-2 flex flex-1 justify-end space-x-2">
                  <Button
                    type="reset"
                    component={Link}
                    to="../.."
                    variant="outline"
                    size="xs"
                    className="text-sm font-medium shadow"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="bg-jira-button hover:bg-jira-button-hover"
                    size="xs"
                    onClick={postClaim}
                  >
                    Save Claim
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <section className="flex h-full w-1/2 max-w-xl flex-col self-start rounded-md bg-white p-2">
            {files.length > 0 && (
              <div className="flex h-3/4 border border-gray-200">
                <aside className="flex flex-1">{thumbs}</aside>
              </div>
            )}
            <div
              {...getRootProps({ className: "dropzone" })}
              className=" mt-1 rounded-md border-2 border-dashed border-gray-300 p-2"
            >
              <input {...getInputProps()} />
              <p className="text-sm font-medium text-gray-500">
                Drag and drop a receipt here
              </p>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};
