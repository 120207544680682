import { useEffect } from "react";
import { Controller } from "react-hook-form";
import { Checkbox, NumberInput } from "@mantine/core";

export const OnCallRole = ({ control, errors, isOnCallRole, unregister, register }: any) => {
  useEffect(() => {
    if (isOnCallRole) {
      register("flatRateWeekday");
      register("flatRateWeekend");
    } else {
      unregister("flatRateWeekday");
      unregister("flatRateWeekend");
    }
  }, [isOnCallRole, register, unregister]);

  return (
    <div className="pt-1">
      <Controller
        control={control}
        name={`isOnCallRole`}
        render={({ field: { value, onChange } }: any) => (
          <Checkbox label="On-call role" checked={value} onChange={onChange} />
        )}
      />
      {isOnCallRole && (
        <div className="mt-2 space-y-4">
          <Controller
            control={control}
            name={`flatRateWeekday`}
            render={({ field }: any) => (
              <NumberInput label="Flat rate (weekday)" {...field} error={errors.flatRateWeekday?.message as string} />
            )}
          />
          <Controller
            control={control}
            name={`flatRateWeekend`}
            render={({ field }: any) => (
              <NumberInput label="Flat rate (weekend)" {...field} error={errors.flatRateWeekend?.message as string} />
            )}
          />
        </div>
      )}
    </div>
  );
};
