import { useState, useEffect, memo } from "react";
import { Modal, Badge, ActionIcon, Tooltip } from "@mantine/core";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { CalendarIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";

import { ReactComponent as DoctorMaleIcon } from "assets/healthIcons/doctor_male.svg";
import { ReactComponent as MedicationIcon } from "assets/healthIcons/medicines.svg";
import { DeleteMedication } from "features/medication/components/DeleteMedication";
import { useAuth } from "stores/Auth";
import { MarkerList } from "components/Markers/MarkerList";
import { DateTime } from "luxon";
import { useRecordStore } from "features/record/store/recordStore";

type MedicationPreviewProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  medication?: any;
};

export const MedicationPreview = memo(function MedicationPreview({
  open,
  setOpen,
  medication,
}: MedicationPreviewProps) {
  const currentDate = DateTime.now().setZone("Europe/London");

  const userLevel = useAuth((state) => state.userLevel);

  const medicationInformation = useRecordStore((state) => state.medicationInformation);

  if (!medicationInformation) {
    return (
      <Modal opened={open} onClose={() => setOpen(false)} size="lg" centered withCloseButton={false}>
        <div className="flex flex-col space-y-4">
          <p>No medication</p>
        </div>
      </Modal>
    );
  }

  return (
    <Modal opened={open} onClose={() => setOpen(false)} size="650px" centered withCloseButton={false}>
      <div className="flex flex-col space-y-4">
        <div className="flex flex-col space-y-2">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2 text-2xl font-semibold text-[#172b4d]">
              <div
                className={`${
                  medication
                    ? !medication.end_date
                      ? "bg-[#57A55A]"
                      : currentDate.toUnixInteger() > medication.end_date
                      ? "bg-red-500"
                      : "bg-[#57A55A]"
                    : !medicationInformation.end_date
                    ? "bg-[#57A55A]"
                    : currentDate.toUnixInteger() > medicationInformation.end_date
                    ? "bg-red-500"
                    : "bg-[#57A55A]"
                } h-4 w-4 rounded-full`}
              />
              <p className=" ">{medication ? medication.name : medicationInformation.name}</p>
              <p>{medication ? medication.dose : medicationInformation.dose}</p>
            </div>
            {userLevel === "admin" && (
              <div className="flex items-center space-x-2">
                <Link to={`../update/${medication ? medication.id : medicationInformation.id}`}>
                  <ActionIcon className={`h-5 w-5 bg-neutral-50 text-neutral-700 hover:bg-gray-100`} variant="light">
                    <PencilSquareIcon className="h-5 w-5" />
                  </ActionIcon>
                </Link>
                <DeleteMedication
                  id={medication ? medication.id : medicationInformation.id}
                  color={{
                    bg: "bg-red-50",
                    text: "text-red-600",
                    hover: "hover:bg-red-100",
                  }}
                  variant="light"
                  // closeEvent={() => setIsUpdateModalOpen(false)}
                />
              </div>
            )}
          </div>
          <InformationRows medication={medication ? medication : medicationInformation} />
        </div>

        {medication ? (
          medication.used_for &&
          medication.used_for.length > 0 && (
            <div className="space-y-4">
              <div className="space-y-1">
                <p className=" text-sm font-bold uppercase text-gray-500">Used For</p>
                <div className="flex max-w-full flex-wrap gap-1">
                  {medication.used_for &&
                    medication.used_for.length > 0 &&
                    medication.used_for.map((use: any) => <Badge className="bg-jira-button text-white">{use}</Badge>)}
                </div>
              </div>
            </div>
          )
        ) : (
          <div className="space-y-4">
            <div className="space-y-1">
              <p className=" text-sm font-bold uppercase text-gray-500">Used For</p>
              <div className="flex max-w-full flex-wrap gap-1">
                {medicationInformation.used_for &&
                  medicationInformation.used_for.length > 0 &&
                  medicationInformation.used_for.map((use: any) => (
                    <Badge className="bg-jira-button text-white">{use}</Badge>
                  ))}
              </div>
            </div>
          </div>
        )}

        {medication ? (
          medication.side_effects &&
          medication.side_effects.length > 0 && (
            <div className="space-y-1">
              <p className=" text-sm font-bold uppercase text-gray-500">Side Effects</p>
              <div className="flex max-w-full flex-wrap gap-1">
                {medication.side_effects.map((sideEffect: any) => (
                  <Badge className="bg-jira-button text-white">{sideEffect}</Badge>
                ))}
              </div>
            </div>
          )
        ) : (
          <div className="space-y-1">
            <p className=" text-sm font-bold uppercase text-gray-500">Side Effects</p>
            <div className="flex max-w-full flex-wrap gap-1">
              {medicationInformation.side_effects &&
                medicationInformation.side_effects.length > 0 &&
                medicationInformation.side_effects.map((sideEffect: any) => (
                  <Badge className="bg-jira-button text-white">{sideEffect}</Badge>
                ))}
            </div>
          </div>
        )}

        {medication && (medication.notes || medicationInformation.notes) && (
          <div className="space-y-1">
            <p className=" text-sm font-bold uppercase text-gray-500">Notes</p>
            <div className="flex max-w-full flex-wrap gap-1">
              <p className="text-gray-800">{medication.notes ? medication.notes : medicationInformation.notes}</p>
            </div>
          </div>
        )}
        <MarkerList bodymap={medication ? medication.bodymap : medicationInformation.bodymap} />
      </div>
    </Modal>
  );
});

const InformationRows = ({ medication }: any) => {
  return (
    <div className="flex flex-col space-y-1 md:grid md:grid-cols-3">
      <MedicationDates startDate={medication.start_date} endDate={medication.end_date} withSeperator={true} />

      <div className="flex items-center justify-between space-x-4  text-sm font-medium text-gray-500">
        <div className="flex items-center space-x-2 font-medium">
          <MedicationIcon className="h-5 w-5 fill-jira-secondary" />
          <p>Medicine Type</p>
          <p className="rounded bg-[#ebecf0] px-2 py-1 font-medium text-gray-900">{medication.type}</p>
        </div>
        <div className="hidden md:flex">
          <p className="text-[#DFE1E6]">|</p>
        </div>
      </div>

      <div className="flex flex-1 items-center space-x-4 overflow-hidden text-ellipsis whitespace-nowrap text-sm font-medium text-[#0052cc] md:justify-center">
        <div className="flex flex-1 items-center space-x-2 overflow-hidden text-ellipsis whitespace-nowrap font-medium text-[#0052cc] md:justify-center">
          <DoctorMaleIcon className=" h-5 min-h-[20px] w-5 min-w-[20px] fill-[#3b82f6]" />
          <div className="flex space-x-1 overflow-hidden whitespace-nowrap">
            <p>Authorisation: </p>
            <Tooltip label={medication.authorisation}>
              <p className="cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap">
                {medication.authorisation}
              </p>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

const MedicationDates = ({
  startDate,
  endDate,
  withSeperator,
}: {
  startDate: number;
  endDate: number;
  withSeperator?: boolean;
}) => {
  return startDate || endDate ? (
    <div className="flex items-center space-x-3 text-sm">
      <div className="flex items-center space-x-1">
        <CalendarIcon className="h-5 w-5 text-gray-500" />
        <div className="flex flex-col justify-center">
          {startDate && (
            <div className="flex space-x-1">
              <p className="font-medium text-gray-500">Start date:</p>
              <p className="text-gray-900">
                {DateTime.fromSeconds(startDate, {
                  zone: "Europe/London",
                }).toFormat("MMMM d, yyyy")}
              </p>
            </div>
          )}
          {endDate && (
            <div className="flex space-x-1">
              <p className="font-medium text-gray-500">End date:</p>
              <p className="text-gray-900">
                {DateTime.fromSeconds(endDate, {
                  zone: "Europe/London",
                }).toFormat("MMMM d, yyyy")}
              </p>
            </div>
          )}
        </div>
      </div>
      {withSeperator && (
        <div className="hidden md:flex">
          <p className="text-[#DFE1E6]">|</p>
        </div>
      )}
    </div>
  ) : null;
};
