import { useQuery } from "react-query";

import { axios } from "lib/axios";
import { useAuth } from "stores/Auth";
import { TClaim } from "../types";
import { AxiosError } from "axios";

export const getClaims = async ({ userId }: any) => {
  try {
    console.log("getClaims");
    const response = await axios.get(`/claims/${userId}`);
    if (response.status === 200) {
      console.log("response 200", response.status);
    }
    // Don't forget to return something
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
    if (err instanceof AxiosError) {
      if (err.response?.status === 401) {
        throw new Error("Not authorised");
      } else if (err.response?.status === 403) {
        throw new Error("Not admin");
      }
    }
    throw new Error("There was an error fetching the request");
  }
};

export const useClaims = ({ userId }: any) => {
  const currentUser = useAuth((state) => state.currentUser);
  console.log("useClaims: ", currentUser);
  return useQuery({
    queryKey: ["claims", userId],
    queryFn: () => getClaims({ userId: userId }),
    enabled: !!currentUser?.id,
  });
};
