import { Routes, Route, useSearchParams } from "react-router-dom";

import { Login } from "./Login";
import { Forgot } from "./Forgot";
import { Verify } from "./Verify";
import { Reset } from "./Reset";
import { Invite } from "./Invite";

export const AuthRoutes = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const mode = searchParams.get("mode");
  const actionCode = searchParams.get("oobCode");
  const continueUrl = searchParams.get("continueUrl");
  const lang = searchParams.get("lang") || "eng";
  const email = searchParams.get("email");
  const tempCode = searchParams.get("tempCode");
  const setting = searchParams.get("setting");

  function AuthController() {
    if (mode === "verifyEmail" || mode === "verifyemail" || setting === "newUser") {
      return <Verify />;
    } else if (mode === "resetPassword" || mode === "resetpassword") {
      return <Reset />;
    } else {
      return (
        <>
          <p>404</p>
        </>
      );
    }
  }

  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="forgot" element={<Forgot />} />
      <Route path="invite" element={<Invite />} />
      <Route path="reset" element={<Reset />} />
      <Route path="*" element={<AuthController />} />
    </Routes>
  );
};
