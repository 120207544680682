import { forwardRef } from "react";
import { Select } from "@mantine/core";

import avatar_one from "assets/avatars/avatar_1.svg";
import avatar_two from "assets/avatars/avatar_2.svg";
import avatar_three from "assets/avatars/anonymous-01.webp";
import avatar_four from "assets/avatars/anonymous-02.webp";

type AreaPictureType = {
  avatar: string | null;
  setAvatar: (value: string) => void;
};

export const AreaPicture = ({ avatar, setAvatar }: AreaPictureType) => {
  return (
    <Select
      data={[
        {
          image: avatar_one,
          label: "Bender Bending Rodríguez",
          value: avatar_one,
          description: "Fascinated with cooking",
        },
        {
          image: avatar_two,
          label: "Carol Miller",
          value: avatar_two,
          description: "One of the richest people on Earth",
        },
        {
          image: avatar_three,
          label: "Anonymous Male",
          value: avatar_three,
          description: "A blank male face",
        },
        {
          image: avatar_four,
          label: "Anonymous Female",
          value: avatar_four,
          description: "A blank female face",
        },
      ]}
      itemComponent={SelectItem}
      value={avatar}
      onChange={setAvatar}
      icon={avatar && <img src={avatar} className="h-6 w-6" />}
      label="Avatar"
      description="Set an avatar for your area"
      placeholder="Select an area avatar"
    />
  );
};

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  image: string;
  label: string;
  description: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ image, label, description, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <div className="flex flex-nowrap space-x-4">
        <img src={image} className="h-10 w-10" />

        <div>
          <p>{label}</p>
          <p>{description}</p>
        </div>
      </div>
    </div>
  )
);
