import { useState, useCallback, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { TextInput, PasswordInput, LoadingOverlay, Button } from "@mantine/core";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import z from "zod";

import logo from "assets/supported_logo.png";
import { useAuth } from "stores/Auth";
import { supabase } from "lib/supabase";

export const Login = () => {
  const status = useAuth((state) => state.status);
  const supabaseSignIn = useAuth((state) => state.supabaseSignIn);
  const loading = useAuth((state) => state.loading);

  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as { from: Location };

  // useEffect(() => {
  //   if (status === "signedIn") {
  //     if (state !== null) {
  //       console.log("pathname", state?.from?.pathname);
  //       navigate(state.from.pathname);
  //     } else {
  //       navigate("/");
  //     }
  //   }
  // }, [status]);

  const validationSchema = z.object({
    email: z.string().nonempty({ message: "Email is required" }),
    password: z.string().nonempty({ message: "Password is required" }),
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<any>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const supabaseLogin = async (data: any) => {
    console.log("supabaseLogin", data);
    try {
      await supabaseSignIn(data.email, data.password, setError);
      navigate("/");
    } catch (error: any) {
      console.log("error: ", error, error.message);
      setError("validationError", {
        type: "custom",
        message: error.message as string,
      });
    }
  };

  function ErrorContainer() {
    return (
      <div className="rounded-md border border-red-500 bg-red-100 p-4 text-center font-medium text-red-500">
        <p>{errors?.validationError?.message as string}</p>
      </div>
    );
  }

  return (
    <div className="flex h-screen w-screen">
      <div className="flex flex-1 items-center justify-center">
        <div className="max-w-sm space-y-4">
          <form
            className="relative flex flex-col bg-white 2xs:h-full 2xs:w-full 2xs:justify-center 2xs:p-6 xs:h-5/6 xs:w-10/12 sm:h-auto sm:w-auto sm:max-w-[512px] sm:px-8 sm:py-6"
            onSubmit={handleSubmit(supabaseLogin)}
          >
            <LoadingOverlay visible={loading} />

            <div className="flex flex-col items-center space-y-10">
              <img src={logo} alt="Supported Logo" className="h-24" />

              <div className="flex flex-col space-y-3 2xs:w-full sm:w-80">
                {errors?.validationError && <ErrorContainer />}

                <Controller
                  control={control}
                  name={`email`}
                  render={({ field }: any) => (
                    <TextInput
                      label="Email address"
                      placeholder="Your email"
                      error={errors.email?.message as string}
                      {...field}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name={`password`}
                  render={({ field }: any) => (
                    <PasswordInput
                      label="Password"
                      placeholder="Your password"
                      error={errors.password?.message as string}
                      {...field}
                    />
                  )}
                />
              </div>

              <div className="flex w-full items-center justify-between 2xs:flex-col 2xs:items-center 2xs:space-y-4 sm:flex-row sm:space-y-0">
                <Link
                  className="font-medium text-jira-button transition-all duration-200 ease-in-out hover:text-jira-button-hover"
                  to="/auth/forgot"
                >
                  Forgot Password?
                </Link>
                <Button className="bg-jira-button hover:bg-jira-button-hover" type="submit">
                  Sign In
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
