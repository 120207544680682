import { AddNewShiftButton } from "./AddNewShiftButton";
import { ShiftCell } from "./ShiftCell";

export const ShiftRow = ({ date, rate, shifts, openCreateShift, selectDate }: any) => {
  if (!shifts || shifts.length <= 0) {
    return (
      <div className="min-h-10 relative grid w-full grid-cols-9 overflow-hidden border-b border-neutral-300">
        <div className="grid-col-1 border-r border-neutral-300">
          <div className="p-1">
            <p className="text-sm font-semibold text-neutral-800">
              {date.toLocaleString({
                weekday: "short",
                month: "2-digit",
                day: "2-digit",
              })}
            </p>
          </div>
        </div>
        <AddNewShiftButton
          onClick={() => {
            openCreateShift();
            selectDate(date);
          }}
        />
      </div>
    );
  }

  return (
    <div className="grid w-full grid-cols-9 overflow-hidden border-b border-neutral-300">
      <div className="col-span-1 border-r border-neutral-300">
        <div className="p-1">
          <p className="text-sm font-semibold text-neutral-800">
            {date.toLocaleString({
              weekday: "short",
              month: "2-digit",
              day: "2-digit",
            })}
          </p>
        </div>
      </div>
      <div className="col-span-8 flex flex-col">
        {shifts.map((shift: any) => (
          <ShiftCell shift={shift} rate={rate} />
        ))}
        <AddNewShiftButton
          onClick={() => {
            openCreateShift();
            selectDate(date);
          }}
        />
      </div>
    </div>
  );
};
