import { Navigate, Route, Routes } from "react-router-dom";

import { Users } from "./Users";
import { User } from "./User";
import { Profile } from "./Profile";

export const UsersRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Users />} />
      <Route path=":userId/*" element={<User />} />
    </Routes>
  );
};
