import { useState } from 'react'
import { LoadingOverlay, Skeleton, Drawer, Button, Affix } from '@mantine/core'
import { Cog8ToothIcon } from '@heroicons/react/24/outline'
import { DocumentIcon, CalendarDaysIcon, FolderIcon } from '@heroicons/react/24/solid'
import { useMediaQuery } from '@mantine/hooks'
import { Link, NavLink } from 'react-router-dom'

import { AreaBadge } from 'components/AreaBadge/AreaBadge'
import { ReactComponent as MedicationIcon } from 'assets/healthIcons/medicines.svg'
import { ADMIN } from 'config'
import { useMe } from 'features/users/api/getMe'
import { SidebarNavLink } from './SidebarNavLink'

export const AreaLayout = ({ children, area, loading }: any) => {
  const me = useMe()

  const isMobile = useMediaQuery('(max-width: 640px)')

  const sidebarMenu = [
    {
      name: 'Settings',
      icon: <Cog8ToothIcon className="h-6 w-6" />,
      link: `/areas/${area?.id}/settings/general`,
      admin: true,
    },
  ]

  const sidebarIcons: any = {
    medication: <MedicationIcon className="h-6 w-6" />,
    recordings: <CalendarDaysIcon className="h-6 w-6" />,
    forms: <DocumentIcon className="h-6 w-6" />,
    files: <FolderIcon className="h-6 w-6" />,
  }

  const [open, setOpen] = useState(false)

  if (loading) {
    return (
      <div className="flex h-full min-h-0 w-full">
        <div className="flex flex-col border-r border-gray-300">
          <div className="p-2">
            <div className="border-b border-stone-200 px-2 py-3 shadow-sm">
              <div className="flex items-center space-x-2">
                <Skeleton height={40} width={40} circle />
                <Skeleton height={15} width="60%" />
              </div>
            </div>
          </div>

          <div className="w-[240px] p-2">
            <div className="flex w-full min-w-[180px] flex-col px-2 py-2">
              <div className="flex items-center space-x-3">
                <Skeleton height={24} width={24} />
                <Skeleton height={12} width="60%" />
              </div>
            </div>
            <div className="flex w-full min-w-[180px] flex-col px-2 py-2">
              <div className="flex items-center space-x-3">
                <Skeleton height={24} width={24} />
                <Skeleton height={12} width="60%" />
              </div>
            </div>
            <div className="flex w-full min-w-[180px] flex-col px-2 py-2">
              <div className="flex items-center space-x-3">
                <Skeleton height={24} width={24} />
                <Skeleton height={12} width="60%" />
              </div>
            </div>
            <div className="flex w-full min-w-[180px] flex-col px-2 py-2">
              <div className="flex items-center space-x-3">
                <Skeleton height={24} width={24} />
                <Skeleton height={12} width="60%" />
              </div>
            </div>
            <div className="flex w-full min-w-[180px] flex-col px-2 py-2">
              <div className="flex items-center space-x-3">
                <Skeleton height={24} width={24} />
                <Skeleton height={12} width="60%" />
              </div>
            </div>
          </div>
        </div>
        <div className="relative flex h-full w-full items-center justify-center bg-stone-100">
          <LoadingOverlay visible={true} overlayOpacity={0.5} />
        </div>
      </div>
    )
  }

  if (!area) {
    return (
      <div className="flex h-full min-h-0 w-full">
        <div className="flex flex-col border-r border-gray-300">
          <div className="p-2">
            <div className="border-b border-stone-200 px-2 py-3 shadow-sm">
              <div className="flex items-center space-x-2">
                <Skeleton height={40} width={40} circle />
                <Skeleton height={15} width="60%" />
              </div>
            </div>
          </div>

          <div className="w-[240px] p-2">
            <div className="flex w-full min-w-[180px] flex-col px-2 py-2">
              <div className="flex items-center space-x-3">
                <Skeleton height={24} width={24} />
                <Skeleton height={12} width="60%" />
              </div>
            </div>
            <div className="flex w-full min-w-[180px] flex-col px-2 py-2">
              <div className="flex items-center space-x-3">
                <Skeleton height={24} width={24} />
                <Skeleton height={12} width="60%" />
              </div>
            </div>
            <div className="flex w-full min-w-[180px] flex-col px-2 py-2">
              <div className="flex items-center space-x-3">
                <Skeleton height={24} width={24} />
                <Skeleton height={12} width="60%" />
              </div>
            </div>
            <div className="flex w-full min-w-[180px] flex-col px-2 py-2">
              <div className="flex items-center space-x-3">
                <Skeleton height={24} width={24} />
                <Skeleton height={12} width="60%" />
              </div>
            </div>
            <div className="flex w-full min-w-[180px] flex-col px-2 py-2">
              <div className="flex items-center space-x-3">
                <Skeleton height={24} width={24} />
                <Skeleton height={12} width="60%" />
              </div>
            </div>
          </div>
        </div>
        <div className="relative flex h-full w-full flex-col items-center justify-center bg-stone-100">
          <p>You do not have access to this area</p>
          <Link to="/home" className="font-semibold text-jira-button hover:text-jira-button-hover">
            Go back
          </Link>
        </div>
      </div>
    )
  }

  if (!area.sections) {
    return (
      <div className="flex h-full min-h-0 w-full">
        <div className="flex flex-col border-r border-[#dfe1e6] bg-[#f4f5f7]">
          <AreaBadge image={area?.avatar} name={area?.name} />
          <div className="w-[240px] p-2">
            {sidebarMenu.map((item) => {
              if (item.admin === false) {
                return (
                  <NavLink
                    key={item.link}
                    to={`${item.link}`}
                    className="duration-250 flex w-full min-w-[180px] flex-col rounded-md px-2 py-2 text-sm font-medium text-gray-900 transition-all ease-in-out hover:bg-[#ebecf0]"
                  >
                    <div className="flex items-center space-x-3">
                      {item.icon}
                      <span className="text-[14px]"> {item.name}</span>
                    </div>
                  </NavLink>
                )
              } else if (me?.data?.level === 'admin') {
                return (
                  <NavLink
                    key={item.link}
                    to={`${item.link}`}
                    className="duration-250 flex w-full min-w-[180px] flex-col rounded-md px-2 py-2 text-sm font-medium text-gray-900 transition-all ease-in-out hover:bg-[#ebecf0]"
                  >
                    <div className="flex items-center space-x-3">
                      {item.icon}
                      <span className="text-[14px]"> {item.name}</span>
                    </div>
                  </NavLink>
                )
              }
            })}
          </div>
        </div>
        <div className="relative flex h-full w-full items-center justify-center bg-white">
          <div className="flex h-full w-full flex-col overflow-y-auto">{children}</div>
        </div>
      </div>
    )
  }

  if (isMobile) {
    return (
      <>
        <Affix position={{ top: 4, left: 60 }}>
          <Button
            onClick={() => setOpen(!open)}
            variant="subtle"
            className="bg-[#3b82f6] text-white hover:bg-[#3b82f6]"
          >
            <p>Menu</p>
          </Button>
        </Affix>

        <Drawer
          opened={open}
          onClose={() => setOpen(false)}
          className="mt-[45px]"
          overlayOpacity={0.4}
          withCloseButton={false}
          size="sm"
        >
          <div className="flex h-full min-h-0 w-full">
            <div className="flex flex-col border-r border-gray-300">
              <AreaBadge image={area?.avatar} name={area?.name} />
              <div className="w-[240px] p-2">
                {area.sections.map((section: any) => {
                  if (section.selected === true) {
                    return (
                      <NavLink
                        key={section.uri}
                        to={`${section.uri}`}
                        className="duration-250 flex w-full min-w-[180px] flex-col rounded-md fill-[#0052cc] px-2 py-2 text-sm font-medium text-zinc-400 transition-all ease-in-out hover:bg-gray-100"
                        onClick={() => setOpen(false)}
                      >
                        <div className="flex items-center space-x-3">
                          {sidebarIcons[`${section.icon}`]}
                          <span> {section.name}</span>
                        </div>
                      </NavLink>
                    )
                  } else {
                    return null
                  }
                })}
                {sidebarMenu.map((item) => {
                  if (item.admin === false) {
                    return (
                      <NavLink
                        key={item.link}
                        to={`${item.link}`}
                        className="duration-250 flex w-full min-w-[180px] flex-col rounded-md fill-[#0052cc] px-2 py-2 text-sm font-medium text-zinc-400 transition-all ease-in-out hover:bg-gray-100"
                        onClick={() => setOpen(false)}
                      >
                        <div className="flex items-center space-x-3">
                          {item.icon}
                          <span> {item.name}</span>
                        </div>
                      </NavLink>
                    )
                  } else if (ADMIN) {
                    return (
                      <NavLink
                        key={item.link}
                        to={`${item.link}`}
                        className="duration-250 flex w-full min-w-[180px] flex-col rounded-md fill-[#0052cc] px-2 py-2 text-sm font-medium text-zinc-400 transition-all ease-in-out hover:bg-gray-100"
                        onClick={() => setOpen(false)}
                      >
                        <div className="flex items-center space-x-3">
                          {item.icon}
                          <span> {item.name}</span>
                        </div>
                      </NavLink>
                    )
                  }
                })}
              </div>
            </div>
          </div>
          ;
        </Drawer>
        <div className="flex h-[calc(100%-45px)] w-full items-center justify-center bg-stone-100">
          <div className="flex h-full w-full flex-col overflow-y-auto">{children}</div>
        </div>
      </>
    )
  }

  return (
    <div className="flex h-full min-h-0 w-full">
      <div className="flex flex-col border-r border-neutral-200 bg-neutral-50">
        <AreaBadge image={area?.avatar_url} name={area?.name} />
        <div className="w-[240px] p-2">
          {area.sections.map((section: any) => {
            if (section.selected === true) {
              return <SidebarNavLink link={section.uri} icon={section.icon} name={section.name} isSection />
            } else {
              return null
            }
          })}
          {sidebarMenu.map((item) => {
            if (item.admin === false) {
              return <SidebarNavLink link={item.link} icon={item.icon} name={item.name} />
            } else if (me?.data?.user_role.role === 'admin') {
              return <SidebarNavLink link={item.link} icon={item.icon} name={item.name} />
            }
          })}
        </div>
      </div>
      <div className="relative flex h-full w-full items-center justify-center overflow-hidden bg-white">
        <div className="flex h-full w-full flex-col overflow-auto">{children}</div>
      </div>
    </div>
  )
}
