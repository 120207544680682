import { DateTime } from "luxon";

export const ShiftHours = ({ shift }: any) => {
  const start = DateTime.fromSeconds(Number(shift.start_time), {
    zone: "Europe/London",
  });
  const end = DateTime.fromSeconds(Number(shift.end_time), {
    zone: "Europe/London",
  });
  let shiftDuration: any = end.diff(start, ["hours", "minutes"]).toObject();

  if (shift.sleep) {
    shiftDuration = {
      hours: shiftDuration.hours - 9,
      minutes: shiftDuration.minutes,
    };
  }

  return (
    <div className="col-span-1">
      <div className="flex space-x-1 p-1">
        <div className="flex">
          <p>{shiftDuration.hours}</p>
          <p>{shiftDuration.minutes && shiftDuration.minutes > 0 ? `:${shiftDuration.minutes}` : ":00"}</p>
        </div>
        <p> hrs</p>
      </div>
    </div>
  );
};
