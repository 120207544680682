import { useQuery } from "react-query";
import { AxiosError } from "axios";

import { axios } from "lib/axios";
import { useAuth } from "stores/Auth";
import { TCustomForm } from "../types";

export const getForms = async ({
  areaId,
  offset,
}: any): Promise<{ total: number; forms: TCustomForm[] }> => {
  try {
    const response = await axios.get(`/forms/${areaId}?offset=${offset}`);
    if (response.status === 200) {
      console.log("response 200", response.status);
    }
    // Don't forget to return something
    return response.data;
  } catch (err) {
    console.log("error: ", err);
    if (err instanceof AxiosError) {
      if (err.response?.status === 401) {
        throw new Error("Not authorised");
      } else if (err.response?.status === 403) {
        throw new Error("Not admin");
      }
    }
    throw new Error("There was an error fetching the request");
  }
};

export const useForms = ({ areaId, offset }: any) => {
  const currentUser = useAuth((state) => state.currentUser);
  return useQuery({
    queryKey: ["forms", offset],
    keepPreviousData: true,
    queryFn: () => getForms({ areaId, offset }),
    enabled: !!currentUser?.id,
  });
};
