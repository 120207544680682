import { NavLink, Route, Routes } from 'react-router-dom'
import { Medications } from './Medications'
import { MedicationRecords } from './MedicationRecords'

export const MedicationDashboard = () => {
  return (
    <div className="flex flex-1 flex-col">
      <div className="sticky top-0 z-20 flex flex-col border-b border-neutral-200 bg-neutral-50">
        <div className=" px-6">
          <div className="flex gap-6">
            <NavLink
              to={``}
              end
              className={({ isActive }: any) =>
                isActive
                  ? 'flex cursor-pointer items-center space-x-2 border-b-2 border-jira-button py-2 text-sm font-medium text-neutral-900 transition hover:opacity-100'
                  : 'flex cursor-pointer items-center space-x-2 py-2 text-sm font-medium text-neutral-900 opacity-50 transition hover:opacity-100'
              }
            >
              <p>Medications</p>
            </NavLink>
            <NavLink
              to={`records`}
              className={({ isActive }: any) =>
                isActive
                  ? 'flex cursor-pointer items-center space-x-2 border-b-2 border-jira-button py-2 text-sm font-medium text-neutral-900 transition hover:opacity-100'
                  : 'flex cursor-pointer items-center space-x-2 py-2 text-sm font-medium text-neutral-900 opacity-50 transition hover:opacity-100'
              }
            >
              <p>Records</p>
            </NavLink>
          </div>
        </div>
      </div>

      <div>
        <Routes>
          <Route path="" element={<Medications />} />
          <Route path="records/*" element={<MedicationRecords />} />
        </Routes>
      </div>
    </div>
  )
}
