import { roleColors } from "lib/rotacloudColors";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

export const ColorPicker = ({
  label,
  colour,
  setColour,
  error,
}: {
  label?: string;
  colour: string;
  setColour: any;
  error?: string | null | undefined;
}) => {
  const colors = Object.keys(roleColors);

  return (
    <div className="flex flex-col items-start justify-start space-y-2">
      <p className="text-sm font-semibold text-gray-500">{label}</p>
      {error && <p className="text-xs font-semibold text-[#f03e3e]">{error}</p>}

      <div className="flex items-center justify-center">
        <div className="grid grid-cols-6 gap-3">
          {colors.map((_color) => (
            <div
              className="flex h-7 w-7 items-center justify-center rounded-full"
              onClick={() => setColour(_color)}
              style={{ backgroundColor: `#${_color}` }}
            >
              <CheckCircleIcon
                className={`h-6 w-6 text-white transition-all duration-200 ease-in ${
                  colour === _color
                    ? "scale-100 opacity-100"
                    : "scale-0 opacity-0"
                }`}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
