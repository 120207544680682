import React from "react";

export const ScaleRating = ({
  label,
  required,
  value,
  onChange,
  minValue,
  maxValue,
}: any) => {
  const rows = [];
  for (let i = minValue; i <= maxValue; i++) {
    rows.push(
      <div
        onClick={() => onChange(i)}
        className={`${
          value === i ? "bg-jira-button text-white" : "bg-gray-100"
        } inline-flex cursor-pointer items-center justify-center rounded-full p-1 transition-all duration-200 ease-linear`}
      >
        <div className="flex h-6 w-6 items-center justify-center text-center">
          <p>{i}</p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <p className="mb-1 text-sm font-semibold text-gray-500">{label}</p>

      <div className="flex flex-wrap justify-start gap-2">
        {rows.map((row) => row)}
      </div>
    </div>
  );
};
