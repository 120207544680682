import { useMutation } from "react-query";
import { queryClient } from "lib/react-query";

import { axios } from "lib/axios";
import { useNotification } from "hooks/useNotification";

export type CreateMedicationType = {
  data: {
    typeId?: any;
    name: string;
    type: string;
    dose: string;
    administered_dose: string;
    used_for: string;
    side_effects: string;
    route: string;
    authorisation: string;
    frequency: any;
    end_date: any;
    start_date: number;
    medication_markers: any;
    area_id: any;
    areaId?: any;
  };
};

export const createMedication = ({ data }: CreateMedicationType) => {
  console.log("createMedication", data);

  return axios.post(`/medications/${data.areaId}`, data);
};

export const useCreateMedication = (successFunction: Function) => {
  const { addNotification } = useNotification();

  return useMutation({
    onError: (_, __, context) => {
      addNotification({
        disallowClose: true,
        type: "error",
        title: "Medicine Not Create",
        message: "Could not create medicine!",
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("medications");
      successFunction();
      addNotification({
        disallowClose: true,
        type: "success",
        title: "Medicine Created",
        message: "Medicine Created Successfully!",
      });
    },
    mutationFn: createMedication,
  });
};
