import { useState, useEffect } from "react";
import { LoadingOverlay, Modal } from "@mantine/core";
import { Route, Routes, NavLink, useNavigate, Link } from "react-router-dom";
import { Button, Menu } from "@mantine/core";
import { ArrowSmallRightIcon, BookmarkIcon } from "@heroicons/react/20/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faReceipt,
  faCarSide,
  faLock,
} from "@fortawesome/free-solid-svg-icons";

import { useClaims } from "../api/getClaims";
import { TClaim } from "features/admin/payroll/claims/types";
import { supabase } from "lib/supabase";
import { CreateClaim } from "./CreateClaim";
import { useDeleteClaim } from "../api/deleteClaim";
import { ExpenseInformation } from "../components/ExpenseInformation";
import { getDate } from "../utils";
import { MileageInformation } from "../components/MileageInformation";
import { useCreateExpenses } from "../api/createExpenses";
import { useAuth } from "stores/Auth";

export const Claims = ({ dateDiff }: { dateDiff: any }) => {
  const claimsQuery = useClaims({ date: dateDiff });
  const createExpensesMutation = useCreateExpenses(() =>
    console.log("submitting claims")
  );

  const currentUser = useAuth((state) => state.currentUser);

  const handleSubmit = () => {
    createExpensesMutation.mutateAsync({
      data: claimsQuery.data,
      userId: currentUser.id,
      date: dateDiff,
    });
  };

  useEffect(() => {
    navigate(".");
    setSelectedClaim(null);
  }, [dateDiff]);

  const [selectedClaim, setSelectedClaim] = useState<TClaim | null>(null);

  console.log("claimsQuery", claimsQuery.data);

  const [receiptUrl, setReceiptUrl] = useState<string | null>(null);

  const getReceipt = async () => {
    if (selectedClaim?.receipt_url) {
      const { data, error } = await supabase.storage
        .from("receipts")
        .download(selectedClaim?.receipt_url as string);

      console.log("receipt data: ", data);

      if (data) {
        const url = webkitURL.createObjectURL(data);
        setReceiptUrl(url);
      }
    } else {
      setReceiptUrl(null);
    }
  };

  useEffect(() => {
    getReceipt();
  }, [selectedClaim]);

  const navigate = useNavigate();

  const deleteClaimMutation = useDeleteClaim(() => {
    setSelectedClaim(null);
    navigate(".");
  });

  if (claimsQuery.isLoading) {
    return null;
  }

  if (claimsQuery.isError) {
    return null;
  }

  return (
    <div className="flex flex-1 overflow-y-auto bg-zinc-50">
      <div className="flex flex-1 p-4">
        <div className="flex flex-1 flex-col rounded border border-gray-200">
          <div className="flex h-[38px] w-full items-center justify-between rounded-t-md border-b bg-white px-2">
            <p className="text-sm font-semibold text-gray-900">Claims</p>
            <div className="flex space-x-1">
              <SubmitClaimModal handleSubmit={handleSubmit} />
              <NewClaimButton />
            </div>
          </div>
          <div className="flex flex-1">
            <div className="relative flex w-60 flex-col border-r border-gray-200">
              <LoadingOverlay
                visible={claimsQuery.isLoading || claimsQuery.isFetching}
              />
              {claimsQuery.data &&
                claimsQuery.data.map((claim: any) => (
                  <ClaimCard
                    claim={claim}
                    selectedClaim={selectedClaim}
                    setSelectedClaim={setSelectedClaim}
                  />
                ))}
            </div>
            <Routes>
              <Route
                path=""
                element={
                  <div className="flex flex-1">
                    <div className="flex flex-1 items-center justify-center p-4">
                      <p>
                        Select a claim to see it's details, or click 'New claim'
                        to get started making a claim
                      </p>
                    </div>
                  </div>
                }
              />
              <Route
                path="/:claimId"
                element={
                  <div className="flex flex-1">
                    <ClaimInformation
                      selectedClaim={selectedClaim}
                      deleteClaim={deleteClaimMutation}
                    />
                    <div className="flex w-1/2 flex-col border-r border-gray-200 bg-white p-4">
                      {selectedClaim?.receipt_url ? (
                        <div className="flex h-3/4 border border-gray-200">
                          <div
                            className="flex h-full w-full items-center justify-center bg-contain bg-center bg-no-repeat"
                            style={{ backgroundImage: `url(${receiptUrl})` }}
                          ></div>
                        </div>
                      ) : (
                        <div className="flex border border-gray-200 p-6">
                          <p className="font-medium text-gray-500">
                            No receipts are attached to this expense
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                }
              />
              <Route path="/add/*" element={<CreateClaim />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

const ClaimCard = ({ claim, selectedClaim, setSelectedClaim }: any) => {
  return (
    <NavLink
      to={`${claim.id}`}
      onClick={() => setSelectedClaim(claim)}
      className={({ isActive }) =>
        isActive
          ? "flex h-14 w-full cursor-pointer flex-col border-b border-gray-200  bg-jira-button bg-opacity-10 p-2 transition-all duration-200 hover:bg-white"
          : "flex h-14 w-full cursor-pointer flex-col border-b border-gray-200 bg-zinc-50 p-2 transition-all duration-200 hover:bg-white"
      }
    >
      <div className="flex flex-1 justify-between space-x-2">
        {claim.type === "Mileage" && (
          <div className="flex items-center">
            <p className="text-base font-medium text-gray-900">
              {claim?.mileage_legs[0].from}
            </p>
            <span>
              <ArrowSmallRightIcon className="h-5 w-5 text-gray-500" />
            </span>
            <p className="text-base font-medium text-gray-900">
              {claim?.mileage_legs?.at(-1).to}
            </p>
          </div>
        )}
        <div className="flex w-full items-center space-x-1 overflow-hidden">
          {claim.expense_id && (
            <FontAwesomeIcon
              icon={faLock}
              className="h-3 w-3 text-jira-button transition-all duration-150 ease-in-out group-hover:text-gray-900"
            />
          )}
          <p className="truncate text-base font-medium text-gray-900">
            {claim.supplier}
          </p>
        </div>
        <p className="text-base font-medium text-gray-900">£{claim.amount}</p>
      </div>
      <div className="flex justify-between">
        <p className="text-sm font-medium text-gray-500">{getDate(claim)}</p>
        {claim.type === "Mileage" ? (
          <p className="text-sm font-medium text-gray-500">{claim.type}</p>
        ) : (
          <p className="text-sm font-medium text-gray-500">{claim.category}</p>
        )}
      </div>
    </NavLink>
  );
};

const ClaimInformation = ({
  selectedClaim,
  deleteClaim,
}: {
  selectedClaim: TClaim | null;
  deleteClaim: any;
}) => {
  return selectedClaim?.type === "Mileage" ? (
    <MileageInformation
      selectedClaim={selectedClaim}
      deleteClaim={deleteClaim}
    />
  ) : (
    <ExpenseInformation
      selectedClaim={selectedClaim}
      deleteClaim={deleteClaim}
    />
  );
};

const NewClaimButton = () => {
  return (
    <Menu
      gutter={3}
      control={
        <Button
          size="xs"
          compact
          className="rounded bg-jira-button py-1 px-2 hover:bg-jira-button-hover"
        >
          New claim
        </Button>
      }
    >
      <Menu.Item
        className="group transition-all duration-150 ease-in-out hover:bg-[#f4f5f7]"
        component={Link}
        to={`/pay/claims/add/expense`}
      >
        <div className="flex w-full items-center space-x-2 px-1">
          <FontAwesomeIcon
            icon={faReceipt}
            className="h-3.5 w-3.5 text-[#5e6c84] transition-all duration-150 ease-in-out group-hover:text-gray-900"
          />
          <p className="text-[12px] font-semibold text-[#5e6c84] transition-all duration-150 ease-in-out group-hover:text-gray-900">
            Add expense
          </p>
        </div>
      </Menu.Item>
      <Menu.Item
        className="group transition-all duration-150 ease-in-out hover:bg-[#f4f5f7]"
        component={Link}
        to={`/pay/claims/add/mileage`}
      >
        <div className="flex w-full items-center space-x-2 px-1">
          <FontAwesomeIcon
            icon={faCarSide}
            className="h-3.5 w-3.5 text-[#5e6c84] transition-all duration-150 ease-in-out group-hover:text-gray-900"
          />
          <p className="text-[12px] font-semibold text-[#5e6c84] transition-all duration-150 ease-in-out group-hover:text-gray-900">
            Add mileage
          </p>
        </div>
      </Menu.Item>
    </Menu>
  );
};

const SubmitClaimModal = ({ handleSubmit }: any) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        variant="outline"
        size="xs"
        className="text-xs font-medium shadow"
        leftIcon={<BookmarkIcon className="h-3.5 w-3.5 text-gray-400" />}
        onClick={() => setOpen(true)}
      >
        Submit
      </Button>

      <Modal
        opened={open}
        onClose={() => setOpen(false)}
        centered={true}
        withCloseButton={false}
      >
        <p className="text-[16px] font-medium text-[#11181c]">
          Submit claims for processing
        </p>
        <div className="py-3">
          <p className="text-[#687076]">
            You can click below to submit your payroll claims, once you do,
            these are locked and cannot be edited
          </p>
        </div>
        <Button
          size="xs"
          className="w-full rounded bg-jira-button py-1 px-2 hover:bg-jira-button-hover"
          onClick={() => handleSubmit()}
        >
          Submit
        </Button>
      </Modal>
    </>
  );
};
