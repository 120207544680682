import { Drawer, Button, ActionIcon, LoadingOverlay } from "@mantine/core";
import { useState } from "react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

import { useUserStore } from "stores/userStore";
import { useEnableUsers } from "../api/enableUsers";

export const EnableUsers = () => {
  const [open, setOpen] = useState(false);

  const closeModal = () => {
    setOpen(false);
  };

  const enableUsersMutation = useEnableUsers(closeModal);

  const enableCheckedList = useUserStore((state) => state.enableCheckedList);

  return (
    <>
      <div className="flex space-x-2">
        <Button
          className={`inline-flex h-[32px] items-center rounded bg-[#f4f5f7] px-3 text-[#42526e] transition-all duration-100 ease-in-out hover:bg-[#ebecf0]`}
          onClick={() => {
            enableCheckedList.length > 0 && setOpen(true);
          }}
          disabled={enableCheckedList.length <= 0}
          classNames={{
            label: "text-[15px] font-semibold",
          }}
        >
          Enabled user
        </Button>
      </div>

      <Drawer
        opened={open}
        onClose={() => setOpen(false)}
        position="right"
        withCloseButton={false}
        overlayOpacity={0.25}
        className=" top-14"
        noFocusTrap
        size="25%"
      >
        <div className="flex min-h-full max-w-full flex-col justify-between space-y-2 py-6 px-10">
          <div className="flex flex-grow flex-col justify-start">
            {enableCheckedList.length > 1 ? (
              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                  console.log("submitting form", enableCheckedList);
                  await enableUsersMutation.mutateAsync({
                    data: enableCheckedList,
                  });
                  console.log("hello");
                  console.log(
                    "mutation values",
                    await enableUsersMutation.data
                  );
                }}
                className="flex flex-grow flex-col justify-between"
              >
                <LoadingOverlay visible={enableUsersMutation.isLoading} />
                <div className="space-y-4">
                  <p className="text-xl font-semibold">Enable Users?</p>
                  <p>{enableCheckedList.length} users selected</p>
                  <p>
                    This will enable the users and allow them to access their
                    account. This process can be reversed by disabling their
                    account from the Users section.
                  </p>
                </div>
                <div className="mt-6 space-x-6">
                  <Button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-600"
                  >
                    Enable Users
                  </Button>
                  <Button
                    type="reset"
                    className="bg-gray-50 text-slate-600 hover:bg-gray-100"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            ) : (
              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                  console.log("submitting form", enableCheckedList);
                  await enableUsersMutation.mutateAsync({
                    data: enableCheckedList,
                  });
                  console.log("hello");
                  console.log(
                    "mutation values",
                    await enableUsersMutation.data
                  );
                }}
                className="flex flex-grow flex-col justify-between"
              >
                <LoadingOverlay visible={enableUsersMutation.isLoading} />
                <div className="space-y-4">
                  <p className="text-xl font-semibold">Enable User?</p>
                  <p>
                    This will enable the selected user and allow them to access
                    their account. This process can be reversed by disabling
                    their account from the Users section.
                  </p>
                </div>
                <div className="mt-6 space-x-6">
                  <Button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-600"
                  >
                    Enable User
                  </Button>
                  <Button
                    type="reset"
                    className="bg-gray-50 text-slate-600 hover:bg-gray-100"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            )}
          </div>
        </div>
      </Drawer>
    </>
  );
};
