import { useRoutes, Navigate, useLocation } from "react-router-dom";

import { publicRoutes } from "./public";
import { useAuth } from "stores/Auth";
import { protectedRoutes } from "./protected";
import { useMe } from "features/users/api/getMe";

export const AppRoutes = () => {
  const status = useAuth((state) => state.status);
  const location = useLocation();
  const meQuery = useMe();

  const redirectToLogin = [
    {
      path: "/*",
      element: <Navigate to="/auth/login" replace state={{ from: location }} />,
    },
  ];

  const verifyAccount = [
    {
      path: "/*",
      element: <Navigate to="/auth/invite" replace state={{ from: location }} />,
    },
  ];

  console.log("APPROUTES", meQuery.data?.verified);

  const routes =
    status !== "signedOut" ? (meQuery.data?.verified !== false ? protectedRoutes : verifyAccount) : redirectToLogin;

  const element = useRoutes([...routes, ...publicRoutes]);

  return <>{element}</>;
};
