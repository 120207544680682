import { useNotifications } from "@mantine/notifications";

const colors = {
  info: "blue",
  success: "green",
  warning: "yellow",
  error: "red",
};

export type NotificationProps = {
  disallowClose: boolean;
  type: keyof typeof colors;
  title: string;
  message?: string;
  closeTime?: number;
};

export const useNotification = () => {
  const notifications = useNotifications();

  const addNotification = ({ disallowClose, type, title, message, closeTime }: NotificationProps) => {
    notifications.showNotification({
      disallowClose: disallowClose,
      color: colors[type],
      title: title,
      message: message,
      autoClose: closeTime || 5000,
    });
  };

  return { addNotification };
};
