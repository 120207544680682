import { memo } from "react";

import { useRecordStore } from "../store/recordStore";

const fullyTaken = <div className="h-[30px] w-[30px] rounded-full border-2 border-[#309A30] bg-[#D2F9D2]"></div>;
const partiallyTaken = (
  <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full border-2 border-[#98671D] bg-[#FFE0A4]">
    <p className="text-sm font-semibold text-[#98671D]">p/t</p>
  </div>
);
const actualRefusal = (
  <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full border-2 border-[#AF251C] bg-[#FABCBB]">
    <p className="text-sm font-semibold text-[#AF251C]">a/r</p>
  </div>
);
const passiveRefusal = (
  <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full border-2 border-[#AF251C] bg-[#FABCBB]">
    <p className="text-sm font-semibold text-[#AF251C]">p/r</p>
  </div>
);
const notObserved = (
  <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full border-2 border-[#AF251C] bg-[#FABCBB]">
    <p className="text-sm font-semibold text-[#AF251C]">n/o</p>
  </div>
);
const destroyedMedication = (
  <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full border-2 border-[#AF251C] bg-[#FABCBB]">
    <p className="text-sm font-semibold text-[#AF251C]">d/m</p>
  </div>
);
const missedMedication = (
  <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full border-2 border-[#AF251C] bg-[#FABCBB]">
    <p className="text-sm font-semibold text-[#AF251C]">m/m</p>
  </div>
);
const administeredByHospital = (
  <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full border-2 border-[#5B5B5B] bg-[#F3F3F3]">
    <p className="text-sm font-semibold text-[#5B5B5B]">a/h</p>
  </div>
);
const administeredByFamily = (
  <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full border-2 border-[#5B5B5B] bg-[#F3F3F3]">
    <p className="text-sm font-semibold text-[#5B5B5B]">a/f</p>
  </div>
);
const selfAdministered = (
  <div className="flex h-[30px] w-[30px] items-center justify-center rounded-full border-2 border-[#5B5B5B] bg-[#F3F3F3]">
    <p className="text-sm font-semibold text-[#5B5B5B]">s/a</p>
  </div>
);

const otherOptionsList: any = {
  "Fully taken": fullyTaken,
  "Partially taken": partiallyTaken,
  "Actual refusal": actualRefusal,
  "Passive refusal": passiveRefusal,
  "Not observed": notObserved,
  "Destroyed medication": destroyedMedication,
  "Missed medication": missedMedication,
  "Administered by hospital": administeredByHospital,
  "Administered by family": administeredByFamily,
  "Self-administered": selfAdministered,
};

type TRecordRow = {
  days: any;
  rows: any;
  medication: any;
  records: any;
  currentDate: number;
};

const RecordRow = memo(function RecordRow({ days, rows, medication, records, currentDate }: TRecordRow) {
  const setIsCreateRecordModalOpen = useRecordStore((state) => state.setIsCreateRecordModalOpen);
  const setSelectedRow = useRecordStore((state) => state.setSelectedRow);
  const setIsUpdateRecordModalOpen = useRecordStore((state) => state.setIsUpdateRecordModalOpen);
  const setSelectedDate = useRecordStore((state) => state.setSelectedDate);
  const setUpdateInformation = useRecordStore((state) => state.setUpdateInformation);
  const setSelectedMedication = useRecordStore((state) => state.setSelectedMedication);
  const useNotify = useRecordStore((state) => state.useNotify);

  const { notify } = useNotify();

  return rows.find((row: any) => row.medication_id === medication.id)
    ? rows
        .filter((row: any) => row.medication_id === medication.id)
        .map((row: any) => (
          <div className="flex">
            {days.map((day: any) =>
              records.find((record: any) => {
                return (
                  medication.id === record.medication_id && Number(record.date) === day.day && row.row === record.dose
                );
              }) ? (
                records
                  .filter(
                    (record: any) =>
                      medication.id === record.medication_id &&
                      Number(record.date) === day.day &&
                      row.row === record.dose
                  )
                  .map((record: any) =>
                    day.day > currentDate ? (
                      <div
                        className="relative flex h-[52px] w-[52px] cursor-pointer items-center justify-center bg-white hover:bg-gray-50"
                        onClick={() => {
                          setSelectedDate(day.day);
                          setSelectedMedication(medication);
                          setSelectedRow(row.row);
                          setUpdateInformation(record);
                        }}
                      >
                        <div className="absolute right-0 top-0 h-0 w-0 border-t-8 border-b-8 border-l-8 border-t-yellow-500 border-b-transparent border-l-transparent" />
                        {otherOptionsList[record.outcome]}
                      </div>
                    ) : (
                      <div
                        className="flex h-[52px] w-[52px] cursor-pointer items-center justify-center bg-white hover:bg-gray-50"
                        onClick={() => {
                          setSelectedDate(day.day);
                          setSelectedMedication(medication);
                          setSelectedRow(row.row);
                          setIsUpdateRecordModalOpen(true);
                          setUpdateInformation(record);
                        }}
                      >
                        {otherOptionsList[record.outcome]}
                      </div>
                    )
                  )
              ) : (medication.end_date && day.day > medication.end_date) || day.day < medication.start_date ? (
                <div
                  className="flex h-[52px] w-[52px] cursor-not-allowed items-center justify-center bg-gray-100"
                  onClick={() => {
                    // handleNotAllowed("record");
                    notify("record");
                  }}
                />
              ) : day.day > currentDate ? (
                <div
                  className="relative flex h-[52px] w-[52px] cursor-pointer items-center justify-center bg-yellow-50"
                  onClick={() => {
                    setSelectedDate(day.day);
                    setSelectedMedication(medication);
                    setSelectedRow(row.row);
                    // handleNotAllowed("futureRecord");
                    notify("futureRecord");
                  }}
                ></div>
              ) : (
                <div
                  className="flex h-[52px] w-[52px] cursor-pointer items-center justify-center bg-white hover:bg-gray-50"
                  onClick={() => {
                    setSelectedDate(day.day);
                    setSelectedMedication(medication);
                    setSelectedRow(row.row);
                    setIsCreateRecordModalOpen(true);
                  }}
                >
                  <p className="">-</p>
                </div>
              )
            )}
          </div>
        ))
    : null;
});

export { RecordRow };
