import { useMutation } from "react-query";
import { queryClient } from "lib/react-query";

import { axios } from "lib/axios";
import { useNotification } from "hooks/useNotification";

export type DeleteGroupType = {
  data: {
    name: string;
  };
};

type Groups = {
  groups: any[];
  areas: any[];
};

export const deleteGroup = ({ groupId }: { groupId: any }) => {
  console.log("deleteGroup", groupId);
  return axios.delete(`/groups/${groupId}`);
};

export const useDeleteGroup = (successFunction?: Function) => {
  const { addNotification } = useNotification();

  return useMutation({
    onMutate: async (deletedGroup) => {
      await queryClient.cancelQueries("groups");

      const previousGroups = queryClient.getQueryData<Groups>("groups");
      console.log("mutating delete groups: ", previousGroups, previousGroups?.groups);

      queryClient.setQueryData("groups", {
        groups: previousGroups?.groups?.filter((group) => group.id !== deletedGroup.groupId),
        areas: previousGroups?.areas,
      });

      return { previousGroups };
    },
    onError: (_, __, context) => {
      addNotification({
        disallowClose: true,
        type: "error",
        title: "Group Not Deleted",
        message: "Could not delete group!",
      });
      if (context?.previousGroups) {
        queryClient.setQueryData("groups", context.previousGroups);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries("groups");
      // successFunction && successFunction();
      addNotification({
        disallowClose: true,
        type: "success",
        title: "Group Deleted",
        message: "Group Deleted Successfully!",
      });
      if (successFunction) {
        successFunction();
      }
    },
    mutationFn: deleteGroup,
  });
};
