import { useEffect, useState } from "react";
import { Switch } from "@mantine/core";

import { useSections } from "../api/getSections";
import { SectionType } from "../types";

type SectionsProps = {
  previousSections?: any[];
  selectedSections: any[];
  setSelectedSections: any;
  isDefaultSections: boolean;
};

export const Sections = ({ previousSections, selectedSections, setSelectedSections }: SectionsProps) => {
  const sectionsQuery = useSections();

  useEffect(() => {
    // if new area, set sections to default, if updating, use the previous sections of the area
    if (sectionsQuery.data) {
      if (previousSections) {
        // if the area has the section, use that as the selected value, if not, default it to false
        const newSections: any = [];

        sectionsQuery.data.forEach((section: any) => {
          const matchingSection = previousSections.find(
            (previousSection: any) => previousSection.id.toString() === section.id
          );

          if (matchingSection) {
            newSections.push(matchingSection);
          } else {
            newSections.push({ ...section, selected: false });
          }
        });

        setSelectedSections(newSections);
      } else {
        const newSections = sectionsQuery.data.map((section) => {
          return { ...section, selected: true };
        });
        setSelectedSections(newSections);
      }
    }
  }, [sectionsQuery.data]);

  const handleSectionChange = (id: number) => {
    setSelectedSections(
      selectedSections.map((section: any) => {
        if (section.id === id) {
          return { ...section, selected: !section.selected };
        } else {
          return section;
        }
      })
    );
  };

  return (
    <div className="flex flex-col space-y-4 pt-1 capitalize">
      {selectedSections?.map((section: any) => (
        <Switch
          label={section.name}
          key={section.name}
          checked={section.selected}
          onChange={() => handleSectionChange(section.id)}
          size="xs"
          classNames={{
            input: "checked:bg-jira-button checked:border-jira-button",
            label: "text-gray-900 text-sm font-medium",
          }}
        />
      ))}
    </div>
  );
};
