import { useQuery } from "react-query";
import { AxiosError } from "axios";

import { axios } from "lib/axios";
import { AreaType } from "../types";

export const getArea = async ({
  areaId,
}: {
  areaId: string;
}): Promise<AreaType> => {
  try {
    const response = await axios.get(`/areas/${areaId}`);
    if (response.status === 200) {
      console.log("response 200", response.status);
    }
    // Don't forget to return something
    return response.data;
  } catch (err) {
    console.log("getArea", err);
    if (err instanceof AxiosError) {
      if (err.response?.status === 401) {
        throw new Error("Not authorised");
      } else if (err.response?.status === 403) {
        throw new Error("Not admin");
      }
    }
    throw new Error("There was an error fetching the request");
  }
};

type UseAreaOptions = {
  areaId: string;
  loading?: boolean;
};

export const useArea = ({ areaId, loading }: UseAreaOptions) => {
  return useQuery({
    enabled: !loading,
    queryKey: ["area", areaId],
    queryFn: () => getArea({ areaId }),
  });
};
