import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { TextInput, PasswordInput, LoadingOverlay, Button } from "@mantine/core";

import logo from "assets/supported_logo.png";

export const VerifyForm = () => {
  const location = useNavigate();

  let [searchParams, setSearchParams] = useSearchParams();
  const mode = searchParams.get("mode");
  const actionCode = searchParams.get("oobCode");
  const continueUrl = searchParams.get("continueUrl");
  const lang = searchParams.get("lang") || "eng";

  const [isLoading, setIsLoading] = useState(false);
  const [accountEmail, setAccountEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleFormSubmit = async (e: any) => {
    console.log("handleFormSubmit");
    setIsLoading(true);
    e.preventDefault();

    setIsLoading(false);
    location("/");
  };

  return (
    <div className="flex h-screen w-full items-center justify-center bg-slate-100">
      <form
        className="3xs:h-full 3xs:w-full 3xs:p-6 3xs:justify-center relative flex flex-col rounded-xl bg-white shadow-md xs:h-5/6 xs:w-10/12 sm:h-auto sm:w-auto sm:max-w-[512px] sm:px-14 sm:py-14"
        onSubmit={handleFormSubmit}
      >
        <LoadingOverlay visible={isLoading} />
        <div className="flex flex-col items-center space-y-8">
          <img src={logo} alt="Supported Logo" className="h-24" />
          <div className="space-y-3">
            <p className="text-xl font-semibold text-gray-800">Create your account</p>
            <div className="space-y-2">
              <TextInput label="Verified Email" disabled value={accountEmail} />
              <PasswordInput
                label="New Password"
                description="Your password must contain a number, and an uppercase, lowercase and special character"
                value={password}
                onChange={(e) => setPassword(e.currentTarget.value)}
                required
              />
              <PasswordInput
                label="Confirm New Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.currentTarget.value)}
                required
              />
            </div>
          </div>
          <Button type="submit" variant="filled" className="bg-blue-500 transition-colors ease-out hover:bg-blue-600">
            Create Account
          </Button>
        </div>
      </form>
    </div>

    // <div className='space-y-12 flex flex-col items-center'>
    //     <img src={logo} alt='Supported Logo' className='h-24' />

    //     <div className='flex flex-col space-y-6 3xs:w-full sm:w-80'>
    //         {error && <ErrorContainer />}

    //         <TextInput
    //             label='Email address'
    //             placeholder='Your email'
    //             value={email}
    //             onChange={(e) => setEmail(e.target.value)}
    //         />

    //         <PasswordInput
    //             label='Password'
    //             placeholder='Your password'
    //             value={password}
    //             onChange={(e) => setPassword(e.target.value)}
    //         />
    //     </div>

    //     <div className='flex w-full items-center justify-between 3xs:flex-col 3xs:space-y-4 3xs:items-center md:flex-row md:space-y-0'>
    //         <Link className='text-blue-500 font-medium hover:text-blue-600 transition-all duration-200 ease-in-out' to="/auth/forgot">Forgot Password?</Link>
    //         <button className='text-white font-medium py-2 px-6 bg-blue-500 rounded-lg shadow hover:bg-blue-600 transition-all duration-200 ease-in-out 3xs:w-full md:w-auto' type="submit">Sign In</button>
    //     </div>
    // </div>
  );
};
