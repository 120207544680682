import { useState } from "react";
import { Modal, Button, ActionIcon, NumberInput } from "@mantine/core";
import { DateTime } from "luxon";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useParams } from "react-router-dom";

import { useRecordStore } from "../store/recordStore";
import { useCreateRecord } from "features/record/api/createRecord";

export const CreateCount = () => {
  const { areaId } = useParams();

  const selectedDate = useRecordStore((state) => state.selectedDate);
  const currentDate = useRecordStore((state) => state.currentDate);
  const isCreateCountModalOpen = useRecordStore(
    (state) => state.isCreateCountModalOpen
  );
  const setIsCreateCountModalOpen = useRecordStore(
    (state) => state.setIsCreateCountModalOpen
  );
  const selectedMedication = useRecordStore(
    (state) => state.selectedMedication
  );

  const [count, setCount] = useState<number>(0);

  const createRecordMutation = useCreateRecord(() =>
    setIsCreateCountModalOpen(false)
  );

  const handleNewCount = async ({ count }: { count: number }) => {
    await createRecordMutation.mutateAsync({
      data: {
        medication_id: selectedMedication.id,
        type: "count",
        date: selectedDate,
        submitted_date: currentDate.toUnixInteger(),
        count: count,
        area_id: areaId,
      },
    });
  };

  console.log("selectedMedication", selectedMedication, selectedDate);

  return (
    <Modal
      opened={isCreateCountModalOpen}
      onClose={() => setIsCreateCountModalOpen(false)}
      centered
      withCloseButton={false}
    >
      <div className="flex flex-col space-y-4">
        <div className="flex items-center justify-between">
          <div className="flex space-x-2">
            <p className="text-[16px] font-semibold text-gray-900">
              {selectedMedication.name}{" "}
            </p>
            <p className="text-[16px] font-semibold text-[#6b778c]">{`${DateTime.fromSeconds(
              Number(selectedDate),
              {
                zone: "Europe/London",
              }
            ).toLocaleString({
              year: "numeric",
              month: "numeric",
              day: "numeric",
            })}`}</p>
          </div>
          <div className="flex space-x-1">
            <ActionIcon
              onClick={() => {
                setIsCreateCountModalOpen(false);
              }}
            >
              <XMarkIcon className="h-6 w-6 text-[#42526E]" />
            </ActionIcon>
          </div>
        </div>
        <NumberInput
          label="Medication Count"
          value={count}
          onChange={(val: number) => setCount(val)}
          hideControls
          min={0}
          max={1000}
        />
        <div>
          <Button
            className="bg-jira-button hover:bg-jira-button-hover"
            onClick={() => {
              handleNewCount({
                count: count,
              });
            }}
            loading={createRecordMutation.isLoading}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};
