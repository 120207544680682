import { useQuery } from "react-query";
import { AxiosError } from "axios";

import { axios } from "lib/axios";
import { useAuth } from "stores/Auth";

export const getGroups = async (): Promise<any> => {
  try {
    const response = await axios.get("/groups");
    if (response.status === 200) {
      console.log("response 200", response.status);
    }
    // Don't forget to return something
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
    if (err instanceof AxiosError) {
      if (err.response?.status === 401) {
        throw new Error("Not authorised");
      } else if (err.response?.status === 403) {
        throw new Error("Not admin");
      }
    }
    throw new Error("There was an error fetching the request");
  }
};

export const useGroups = () => {
  const currentUser = useAuth((state) => state.currentUser);
  return useQuery({
    queryKey: ["groups"],
    queryFn: () => getGroups(),
    enabled: !!currentUser?.id,
  });
};
