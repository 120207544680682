import { useQuery } from "react-query";

import { axios } from "lib/axios";
import { useAuth } from "stores/Auth";
import { TUser } from "../types/index";
import { AxiosError } from "axios";

export const getUsers = async () => {
  try {
    console.log("getUsers");
    const response = await axios.get("/users");
    if (response.status === 200) {
      console.log("response 200", response.status);
    }
    // Don't forget to return something
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
    if (err instanceof AxiosError) {
      if (err.response?.status === 401) {
        throw new Error("Not authorised");
      } else if (err.response?.status === 403) {
        throw new Error("Not admin");
      }
    }
    throw new Error("There was an error fetching the request");
  }
};

export const useUsers = () => {
  const currentUser = useAuth((state) => state.currentUser);
  console.log("useUsers: ", currentUser);
  return useQuery({
    queryKey: ["users"],
    queryFn: () => getUsers(),
    enabled: !!currentUser?.id,
  });
};
