import { SegmentedControl } from "@mantine/core";

export const MedicationFrequency = ({
  value,
  onChange,
  maxValue,
  error,
}: {
  value: number;
  onChange: (value: number) => void;
  maxValue: number;
  error: any;
}) => {
  const handleSelect = (e: any) => {
    onChange(Number(e));
  };

  const data = () => {
    let array = [];

    if (maxValue) {
      for (let i = 1; i <= maxValue; i++) {
        array.push({ label: i.toString(), value: i.toString() });
      }
    }

    console.log("data of array", array);

    return array;
  };

  return (
    <div>
      <p className="text-sm font-semibold text-[#5e6c84]">
        Administration Frequency
      </p>
      <p className="mb-2 text-xs font-medium text-[#868e96]">
        How often this medication should be taken daily
      </p>
      <SegmentedControl
        value={value.toString()}
        onChange={handleSelect}
        data={data()}
        classNames={{
          labelActive: "bg-jira-button text-white",
          active: "bg-jira-button",
          controlActive: "text-red-500",
        }}
        color="blue"
      />
      <p className="text-xs font-medium text-[#f03e3e]">{error && error[0]}</p>
    </div>
  );
};
