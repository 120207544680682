import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import { queryClient } from "lib/react-query";

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <MantineProvider
        theme={{
          fontFamily: "figtree",
        }}
        classNames={{
          InputWrapper: {
            label: "text-neutral-500 font-semibold text-[13px]",
            description: "text-gray-500 font-medium text-[12px]",
            icon: "text-[#5e6c84]",
            error: "font-medium text-xs",
            input:
              "h-[2.57em] min-h-[32px] rounded-[3px] py-[8px] px-[10px] placeholder:text-[#8993a4] placeholder:font-medium font-medium text-neutral-800 bg-neutral-50 border border-neutral-300 focus:border-neutral-400 focus:shadow hover:bg-neutral-100 transition-all duration-100 ease-in-out",
          },
          Input: {
            label: "text-neutral-500 font-semibold text-[13px]",
            description: "text-gray-500 font-medium text-[12px]",
            icon: "text-[#5e6c84]",
            error: "font-medium text-xs",
            input:
              "h-[2.57em] min-h-[32px] rounded-[3px] py-[8px] px-[10px] placeholder:text-[#8993a4] placeholder:font-medium font-medium text-neutral-800 bg-neutral-50 border border-neutral-300 focus:border-neutral-400 focus:shadow hover:bg-neutral-100 transition-all duration-100 ease-in-out",
          },
          TextInput: {
            label: "text-neutral-500 font-semibold text-[13px]",
            description: "text-gray-500 font-medium text-[12px]",
            icon: "text-[#5e6c84]",
            error: "font-medium text-xs",
            input:
              "h-[2.57em] min-h-[32px] rounded-[3px] py-[8px] px-[10px] placeholder:text-[#8993a4] placeholder:font-medium font-medium text-neutral-800 bg-neutral-50 border border-neutral-300 focus:border-neutral-400 focus:shadow hover:bg-neutral-100 transition-all duration-100 ease-in-out",
          },
          PasswordInput: {
            label: "text-neutral-500 font-semibold text-[13px]",
            description: "text-gray-500 font-medium text-[12px]",
            icon: "text-[#5e6c84]",
            visibilityToggle: "text-[#5e6c84]",
            error: "font-medium text-xs",
            input:
              "h-[2.57em] min-h-[32px] rounded-[3px] py-[8px] px-[10px] placeholder:text-[#8993a4] placeholder:font-medium font-medium text-red-500 bg-[#fafbfc] border-2 border-[#DFE1E6] focus:bg-white focus:border-2 focus:border-jira-button focus:shadow-inputFocus hover:bg-[#ebecf0] transition-all duration-100 ease-in-out",
            innerInput:
              "h-[2.57em] min-h-[32px] rounded-[3px] py-[8px] px-[10px] placeholder:text-[#8993a4] placeholder:font-medium font-medium text-gray-900 bg-[#fafbfc] border-2 border-[#DFE1E6] focus:bg-white focus:border-2 focus:border-jira-button focus:shadow-inputFocus hover:bg-[#ebecf0] transition-all duration-100 ease-in-out",
          },
          Textarea: {
            label: "text-neutral-500 font-semibold text-[13px]",
            description: "text-gray-500 font-medium text-[12px]",
            icon: "text-[#5e6c84]",
            error: "font-medium text-xs",
            input:
              "min-h-[32px] rounded-[3px] py-[8px] px-[10px] placeholder:text-[#8993a4] placeholder:font-medium font-medium text-neutral-800 bg-neutral-50 border border-neutral-300 focus:border-neutral-400 focus:shadow hover:bg-neutral-100 transition-all duration-100 ease-in-out",
          },
          NumberInput: {
            label: "text-neutral-500 font-semibold text-[13px]",
            description: "text-gray-500 font-medium text-[12px]",
            icon: "text-[#5e6c84]",
            error: "font-medium text-xs",
            input:
              "h-[2.57em] min-h-[32px] rounded-[3px] py-[8px] px-[10px] placeholder:text-[#8993a4] placeholder:font-medium font-medium text-gray-900 bg-[#fafbfc] border-2 border-[#DFE1E6] focus:bg-white focus:border-2 focus:border-jira-button focus:shadow-inputFocus hover:bg-[#ebecf0] transition-all duration-100 ease-in-out",
          },
          DatePicker: {
            label: "text-neutral-500 font-semibold text-[13px]",
            description: "text-gray-500 font-medium text-[12px]",
            icon: "text-[#5e6c84]",
            error: "font-medium text-xs",
            input:
              "h-[2.57em] min-h-[32px] rounded-[3px] py-[8px] px-[10px] placeholder:text-[#8993a4] placeholder:font-medium font-medium text-gray-900 bg-[#fafbfc] border-2 border-[#DFE1E6] focus:bg-white focus:border-2 focus:border-jira-button focus:shadow-inputFocus hover:bg-[#ebecf0] transition-all duration-100 ease-in-out",
          },
          Checkbox: {
            label: "text-neutral-500 font-semibold text-[13px]",
            input: "text-neutral-800 checked:bg-[#0052cc]",
          },
          CheckboxGroup: {
            label: "text-neutral-500 font-semibold text-[13px]",
            input: "text-neutral-800 checked:bg-[#0052cc]",
          },
          Switch: {
            label: "text-neutral-500 font-semibold text-[13px]",
            input: "text-neutral-800 checked:bg-[#0052cc]",
          },
          Chips: {
            label: "bg-none text-neutral-500 font-semibold text-[13px] border-2 border-[#DFE1E6]",
            iconWrapper: "text-[#0052cc]",
            checked: "bg-none text-jira-button border-2 border-[#0052cc]",
            description: "text-gray-500 font-medium text-[12px]",
            icon: "text-[#5e6c84]",
            error: "font-medium text-xs",
            input:
              "h-[2.57em] min-h-[32px] rounded-[3px] py-[8px] px-[10px] placeholder:text-[#8993a4] placeholder:font-medium font-medium text-gray-900 bg-[#fafbfc] border-2 border-[#DFE1E6] focus:bg-white focus:border-2 focus:border-jira-button focus:shadow-inputFocus hover:bg-[#ebecf0] transition-all duration-100 ease-in-out",
          },
          Chip: {
            label: "text-neutral-500 font-semibold text-[13px] border-2 border-[#DFE1E6]",
            iconWrapper: "text-[#0052cc]",
            checked: "text-jira-button border-2 border-[#0052cc]",
            description: "text-gray-500 font-medium text-[12px]",
            icon: "text-[#5e6c84]",
            error: "font-medium text-xs",
            input:
              "h-[2.57em] min-h-[32px] rounded-[3px] py-[8px] px-[10px] placeholder:text-[#8993a4] placeholder:font-medium font-medium text-gray-900 bg-[#fafbfc] border-2 border-[#DFE1E6] focus:bg-white focus:border-2 focus:border-jira-button focus:shadow-inputFocus hover:bg-[#ebecf0] transition-all duration-100 ease-in-out",
          },
          Menu: {
            body: "shadow-select p-0",
            itemHovered: "rounded-none",
            itemLabel: "flex justify-between w-full",
          },
          Select: {
            label: "text-neutral-500 font-semibold text-[13px]",
            description: "text-gray-500 font-medium text-[12px]",
            error: "font-medium text-xs",
            hovered: "hover:bg-neutral-100 !text-neutral-900",
            selected: "bg-neutral-100 !text-neutral-900",
            item: "text-sm text-neutral-500",
            input:
              "text-[13px] rounded-[3px] py-[8px] placeholder:text-[#8993a4] placeholder:font-medium font-medium text-neutral-800 bg-neutral-50 border border-neutral-300 focus:border-neutral-400 focus:shadow hover:bg-neutral-100 transition-all duration-100 ease-in-out",
          },
          MultiSelect: {
            wrapper: "focus:bg-white focus:border-2 focus:border-jira-button focus:shadow-inputFocus",
            label: "text-neutral-500 font-semibold text-[13px]",
            description: "text-gray-500 font-medium text-[12px]",
            error: "font-medium text-xs",
            hovered: "hover:bg-[#f1f3f5] text-gray-900",
            selected: "bg-[#dfe3e6] text-gray-900",
            item: "text-sm text-gray-900",
            values: "p-0",
            searchInput: "focus:outline-none",
            input:
              "text-[13px] rounded-[3px] placeholder:text-[#8993a4] placeholder:font-medium font-medium text-gray-900 bg-[#fafbfc] border-2 border-[#DFE1E6] transition-all duration-100 ease-in-out focus-within:bg-white focus-within:border-2 focus-within:rounded-[3px] focus-within:border-jira-button focus-within:shadow-inputFocus focus:bg-white focus:border-2 focus:rounded-[3px] focus:border-jira-button focus:shadow-inputFocus",
          },
          NativeSelect: {
            label: "text-neutral-500 font-semibold text-[13px]",
            description: "text-gray-500 text-[13px]",
            input:
              "placeholder:text-[#8993a4] placeholder:font-medium font-medium text-gray-900 bg-[#f4f5f7] border border-[#DFE1E6] focus:bg-white focus:border-2 focus:border-jira-button focus:shadow-inputFocus hover:bg-[#ebecf0] transition-all duration-100 ease-in-out",
          },
          Button: {
            root: "rounded-[.25rem] h-[28px]",
            label: "text-xs",
            filled: "bg-jira-button hover:bg-jira-button-hover",
            subtle: "text-jira-button hover:bg-jira-button-hover hover:bg-opacity-10",
            light: "hover:bg-neutral-100 text-neutral-500",
            default: "text-neutral-700 shadow",
            outline: "text-neutral-700 outline-none border-none hover:bg-neutral-100",
          },
        }}
        styles={{
          Button: () => ({
            root: {
              padding: "6px 10px;",
              borderRadius: "3px;",
              cursor: "pointer;",
              // fontSize: "14.5px;",
              transition: "all 0.1s ease 0s;",
            },
            outline: {
              backgroundColor: "red",
              "&:hover": {
                backgroundColor: "#f1f3f5",
              },
              borderColor: "#d1d5db",
              height: "30px;",
              fontSize: "12px;",
              color: "#172b4d",
              fontWeight: "bold",
              leftIcon: {
                color: "#0052cc",
              },
            },
            filled: {
              backgroundColor: "rgb(0 82 204)",
            },
            subtle: {},
          }),
          Modal: () => ({
            overlay: {
              backgroundColor: "rgba(9, 30, 66, 0.54) !important",
            },
          }),
          Drawer: () => ({
            overlay: {
              backgroundColor: "rgba(9, 30, 66, 0.54) !important",
            },
          }),
          Divider: () => ({
            root: {
              borderTop: "2px solid #ebedf0 !important",
            },
          }),
        }}
      >
        <NotificationsProvider position="bottom-right">
          <ReactQueryDevtools initialIsOpen={true} />
          <Router>{children}</Router>
        </NotificationsProvider>
      </MantineProvider>
    </QueryClientProvider>
  );
};
