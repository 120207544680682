import { useEffect, useState } from "react";
import { Button, TextInput, LoadingOverlay } from "@mantine/core";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";

import { FormSection } from "components/Form/FormSection";
import { SelectBodymap } from "../components/SelectBodymap";
import Calendar from "components/Calendar/Calendar";

type TSettingsMedicationProps = {
  control: any;
};

export const SettingsMedication = ({ control }: TSettingsMedicationProps) => {
  return (
    <div className="flex flex-1 flex-col space-y-6">
      <FormSection
        title="Medication bodymap"
        subtitle="Select the correct bodymap for the supported person"
      >
        <Controller
          control={control}
          name="selectedBodymap"
          render={({ field: { value, onChange } }) => (
            <SelectBodymap
              selectedBodymap={value}
              setSelectedBodymap={onChange}
            />
          )}
        />
      </FormSection>

      <FormSection
        title="Medication Backdate"
        subtitle="Backdate medication records to this date"
      >
        <Controller
          control={control}
          name="backdate"
          render={({ field: { value, onChange } }) => (
            <Calendar
              label="Backdate Medication"
              value={value}
              onChange={onChange}
            />
          )}
        />
      </FormSection>
    </div>
  );
};
