import { Route, Routes } from "react-router-dom";
import { NavLink } from "react-router-dom";

import { Claims } from "./Claims";
import { useUsers } from "features/users/api/getUsers";

export const ClaimRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Claim />} />
      <Route path=":userId" element={<Claims />} />
    </Routes>
  );
};

const Claim = () => {
  const usersQuery = useUsers();

  return (
    <div>
      {usersQuery.isLoading ? (
        <p>Loading</p>
      ) : (
        <UsersList users={usersQuery.data ? usersQuery.data : []} />
      )}
    </div>
  );
};

const UsersList = ({ users }: any) => {
  return (
    <div className="fixed bottom-0 left-0 h-[calc(100vh-45px)] w-[200px] overflow-hidden border-t border-r border-gray-300">
      <div className="absolute bottom-0 left-0 box-border h-[calc(100%)] w-full overflow-y-auto overflow-x-hidden">
        {users.map((user: any) => (
          <NavLink
            key={user.id}
            to={`/admin/payroll/claims/${user.id}`}
            className={({ isActive }: any) =>
              isActive
                ? "duration-250 relative flex w-full flex-col rounded-md px-2 py-2 text-sm font-semibold text-jira-button transition-all ease-in-out"
                : "duration-250 relative flex w-full flex-col rounded-md px-2 py-2 text-sm font-semibold text-gray-900 transition-all ease-in-out hover:text-jira-button-hover"
            }
          >
            <div className="flex items-center space-x-3">
              <span className="overflow-hidden text-ellipsis whitespace-nowrap text-[14px]">
                {user.full_name}
              </span>
            </div>
          </NavLink>
        ))}
      </div>
    </div>
  );
};
