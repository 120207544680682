import { useMutation } from "react-query";
import { queryClient } from "lib/react-query";

import { axios } from "lib/axios";
import { useNotification } from "hooks/useNotification";

export const inviteUser = ({ email }: { email: string }) => {
  console.log("inviteUser email: ", email);
  return axios.post<any>(`/invite`, { email: email });
};

export const useInviteUser = (successFunction: Function) => {
  const { addNotification } = useNotification();

  return useMutation({
    onMutate: async (newUser) => {
      await queryClient.cancelQueries("users");

      const previousUsers: any = queryClient.getQueryData("users");

      //   queryClient.setQueryData("users", [
      //     ...(previousUsers.users || []),
      //     newUser.data,
      //   ]);

      return { previousUsers };
    },
    onError: (_, __, context) => {
      if (context?.previousUsers) {
        queryClient.setQueryData("users", context.previousUsers);
      }
      addNotification({
        disallowClose: true,
        type: "error",
        title: "Error sending invite email",
        message: "",
      });
    },
    onSuccess: ({ data }) => {
      console.log("onSuccess inviteUser", data.user.email);
      queryClient.invalidateQueries("users");
      addNotification({
        disallowClose: false,
        type: "success",
        title: `Sent invite email to ${data.user.email}`,
        message: "",
      });
      successFunction();
    },
    mutationFn: inviteUser,
  });
};
