import { useState, useEffect } from "react";
import { DateTime } from "luxon";
import { PencilSquareIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { useParams } from "react-router-dom";

import { TimeInput } from "components/TimeInput/TimeInput";

import {
  Modal,
  Select,
  Button,
  ActionIcon,
  Textarea,
  Tooltip,
} from "@mantine/core";
import { useUpdateRecord } from "../api/updateRecord";
import { useMe } from "features/users/api/getMe";
import { useRecordStore } from "../store/recordStore";
import { Logs } from "./Logs";

export const UpdateRecord = ({ selectedMonth }: { selectedMonth: any }) => {
  const { areaId } = useParams();

  const selectedRow = useRecordStore((state) => state.selectedRow);
  const isUpdateRecordModalOpen = useRecordStore(
    (state) => state.isUpdateRecordModalOpen
  );
  const setIsUpdateRecordModalOpen = useRecordStore(
    (state) => state.setIsUpdateRecordModalOpen
  );
  const selectedDate = useRecordStore((state) => state.selectedDate);
  const updateInformation = useRecordStore((state) => state.updateInformation);
  const selectedMedication = useRecordStore(
    (state) => state.selectedMedication
  );

  console.log("updateInformation", updateInformation);

  const user = useMe();

  const otherOptionsList = [
    "Fully taken",
    "Partially taken",
    "Actual refusal",
    "Passive refusal",
    "Not observed",
    "Destroyed medication",
    "Missed medication",
    "Administered by hospital",
    "Administered by family",
    "Self-administered",
  ];

  const [outcome, setOutcome] = useState<string | null>(
    updateInformation ? updateInformation.outcome : null
  );

  console.log("updateMedication", selectedMedication, updateInformation);

  const timezone = "Europe/London";

  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (updateInformation) {
      setOutcome(updateInformation.outcome);
    }
  }, [updateInformation]);

  // get first day of calendar
  const currentDate = DateTime.now().setZone(timezone);

  const defaultTime = DateTime.fromSeconds(selectedDate, {
    zone: "Europe/London",
  });

  const [time, setTime] = useState(defaultTime);
  const [notes, setNotes] = useState<string | null>(null);
  const [timeAdministered, setTimeAdministered] = useState<number>(0);

  useEffect(() => {
    setTimeAdministered(time.toSeconds());
  }, [time]);

  useEffect(() => {
    setTime(defaultTime);
  }, [selectedDate]);

  const updateRecordMutation = useUpdateRecord({
    areaId,
    start: selectedMonth.start.toUnixInteger(),
    end: selectedMonth.end.toUnixInteger(),
  });

  const handleUpdateRecord = async () => {
    console.log("outcome: ", outcome);

    await updateRecordMutation.mutateAsync({
      data: {
        id: updateInformation.id,
        type: updateInformation.type,
        submitted_date: Math.trunc(currentDate.toSeconds()),
        previous_notes: notes,
        previous_outcome: outcome,
        area_id: updateInformation.area_id,
        medication_id: updateInformation.medication_id,
        previous_time_administered: timeAdministered,
        logs: updateInformation.logs,
        start: selectedMonth.start.toUnixInteger(),
        end: selectedMonth.end.toUnixInteger(),
      },
    });
  };

  const closeModal = () => {
    setIsUpdateRecordModalOpen(false);
    setIsEditing(false);
    setNotes(null);
  };

  if (!updateInformation) {
    return null;
  }

  return (
    <Modal
      opened={isUpdateRecordModalOpen}
      onClose={closeModal}
      centered
      withCloseButton={false}
    >
      <div className="flex flex-col space-y-4">
        <div className="flex items-center justify-between">
          <div className="flex space-x-2">
            <p className="text-[16px] font-semibold text-gray-900">
              {selectedMedication.name}{" "}
            </p>
            <p className="text-[16px] font-semibold text-gray-500">{`${DateTime.fromSeconds(
              Number(updateInformation.date),
              {
                zone: timezone,
              }
            ).toLocaleString({
              year: "numeric",
              month: "numeric",
              day: "numeric",
            })}`}</p>
          </div>
          <div className="flex space-x-1">
            {user?.data?.level !== "user" && !isEditing && (
              <ActionIcon
                onClick={() => {
                  setIsEditing(true);
                }}
              >
                <PencilSquareIcon className="h-6 w-6 text-[#42526E]" />
              </ActionIcon>
            )}
            <ActionIcon
              onClick={() => {
                setIsUpdateRecordModalOpen(false);
                setIsEditing(false);
              }}
            >
              <XMarkIcon className="h-6 w-6 text-[#42526E]" />
            </ActionIcon>
          </div>
        </div>
        <div className="flex flex-col space-y-1">
          <p className="text-[16px] font-semibold text-gray-900">Result</p>
          <div className="flex flex-col space-y-1">
            <div className="flex items-center space-x-2">
              <p className="text-sm font-semibold text-gray-500">Outcome</p>
              {user?.data?.level !== "user" && isEditing ? (
                <Select
                  data={otherOptionsList}
                  value={outcome}
                  onChange={setOutcome}
                />
              ) : (
                <p className="font-medium text-gray-900">
                  {updateInformation.outcome}
                </p>
              )}
            </div>
            <div className="flex items-center space-x-2">
              <p className="text-sm font-semibold text-gray-500">
                Time administered
              </p>
              {user?.data?.level !== "user" && isEditing ? (
                <TimeInput
                  defaultTime={defaultTime}
                  value={time}
                  onChange={setTime}
                />
              ) : (
                <p className="text-sm font-semibold text-gray-900">
                  {DateTime.fromSeconds(
                    Number(updateInformation.time_administered),
                    {
                      zone: "Europe/London",
                    }
                  ).toLocaleString({
                    hour: "numeric",
                    minute: "numeric",
                  })}
                </p>
              )}
            </div>
            {updateInformation.notes ? (
              <div
                className={`${
                  isEditing ? "items-start" : "items-center"
                } flex space-x-2`}
              >
                <p className="text-sm font-semibold text-gray-500">Notes</p>
                {user?.data?.level !== "user" && isEditing ? (
                  <Textarea
                    value={notes ? notes : ""}
                    onChange={(e: any) => setNotes(e.currentTarget.value)}
                  />
                ) : (
                  <p className="font-medium text-gray-900">
                    {updateInformation.notes}
                  </p>
                )}
              </div>
            ) : (
              user?.data?.level !== "user" &&
              isEditing && (
                <div className="flex">
                  <Textarea
                    className="w-full"
                    label="Notes"
                    value={notes ? notes : ""}
                    onChange={(e: any) => setNotes(e.currentTarget.value)}
                  />
                </div>
              )
            )}
          </div>
        </div>

        {updateInformation.logs.length > 0 && (
          <Logs logs={updateInformation.logs} type="record" />
        )}
        {isEditing && (
          <div className="flex space-x-2">
            <Button
              className="bg-jira-button hover:bg-jira-button-hover"
              onClick={() => {
                console.log("Outcome: ", outcome);
                handleUpdateRecord();
                setIsUpdateRecordModalOpen(false);
                setIsEditing(false);
              }}
            >
              Save
            </Button>

            <Button
              className="bg-none font-medium text-slate-600 hover:bg-jira-button-subtle"
              variant="subtle"
              color="gray"
              onClick={() => {
                console.log("Outcome: ", outcome);
                setIsEditing(false);
                setNotes(null);
              }}
            >
              Cancel
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};
