import { Button, LoadingOverlay } from "@mantine/core";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { DateTime } from "luxon";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { useForms } from "../api/getForms";

const crumbs = [
  { title: "Forms Dashboard", href: ".." },
  { title: "Forms", href: "#" },
];

export const Forms = () => {
  const [offset, setOffset] = React.useState(0);

  const { areaId } = useParams();
  const formsQuery = useForms({ areaId, offset });

  console.log("formsQuery", formsQuery.data);

  return (
    <div className="flex flex-1 flex-col overflow-hidden bg-[#f8f9fa]">
      <div className="flex max-h-12 items-center border-b border-gray-200 py-1.5 px-5">
        <Breadcrumbs items={crumbs} />
      </div>
      <div className="flex h-full min-h-0 w-full flex-col items-start overflow-y-auto px-6 py-4 pt-0">
        <div className="pt-3 pb-2">
          <Button
            size="xs"
            className="border-none bg-jira-button text-xs font-medium text-white hover:bg-jira-button-hover"
            component={Link}
            to="../add"
            classNames={{
              root: "h-[24px]",
              label: "p-0 overflow-hidden overflow-ellipsis whitespace-nowrap",
            }}
          >
            Add form
          </Button>
        </div>

        <table className="relative w-full border-separate border-spacing-0 rounded border border-gray-200 bg-white">
          <LoadingOverlay
            visible={formsQuery.isLoading || formsQuery.isFetching}
          />
          <thead className="rounded-t">
            <tr className="grid grid-cols-5 rounded-t border-b border-gray-200 bg-gray-50 font-medium text-gray-500">
              <th className=" col-span-2 w-full justify-self-start p-2 text-start font-medium">
                Form Name
              </th>
              <th className=" col-span-1 w-full justify-self-start p-2 text-start font-medium">
                Created
              </th>
              <th className=" col-span-1 w-full justify-self-start p-2 text-start font-medium">
                Last Submitted
              </th>
              <th className=" col-span-1 w-full justify-self-start p-2 text-start font-medium">
                Times Submitted
              </th>
            </tr>
          </thead>
          <tbody>
            {formsQuery?.data?.forms.map((form) => {
              return (
                <tr className="grid grid-cols-5 hover:bg-gray-50">
                  <th className=" col-span-2 w-full justify-self-start p-2 text-start">
                    <Link
                      to={`../${form.id}`}
                      key={form.id}
                      className="flex flex-col items-start border-b border-gray-200 last:border-none hover:underline"
                    >
                      <p className="font-medium text-gray-900">{form.title}</p>
                      <p className="text-xs font-medium text-gray-500">
                        {form.description}
                      </p>
                    </Link>
                  </th>
                  <th className=" col-span-1 flex w-full items-center justify-self-start p-2 text-start">
                    <p className="text-sm font-medium text-gray-900">
                      {DateTime.fromISO(form?.created_at as string, {
                        zone: "Europe/London",
                      }).toFormat("dd MMM',' yyyy HH:mm")}
                    </p>
                  </th>
                  <th className=" col-span-1 flex w-full items-center justify-self-start p-2 text-start">
                    <p className="text-sm font-medium text-gray-500">
                      Last Submitted
                    </p>
                  </th>
                  <th className=" col-span-1 flex w-full items-center justify-self-start p-2 text-start">
                    <p className="text-sm font-medium text-gray-500">
                      {form?.responses?.length}
                    </p>
                  </th>
                </tr>
              );
            })}
            <div className="flex items-center justify-between border-t border-gray-200 p-2">
              <p className="text-xs font-medium text-gray-400">
                Showing{" "}
                <span className="font-semibold text-gray-500">
                  {offset * 10 + 1}{" "}
                </span>
                to{" "}
                <span className="font-semibold text-gray-500">
                  {offset * 10 +
                    (formsQuery?.data?.forms
                      ? formsQuery?.data?.forms.length
                      : 0)}
                </span>{" "}
                of{" "}
                <span className="font-semibold text-gray-500">
                  {formsQuery?.data?.total}
                </span>{" "}
                results
              </p>
              <div className="flex space-x-1">
                {offset > 0 && (
                  <Button
                    onClick={() => setOffset((prevOffset) => prevOffset - 1)}
                    size="xs"
                    className="border-none bg-gray-900 p-0 px-2 text-xs font-medium text-white hover:bg-gray-900 hover:text-gray-300"
                    classNames={{
                      root: "h-[24px]",
                      label:
                        "p-0 overflow-hidden overflow-ellipsis whitespace-nowrap",
                    }}
                  >
                    Previous
                  </Button>
                )}
                {offset * 10 +
                  (formsQuery?.data?.forms
                    ? formsQuery?.data?.forms.length
                    : 0) !==
                  Number(formsQuery?.data?.total) && (
                  <Button
                    onClick={() => setOffset((prevOffset) => prevOffset + 1)}
                    size="xs"
                    className="border-none bg-gray-900 p-0 px-2 text-xs font-medium text-white hover:bg-gray-900 hover:text-gray-300"
                    classNames={{
                      root: "h-[24px]",
                      label:
                        "p-0 overflow-hidden overflow-ellipsis whitespace-nowrap",
                    }}
                  >
                    Next
                  </Button>
                )}
              </div>
            </div>
          </tbody>
        </table>
      </div>
    </div>
  );
};

const LoadingState = () => {
  return <div>Loading...</div>;
};
