import { useEffect, useState } from "react";
import {
  NativeSelect,
  TextInput,
  ActionIcon,
  Menu,
  Divider,
  CheckboxGroup,
  Checkbox,
} from "@mantine/core";
import { useUserStore } from "stores/userStore";
import {
  MagnifyingGlassIcon,
  FingerPrintIcon,
  LockOpenIcon,
  LockClosedIcon,
  ArrowTopRightOnSquareIcon,
} from "@heroicons/react/24/solid";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/20/solid";

const userTypes = [
  { value: "user", label: "User" },
  { value: "manager", label: "Manager" },
  { value: "administrator", label: "Administrator" },
];

const userRoles = [
  { value: "team leader", label: "Team Leader" },
  { value: "developer", label: "Developer" },
  {
    value: "support and education mentor",
    label: "Support and Education Mentor",
  },
];

export const UsersFilter = () => {
  const defaultFilters = useUserStore((state) => state.defaultFilters);
  const { name, role, type } = useUserStore((state) => state.filters);
  const resetFilters = useUserStore((state) => state.resetFilters);
  const setFilters = useUserStore((state) => state.setFilters);
  const isFiltersMenuOpen = useUserStore((state) => state.isFiltersMenuOpen);

  const isFiltersClear =
    defaultFilters.name === name &&
    defaultFilters.type === type &&
    defaultFilters.role === role;

  useEffect(() => {
    console.log(isFiltersClear);
  }, [isFiltersClear]);

  useEffect(() => {
    console.log("checked selected", type);
  }, [type]);

  return isFiltersMenuOpen ? (
    <div className="flex w-full items-end justify-between space-x-5 self-start">
      <div className="flex space-x-4">
        <TextInput
          classNames={{
            input: "w-[250px]",
          }}
          icon={<MagnifyingGlassIcon className="h-5 w-5" />}
          value={name}
          onChange={(e) => setFilters("name", e.target.value)}
          label="User Name"
        />
        <NativeSelect
          classNames={{
            input: "w-[250px]",
          }}
          data={[
            { value: "user", label: "User" },
            { value: "manager", label: "Manager" },
            { value: "administrator", label: "Administrator" },
          ]}
          value={type}
          onChange={(e) => setFilters("type", e.target.value)}
          placeholder="Select filter"
          label="User Type"
        />
        <NativeSelect
          classNames={{
            input: "w-[250px]",
          }}
          data={[
            { value: "team leader", label: "Team Leader" },
            { value: "developer", label: "Developer" },
            {
              value: "Support and Education Mentor",
              label: "Support and Education Mentor",
            },
          ]}
          value={role}
          onChange={(e) => setFilters("role", e.target.value)}
          placeholder="Select filter"
          label="User Role"
        />
      </div>
      <div className="flex divide-x-2 divide-gray-300">
        <button
          className=" whitespace-nowrap rounded-l-md p-2 text-sm font-semibold text-green-500 hover:bg-green-50"
          onClick={resetFilters}
        >
          Search
        </button>
        <button
          // className={`${
          //   isFiltersClear ? "text-gray-400" : "text-gray-700 hover:bg-gray-50"
          // }  whitespace-nowrap rounded-r-md p-2 text-sm font-semibold`}
          onClick={() => {
            !isFiltersClear && resetFilters();
          }}
        >
          Reset
        </button>
      </div>
    </div>
  ) : null;
};

export const ShowFilters = () => {
  const isFiltersMenuOpen = useUserStore((state) => state.isFiltersMenuOpen);
  const toggleFiltersMenuOpen = useUserStore(
    (state) => state.toggleFiltersMenuOpen
  );

  return (
    <>
      <ActionIcon
        // className={`${
        //   isFiltersMenuOpen ? "bg-gray-200 hover:bg-gray-200 " : "bg-gray-200 hover:bg-gray-200"
        // } text-gray-600 hover:text-gray-700`}
        onClick={toggleFiltersMenuOpen}
      >
        <MagnifyingGlassIcon />
      </ActionIcon>
    </>
  );
};

export const NewFilters = () => {
  const defaultFilters = useUserStore((state) => state.defaultFilters);
  const { name, role, type } = useUserStore((state) => state.filters);
  const resetFilters = useUserStore((state) => state.resetFilters);
  const setFilters = useUserStore((state) => state.setFilters);
  const isFiltersMenuOpen = useUserStore((state) => state.isFiltersMenuOpen);

  const isFiltersClear =
    defaultFilters.name === name &&
    defaultFilters.type === type &&
    defaultFilters.role === role;

  useEffect(() => {
    console.log(isFiltersClear);
  }, [isFiltersClear]);

  const [checked, setChecked] = useState<any>();

  const users = [
    { type: "user", name: "1" },
    { type: "manager", name: "2" },
    { type: "user", name: "3" },
    { type: "administrator", name: "4" },
    { type: "administrator", name: "5" },
    { type: "manager", name: "6" },
  ];

  useEffect(() => {
    if (checked) {
      const checkedFilter = users.filter((user) => checked.includes(user.type));

      console.log("checked", checked, checkedFilter);
    }
  }, [checked]);

  return (
    <Menu
      gutter={5}
      control={
        <div className="inline-flex h-[32px] items-center space-x-3 rounded bg-[#f4f5f7] px-3 text-[#42526e] transition-all duration-100 ease-in-out hover:bg-[#ebecf0]">
          <AdjustmentsHorizontalIcon className=" h-5 w-5" />
          <p className="text-[15px] font-semibold">Filters</p>
        </div>
      }
      size={350}
    >
      <Menu.Label>
        <div className="flex w-full justify-between p-1">
          <p className="text-[14px] font-semibold text-gray-900">Filter by</p>
          <p
            className="text-[14px] font-semibold text-jira-button"
            onClick={() => {
              !isFiltersClear && resetFilters();
            }}
          >
            Reset filters
          </p>
        </div>
      </Menu.Label>
      <Divider />
      <Menu.Label>
        <div className="flex space-x-10">
          <div className="flex w-full flex-col space-y-1">
            <p className="text-[11px] font-extrabold uppercase text-[#5e6c84]">
              Permissions
            </p>
            <CheckboxGroup
              // value={selectedUsers}
              // onChange={setSelectedUsers}
              spacing={10}
              size="xs"
              orientation="vertical"
              styles={{
                label: { cursor: "pointer" },
                input: { cursor: "pointer" },
              }}
              classNames={{
                input: "checked:bg-jira-button checked:border-jira-button",
              }}
              onClick={(e) => e.stopPropagation()}
              value={type}
              onChange={(e) => setFilters("type", e)}
            >
              {userTypes.map((type: { value: string; label: string }) => (
                <Checkbox
                  value={type.value}
                  label={
                    <div className="flex w-full items-center space-x-3 text-sm font-medium text-[#091e42] ">
                      <p>{type.label}</p>
                    </div>
                  }
                />
              ))}
            </CheckboxGroup>
          </div>

          <div className="flex w-full flex-col space-y-1">
            <p className="text-[11px] font-extrabold uppercase text-[#5e6c84]">
              Roles
            </p>
            <CheckboxGroup
              // value={selectedUsers}
              // onChange={setSelectedUsers}
              spacing={10}
              size="xs"
              orientation="vertical"
              styles={{
                label: { cursor: "pointer" },
                input: { cursor: "pointer" },
              }}
              classNames={{
                input: "checked:bg-jira-button checked:border-jira-button",
              }}
              onClick={(e) => e.stopPropagation()}
              value={type}
              onChange={(e) => setFilters("type", e)}
            >
              {userRoles.map((type: { value: string; label: string }) => (
                <Checkbox
                  value={type.value}
                  label={
                    <div className="flex w-full items-center space-x-3 text-sm font-medium text-[#091e42] ">
                      <p>{type.label}</p>
                    </div>
                  }
                />
              ))}
            </CheckboxGroup>
          </div>
        </div>
      </Menu.Label>
    </Menu>
  );
};
