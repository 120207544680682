import React from 'react'

export const Views = ({ children, activeView }: any) => {
    console.log('views', children);

  return (
    <div className='flex w-full h-full'>
        {children[activeView]}
    </div>
  );
};
