import React from "react";
import { Tooltip } from "@mantine/core";
import { DateTime } from "luxon";

import { TMedicationLogs } from "../types";

export const Logs = ({
  logs,
  type,
}: {
  logs: TMedicationLogs[];
  type: "record" | "count" | "received" | "returned";
}) => {
  const Record = ({ log }: { log: TMedicationLogs }) => {
    return (
      <p className="font-medium text-gray-900">
        <span>{log.previous_outcome}</span>
        <span> @ </span>
        <span>
          {DateTime.fromSeconds(Number(log.previous_time_administered), {
            zone: "Europe/London",
          }).toLocaleString({
            hour: "numeric",
            minute: "numeric",
          })}
        </span>
      </p>
    );
  };

  const Count = ({ log }: { log: TMedicationLogs }) => {
    return (
      <p className="font-medium text-gray-900">
        <span>{log.previous_count}</span>
      </p>
    );
  };

  const Returned = ({ log }: { log: TMedicationLogs }) => {
    return (
      <p className="font-medium text-gray-900">
        <span> Returned </span>
        <span>{log.previous_returned_amount}</span>
        <span> to </span>
        <span>{log.previous_returned_to}</span>
      </p>
    );
  };

  const Received = ({ log }: { log: TMedicationLogs }) => {
    return (
      <p className="font-medium text-gray-900">
        <span> Received </span>
        <span>{log.previous_received_amount}</span>
        <span> from </span>
        <span>{log.previous_received_from}</span>
      </p>
    );
  };

  return (
    <div className="flex flex-col space-y-1">
      <p className="text-[16px] font-semibold text-gray-900">Logs</p>
      <div className="flex flex-col space-y-1">
        {logs.map((log: any) => (
          <Tooltip
            label={log.submitted_user_name}
            placement="start"
            withArrow={true}
          >
            <div className="flex cursor-pointer flex-col">
              <div className="flex items-center space-x-2">
                <p className="text-sm font-semibold text-gray-500">
                  {DateTime.fromSeconds(Number(log.submitted_date), {
                    zone: "Europe/London",
                  }).toLocaleString(DateTime.DATETIME_SHORT)}
                </p>
                {(() => {
                  switch (type) {
                    case "record":
                      return <Record log={log} />;
                    case "count":
                      return <Count log={log} />;
                    case "received":
                      return <Received log={log} />;
                    case "returned":
                      return <Returned log={log} />;
                    default:
                      return null;
                  }
                })()}
              </div>
              {log.previous_notes && (
                <div>
                  <p className="text-sm italic text-gray-500">
                    "{log.previous_notes}"
                  </p>
                </div>
              )}
            </div>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};
