import { useMutation } from "react-query";

import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useNotification } from "hooks/useNotification";

import { AreaType } from "../types";

export const deleteArea = ({ deletedAreas }: { deletedAreas: Array<any> }) => {
  return axios.delete(`/areas`, { data: deletedAreas });
};

export const useDeleteArea = () => {
  const { addNotification } = useNotification();

  return useMutation({
    onMutate: async ({ deletedAreas }) => {
      await queryClient.cancelQueries("areas");

      const previousAreas = queryClient.getQueryData<AreaType[]>("areas");

      console.log("deletedUsersList", deletedAreas);

      if (deletedAreas.length <= 1) {
        console.log("deletedUsersList 1", previousAreas);
        queryClient.setQueryData(
          "areas",
          previousAreas?.filter((area) => area.id !== deletedAreas[0].id)
        );
      } else {
        console.log("deletedUsersList 2", previousAreas);
      }

      // queryClient.setQueryData(
      //   "areas",
      //   previousAreas?.filter((area) => area.id.toString() !== deletedArea.areaId)
      // );

      return { previousAreas };
    },
    onError: (_, __, context: any) => {
      if (context?.previousAreas) {
        queryClient.setQueryData("areas", context.previousAreas);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries("areas");
      addNotification({
        disallowClose: false,
        type: "success",
        title: "Area Deleted",
        message: "Area Deleted Successfully!",
      });
    },
    mutationFn: deleteArea,
  });
};
