import { Route, Routes } from "react-router-dom";

import { CreateGroup } from "./CreateGroup";
import { Group } from "./Group";
import { Groups } from "./Groups";
import { UpdateGroup } from "./UpdateGroup";

export const GroupRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Groups />} />
      <Route path="group" element={<Group />} />
      <Route path="new" element={<CreateGroup />} />
      <Route path=":groupId" element={<UpdateGroup />} />
    </Routes>
  );
};
