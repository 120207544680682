import { useQuery } from "react-query";
import { AxiosError } from "axios";

import { axios } from "lib/axios";

export const getRotacloudShifts = async ({
  start,
  end,
  id,
}: any): Promise<any[]> => {
  try {
    const response = await axios.get(
      `/shifts?user=${id}&start=${start}&end=${end}&rotacloud=true`
    );
    if (response.status === 200) {
      console.log("response 200", response.status);
    }
    // Don't forget to return something
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
    if (err instanceof AxiosError) {
      if (err.response?.status === 401) {
        throw new Error("Not authorised");
      } else if (err.response?.status === 403) {
        throw new Error("Not admin");
      }
    }
    throw new Error("There was an error fetching the request");
  }
};

export const useRotacloudShifts = ({ start, end, id, success }: any) => {
  return useQuery({
    enabled: false,
    onSuccess: () => {
      success();
      console.log("WHY IS THIS RUNNING?");
    },
    queryKey: ["rotacloudShifts", id, start, end],
    queryFn: () => getRotacloudShifts({ start, end, id }),
  });
};
