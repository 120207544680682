import { useState, useEffect, memo } from "react";
import { DateTime, DurationObjectUnits } from "luxon";
import { Select } from "@mantine/core";

export const MonthRangePicker = memo(({ setDateRange }: any) => {
  const [data, setData] = useState<any>([]);
  const [value, setValue] = useState<any>("");

  const date = data.find(
    (item: { value: string; label: string; date: { start: number; end: number } }) => item.value === value
  )?.date;

  useEffect(() => {
    if (date) {
      setDateRange(date);
    }
  }, [date]);

  const now = DateTime.now().setZone("Europe/London");

  const backdateTo = DateTime.local(2020, 5, 15, { zone: "Europe/London" });

  const startMonth = DateTime.local(now.year, now.month, 15, {
    zone: "Europe/London",
  });
  const endMonth = startMonth.plus({ month: 1 });

  //* Get the current payroll date, if the current day is before the start of the next payroll, show previous month, if not, show next (e.g if on the 14th of Feb, show Jan-Feb if after the 15th of Feb, show Feb-Mar)

  const handleData = () => {
    const difference: DurationObjectUnits = endMonth.diff(backdateTo, "months").toObject();

    let data = [];

    if (difference.months) {
      for (let i = 0; i < difference.months; i++) {
        const start = backdateTo.plus({ month: i });
        const end = backdateTo.plus({ month: i + 1 });

        data.push({
          value: start.toUnixInteger().toString(),
          label: `${start.toLocaleString({
            day: "2-digit",
            month: "short",
          })} - ${end.toLocaleString({
            day: "2-digit",
            month: "short",
            year: "numeric",
          })}`,
          date: { start: start.toUnixInteger(), end: end.toUnixInteger() },
        });
      }
    }
    const reversedList = data.reverse();

    console.log(reversedList);

    return reversedList;
  };

  useEffect(() => {
    const data = handleData();
    setData(data);
    setValue(startMonth.toUnixInteger().toString());
  }, []);

  return (
    <div className="inline-block">
      {/* <p onClick={setData}>{startMonth.toString()}</p>
      <p>{endMonth.toString()}</p>
      <p>{startMonth.toUnixInteger().toString()}</p>
      <p>{endMonth.toUnixInteger().toString()}</p>
      <div>
        <p>Selected Date Range</p>
        <p>{date && date.start.toString()}</p>
        <p>{date && date.end.toString()}</p>
      </div> */}
      <Select data={data} value={value} onChange={setValue} />
    </div>
  );
});
