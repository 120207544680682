import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { Controller } from "react-hook-form";

import { roleColors } from "lib/rotacloudColors";

export const ColourPicker = ({
  control,
  error,
  label,
}: {
  control: any;
  error?: string | null | undefined;
  label: string;
}) => {
  const colors = Object.keys(roleColors);

  return (
    <Controller
      control={control}
      name={`colour`}
      render={({ field: { onChange, value } }: any) => (
        <div className="flex flex-col items-start justify-start space-y-2">
          <p className="text-sm font-semibold text-gray-500">{label}</p>
          {error && (
            <p className="text-xs font-semibold text-[#f03e3e]">{error}</p>
          )}

          <div className="flex items-center justify-center">
            <div className="grid grid-cols-6 gap-3">
              {colors.map((_color) => (
                <div
                  className="flex h-7 w-7 items-center justify-center rounded-full"
                  onClick={() => onChange(_color)}
                  style={{ backgroundColor: `#${_color}` }}
                >
                  <CheckCircleIcon
                    className={`h-6 w-6 text-white transition-all duration-200 ease-in ${
                      value === _color
                        ? "scale-100 opacity-100"
                        : "scale-0 opacity-0"
                    }`}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    />
  );
};
