import { useState } from "react";
import { Modal, Button, ActionIcon, Textarea, TextInput } from "@mantine/core";
import { DateTime } from "luxon";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useParams } from "react-router-dom";

import { useRecordStore } from "../store/recordStore";
import { useCreateRecord } from "../api/createRecord";

export const CreateReturn = () => {
  const { areaId } = useParams();

  const selectedDate = useRecordStore((state) => state.selectedDate);
  const currentDate = useRecordStore((state) => state.currentDate);
  const isCreateReturnModalOpen = useRecordStore(
    (state) => state.isCreateReturnModalOpen
  );
  const setIsCreateReturnModalOpen = useRecordStore(
    (state) => state.setIsCreateReturnModalOpen
  );
  const selectedMedication = useRecordStore(
    (state) => state.selectedMedication
  );

  const [returnedTo, setReturnedTo] = useState("");
  const [returnedAmount, setReturnedAmount] = useState("");
  const [notes, setNotes] = useState("");

  const createRecordMutation = useCreateRecord(() =>
    setIsCreateReturnModalOpen(false)
  );

  const handleNewCount = async () => {
    await createRecordMutation.mutateAsync({
      data: {
        type: "returned",
        date: selectedDate,
        submitted_date: DateTime.now().setZone("Europe/London").toUnixInteger(),
        area_id: areaId,
        medication_id: selectedMedication.id,
        notes: notes,
        returned_to: returnedTo,
        returned_amount: returnedAmount,
      },
    });
  };

  console.log("selectedMedication", selectedMedication, selectedDate);

  return (
    <Modal
      opened={isCreateReturnModalOpen}
      onClose={() => setIsCreateReturnModalOpen(false)}
      centered
      withCloseButton={false}
    >
      <div className="flex flex-col space-y-4">
        <div className="flex items-center justify-between">
          <div className="flex space-x-2">
            <p className="text-[16px] font-semibold text-gray-900">
              {selectedMedication.name}
            </p>
            <p className="text-[16px] font-semibold text-[#6b778c]">{`${DateTime.fromSeconds(
              Number(selectedDate),
              {
                zone: "Europe/London",
              }
            ).toLocaleString({
              year: "numeric",
              month: "numeric",
              day: "numeric",
            })}`}</p>
          </div>
          <div className="flex space-x-1">
            <ActionIcon
              onClick={() => {
                setIsCreateReturnModalOpen(false);
              }}
            >
              <XMarkIcon className="h-6 w-6 text-[#42526E]" />
            </ActionIcon>
          </div>
        </div>
        <TextInput
          label="Returned to"
          value={returnedTo}
          onChange={(e) => setReturnedTo(e.currentTarget.value)}
        />
        <TextInput
          label="Returned amount"
          value={returnedAmount}
          onChange={(e) => setReturnedAmount(e.currentTarget.value)}
        />
        <Textarea
          label="Notes"
          value={notes}
          onChange={(e) => setNotes(e.currentTarget.value)}
        />
        <div>
          <Button
            className="bg-jira-button hover:bg-jira-button-hover"
            onClick={() => {
              handleNewCount();
            }}
            loading={createRecordMutation.isLoading}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};
