import { useMutation } from "react-query";
import { queryClient } from "lib/react-query";

import { axios } from "lib/axios";
import { useNotification } from "hooks/useNotification";
import { supabase } from "lib/supabase";

export const createClaim = async ({ data }: { data: any & { file: any } }) => {
  console.log("createClaim: ", data);

  if (data.file) {
    const receiptFilePath = `${data.user_id}/${data.file.name}`;
    let { data: uploadData, error: uploadError } = await supabase.storage
      .from("receipts")
      .upload(receiptFilePath, data.file);

    const claim = {
      user_id: data.user_id,
      date: data.date,
      category: data.category,
      type: data.type,
      amount: data.amount,
      supplier: data.supplier,
      receipt_url: uploadData?.path as string,
    };
    const { data: returnData, error } = await supabase.from("claim").insert(claim);
    console.log("createClaim: ", data.file);
  } else {
    if (data.type === "Expense") {
      const claim = {
        user_id: data.user_id,
        date: data.date,
        type: data.type,
        amount: data.amount,
        supplier: data.supplier,
        category: data.category,
        notes: data.note,
      };
      const { data: returnData, error } = await supabase.from("claim").insert(claim);
      console.log("createClaim: ", data.file);
    } else {
      const claim = {
        user_id: data.user_id,
        date: data.date,
        type: data.type,
        amount: data.amount,
        mileage_legs: data.mileage_legs,
        total_miles: data.total_miles,
      };
      const { data: returnData, error } = await supabase.from("claim").insert(claim);
      console.log("createClaim: ", data.file);
    }
  }
};

export const useCreateClaim = (successFunction: Function) => {
  const { addNotification } = useNotification();

  return useMutation({
    onError: (_, __, context) => {
      addNotification({
        disallowClose: true,
        type: "error",
        title: "Could not create claim",
        message: "There was an error creating the claim, please try again",
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("claims");
      console.log("created claim onSuccess", successFunction);
      successFunction();
      addNotification({
        disallowClose: true,
        type: "success",
        title: "Claim created successfully",
        message: "",
      });
    },
    mutationFn: createClaim,
  });
};
