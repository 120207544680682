import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useNotification } from "hooks/useNotification";
import { SectionType, AreaType } from "../types";

export type CreateAreaType = {
  data: AreaType;
};

export const createArea = ({ data }: CreateAreaType) => {
  console.log("createArea", data);
  return axios.post("/areas", data);
};

export const useCreateArea = () => {
  const { addNotification } = useNotification();
  const navigate = useNavigate();

  return useMutation({
    onMutate: async (newArea) => {
      await queryClient.cancelQueries("areas");

      const previousAreas = queryClient.getQueryData<AreaType[]>("areas");

      queryClient.setQueryData("areas", [...(previousAreas || []), newArea.data]);

      return { previousAreas };
    },
    onError: (_, __, context: any) => {
      if (context?.previousAreas) {
        queryClient.setQueryData("areas", context.previousAreas);
      }

      addNotification({
        disallowClose: false,
        type: "error",
        title: "Could not create area!",
        message: "The area could not be created at this time, please try again",
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("areas");
      addNotification({
        disallowClose: false,
        type: "success",
        title: "Area Created",
        message: "Area Created Successfully!",
      });
      navigate("/areas");
    },
    mutationFn: createArea,
  });
};
