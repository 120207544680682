import { useState } from "react";
import { Button, TextInput } from "@mantine/core";
import { Link } from "react-router-dom";
import { z } from "zod";

import { useCreateArea } from "../api/createArea";
import { SelectBodymap } from "../components/SelectBodymap";
import { AreaPicture } from "../components/AreaPicture";
import { ADMIN } from "config";
import { UsersSelect } from "../components/UsersSelect";
import { Sections } from "../components/Sections";
import { AreaGroup } from "../components/AreaGroup";
import { SectionType } from "../types";
import { useNotification } from "hooks/useNotification";

export const CreateArea = () => {
  const { addNotification } = useNotification();

  const createAreaMutation = useCreateArea();

  const [formErrors, setFormErrors] = useState<any>([]);

  const [name, setName] = useState("");
  const [avatar, setAvatar] = useState<string | null>(null);
  const [selectedBodymap, setSelectedBodymap] = useState("male");
  const [selectedSections, setSelectedSections] = useState<any>([]);
  const [selectedGroup, setSelectedGroup] = useState<string>("null");
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const AreaForm = z.object({
    name: z
      .string({ required_error: "Area name is required" })
      .min(1, "Area name is required"),
    avatar: z.string().nullish(),
    selectedBodymap: z.string().min(1, "Bodymap is required"),
    selectedSections: z
      .object({
        id: z.string(),
        name: z.string(),
        icon: z.string(),
        uri: z.string(),
        selected: z.boolean(),
      })
      .array(),
    selectedGroup: z.string().nullish(),
    selectedUsers: z.string().array(),
  });

  const submitArea = async () => {
    try {
      AreaForm.parse({
        name,
        avatar,
        selectedBodymap,
        selectedSections,
        selectedGroup,
        selectedUsers,
      });

      if (ADMIN) {
        console.log("createAreaMutation values", name, selectedBodymap, avatar);
        await createAreaMutation.mutateAsync({
          data: {
            name: name,
            avatar: avatar,
            selected_bodymap: selectedBodymap,
            sections: selectedSections,
            group_id: Number(selectedGroup),
            users: selectedUsers,
            medication_records_backdate: 0,
          },
        });
      }
    } catch (error: any) {
      setFormErrors(error.flatten());
      console.log("form submission error", error, error.flatten());
      addNotification({
        disallowClose: false,
        type: "error",
        title: "Form not submitted",
        message:
          "There is an error in this form, check if you are missing any required fields and try again",
      });
    }
  };

  return (
    <div className="flex h-full min-h-0 w-full flex-col items-center overflow-y-auto px-12 py-6">
      <div className="flex w-full max-w-5xl flex-col">
        <div className="flex flex-col space-y-4">
          <div className="flex flex-col">
            <p className="text-2xl font-semibold text-gray-900">New area</p>
            <Link
              className="cursor-pointer text-sm font-semibold text-jira-button hover:text-[#0065FF]"
              to="/areas"
            >
              Back to areas
            </Link>
          </div>

          <form
            onSubmit={async (e: any) => {
              e.preventDefault();
              await submitArea();
            }}
            className="space-y-8"
          >
            <div className="flex flex-col space-y-6">
              <div className="space-y-2">
                <div>
                  <p className="text-[16px] font-semibold text-gray-900">
                    Area details
                  </p>
                  <p className="text-[13px] font-medium text-gray-900">
                    This is the basic information of the area
                  </p>
                </div>

                <div className="py-1">
                  <div className="space-y-10">
                    <div className="flex flex-col space-y-3">
                      <div>
                        <TextInput
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Enter the area name"
                          label="Name"
                          description="The areas name, this might be the of name a person, a place or anything else"
                          error={
                            formErrors.fieldErrors?.name &&
                            formErrors.fieldErrors?.name[0]
                          }
                        />
                      </div>
                      <AreaPicture avatar={avatar} setAvatar={setAvatar} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="space-y-2">
                <div>
                  <p className="text-[16px] font-semibold text-gray-900">
                    Bodymap
                  </p>
                  <p className="text-[13px] font-medium text-gray-900">
                    Select the bodymap that most closely physically represents
                    the person
                  </p>
                </div>

                <div className="py-2">
                  <SelectBodymap
                    selectedBodymap={selectedBodymap}
                    setSelectedBodymap={setSelectedBodymap}
                  />
                </div>
              </div>

              <div className="space-y-2">
                <div>
                  <p className="text-[16px] font-semibold text-gray-900">
                    Area sections
                  </p>
                  <p className="text-[13px] font-medium text-gray-900">
                    Choose the sections you want your area to have, or for the
                    default experince, leave it as is
                  </p>
                </div>

                <div className="py-1">
                  <Sections
                    selectedSections={selectedSections}
                    setSelectedSections={setSelectedSections}
                    isDefaultSections={true}
                  />
                </div>
              </div>

              <AreaGroup
                selectedGroup={selectedGroup}
                setSelectedGroup={setSelectedGroup}
              />

              <div className="space-y-2">
                <div>
                  <p className="text-[16px] font-semibold text-gray-900">
                    Area users
                  </p>
                  <p className="text-[13px] font-medium text-gray-900">
                    Select users to give them access to this area
                  </p>
                </div>

                <div className="py-1">
                  <UsersSelect
                    selectedUsers={selectedUsers}
                    setSelectedUsers={setSelectedUsers}
                  />
                </div>
              </div>
            </div>

            <div className="flex items-center space-x-3">
              <Button
                type="submit"
                className="bg-jira-button hover:bg-jira-button-hover"
              >
                Save
              </Button>
              <Button
                className="bg-none font-medium text-slate-600 hover:bg-jira-button-subtle"
                disabled={!ADMIN}
                variant="subtle"
                color="gray"
                component={Link}
                to="/areas"
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
