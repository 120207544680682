import { useParams } from 'react-router-dom'

import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs'
import { MedicationsTable } from '../components/MedicationsTable'

export const Medications = () => {
  const { areaId } = useParams()

  const crumbs = [
    { title: 'Medication Dashboard', href: `/areas/${areaId}/medication` },
    { title: 'Medications', href: '#' },
  ]

  return (
    <div className="flex flex-col">
      <MedicationsTable />
    </div>
  )
}
