import { useEffect } from "react";
import { Controller } from "react-hook-form";
import { Checkbox, NumberInput } from "@mantine/core";

export const SleepRole = ({ control, errors, isSleepRole, unregister, register }: any) => {
  useEffect(() => {
    if (isSleepRole) {
      register("sleepFlatRateWeekday");
      register("sleepFlatRateWeekend");
    } else {
      unregister("sleepFlatRateWeekday");
      unregister("sleepFlatRateWeekend");
    }
  }, [isSleepRole, register, unregister]);

  return (
    <div className="pt-1">
      <Controller
        control={control}
        name={`isSleepRole`}
        render={({ field: { value, onChange } }: any) => (
          <Checkbox label="Sleep role" checked={value} onChange={onChange} />
        )}
      />
      {isSleepRole && (
        <div className="mt-2 space-y-4">
          <Controller
            control={control}
            name={`sleepFlatRateWeekday`}
            render={({ field }: any) => (
              <NumberInput
                label="Sleep flat rate (weekday)"
                {...field}
                error={errors.sleepFlateRateWeekday?.message as string}
              />
            )}
          />
          <Controller
            control={control}
            name={`sleepFlatRateWeekend`}
            render={({ field }: any) => (
              <NumberInput
                label="Sleep flat rate (weekend)"
                {...field}
                error={errors.sleepFlateRateWeekend?.message as string}
              />
            )}
          />
        </div>
      )}
    </div>
  );
};
