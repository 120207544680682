import { DateTime } from "luxon";

export const getPayrollBackdate = (backdate: any, payrollDay: number) => {
  const currentDate: DateTime = DateTime.now().setZone("Europe/London");

  const startMonth: DateTime = DateTime.local(currentDate.year, currentDate.month, payrollDay, {
    zone: "Europe/London",
  });
  const endMonth: DateTime = DateTime.local(currentDate.year, currentDate.month, payrollDay, {
    zone: "Europe/London",
  }).plus({ month: 1 });

  return [startMonth, endMonth];
};
