import { DateTime } from "luxon";

export const getDate = (selectedClaim: any) => {
  if (selectedClaim?.date) {
    const date = DateTime.fromSeconds(selectedClaim.date, {
      zone: "Europe/London",
    })
      .setLocale("en-gb")
      .toLocaleString({
        day: "2-digit",
        month: "short",
        year: "numeric",
      });

    return date;
  }
};
