import { useEffect, useState } from "react";
import { useRoutes, Navigate, useLocation, Route, Routes, NavLink } from "react-router-dom";
import { Checkbox, NumberInput, TextInput, Select, CheckboxGroup, Button } from "@mantine/core";

import { useAuth } from "stores/Auth";
import { useUser } from "features/users/api/getUser";
import { useRoles } from "features/admin/payroll/roles/api/getRoles";

export const Profile = () => {
  const currentUser = useAuth((state: any) => state.currentUser);
  const userLevel = useAuth((state: any) => state.userLevel);
  const userQuery = useUser({ userId: currentUser?.id });
  const rolesQuery = useRoles(() => console.log("admin"));

  const redirectToLogin = [
    {
      path: "/roles",
      element: <Navigate to="/profile/information" />,
    },
    {
      path: "/admin",
      element: <Navigate to="/profile/information" />,
    },
  ];

  const protectedRoutes = [
    {
      path: "/roles",
      element: <Roles user={userQuery?.data} roles={rolesQuery?.data} />,
    },
    {
      path: "/admin",
      element: <AdminOptions user={userQuery?.data} />,
    },
  ];

  const publicRoutes = [
    {
      path: "/wage",
      element: <Wage user={userQuery?.data} />,
    },
    {
      path: "/information",
      element: <Information user={userQuery?.data} />,
    },
  ];

  const routes = userLevel === "admin" ? protectedRoutes : redirectToLogin;

  const element = useRoutes([...routes, ...publicRoutes]);

  if (!currentUser) {
    return null;
  }

  return (
    <div className="flex h-full w-full bg-gray-50">
      <div className="flex h-full w-full flex-1 flex-col overflow-y-auto">
        <div className="flex h-full max-h-full">
          <div className="h-full max-h-full w-64 border-r border-gray-200 bg-white">
            <div className="mb-2">
              <div className="flex h-12 max-h-12 items-center border-b border-gray-200 px-6">
                <h4 className="mb-0 truncate text-lg font-semibold text-gray-900">Account</h4>
              </div>
            </div>
            <nav className="flex flex-col rounded-md">
              <div className="border-b border-gray-200 py-5 px-5">
                <NavLink
                  key="information"
                  to="information"
                  className={({ isActive }) =>
                    isActive
                      ? "duration-250 text-sm font-medium text-jira-button transition-all ease-in-out hover:text-jira-button"
                      : "duration-250 text-sm font-medium text-gray-500 transition-all ease-in-out hover:text-jira-button"
                  }
                >
                  <div className="flex items-center space-x-3">
                    <span className="text-[14px]">My Information</span>
                  </div>
                </NavLink>
              </div>
              <div className="border-b border-gray-200 py-5 px-5">
                <div className="mb-2 font-medium text-gray-900">
                  <span>Payroll Information</span>
                </div>
                <div className="flex flex-col space-y-1">
                  {userLevel === "admin" && (
                    <NavLink
                      key="roles"
                      to="roles"
                      className={({ isActive }) =>
                        isActive
                          ? "duration-250 text-sm font-medium text-jira-button transition-all ease-in-out hover:text-jira-button"
                          : "duration-250 text-sm font-medium text-gray-500 transition-all ease-in-out hover:text-jira-button"
                      }
                    >
                      <div className="flex items-center space-x-3">
                        <span className="text-[14px]">Roles</span>
                      </div>
                    </NavLink>
                  )}
                  <NavLink
                    key="wage"
                    to="wage"
                    className={({ isActive }) =>
                      isActive
                        ? "duration-250 text-sm font-medium text-jira-button transition-all ease-in-out hover:text-jira-button"
                        : "duration-250 text-sm font-medium text-gray-500 transition-all ease-in-out hover:text-jira-button"
                    }
                  >
                    <div className="flex items-center space-x-3">
                      <span className="text-[14px]">Wages & Salary</span>
                    </div>
                  </NavLink>
                </div>
              </div>
              {userLevel === "admin" && (
                <div className="border-b border-gray-200 py-5 px-5">
                  <div className="mb-2 font-medium text-gray-900">
                    <span>Admin</span>
                  </div>
                  <NavLink
                    key="admin"
                    to="admin"
                    className={({ isActive }) =>
                      isActive
                        ? "duration-250 text-sm font-medium text-jira-button transition-all ease-in-out hover:text-jira-button"
                        : "duration-250 text-sm font-medium text-gray-500 transition-all ease-in-out hover:text-jira-button"
                    }
                  >
                    <div className="flex items-center space-x-3">
                      <span className="text-[14px]">Admin Options</span>
                    </div>
                  </NavLink>
                </div>
              )}
            </nav>
          </div>
          <div className="flex flex-1 flex-col">
            <div className="max-w-4xl p-4">
              <form className="w-full rounded-md border border-gray-200 bg-white">{element}</form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Information = ({ user }: { user: any }) => {
  return (
    <section>
      <div className="border-b border-[#e6e8eb]">
        <div className="flex items-center px-6 py-4">
          <p className="text-[16px] font-semibold text-gray-900">My Information</p>
        </div>
      </div>
      <div className="px-6 py-4">
        <div className="space-y-2">
          <TextInput label="Name" value={user?.full_name} />
          <TextInput label="Email" value={user?.email} disabled />
        </div>
      </div>
      <div className="border-t border-gray-200" />
      <div className="flex px-6 py-4">
        <div className="flex w-full items-center justify-end gap-2">
          <div className="flex items-center gap-2">
            <Button type="reset" className="bg-none font-medium text-slate-600 hover:bg-jira-button-subtle">
              Cancel
            </Button>
            <Button className="bg-jira-button hover:bg-jira-button-hover" type="submit">
              Save
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

const Wage = ({ user }: { user: any }) => {
  const [payrollId, setPayrollId] = useState<string>(user?.payroll_id);
  const [salary, setSalary] = useState<number>(user?.salary);
  const [salaryType, setSalaryType] = useState<any>(user?.salary_type);

  return (
    <section>
      <div className="border-b border-[#e6e8eb]">
        <div className="flex items-center px-6 py-4">
          <p className="text-[16px] font-semibold text-gray-900">Wage</p>
        </div>
      </div>
      <div className="px-6 py-4">
        <div className="space-y-2">
          <TextInput label="Payroll Id" value={payrollId} onChange={(e) => setPayrollId(e.currentTarget.value)} />
          <div className="flex flex-col space-y-1">
            <p className="text-sm font-semibold text-gray-500">Default wage/salary</p>
            <div className="flex items-center space-x-1">
              <span>£</span>
              <NumberInput
                className="w-[100px]"
                value={salary}
                onChange={(value: number) => setSalary(value)}
                precision={2}
                hideControls
              />
              <Select
                data={[
                  { label: "per annum", value: "salary" },
                  { label: "per hour", value: "hourly" },
                ]}
                className="w-[120px]"
                value={salaryType}
                onChange={setSalaryType}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="border-t border-gray-200" />
      <div className="flex px-6 py-4">
        <div className="flex w-full items-center justify-end gap-2">
          <div className="flex items-center gap-2">
            <Button type="reset" className="bg-none font-medium text-slate-600 hover:bg-jira-button-subtle">
              Cancel
            </Button>
            <Button className="bg-jira-button hover:bg-jira-button-hover" type="submit">
              Save
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

const Roles = ({ user, roles }: any) => {
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);

  useEffect(() => {
    if (roles) {
      const roles: any = [];

      roles.forEach((role: any) => {
        if (role.users.includes(user?.id)) {
          roles.push(role.id.toString());
        }
      });

      setSelectedRoles(roles);

      console.log("User Roles: ", roles);
    }
  }, [roles]);

  useEffect(() => {
    console.log("selectedRoles", selectedRoles);
  }, [selectedRoles]);

  return (
    <section>
      <div className="border-b border-[#e6e8eb]">
        <div className="flex items-center px-6 py-4">
          <p className="text-[16px] font-semibold text-gray-900">Roles</p>
        </div>
      </div>
      <div className="px-6 py-4">
        <div className="space-y-2">
          <CheckboxGroup
            value={selectedRoles}
            onChange={setSelectedRoles}
            spacing={10}
            size="xs"
            classNames={{
              input: "checked:bg-jira-button checked:border-jira-button",
            }}
          >
            {roles?.map((role: any) => (
              <Checkbox
                value={role.id.toString()}
                label={
                  <div className="flex w-full items-center space-x-3 text-sm font-medium text-[#091e42] ">
                    <p>{role.name}</p>
                  </div>
                }
              />
            ))}
          </CheckboxGroup>
        </div>
      </div>
      <div className="border-t border-gray-200" />
      <div className="flex px-6 py-4">
        <div className="flex w-full items-center justify-end gap-2">
          <div className="flex items-center gap-2">
            <Button type="reset" className="bg-none font-medium text-slate-600 hover:bg-jira-button-subtle">
              Cancel
            </Button>
            <Button className="bg-jira-button hover:bg-jira-button-hover" type="submit">
              Save
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

const AdminOptions = ({ user }: { user: any }) => {
  const [canSetClaims, setCanSetClaims] = useState<boolean>(user?.can_set_claims);

  return (
    <section>
      <div className="border-b border-[#e6e8eb]">
        <div className="flex items-center px-6 py-4">
          <p className="text-[16px] font-semibold text-gray-900">Admin Options</p>
        </div>
      </div>
      <div className="px-6 py-4">
        <div className="space-y-2">
          <Checkbox
            checked={canSetClaims}
            onChange={(e) => setCanSetClaims(e.currentTarget.checked)}
            label="User can change claims"
            size="xs"
          />
        </div>
      </div>
      <div className="border-t border-gray-200" />
      <div className="flex px-6 py-4">
        <div className="flex w-full items-center justify-end gap-2">
          <div className="flex items-center gap-2">
            <Button type="reset" className="bg-none font-medium text-slate-600 hover:bg-jira-button-subtle">
              Cancel
            </Button>
            <Button className="bg-jira-button hover:bg-jira-button-hover" type="submit">
              Save
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};
