import React from 'react'
import { NavLink } from 'react-router-dom'
import { DocumentIcon, CalendarDaysIcon, FolderIcon } from '@heroicons/react/24/solid'
import { ReactComponent as MedicationIcon } from 'assets/healthIcons/medicines.svg'

type TSidebarNavLinkProps = {
  link: string
  icon?: React.ReactNode
  name: string
  isSection?: boolean
}

const sidebarIcons: any = {
  medication: <MedicationIcon className="h-6 w-6" />,
  recordings: <CalendarDaysIcon className="h-6 w-6" />,
  forms: <DocumentIcon className="h-6 w-6" />,
  files: <FolderIcon className="h-6 w-6" />,
}

export const SidebarNavLink = ({ link, icon, name, isSection }: TSidebarNavLinkProps) => {
  return (
    <NavLink
      key={link}
      to={`${link}`}
      className={({ isActive }: any) =>
        isActive
          ? 'duration-250 flex w-full min-w-[180px] flex-col rounded-md bg-neutral-200 bg-opacity-50 px-2 py-2 text-sm font-medium text-jira-button transition-all ease-in-out'
          : 'duration-250 flex w-full min-w-[180px] flex-col rounded-md px-2 py-2 text-sm font-medium text-neutral-800 transition-all ease-in-out hover:bg-neutral-100'
      }
    >
      <div className="flex items-center space-x-3">
        {isSection ? sidebarIcons[`${icon}`] : icon}
        <span className="text-[14px]">{name}</span>
      </div>
    </NavLink>
  )
}
