import { useState, useEffect } from "react";
import { DateTime } from "luxon";
import {
  TextInput,
  RadioGroup,
  Radio,
  NumberInput,
  Select,
  Textarea,
  Button,
} from "@mantine/core";
import { useDropzone } from "react-dropzone";
import Compressor from "compressorjs";

import Calendar from "components/Calendar/Calendar";
import { useMe } from "features/users/api/getMe";
import { useAuth } from "stores/Auth";
import { useCreateClaim } from "features/pay/api/createClaim";

export const CreateClaim = () => {
  const currentUser = useAuth((state) => state.currentUser);

  const [type, setType] = useState<string | undefined>(undefined);
  const [supplier, setSupplier] = useState<string | undefined>(undefined);
  const [amount, setAmount] = useState<number | undefined>(undefined);
  const [date, setDate] = useState();
  const [category, setCategory] = useState<string | null>(null);
  const [notes, setNotes] = useState<string | undefined>(undefined);

  const meQuery = useMe();

  const createClaimMutation = useCreateClaim(() =>
    console.log("useCreateClaim")
  );

  const postClaim = async () => {
    const payPerMile = 0.4;

    console.log("image", files[0]);
    await createClaimMutation.mutateAsync({
      data: {
        user_id: currentUser.id,
        date: date,
        type: "Expense",
        amount: amount,
        category: category,
        supplier: supplier,
        file: compressedFile,
      },
    });
  };

  const [files, setFiles] = useState<any>([]);

  const [compressedFile, setCompressedFile] = useState<any>(null);

  const handleCompressedUpload = () => {
    new Compressor(files[0], {
      quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
        setCompressedFile(compressedResult);
      },
    });
  };

  useEffect(() => {
    if (files.length > 0) {
      handleCompressedUpload();
    }
  }, [files]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    multiple: false,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file: any) => (
    <div key={file.name}>
      <div>
        <img
          src={file.preview}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      files.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <div className="flex flex-row space-x-4">
      <p onClick={postClaim}>Post claim</p>
      <div className="w-2/3 space-y-4 self-start rounded-md bg-white p-2">
        <div className="flex flex-col">
          <div>
            <RadioGroup
              orientation="horizontal"
              classNames={{
                label: "text-sm font-semibold text-gray-500",
                radio:
                  "text-yellow-500 checked:bg-jira-button checked:border-jira-button",
              }}
              size="sm"
              label="Claim Type"
              value={type}
              onChange={setType}
            >
              <Radio value="expense" label="Expense" />
              <Radio value="mileage" label="Mileage" />
            </RadioGroup>
          </div>
          <div>
            <TextInput
              label="Supplier"
              value={supplier}
              onChange={(e) => setSupplier(e.currentTarget.value)}
            />
          </div>
          <NumberInput
            label="Amount"
            value={amount}
            onChange={setAmount}
            hideControls
            precision={2}
          />
          <Calendar label="Date" />
        </div>
        <div>
          <Select
            label="Category"
            data={[
              { label: "Food", value: "food" },
              { label: "Office Equipment", value: "office equipment" },
            ]}
            value={category}
            onChange={setCategory}
          />
          <Textarea
            label="Notes"
            value={notes}
            onChange={(e) => setNotes(e.currentTarget.value)}
          />
        </div>
        <div className="flex space-x-2">
          <Button
            className="bg-jira-button hover:bg-jira-button-hover"
            onClick={postClaim}
          >
            Save Claim
          </Button>
          <Button
            variant="outline"
            size="xs"
            className="text-sm font-medium shadow"
            type="reset"
          >
            Cancel
          </Button>
        </div>
      </div>
      <section className="flex w-1/2 flex-col self-start rounded-md bg-white p-2">
        <aside className="w-full">{thumbs}</aside>
        <div
          {...getRootProps({ className: "dropzone" })}
          className=" mt-1 rounded-md border-2 border-dashed border-gray-300 p-2"
        >
          <input {...getInputProps()} />
          <p className="text-sm font-medium text-gray-500">
            Drag and drop a receipt here
          </p>
        </div>
      </section>
    </div>
  );
};
