import React from "react";
import { Controller, useForm } from "react-hook-form";
import z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Checkbox, NumberInput, TextInput } from "@mantine/core";
import { useNavigate } from "react-router-dom";

import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { useCreateRole } from "../api/createRole";
import { ColourPicker } from "components/ColorPicker/ColourPicker";
import { UsersSelect } from "components/UsersSelect/UsersSelect";
import { OnCallRole } from "../components/OnCallRole";
import { SleepRole } from "../components/SleepRole";

const crumbs = [
  { title: "Roles", href: "../" },
  { title: "Add Role", href: "#" },
];

export const CreateRole = () => {
  const navigate = useNavigate();

  const createRoleMutation = useCreateRole(() => navigate(-1));

  const validationSchema = z.object({
    name: z.string().min(1, "Area name is required"),
    defaultBreak: z.number().nullable(),
    colour: z.string().min(1, "Area colour is required"),
    payCode: z.string().nullable(),
    shouldShiftsClash: z.boolean(),
    isOnCallRole: z.boolean(),
    flatRateWeekday: z.number().optional(),
    flatRateWeekend: z.number().optional(),
    isSleepRole: z.boolean(),
    sleepFlatRateWeekday: z.number().optional(),
    sleepFlatRateWeekend: z.number().optional(),
    selectedUsers: z.any(),
  });

  const {
    control,
    handleSubmit,
    watch,
    unregister,
    register,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      shiftsShouldClash: false,
      isOnCallRole: false,
      isSleepRole: false,
    },
    resolver: zodResolver(validationSchema),
  });

  const [data, setData] = React.useState<any>(null);
  const isOnCallRole = watch("isOnCallRole");
  const isSleepRole = watch("isSleepRole");
  const selectedUsers = watch("selectedUsers");

  const onSubmit = (data: any) => {
    console.log("data", data);
    setData(data);
    createRoleMutation.mutate(data);
  };

  return (
    <div className="flex h-full min-h-0 w-full flex-col items-center overflow-y-auto bg-gray-50">
      <div className="flex h-full min-h-0 w-full flex-col">
        <div className=" relative top-0 right-0 flex h-full min-h-0">
          <div className="flex h-full min-h-0 w-full flex-col">
            <div className="flex max-h-9 items-center border-b border-gray-200 py-2 px-5">
              <Breadcrumbs items={crumbs} />
            </div>
            <div className="flex flex-col">
              <div className="flex justify-between px-6 pt-2 pb-4">
                <div className="w-full max-w-4xl rounded border border-gray-200 bg-white shadow">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="space-y-4 border-b border-gray-200 px-4 py-4">
                      <Controller
                        control={control}
                        name={`name`}
                        render={({ field }: any) => (
                          <TextInput
                            label="Name"
                            placeholder="Enter your name"
                            {...field}
                            error={errors.name?.message as string}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name={`defaultBreak`}
                        render={({ field }: any) => (
                          <NumberInput
                            label="Default break"
                            description="In minutes"
                            {...field}
                            error={errors.defaultBreak?.message as string}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name={`payCode`}
                        render={({ field }: any) => (
                          <TextInput label="Pay code" {...field} error={errors.payCode?.message as string} />
                        )}
                      />
                      <ColourPicker control={control} error={errors.colourPicker?.message as string} label="Colour" />
                      <Controller
                        control={control}
                        name={`shouldShiftsClash`}
                        render={({ field }: any) => (
                          <Checkbox
                            label="Should shifts clash"
                            {...field}
                            error={errors.shouldShiftsClash?.message as string}
                          />
                        )}
                      />
                      <OnCallRole
                        control={control}
                        errors={errors}
                        isOnCallRole={isOnCallRole}
                        unregister={unregister}
                        register={register}
                      />
                      <SleepRole
                        control={control}
                        errors={errors}
                        isSleepRole={isSleepRole}
                        unregister={unregister}
                        register={register}
                      />
                      <UsersSelect control={control} />
                    </div>
                    <div className="flex justify-end space-x-2 py-2 px-4">
                      <Button
                        size="xs"
                        compact
                        variant="outline"
                        className="text-xs font-medium shadow"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                      <Button
                        size="xs"
                        compact
                        className="rounded bg-jira-button py-1 px-2 hover:bg-jira-button-hover"
                        type="submit"
                        loading={createRoleMutation.isLoading}
                      >
                        Add Role
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
