import { useForm } from "react-hook-form";
import z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Route, Routes, NavLink } from "react-router-dom";

import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { SettingsGeneral } from "../components/SettingsGeneral";
import { SettingsMedication } from "../components/SettingsMedication";
import { useUpdateArea } from "../api/updateArea";
import { useNotification } from "hooks/useNotification";

const crumbs = [
  { title: "Area Dashboard", href: ".." },
  { title: "Settings", href: "#" },
];

export const Settings = ({ area }: any) => {
  const { addNotification } = useNotification();

  const updateAreaMutation = useUpdateArea();

  const validationSchema = z.object({
    name: z.string().min(1, "Area name is required"),
    avatar: z.string().nullish(),
    group: z.any(),
    sections: z.any(),
    selectedUsers: z.any(),
    backdate: z.any(),
    selectedBodymap: z.any(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      name: area.name,
      avatar: area.avatar_url,
      group: area.group_id,
      sections: area.sections,
      backdate: area.medication_records_backdate,
      selectedBodymap: area.selected_bodymap,
      selectedUsers: [],
    },
    resolver: zodResolver(validationSchema),
  });

  const onSubmit = async (data: any) => {
    try {
      await updateAreaMutation.mutateAsync({
        data: {
          ...data,
        },
        areaId: area.id,
      });
    } catch (error: any) {
      addNotification({
        disallowClose: false,
        type: "error",
        title: "Form not submitted",
        message: "There is an error in this form, check if you are missing any required fields and try again",
      });
    }
  };

  return (
    <div className="flex flex-1 flex-col overflow-hidden bg-[#f8f9fa]">
      <div className="flex max-h-12 items-center border-b border-gray-200 py-3 px-5">
        <Breadcrumbs items={crumbs} />
      </div>

      <div className="flex h-full min-h-0 w-full flex-col items-start overflow-y-auto p-4 pt-0">
        <div className="flex w-full flex-col">
          <div className="mt-4 flex flex-col space-y-4">
            <div>
              <p className="text-2xl font-semibold text-gray-900">Update area</p>
              <nav className="flex border-b border-gray-200">
                <NavLink
                  key="general"
                  to={`general`}
                  className={({ isActive }: any) =>
                    isActive
                      ? "duration-250 inline-flex h-full items-center justify-center border-b-2 border-[#11181c] py-2 px-3  text-sm font-medium text-[#11181c] transition-all ease-in-out"
                      : "duration-250 inline-flex h-full items-center justify-center rounded-md border-b-2 border-transparent py-2 px-3 text-sm font-medium text-[#889096] transition-all ease-in-out hover:text-[#11181c]"
                  }
                >
                  <p>General</p>
                </NavLink>
                <NavLink
                  key="medication"
                  to={`medication`}
                  className={({ isActive }: any) =>
                    isActive
                      ? "duration-250 inline-flex h-full items-center justify-center border-b-2 border-[#11181c] py-2 px-3  text-sm font-medium text-[#11181c] transition-all ease-in-out"
                      : "duration-250 inline-flex h-full items-center justify-center rounded-md border-b-2 border-transparent py-2 px-3 text-sm font-medium text-[#889096] transition-all ease-in-out hover:text-[#11181c]"
                  }
                >
                  <p>Medication</p>
                </NavLink>
              </nav>
            </div>

            <form className="flex max-w-4xl" onSubmit={handleSubmit(onSubmit)}>
              <Routes>
                <Route path="general" element={<SettingsGeneral control={control} errors={errors} area={area} />} />
                <Route path="medication" element={<SettingsMedication control={control} />} />
              </Routes>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
