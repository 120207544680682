import { Tooltip } from "@mantine/core";
import { DateTime } from "luxon";
import { MoonIcon } from "@heroicons/react/20/solid";
import { ChatBubbleLeftEllipsisIcon } from "@heroicons/react/24/outline";

import { roleColors } from "lib/rotacloudColors";
import { ShiftHours } from "./ShiftHours";
import { ShiftPay } from "./ShiftPay";

export const ShiftCell = ({ shift, rate }: any) => {
  return (
    <div className="grid h-10 grid-cols-8 items-center border-b border-neutral-300 bg-neutral-50 font-medium text-neutral-800">
      <div className="col-span-2 flex">
        <div className="w-1/2">
          <div className="flex items-center justify-start p-1">
            <div className="inline-flex whitespace-nowrap">
              <p className=" inline-flex w-full flex-shrink text-neutral-800">
                {DateTime.fromSeconds(Number(shift.start_time), {
                  zone: "Europe/London",
                }).toLocaleString({
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                })}
              </p>
            </div>
            <div className="ml-1 flex h-[1px] w-full border-b-2 border-dotted border-neutral-400" />
          </div>
        </div>
        <div className="w-1/2">
          <div className="p-1 text-neutral-800">
            {" "}
            {DateTime.fromSeconds(Number(shift.end_time), {
              zone: "Europe/London",
            }).toLocaleString({
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })}
          </div>
        </div>
      </div>
      <div className="col-span-1">
        <div className="flex items-center space-x-4 p-1">
          {shift.role && (
            <div className="flex min-w-[70px] space-x-1">
              <p
                className="rounded p-1 text-sm font-semibold"
                style={{
                  color: `${roleColors[shift.role.colour as keyof typeof roleColors]}`,
                  backgroundColor: `${roleColors[shift.role.colour as keyof typeof roleColors]}20`,
                }}
              >
                {shift.role.name}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="col-span-1">
        <div className="flex space-x-1 p-1">
          <p>{shift.minutes_break > 0 ? shift.minutes_break : "0:00"}</p>
          <span>hrs</span>
        </div>
      </div>
      <ShiftHours shift={shift} />
      <ShiftPay shift={shift} rate={rate} />
      {shift.notes ? (
        <Tooltip label={shift.notes} withArrow className="flex justify-center">
          <ChatBubbleLeftEllipsisIcon className="h-5 w-5 text-neutral-500" />
        </Tooltip>
      ) : (
        <div className="col-span-1" />
      )}
      <div className="col-span-1">
        {shift.sleep && (
          <div className="text-neutral-500">
            <MoonIcon className="h-4 w-4" />
          </div>
        )}
      </div>
    </div>
  );
};
