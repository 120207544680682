import { SegmentedControl } from "@mantine/core";
import { MapPinIcon, Square2StackIcon } from "@heroicons/react/24/outline";

import { BodypartType } from "./Bodypart";
import { memo } from "react";

export const ControlBar = memo(
  ({
    selectedBodypart,
    markerType,
    setMarkerType,
  }: {
    selectedBodypart: BodypartType | null;
    markerType?: "draw" | "place";
    setMarkerType?: any;
  }) => {
    return (
      <div className="flex w-full flex-row items-center justify-between space-y-0 px-4 py-1">
        {selectedBodypart?.name ? (
          <p className="text-lg font-medium">{selectedBodypart.name}</p>
        ) : (
          <p className="text-lg font-medium">None selected</p>
        )}
        <div className="flex items-center justify-between">
          <div>
            <SegmentedControl
              value={markerType}
              onChange={(e) => setMarkerType(e)}
              classNames={{
                labelActive: "text-blue-500 bg-blue-50",
                active: "bg-blue-50",
                root: "bg-white border border-gray-200",
              }}
              data={[
                {
                  value: "draw",
                  label: <Square2StackIcon className="h-6 w-6" />,
                },
                {
                  value: "place",
                  label: <MapPinIcon className="h-6 w-6" />,
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
);
