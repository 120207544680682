import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import { axios } from "lib/axios";
import { queryClient } from "lib/react-query";
import { useNotification } from "hooks/useNotification";
import { TCustomForm } from "../types";

export const createForm = ({ data }: { data: any }) => {
  console.log("createForm", data);
  return axios.post(`/form/${data.formId}`, data);
};

export const useCreateFormSubmission = () => {
  const { addNotification } = useNotification();
  const navigate = useNavigate();

  return useMutation({
    onMutate: async (newForm: any) => {
      await queryClient.cancelQueries("submitted_form");

      const previousForm = queryClient.getQueryData<any>("submitted_form");

      queryClient.setQueryData("submitted_form", [
        ...(previousForm || []),
        newForm.data,
      ]);

      return { previousForm };
    },
    onError: (_, __, context: any) => {
      if (context?.previousForm) {
        queryClient.setQueryData("submitted_form", context.previousForm);
      }

      addNotification({
        disallowClose: false,
        type: "error",
        title: "Could not create form!",
        message: "The form could not be created at this time, please try again",
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("submitted_form");
      addNotification({
        disallowClose: false,
        type: "success",
        title: "Form Created",
        message: "Form Created Successfully!",
      });
      //   navigate("/forms");
    },
    mutationFn: createForm,
  });
};
