import { useState, useEffect } from "react";
import { Route, Routes, NavLink, useLocation } from "react-router-dom";
import { DateTime } from "luxon";

import { Timesheet } from "components/Timesheet/Timesheet";
import { Claims } from "./Claims";
import { MonthRangePicker } from "components/MonthPicker/MonthPicker";
import { Button } from "@mantine/core";
import { BookmarkIcon } from "@heroicons/react/20/solid";
import { useMe } from "features/users/api/getMe";

export const PayRoutes = () => {
  const dateStart = DateTime.local(2023, 3, 15, { zone: "Europe/London" });
  const dateEnd = DateTime.local(2023, 4, 15, { zone: "Europe/London" });

  const [dateDiff, setDateDiff] = useState({
    start: dateStart.toUnixInteger(),
    end: dateEnd.toUnixInteger(),
  });

  const meQuery = useMe();

  const location = useLocation();

  const [selected, setSelected] = useState<string | null>(null);

  useEffect(() => {
    setSelected(location.pathname.split("/")[2]);
  }, [location]);

  const handleSubmit = async () => {
    if (location.pathname.split("/")[2] === "claims") {
      console.log("submitting claims");
    } else {
      console.log("submitting timesheet");
    }
  };

  return (
    <div className="flex h-full w-full flex-col overflow-hidden">
      <div className="flex border-b border-gray-200 px-6 py-2">
        <div className="flex flex-1 justify-between">
          <div className="flex items-center space-x-2">
            <div>
              <div className="relative h-[30px] w-48 rounded-md border border-gray-200 p-px">
                <span
                  className={`${
                    selected === "timesheet" && "translate-x-[97px] "
                  } z-0 inline-block h-full w-[90px] transform rounded border border-gray-300 shadow transition-all ease-in-out`}
                ></span>
                <NavLink
                  key="timesheet"
                  to={`timesheet/${meQuery?.data?.id}/${dateDiff.start}/${dateDiff.end}`}
                  className={({ isActive }: any) =>
                    isActive
                      ? "duration-250 absolute right-0 top-0 z-10 inline-flex h-full w-[90px] items-center justify-center rounded-md  text-sm font-semibold text-jira-button transition-all ease-in-out"
                      : "duration-250 absolute right-0 top-0 z-10 inline-flex h-full w-[90px] items-center justify-center rounded-md text-sm font-semibold text-gray-900 transition-all ease-in-out hover:text-jira-button-hover "
                  }
                >
                  <p>Timesheet</p>
                </NavLink>
                <NavLink
                  key="claims"
                  to={`claims/${meQuery?.data?.id}/${dateDiff.start}/${dateDiff.end}`}
                  className={({ isActive }: any) =>
                    isActive
                      ? "duration-250 absolute left-0 top-0 z-10 inline-flex h-full w-[90px] items-center justify-center rounded-md  text-sm font-semibold text-jira-button transition-all ease-in-out"
                      : "duration-250 absolute left-0 top-0 z-10 inline-flex h-full w-[90px] items-center justify-center rounded-md text-sm font-semibold text-gray-900 transition-all ease-in-out hover:text-jira-button-hover"
                  }
                >
                  <p>Claims</p>
                </NavLink>
              </div>
            </div>
            <div className="h-[24px] w-px border-r border-gray-200" />
            <MonthRangePicker setDateRange={setDateDiff} />
          </div>
        </div>
      </div>
      <Routes>
        <Route path="/timesheet/:id/:start/:end" element={<Timesheet />} />
        <Route path="/claims/:id/:start/:end/*" element={<Claims dateDiff={dateDiff} />} />
      </Routes>
    </div>
  );
};
