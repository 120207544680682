import React from "react";

export const BodymapList = ({
  marker,
  editBodymap,
  deleteItem,
  editItem,
}: any) => {
  return (
    <div
      className="flex max-h-[109px] cursor-pointer space-x-2 overflow-hidden rounded border-b border-neutral-200 p-1 hover:bg-neutral-50 active:bg-neutral-50"
      onClick={editItem}
    >
      <div className="flex h-[70px] w-[70px] items-center justify-center sm:h-[100px] sm:w-[100px]">
        <div className="grid h-full w-full grid-cols-1 grid-rows-1 overflow-hidden">
          <div className="z-50 col-start-1 row-start-1 h-full w-full">
            <svg
              className={`flex aspect-square h-full w-full touch-none`}
              // used to stop the dragging of a ghost image
              onDrag={(e) => {
                console.log("drag");
                e.preventDefault();
              }}
              onDragStart={(e) => {
                console.log("drag");
                e.preventDefault();
              }}
              viewBox="0 0 800 800"
            >
              <g className="cursor-grab active:cursor-grabbing">
                {marker.type === "rectangle" ? (
                  <rect
                    x={marker.x}
                    y={marker.y}
                    width={marker.width}
                    height={marker.height}
                    fill="#ef4444"
                    fillOpacity={0.4}
                    stroke="#ef4444"
                  />
                ) : (
                  <circle
                    cx={marker.x}
                    cy={marker.y}
                    r="20"
                    fill="#ef4444"
                    fillOpacity={0.4}
                    stroke="#ef4444"
                  />
                )}
              </g>
            </svg>
          </div>
          <div className="z-10 col-start-1 row-start-1 h-full w-full">
            <img
              src={marker.bodypart_image}
              alt="bodypart"
              className="h-full max-h-[800px] w-full max-w-[800px]"
            />
          </div>
        </div>
      </div>
      <div className="flex w-full flex-col space-y-1">
        <p className="text-sm text-neutral-900">{marker.bodypart_name}</p>
        {Object.keys(marker.notes).map((keyName, i) => (
          <div className="text-[13px] font-medium text-neutral-400" key={i}>
            <p className="capitalize text-neutral-600">{keyName}</p>

            <p className="overflow-hidden text-ellipsis">
              {marker.notes[keyName]}
            </p>
          </div>
        ))}
      </div>
      <button
        type="button"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          deleteItem();
        }}
      >
        Delete
      </button>
    </div>
  );
};
