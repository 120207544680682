import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { Indicator, Drawer, Skeleton } from "@mantine/core";
import { useUsers } from "features/users/api/getUsers";
import { NavLink } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import { isWeekend } from "utils/isWeekend";
import { DateTime } from "luxon";
import { BanknotesIcon } from "@heroicons/react/24/outline";
import { useSpring, animated } from "@react-spring/web";

import { useShifts } from "features/admin/payroll/timesheet/api/getShifts";
import { useUser } from "features/users/api/getUser";

const AnimatedNumber = ({ n }: any) => {
  const { number } = useSpring({
    from: { number: 0 },
    number: n,
    delay: 10,
  });

  return <animated.span>{number.to((n) => n.toFixed(2))}</animated.span>;
};

export const TimesheetSideMenu = ({ id, start, end, admin }: any) => {
  const shiftsQuery = useShifts({
    user_id: id,
    start: start,
    end: end,
  });

  const userQuery = useUser(id);

  if (!shiftsQuery.data || shiftsQuery.isLoading || userQuery.isLoading || !userQuery.data) return <SideMenuLoading />;

  function onCallShifts() {
    return shiftsQuery?.data?.filter((shift) => shift.role.is_on_call === true);
  }

  function getOnCallPay() {
    const shifts = onCallShifts();

    let pay = 0;

    // figure out if it is weekend or weekday using luxon and unix time
    // if it is weekend, add the weekend rate to the pay
    // if it is weekday, add the weekday rate to the pay

    shifts?.forEach((shift) => {
      const isWeekendShift = isWeekend(shift.start_time);

      if (isWeekendShift) {
        pay += shift.role.flat_rate_weekend;
      } else {
        pay += shift.role.flat_rate_weekday;
      }
    });

    return pay;
  }

  function getHoursPay() {
    const hourlyRate = userQuery?.data?.salary ? userQuery?.data?.salary : 0;

    let pay = 0;

    shiftsQuery?.data?.forEach((shift) => {
      const start = DateTime.fromSeconds(Number(shift.start_time), {
        zone: "Europe/London",
      });
      const end = DateTime.fromSeconds(Number(shift.end_time), {
        zone: "Europe/London",
      });
      let shiftDuration: any = end.diff(start, ["hours", "minutes"]).toObject();

      if (shift.sleep) {
        shiftDuration = {
          hours: shiftDuration.hours - 9,
          minutes: shiftDuration.minutes,
        };
      }
      console.log("shift duration:", shiftDuration, shift);

      if (shift.role.is_on_call) {
        shiftDuration = {
          hours: 0,
          minutes: 0,
        };
      } else if (shift.sleep) {
        pay += (shiftDuration.minutes * hourlyRate) / 60 + shiftDuration.hours * hourlyRate;
      } else {
        pay += (shiftDuration.minutes * hourlyRate) / 60 + shiftDuration.hours * hourlyRate;
      }
    });

    return pay;
  }

  function sleepShifts() {
    return shiftsQuery?.data?.filter((shift) => shift.sleep === true);
  }

  function getSleepPay() {
    const shifts = sleepShifts();

    let pay = 0;

    shifts?.forEach((shift) => {
      const isWeekendShift = isWeekend(shift.start_time);

      if (isWeekendShift) {
        pay += shift.role.sleep_flat_rate_weekend;
      } else {
        pay += shift.role.sleep_flat_rate_weekday;
      }
    });

    return pay;
  }

  function getPayTotal() {
    return getHoursPay() + getSleepPay() + getOnCallPay();
  }

  function getAllHours() {
    let regularHours = 0.0;
    let overtimeHours = 0;
    let trainingHours = 0;
    let holidayHours = 0;

    shiftsQuery?.data?.forEach((shift) => {
      const start = DateTime.fromSeconds(Number(shift.start_time), {
        zone: "Europe/London",
      });
      const end = DateTime.fromSeconds(Number(shift.end_time), {
        zone: "Europe/London",
      });
      let shiftDuration: any = end.diff(start, ["hours", "minutes"]).toObject();

      if (shift.sleep) {
        shiftDuration = {
          hours: shiftDuration.hours - 9,
          minutes: shiftDuration.minutes,
        };
      }

      if (shift.role.is_on_call) {
        shiftDuration = {
          hours: 0,
          minutes: 0,
        };
      }

      regularHours += shiftDuration.hours + shiftDuration.minutes / 60;
    });

    return regularHours;
  }

  return (
    <div className="w-[300px] border-r border-neutral-200 bg-neutral-50">
      <PayUserCard start={start} end={end} user={userQuery} />
      <div className="space-y-10 px-4 py-6">
        <div className="space-y-4">
          <div className="flex items-end space-x-1">
            <FontAwesomeIcon className=" h-4 w-4 text-neutral-700" icon={faClock} />
            <p className="inline-flex items-end text-[20px] font-bold leading-[19px] text-neutral-900">
              <AnimatedNumber n={getAllHours()} />
            </p>
            <p className="font-medium leading-[14px] text-neutral-700">Total hours</p>
          </div>
          <div className="flex items-end space-x-1">
            <BanknotesIcon className=" h-5 w-5 text-neutral-700" />
            <p className="inline-flex items-end text-[20px] font-bold leading-[19px] text-neutral-900">
              £<AnimatedNumber n={getPayTotal()} />
            </p>
            <p className="font-medium leading-[14px] text-neutral-700">Pay total</p>
          </div>
        </div>
        <div className="space-y-8">
          <div className="grid grid-cols-2 gap-y-4">
            <PaySubCard title="Regular" hours={getAllHours().toFixed(2)} withHours />
            <PaySubCard title="Overtime" withHours />
            <PaySubCard title="Training" withHours />
            <PaySubCard title="Holiday" withHours />
          </div>
          <div className="grid grid-cols-2 gap-y-4">
            <PaySubCard title="Sleeps" />
            <PaySubCard title="On-calls" />
          </div>
          <div className="grid grid-cols-2 gap-y-4">
            <PaySubCard title="Hours pay" hours={JSON.stringify(getHoursPay())} withPay />
            <PaySubCard title="Sleep pay" hours={JSON.stringify(getSleepPay())} withPay />
            <PaySubCard title="On-call pay" hours={JSON.stringify(getOnCallPay())} withPay />
            <PaySubCard title="Claims pay" hours={JSON.stringify(getOnCallPay())} withPay />
          </div>
        </div>
      </div>
    </div>
  );
};

const PayUserCard = ({ start, end, user }: any) => {
  const usersQuery = useUsers();

  const [opened, { open, close }] = useDisclosure(false);

  if (!user) {
    return null;
  }

  return (
    <div className="flex flex-col items-center space-y-4 border-b border-neutral-200 p-4">
      <Indicator
        inline
        label="Hourly"
        size={16}
        offset={2}
        position="bottom-center"
        classNames={{ indicator: "bg-jira-button" }}
      >
        <img
          src={`https://ui-avatars.com/api/?name=LB&rounded=true&background=fff&color=111827&bold=true&uppercase=true`}
          alt="avatar"
          className="h-20 w-20 rounded-full"
        />
      </Indicator>
      {false && (
        <Drawer opened={opened} onClose={close} withCloseButton={false} className="mt-[45px]">
          <div className="py-4 px-2">
            <p className="text-lg font-semibold text-neutral-900">Go to users timesheet</p>
            <div>
              {usersQuery.data.map((user: any) => (
                <NavLink
                  key={user.id}
                  to={`/admin/payroll/timesheet/${user.id}/${start}/${end}`}
                  className={({ isActive }: any) =>
                    isActive
                      ? "duration-250 relative flex w-full flex-col rounded-md py-2 text-sm font-semibold text-jira-button transition-all ease-in-out"
                      : "duration-250 relative flex w-full flex-col rounded-md py-2 text-sm font-semibold text-gray-900 transition-all ease-in-out hover:text-jira-button-hover"
                  }
                >
                  <div className="flex items-center space-x-3">
                    <span className="overflow-hidden text-ellipsis whitespace-nowrap text-[14px]">
                      {user.full_name}
                    </span>
                  </div>
                </NavLink>
              ))}
            </div>
          </div>
        </Drawer>
      )}
      <div className="cursor-pointer text-center" onClick={open}>
        <p className="text-lg font-semibold text-neutral-900">{user.data.full_name}</p>
        <p className="font-medium text-jira-button">{user.data.job_title}</p>
      </div>
    </div>
  );
};

const PaySubCard = ({
  title,
  hours,
  withPay,
  withHours,
}: {
  title: string;
  hours?: number | string;
  withPay?: boolean;
  withHours?: boolean;
}) => {
  return (
    <div>
      <div className="my-[1px] w-8 rounded-full border-t-2 border-b-2 border-jira-button" />
      <div className="flex items-end space-x-1">
        <p className="inline-flex items-end text-[16px] font-semibold text-neutral-900">
          <span>{withPay ? "£" : null}</span>
          {hours ? <AnimatedNumber n={Number(hours)} /> : "00.00"}
        </p>
        <span className="font-semibold text-neutral-900">{withHours ? "hrs" : null}</span>
      </div>
      <p className="font-medium leading-[14px] text-neutral-500">{title}</p>
    </div>
  );
};

const SideMenuLoading = () => {
  return (
    <div className="w-[300px] border-r border-neutral-200 bg-neutral-100">
      <div className="flex flex-col items-center space-y-4 border-b border-neutral-200 p-4">
        <Indicator
          inline
          label=""
          size={16}
          offset={2}
          position="bottom-center"
          classNames={{ indicator: "bg-jira-button" }}
        >
          <Skeleton width="80px" height="80px" radius={100} />
        </Indicator>
        <div className="flex cursor-pointer flex-col items-center space-y-1 text-center">
          <Skeleton width="120px" height="25px" />
          <Skeleton width="180px" height="18px" />
        </div>
      </div>
      <div className="space-y-10 px-4 py-6">
        <div className="space-y-4">
          <div className="flex items-end space-x-1">
            <FontAwesomeIcon className=" h-4 w-4 text-neutral-700" icon={faClock} />
            <p className="inline-flex items-end text-[20px] font-bold leading-[19px] text-neutral-900">0.00</p>
            <p className="font-medium leading-[14px] text-neutral-700">Total hours</p>
          </div>
          <div className="flex items-end space-x-1">
            <BanknotesIcon className=" h-5 w-5 text-neutral-700" />
            <p className="inline-flex items-end text-[20px] font-bold leading-[19px] text-neutral-900">£00.00</p>
            <p className="font-medium leading-[14px] text-neutral-700">Pay total</p>
          </div>
        </div>
        <div className="space-y-8">
          <div className="grid grid-cols-2 gap-y-4">
            <PaySubCard title="Regular" withHours />
            <PaySubCard title="Overtime" withHours />
            <PaySubCard title="Training" withHours />
            <PaySubCard title="Holiday" withHours />
          </div>
          <div className="grid grid-cols-2 gap-y-4">
            <PaySubCard title="Sleeps" />
            <PaySubCard title="On-calls" />
          </div>
          <div className="grid grid-cols-2 gap-y-4">
            <PaySubCard title="Hours pay" withPay />
            <PaySubCard title="Sleep pay" withPay />
            <PaySubCard title="On-call pay" withPay />
            <PaySubCard title="Claims pay" withPay />
          </div>
        </div>
      </div>
    </div>
  );
};
