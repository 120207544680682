import React from "react";
import { useParams } from "react-router-dom";
import { useForm } from "../api/getForm";
import { axios } from "lib/axios";

export const Response = () => {
  const { areaId, formId, responseId } = useParams();
  const formQuery = useForm({ formId });

  const response = formQuery?.data?.responses?.find((response: any) => response.id === responseId);

  const createForm = async ({ data }: { data: any }) => {
    try {
      const response = await axios.post("/test", data, {
        headers: {
          "Content-Type": "application/json",
        },
        responseType: "blob",
      });

      // Create a temporary URL for the blob
      const url = URL.createObjectURL(response.data);

      // Open the URL in a new tab or initiate a download
      window.open(url);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <p
        onClick={() =>
          createForm({
            data: {
              ...response,
              form_title: formQuery?.data?.title,
              form_description: formQuery?.data?.description,
            },
          })
        }
      >
        Response
      </p>
      {/* {response &&
        response?.results?.map((result: any, index: any) => (
          <div>
            <p className="text-[15px] font-medium text-gray-900">
              {index + 1}.<span> </span>
              {result.label}
            </p>
            <p className="text-sm font-medium text-gray-500">{result.answer}</p>
          </div>
        ))} */}
    </div>
  );
};
