import { useMutation } from "react-query";

import { axios } from "lib/axios";
import { useNotification } from "hooks/useNotification";
import { queryClient } from "lib/react-query";
import { Medication } from "../types";

export type UpdateMedicationType = {
  data: {
    name: string;
    type: string;
    is_discontinued: boolean;
    dose: string;
    administered_dose: string;
    used_for: string;
    side_effects: string;
    route: string;
    authorisation: string;
    frequency: string;
    end_date: string;
    start_date: number;
    medication_markers: any;
  };
  medicationId: number;
};

export const updateMedication = ({ data, medicationId }: any) => {
  console.log("update medication data: ", data, medicationId);
  return axios.put(`/medications/${data.id}`, data);
};

export const useUpdateMedication = (successFunction?: Function) => {
  const { addNotification } = useNotification();

  return useMutation({
    onMutate: async (updatingMedication: any) => {
      console.log("error: ", updatingMedication);

      await queryClient.cancelQueries(["medication", updatingMedication?.medicationId]);

      const previousMedication = queryClient.getQueryData<Medication>(["medication", updatingMedication?.medicationId]);

      queryClient.setQueryData(["medication", updatingMedication?.medicationId], {
        ...previousMedication,
        ...updatingMedication.data,
        id: updatingMedication.medicationId,
      });

      return { previousMedication };
    },
    onError: (_, __, context: any) => {
      console.log("hello", context);
      if (context?.previousMedication) {
        queryClient.setQueryData(["medication", context.previousMedication.id], context.previousMedication);
      }
      addNotification({
        disallowClose: true,
        type: "error",
        title: "Medication Not Updated",
        message: "Medication Updated Failed!",
      });
    },
    onSuccess: (data: any) => {
      queryClient.refetchQueries(["medication", data.id]);
      queryClient.invalidateQueries("medications");
      successFunction && successFunction();
      addNotification({
        disallowClose: true,
        type: "success",
        title: "Medications Updated",
        message: "Medications Updated Successfully!",
      });
    },
    mutationFn: updateMedication,
  });
};
