import { DateTime } from "luxon";

type TCalculateHoursPaidReturn = {
  hours: number;
  minutes: number;
  seconds: number;
};

type TCalculateHoursPaidProps = {
  role: any;
  timeFrom: DateTime;
  timeTo: DateTime;
};

export const calculateHoursPaid = ({ role, timeFrom, timeTo }: TCalculateHoursPaidProps): TCalculateHoursPaidReturn => {
  // import this from company payroll settings
  const sleepDeductionHours = 9;

  // first, check if the shift is an on-call and return 0:00 hours paid
  if (!role || role.is_on_call) {
    return {
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  const difference = timeTo.diff(timeFrom, ["hours", "minutes", "seconds"]).toObject();

  if (role.is_sleep) {
    // avoid returning negative hours/minutes
    if (((difference.hours as number) - sleepDeductionHours || (difference.minutes as number)) < 0) {
      return {
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    return {
      hours: (difference.hours as number) - sleepDeductionHours,
      minutes: difference.minutes as number,
      seconds: difference.seconds as number,
    };
  }

  // avoid returning negative hours/minutes
  if (((difference.hours as number) || (difference.minutes as number)) < 0) {
    return {
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  return {
    hours: difference.hours as number,
    minutes: difference.minutes as number,
    seconds: difference.seconds as number,
  };
};
