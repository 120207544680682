import React from "react";
import { Link } from "react-router-dom";
import { ArrowLongLeftIcon } from "@heroicons/react/24/outline";

export const Breadcrumbs = ({ items }: any) => {
  return (
    <div className="-ml-2 flex cursor-pointer items-center space-x-[2px] text-sm">
      {items.map((item: any, index: any) => {
        if (index === 0 && items.length <= 1) {
          return (
            <p
              key={index}
              className="flex items-center rounded-md bg-transparent px-2 py-[2px] font-medium text-gray-900"
            >
              <span>{item.title}</span>
            </p>
          );
        } else if (index === 0) {
          return (
            <div className="inline-flex items-center space-x-[2px]">
              <Link
                to={item.href}
                key={index}
                className="flex items-center space-x-2 rounded-md bg-transparent px-2 py-[2px] font-medium text-slate-500 transition-all duration-200 hover:bg-gray-200"
              >
                <span>{item.title}</span>
              </Link>
              <span className="text-gray-300">/</span>
            </div>
          );
        } else if (index !== items.length - 1) {
          return (
            <div className="inline-flex items-center space-x-[2px]">
              <Link
                to={item.href}
                key={index}
                className="rounded-md bg-transparent px-2 py-[2px] font-medium text-slate-500 transition-all duration-200 hover:bg-gray-200"
              >
                <span>{item.title}</span>
              </Link>
              <span className="text-gray-300">/</span>
            </div>
          );
        } else {
          return (
            <p key={index} className="rounded-md bg-transparent px-2 py-[2px] font-medium text-gray-900">
              {item.title}
            </p>
          );
        }
      })}
    </div>
  );
};
