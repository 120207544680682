import React from "react";

export const Views = ({
  children,
  activeView,
  prevView,
  nextView,
}: {
  children: any;
  activeView: number;
  prevView: Function;
  nextView: Function;
}) => {
  return <>{children[activeView]}</>;
};
