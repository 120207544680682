import { TShift } from "features/pay/types";
import { DateTime } from "luxon";
import { isWeekend } from "utils/isWeekend";

export const calculateShiftData = (shifts: TShift[], user: any, dateDiff: any) => {
  console.log("calculateShiftData", shifts);

  // import this from company payroll settings
  const sleepDeductionHours = 9;

  let hours = 0;
  let hoursPay = 0;
  let onCallPay = 0;
  let sleepPay = 0;
  let totalPay = 0;
  let sleeps = 0;
  let onCalls = 0;

  shifts.forEach((shift) => {
    let shiftDifference: any = DateTime.fromSeconds(shift.end_time)
      .diff(DateTime.fromSeconds(shift.start_time), ["hours", "minutes", "seconds"])
      .toObject();

    if (shift.sleep) {
      sleeps += 1;

      shiftDifference = {
        hours: shiftDifference.hours - sleepDeductionHours,
        minutes: 0,
        seconds: 0,
      };

      if (isWeekend(shift.start_time)) {
        sleepPay += shift.role.sleep_flat_rate_weekend;
      } else {
        sleepPay += shift.role.sleep_flat_rate_weekday;
      }
    }

    if (shift.role.is_on_call) {
      onCalls += 1;

      shiftDifference = {
        hours: 0,
        minutes: 0,
        seconds: 0,
      };

      if (isWeekend(shift.start_time)) {
        onCallPay += shift.role.flat_rate_weekend;
      } else {
        onCallPay += shift.role.flat_rate_weekday;
      }
    }

    hours += shiftDifference.hours + shiftDifference.minutes / 60 + shiftDifference.seconds / 3600;
    hoursPay = hours * user.salary;
    totalPay = hoursPay + onCallPay + sleepPay;
  });
  console.log(
    "shiftDifference hours",
    hours,
    "£",
    hoursPay,
    onCallPay,
    sleepPay,
    totalPay,
    dateDiff,
    onCalls,
    sleeps,
    user.id
  );

  return {
    user_id: user.id,
    submitted: true,
    date_from: dateDiff.start,
    date_to: dateDiff.end,
    total_hours: hours,
    base_hours: hours,
    base_rate: user.salary,
    overtime_hours: 0,
    overtime_rate: user.salary,
    training_hours: 0,
    training_rate: user.salary,
    leave_hours: 0,
    leave_rate: user.salary,
    sleep_amount: sleeps,
    sleep_rate: 80,
    adjustment: null,
    adjustment_notes: null,
    on_call_amount: onCallPay,
    claims_amount: 0,
    time_half_hours: 0,
    time_half_rate: 0,
    salaried: false,
    salary: null,
  };
};
