import { Drawer, Button, ActionIcon, LoadingOverlay } from "@mantine/core";
import { useState } from "react";
import { NoSymbolIcon } from "@heroicons/react/24/solid";

import { useUserStore } from "stores/userStore";
import { useDisableUsers } from "../api/disableUsers";

export const DisableUsers = () => {
  const [open, setOpen] = useState(false);

  const closeModal = () => {
    setOpen(false);
  };

  const disableUsersMutation = useDisableUsers(closeModal);

  const disableCheckedList = useUserStore((state) => state.disableCheckedList);

  return (
    <>
      <div className="flex">
        <Button
          className={`inline-flex h-[32px] items-center rounded bg-[#f4f5f7] px-3 text-[#42526e] transition-all duration-100 ease-in-out hover:bg-[#ebecf0]`}
          onClick={() => {
            disableCheckedList.length > 0 && setOpen(true);
          }}
          disabled={disableCheckedList.length <= 0}
          classNames={{
            label: "text-[15px] font-semibold",
          }}
        >
          Disable user
        </Button>
      </div>

      <Drawer
        opened={open}
        onClose={() => setOpen(false)}
        position="right"
        withCloseButton={false}
        overlayOpacity={0.25}
        className=" top-14"
        noFocusTrap
        size="25%"
      >
        <div className="flex min-h-full max-w-full flex-col justify-between space-y-2 py-6 px-10">
          <div className="flex flex-grow flex-col justify-start">
            {disableCheckedList.length > 1 ? (
              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                  console.log("submitting form", disableCheckedList);
                  await disableUsersMutation.mutateAsync({
                    data: disableCheckedList,
                  });
                  console.log("hello");
                  console.log(
                    "mutation values",
                    await disableUsersMutation.data
                  );
                }}
                className="flex flex-grow flex-col justify-between"
              >
                <LoadingOverlay visible={disableUsersMutation.isLoading} />
                <div className="space-y-4">
                  <p className="text-xl font-semibold">Disable Users?</p>
                  <p>{disableCheckedList.length} users selected</p>
                  <p>
                    This will disable the users this does not delete any of
                    their information, but it stops them from being able to sign
                    in to their account. This process can be reversed by
                    reinstating their account from the 'Disabled Users' list
                  </p>
                </div>
                <div className="mt-6 space-x-6">
                  <Button type="submit" className="bg-red-500 hover:bg-red-600">
                    Disable Users
                  </Button>
                  <Button
                    type="reset"
                    className="bg-gray-50 text-slate-600 hover:bg-gray-100"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            ) : (
              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                  console.log("submitting form", disableCheckedList);
                  await disableUsersMutation.mutateAsync({
                    data: disableCheckedList,
                  });
                  console.log("hello");
                  console.log(
                    "mutation values",
                    await disableUsersMutation.data
                  );
                }}
                className="flex flex-grow flex-col justify-between"
              >
                <LoadingOverlay visible={disableUsersMutation.isLoading} />
                <div className="space-y-4">
                  <p className="text-xl font-semibold">Disable User?</p>
                  <p>
                    This will disable the selected user. This does not delete
                    any of their information, but it stops them from being able
                    to sing in to their account. This process can be reversed by
                    reinstating their account from the 'Disabled Users' list
                  </p>
                </div>
                <div className="mt-6 space-x-6">
                  <Button
                    type="submit"
                    className="bg-[#de350b] hover:bg-[#ff5630]"
                  >
                    Disable User
                  </Button>
                  <Button
                    type="reset"
                    className="bg-gray-50 text-slate-600 hover:bg-gray-100"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            )}
          </div>
        </div>
      </Drawer>
    </>
  );
};

// export const DisableUsers = () => {
//     const [open, setOpen] = useState(false);

//     const disableUsersMutation = useDisableUsers();

//     const checkedList = useUserListStore((state) => state.checkedList);

//     return (
//         <>
//             <div className='flex space-x-2'>
//                 <ActionIcon
//                     className={`${checkedList.length > 0 ? 'bg-red-50 hover:bg-red-100 text-red-500 hover:text-red-600' : 'hover:bg-white text-gray-400'}`}
//                     onClick={() => {checkedList.length > 0 && setOpen(true)}}
//                 >
//                     <BanIcon />
//                 </ActionIcon>
//             </div>

//             <Drawer
//                 opened={open}
//                 onClose={() => setOpen(false)}
//                 position="right"
//                 withCloseButton={false}
//                 overlayOpacity={0.25}
//                 className=' top-14'
//                 noFocusTrap
//                 size="25%"
//             >
//                 <div className="flex max-w-full min-h-full flex-col space-y-2 py-6 px-10 justify-between">
//                     <div className='flex justify-start flex-col flex-grow'>
//                         {checkedList.length > 1 ?
//                             <form
//                                 onSubmit={async (e) => {
//                                     e.preventDefault();
//                                     console.log('submitting form', checkedList);
//                                     await disableUsersMutation.mutateAsync({ data: checkedList });
//                                     console.log('hello');
//                                     console.log('mutation values', await disableUsersMutation.data);
//                                 }}
//                                 className="flex flex-col flex-grow justify-between"
//                             >

//                                 <div className='space-y-4'>
//                                     <p className='text-xl font-semibold'>Disable Users?</p>
//                                     <p>{checkedList.length} users selected</p>
//                                     <p>This will disable the users this does not delete any of their information, but it stops them from being able to sign in to their account. This process can be reversed by reinstating their account from the 'Disabled Users' list</p>
//                                 </div>
//                                 <div className='mt-6 space-x-6'>
//                                     <Button
//                                         type='submit'
//                                         className="bg-red-500 hover:bg-red-600"
//                                     >
//                                         Disable Users
//                                     </Button>
//                                     <Button
//                                         type="reset"
//                                         className="bg-gray-50 hover:bg-gray-100 text-slate-600"
//                                         onClick={() => setOpen(false)}
//                                     >
//                                         Cancel
//                                     </Button>
//                                 </div>
//                             </form>
//                             :
//                             <form
//                                 onSubmit={async (e) => {
//                                     e.preventDefault();
//                                     console.log('submitting form', checkedList);
//                                     await disableUsersMutation.mutateAsync({ data: checkedList });
//                                     console.log('hello');
//                                     console.log('mutation values', await disableUsersMutation.data);
//                                 }}
//                                 className="flex flex-col flex-grow justify-between"
//                             >

//                                 <div className='space-y-4'>
//                                     <p className='text-xl font-semibold'>Disable User?</p>
//                                     <p>This will disable the selected user. This does not delete any of their information, but it stops them from being able to sing in to their account. This process can be reversed by reinstating their account from the 'Disabled Users' list</p>
//                                 </div>
//                                 <div className='mt-6 space-x-6'>
//                                     <Button
//                                         type='submit'
//                                         className="bg-red-500 hover:bg-red-600"
//                                     >
//                                         Disable User
//                                     </Button>
//                                     <Button
//                                         type="reset"
//                                         className="bg-gray-50 hover:bg-gray-100 text-slate-600"
//                                         onClick={() => setOpen(false)}
//                                     >
//                                         Cancel
//                                     </Button>
//                                 </div>
//                             </form>
//                         }
//                     </div>
//                 </div>
//             </Drawer>
//         </>
//     );
// };
