import { Controller } from "react-hook-form";
import { TextInput, Button } from "@mantine/core";

export const UserInformation = ({ control }: { control: any }) => {
  return (
    <section>
      <div className="border-b border-[#e6e8eb]">
        <div className="flex items-center px-6 py-4">
          <p className="text-[16px] font-semibold text-gray-900">My Information</p>
        </div>
      </div>
      <div className="px-6 py-4">
        <div className="space-y-2">
          <Controller
            control={control}
            name={`name`}
            render={({ field: { onChange, value } }: any) => (
              <TextInput label="Name" value={value} onChange={onChange} />
            )}
          />
          <Controller
            control={control}
            name={`email`}
            render={({ field: { onChange, value } }: any) => <TextInput label="Email" value={value} disabled />}
          />
        </div>
      </div>
      <div className="border-t border-gray-200" />
      <div className="flex px-6 py-4">
        <div className="flex w-full items-center justify-end gap-2">
          <div className="flex items-center gap-2">
            <Button type="reset" className="bg-none font-medium text-slate-600 hover:bg-jira-button-subtle">
              Cancel
            </Button>
            <Button className="bg-jira-button hover:bg-jira-button-hover" type="submit">
              Save
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};
