import { Route, Routes } from "react-router-dom";

import { Roles } from "./Roles";
import { CreateRole } from "./CreateRole";
import { UpdateRole } from "./UpdateRole";

export const RolesRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Roles />} />
      <Route path="/add" element={<CreateRole />} />
      <Route path="/:roleId" element={<UpdateRole />} />
    </Routes>
  );
};
